// // import React, { useState } from 'react';
// // import './SimSelection.css';
// // import PhoneNumbersList from './PhoneNumbersList';
// // import PhoneInput from 'react-phone-input-2';
// // import 'react-phone-input-2/lib/style.css';

// // const SimSelection = ({
// //     hasSim, setHasSim, organizationPhoneNumber, setOrganizationPhoneNumber,
// //     isPhoneNumberSelected, setIsPhoneNumberSelected, nextStep, prevStep,
// //     setForwardingPhoneNumber,forwardingPhoneNumber
// // }) => {
// //     const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
// //     const [callForwarding, setCallForwarding] = useState(false);

// //     const handlePhoneNumberChange = (phone) => {
// //         setOrganizationPhoneNumber(phone);
// //         setIsValidPhoneNumber(phone ? true : false);
// //     };

    
// //     const handleForwardingNumberChange = (phone) => {
// //         setForwardingPhoneNumber(phone);
// //         // Add validation for forwarding phone number if necessary
// //     };

// //     const handleCallForwardingChange = (event) => {
// //         setCallForwarding(event.target.value === 'yes');
// //     };

// //     const handlePhoneNumberPurchase = (number) => {
// //         setOrganizationPhoneNumber(number);
// //         setIsPhoneNumberSelected(true);
// //     };

// //     const handleNextClick = () => {
// //         if (hasSim && isValidPhoneNumber || (isPhoneNumberSelected && (!callForwarding || (callForwarding && forwardingPhoneNumber)))) {
// //             nextStep();
// //         }
// //     };

// //     return (
// //         <div className="sim-selection-container" dir="rtl">
// //             <h2>בחר SIM</h2>

// //             <div className="sim-selection-buttons">
// //                 <button className="sim-selection-button" onClick={() => setHasSim(true)}>יש לי SIM משלי</button>
// //                 <button className="sim-selection-button" onClick={() => setHasSim(false)}>אני רוצה לרכוש</button>
// //             </div>

// //             {hasSim ? (
// //                 <div className="sim-selection-phone-number-input">
// //                     <h4>אנא הזן את מספר הטלפון שלך</h4>
// //                     <PhoneInput
// //                         country={'il'}
// //                         value={organizationPhoneNumber}
// //                         onChange={handlePhoneNumberChange}
// //                         inputProps={{
// //                             name: 'phoneNumber',
// //                             required: true,
// //                             placeholder: " הזן את מספר הטלפון שלך (+972559776699)",
// //                         }}
// //                         enableSearch
// //                         disableDropdown={false}
// //                         containerClass="sim-selection-phone-number-input-container"
// //                         inputClass="sim-selection-phone-number-input-field"
// //                     />
// //                     {!isValidPhoneNumber && <p className="sim-selection-error-message">פורמט מספר טלפון לא חוקי. אנא השתמש בפורמט הנכון (למשל +972559776699).</p>}
// //                 </div>
// //             ) : (
// //                 <>
// //                     <div className="sim-selection-phone-number-list">
// //                         <PhoneNumbersList onPhoneNumberSelect={handlePhoneNumberPurchase} />
// //                     </div>

// // {isPhoneNumberSelected && (
// //     <div>
// //         <h4>האם תרצה הפניית שיחות שיכנסו למספר זה?</h4>
// //         <select onChange={handleCallForwardingChange}>
// //             <option value="no">לא</option>
// //             <option value="yes">כן</option>
// //         </select>
// //         {callForwarding && (
// //             <div className="forwarding-number-input">
// //                 <h4>הצג מספר טלפון אליו יועברו השיחות</h4>
// //                 <PhoneInput
// //                     country={'il'}
// //                     value={forwardingPhoneNumber}
// //                     onChange={handleForwardingNumberChange}
// //                     inputProps={{
// //                         name: 'forwardingPhoneNumber',
// //                         required: true,
// //                         placeholder: "הזן את מספר הטלפון להעברה (+972559776699)",
// //                     }}
// //                     containerClass="forwarding-number-input-container"  // Unique class for BasicInfo
                    
// //                     enableSearch
// //                     disableDropdown={false}
// //                 />
// //             </div>
// //         )}
// //     </div>
// // )}
// //                 </>
// //             )}

// //             <div className="sim-selection-button-container">
// //                 <button
// //                     className="sim-selection-prev-button"
// //                     onClick={prevStep}
// //                 >
// //                     הקודם
// //                 </button>
// //                 <button
// //                     className="sim-selection-next-button"
// //                     onClick={handleNextClick}
// //                     disabled={hasSim ? !isValidPhoneNumber : (!isPhoneNumberSelected || (callForwarding && !forwardingPhoneNumber))}
// //                 >
// //                     הבא
// //                 </button>
// //             </div>  
// //         </div>
// //     );
// // };

// // export default SimSelection;


// import React, { useState } from 'react';
// import './SimSelection.css';
// import PhoneNumbersList from './PhoneNumbersList';
// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';

// const SimSelection = ({
//     hasSim, setHasSim, organizationPhoneNumber, setOrganizationPhoneNumber,
//     isPhoneNumberSelected, setIsPhoneNumberSelected, nextStep, prevStep,
//     setForwardingPhoneNumber, forwardingPhoneNumber
// }) => {
//     const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
//     const [callForwarding, setCallForwarding] = useState(false);

//     const handlePhoneNumberChange = (phone) => {
//         setOrganizationPhoneNumber(phone);
//         setIsValidPhoneNumber(phone ? true : false);
//     };

//     const handleForwardingNumberChange = (phone) => {
//         setForwardingPhoneNumber(phone);
//     };

//     const handleCallForwardingChange = (event) => {
//         setCallForwarding(event.target.value === 'yes');
//     };

//     const handlePhoneNumberPurchase = (number) => {
//         setOrganizationPhoneNumber(number);
//         setIsPhoneNumberSelected(true);
//     };

//     const handleNextClick = () => {
//         if (hasSim && isValidPhoneNumber || (isPhoneNumberSelected && (!callForwarding || (callForwarding && forwardingPhoneNumber)))) {
//             nextStep();
//         }
//     };

//     return (
//         <div className="sim-selection-container" dir="rtl">
//             <h2>בחר SIM</h2>

//             {/* כפתורים ראשוניים */}
//             <div className="sim-selection-buttons">
//                 <button className="sim-selection-button" onClick={() => setHasSim(true)}>יש לי SIM משלי</button>
//                 <button className="sim-selection-button" onClick={() => setHasSim(false)}>אני רוצה לרכוש</button>
//             </div>

//             {/* הצגת החלקים הרלוונטיים לפי הבחירה */}
//             {hasSim !== null && (
//                 <div className="sim-selection-content">
//                     {hasSim ? (
//                         <div className="sim-selection-phone-number-input">
//                             <h4>אנא הזן את מספר הטלפון שלך</h4>
//                             <PhoneInput
//                                 country={'il'}
//                                 value={organizationPhoneNumber}
//                                 onChange={handlePhoneNumberChange}
//                                 inputProps={{
//                                     name: 'phoneNumber',
//                                     required: true,
//                                     placeholder: "הזן את מספר הטלפון שלך (+972559776699)",
//                                 }}
//                                 enableSearch
//                                 disableDropdown={false}
//                                 containerClass="sim-selection-phone-number-input-container"
//                                 inputClass="sim-selection-phone-number-input-field"
//                             />
//                             {!isValidPhoneNumber && (
//                                 <p className="sim-selection-error-message">
//                                     פורמט מספר טלפון לא חוקי. אנא השתמש בפורמט הנכון (למשל +972559776699).
//                                 </p>
//                             )}
//                         </div>
//                     ) : (
//                         <>
//                             {/* הצגת רשימת מספרי טלפון לרכישה */}
//                             <div className="sim-selection-phone-number-list">
//                                 <PhoneNumbersList onPhoneNumberSelect={handlePhoneNumberPurchase} />
//                             </div>

//                             {isPhoneNumberSelected && (
//                                 <div>
//                                     <h4>האם תרצה הפניית שיחות שיכנסו למספר זה?</h4>
//                                     <select onChange={handleCallForwardingChange}>
//                                         <option value="no">לא</option>
//                                         <option value="yes">כן</option>
//                                     </select>
//                                     {callForwarding && (
//                                         <div className="forwarding-number-input">
//                                             <h4>הזן מספר טלפון אליו יועברו השיחות</h4>
//                                             <PhoneInput
//                                                 country={'il'}
//                                                 value={forwardingPhoneNumber}
//                                                 onChange={handleForwardingNumberChange}
//                                                 inputProps={{
//                                                     name: 'forwardingPhoneNumber',
//                                                     required: true,
//                                                     placeholder: "הזן את מספר הטלפון להעברה (+972559776699)",
//                                                 }}
//                                                 containerClass="forwarding-number-input-container"
//                                                 enableSearch
//                                                 disableDropdown={false}
//                                             />
//                                         </div>
//                                     )}
//                                 </div>
//                             )}
//                         </>
//                     )}

//                     {/* כפתורי ניווט */}
//                     <div className="sim-selection-button-container">
//                         <button className="sim-selection-prev-button" onClick={prevStep}>
//                             הקודם
//                         </button>
//                         <button
//                             className="sim-selection-next-button"
//                             onClick={handleNextClick}
//                             disabled={hasSim ? !isValidPhoneNumber : (!isPhoneNumberSelected || (callForwarding && !forwardingPhoneNumber))}
//                         >
//                             הבא
//                         </button>
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default SimSelection;

import React, { useState } from 'react';
import './SimSelection.css';
import PhoneNumbersList from './PhoneNumbersList';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { GrPowerCycle } from "react-icons/gr";

const SimSelection = ({
    hasSim, setHasSim, organizationPhoneNumber, setOrganizationPhoneNumber,
    isPhoneNumberSelected, setIsPhoneNumberSelected, nextStep, prevStep,
    setForwardingPhoneNumber, forwardingPhoneNumber
}) => {
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
    const [callForwarding, setCallForwarding] = useState(false);
    const [showPhoneNumbersList, setShowPhoneNumbersList] = useState(false);
    const [showSimSelectionButtons, setShowSimSelectionButtons] = useState(true); // שליטה על הצגת הכפתורים

    const handlePhoneNumberChange = (phone) => {
        setOrganizationPhoneNumber(phone);
        setIsValidPhoneNumber(phone ? true : false);
    };

    const handleForwardingNumberChange = (phone) => {
        setForwardingPhoneNumber(phone);
    };

    const handleCallForwardingChange = (event) => {
        setCallForwarding(event.target.value === 'yes');
    };

    const handlePhoneNumberPurchase = (number) => {
        setOrganizationPhoneNumber(number);
        setIsPhoneNumberSelected(true);
        setTimeout(() => {
            setShowPhoneNumbersList(false);
        }, 200);
    };

    const handleResetPhoneNumber = () => {
        setIsPhoneNumberSelected(false);
        setOrganizationPhoneNumber("");
        setShowPhoneNumbersList(true);
    };

    const handleNextClick = () => {
        if (hasSim && isValidPhoneNumber || (isPhoneNumberSelected && (!callForwarding || (callForwarding && forwardingPhoneNumber)))) {
            nextStep();
        }
    };

  

    return (
        <div className="sim-selection-container" dir="rtl">
            <h2>בחירת SIM</h2>

            {/* הצגת כפתורי בחירה רק אם המשתמש לא בחר עדיין */}
            {showSimSelectionButtons && (
                <div className="sim-selection-buttons">
                    <button 
                        className="sim-selection-button" 
                        onClick={() => {
                            setHasSim(true);
                            setShowPhoneNumbersList(false);
                            setShowSimSelectionButtons(false); // הסתרת הכפתורים אחרי בחירה
                        }}
                    >
                        יש לי SIM משלי
                    </button>
                    <button 
                        className="sim-selection-button" 
                        onClick={() => {
                            setHasSim(false);
                            setShowPhoneNumbersList(true);
                            setShowSimSelectionButtons(false); // הסתרת הכפתורים אחרי בחירה
                        }}
                    >
                        אני רוצה לרכוש
                    </button>
                </div>
            )}

            {/* הצגת החלקים הרלוונטיים לפי הבחירה */}
            {hasSim !== null && (
                <div className="sim-selection-content">
                    {hasSim ? (
                        <div className="sim-selection-phone-number-input">
                            <h4>אנא הזן את מספר הטלפון שלך</h4>
                            <PhoneInput
                                country={'il'}
                                value={organizationPhoneNumber}
                                onChange={handlePhoneNumberChange}
                                inputProps={{
                                    name: 'phoneNumber',
                                    required: true,
                                    placeholder: "הזן את מספר הטלפון שלך (+972559776699)",
                                }}
                                enableSearch
                                disableDropdown={false}
                                containerClass="sim-selection-phone-number-input-container"
                                inputClass="sim-selection-phone-number-input-field"
                            />
                            {!isValidPhoneNumber && (
                                <p className="sim-selection-error-message">
                                    פורמט מספר טלפון לא חוקי. אנא השתמש בפורמט הנכון (למשל +972559776699).
                                </p>
                            )}
                        </div>
                    ) : (
                        <>
                            {showPhoneNumbersList && !isPhoneNumberSelected && (
                                <div className="sim-selection-phone-number-list">
                                    <PhoneNumbersList onPhoneNumberSelect={handlePhoneNumberPurchase} />
                                </div>
                            )}

                            {isPhoneNumberSelected && (
                           <div className="selected-phone-number">
                           <h4>
                               בחרת את המספר:
                               <span className="phone-wrapper">
                                   <span className="phone-prefix">{organizationPhoneNumber.slice(0, 4)}</span>
                                   <span className="phone-space">&nbsp;</span>
                                   <span className="phone-number">{organizationPhoneNumber.slice(4)}</span>
                               </span>
                           </h4>
                           <button className="reset-phone-number-button" onClick={handleResetPhoneNumber}>
                               <span>בחירה חוזרת של מספר</span>
                               <GrPowerCycle />
                           </button>
                           </div>
                       
                            )} 
                            {isPhoneNumberSelected && (
                                <div>
                                    <h4>האם תרצה הפניית שיחות שיכנסו למספר זה?</h4>
                                    <select className="forwarding-number-select" onChange={handleCallForwardingChange}>
                                        <option value="no">לא</option>
                                        <option value="yes">כן</option>
                                    </select>
                                    {callForwarding && (
                                        <div className="forwarding-number-input">
                                            <h4>הזן את מספר הטלפון אליו יועברו השיחות:</h4>
                                            <PhoneInput
                                                country={'il'}
                                                value={forwardingPhoneNumber}
                                                onChange={handleForwardingNumberChange}
                                                inputProps={{
                                                    name: 'forwardingPhoneNumber',
                                                    required: true,
                                                    placeholder: "הזן את מספר הטלפון להעברה (+972559776699)",
                                                }}
                                                containerClass="forwarding-number-input-container"
                                                enableSearch
                                                disableDropdown={false}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    )}

                    {/* כפתורי ניווט */}
                    <div className="sim-selection-button-container">
                       <button className="sim-selection-prev-button" onClick={() => {
                            prevStep();
                            setShowSimSelectionButtons(true); 
                            setHasSim(null); 
                            setIsPhoneNumberSelected(false); 
                            setOrganizationPhoneNumber(""); 
                            setShowPhoneNumbersList(false); 
                        }}>
                            הקודם
                        </button>
                        <button
                            className="sim-selection-next-button"
                            onClick={handleNextClick}
                            disabled={hasSim ? !isValidPhoneNumber : (!isPhoneNumberSelected || (callForwarding && !forwardingPhoneNumber))}
                        >
                            הבא
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SimSelection;



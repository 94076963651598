import React, { useState, useRef } from 'react';
import './ActionInputField.css';

const ActionInputField = ({ label, value = '', onChange, dynamicContent = [], type = 'input', readOnly = false }) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const textAreaRef = useRef(null); // Ref to track the textarea/input

  // Ensure `value` is always a string
  const normalizedValue = typeof value === 'string' ? value : '';

  const filteredContent = dynamicContent?.filter((item) =>
    item?.label?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const handleSelectDynamic = (selectedValue) => {
    if (readOnly) return; // Do nothing if in readOnly mode

    const dynamicValue = `{{${selectedValue}}}`;

    if (textAreaRef.current) {
      const { selectionStart, selectionEnd } = textAreaRef.current;

      // Insert dynamic content at the cursor position
      const newValue =
        normalizedValue.substring(0, selectionStart) +
        dynamicValue +
        normalizedValue.substring(selectionEnd);

      onChange(newValue);

      // Set cursor position after the inserted dynamic content
      setTimeout(() => {
        textAreaRef.current.setSelectionRange(
          selectionStart + dynamicValue.length,
          selectionStart + dynamicValue.length
        );
        textAreaRef.current.focus();
      }, 0);
    }

    setIsPickerOpen(false); // Close the picker after selection
  };

  return (
    <div className="action-input-field">
      <label>{label}</label>
      <div style={{ position: 'relative', flexGrow: 1 }}>
        {type === 'textarea' ? (
          <textarea
            ref={textAreaRef}
            value={normalizedValue}
            onChange={(e) => onChange(e?.target?.value)}
            onFocus={() => setIsPickerOpen(!readOnly)}
            onBlur={() => setTimeout(() => setIsPickerOpen(false), 200)}
            placeholder="Enter value or select dynamic content"
            style={{ width: '98%', minHeight: '100px' }}
            readOnly={readOnly} // Disable editing if readOnly is true
          />
        ) : (
          <input className='InputActionInputField'
            ref={textAreaRef}
            type="text"
            value={normalizedValue}
            onChange={(e) => onChange(e?.target?.value)}
            onFocus={() => setIsPickerOpen(!readOnly)}
            onBlur={() => setTimeout(() => setIsPickerOpen(false), 200)}
            placeholder="Enter value or select dynamic content"
            readOnly={readOnly} // Disable editing if readOnly is true
          />
        )}
      </div>
      {isPickerOpen && !readOnly && (
        <div className="picker-dropdown">
          <input
            type="text"
            className="picker-search"
            placeholder="Search dynamic content"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e?.target?.value)}
          />
          {filteredContent?.length === 0 ? (
            <p>No dynamic content available</p>
          ) : (
            filteredContent?.map((item) => (
              <div
                key={item.name}
                className="picker-item"
                onClick={() => handleSelectDynamic(item.name)}
              >
                {item.label}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default ActionInputField;

import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import LookUp from '../General/Lookup';
import './TemplatesHandler.css';
import ActionInputField from '../Botomation/WorkflowDesigner/ActionInputField';

function isArrayByPropertyName(property) {
    return property === 'field1' || property === 'field2' || property === 'retrieveFields';
}

const TemplatesHandler = ({
    organization,
    onTemplatesVariableChange,
    templateId,
    templateVariableQuery,
    recipientSource,
    locationName,
    locationAddress,
    longitude,
    latitude,
    setLocationName,
    setLocationAddress,
    setLongitude,
    setLatitude,
    parent,
    dynamicContent = [], // Pass dynamic content for pickable fields
    historyMode,
    initialValue
}) => {
    console.log(dynamicContent);
    const [template, setTemplate] = useState({});
    const [templateObj, setTemplateObj] = useState(null);
    const [allTemplates, setAllTemplates] = useState([]);
    const [variableArr, setVariablesArr] = useState({});
    console.log(templateVariableQuery)
    // const [queries, setQueries] = useState(templateVariableQuery || []);
    const [queries, setQueries] = useState(templateVariableQuery || []);
    
    const [contactsColumns, setContactsColumns] = useState(null);
    useEffect(() => {
        if (historyMode && initialValue?.templateVariablesData) {
            // Populate queries with templateVariablesData from initialValue
            const historyQueries = initialValue.templateVariablesData.map((value, index) => {
                const isWebhookValue = typeof value === 'object' && value.hasOwnProperty('parameters_webhook_value');
                const isHardCodedText = typeof value === 'string' || value.hasOwnProperty('parameters_hardCoded_Text');

                return {
                    index: index + 1,
                    Variable: templateVariableQuery[index].Variable, // Adjust if variable names are available
                    dataSource1: templateVariableQuery[index].dataSource1,
                    // dataSource1: isWebhookValue
                    //     ? 'data_source1_webhook'
                    //     : isHardCodedText
                    //     ? 'data_source1_HardCoded'
                    //     : '', // Default if no specific source is identified
                    parameters_hardCoded_Text: isHardCodedText ? value.parameters_hardCoded_Text || value : '',
                    parameters_webhook_value: isWebhookValue ? value.parameters_webhook_value : '',
                };
            });
            setQueries(historyQueries);
        }
    }, [historyMode]);

    useEffect(() => {
        if (!historyMode && (!templateVariableQuery || templateVariableQuery.length === 0) && Array.isArray(variableArr?.body_text)) {
            const newQueries = variableArr?.body_text[0].map((variable, index) => ({
                index: index + 1,
                Variable: variable,
                dataSource1: '',
                table1: '',
                field1: [],
                conditionOperator: '',
                dataSource2: '',
                table2: '',
                field2: [],
                retrieveFields: []
            }));
            setQueries(newQueries);
            // setQueries(...queries,  newQueries);
            console.log("New queries set based on body_text:", newQueries);
        }
    }, [variableArr?.body_text, parent]);

    const handleSelectChange = useCallback((index, property, value) => {
        setQueries(prevQueries => {
            const updatedQueries = [...prevQueries];
            updatedQueries[index][property] = isArrayByPropertyName(property) ? [value] : value;
            return updatedQueries;
        });
        console.log(`Query at index ${index} updated:`, { property, value });
    }, []);

    useEffect(() => {
        onTemplatesVariableChange(templateObj, variableArr, queries);
        console.log("Template variables changed:", { templateObj, variableArr, queries });
    }, [variableArr, queries, templateObj]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetAllTemplates', {
                    organization: organization
                });

                // Filter approved templates
                const approvedTemplates = response.data.filter(template => template.status === "APPROVED");

                // Sort templates by 'modifiedOn' in descending order (most recent first)
                const sortedTemplates = approvedTemplates?.sort((a, b) => {
                    return new Date(b?.modifiedOn) - new Date(a?.modifiedOn); // Sorting by modifiedOn, most recent first
                });

                setAllTemplates(sortedTemplates); // Set sorted templates to state
                console.log("All templates fetched and sorted:", sortedTemplates);

            } catch (error) {
                console.error('Error fetching templates:', error);
            }
        };
        fetchData();
    }, [organization]);
    useEffect(() => {
        const fetchContactsColumns = async (organization) => {
            try {
                const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetContactsColumnsByOrg', {
                    organization: organization
                });
                setContactsColumns(response.data);
                console.log("Contacts columns fetched:", response.data);
            } catch (error) {
                console.error('Error fetching contacts columns:', error);
            }
        };
        fetchContactsColumns(organization);
    }, [organization]);

    // useEffect(() => {
    //     const fetchTemplateById = async (templateId) => {
    //         try {
    //             const response = await axios.post(
    //                 'https://gambot.azurewebsites.net/api/Webhooks/GetTemplateById',
    //                 { organizationId: organization, templateId: templateId },
    //                 { headers: { 'Content-Type': 'application/json' } }
    //             );
    //             const fetchedTemplate = response.data;
    //             console.log('fetchedTemplate', fetchedTemplate);
    //             setTemplate({ 'label': fetchedTemplate.name });
    //             setTemplateObj(fetchedTemplate);
    //             if (fetchedTemplate.components) {
    //                 fetchedTemplate.components.forEach(component => {
    //                     if (component.type === "BODY") {
    //                         setVariablesArr(component.example);
    //                     }
    //                     if (component.type === "BUTTONS" && component.buttons && component.buttons.length > 0) {
    //                         component.buttons.forEach((button, buttonIndex) => {
    //                             if (button?.example && button?.url) {
    //                                 const buttonUrl = button.url; // Use button.url instead of button.example.url
    //                                 const regex = /{{(\d+)}}/g; // Find all {{index}} placeholders in the URL
    //                                 let match;
    //                                 const dynamicVariables = [];
        
    //                                 // Extract dynamic variables from the button URL
    //                                 while ((match = regex.exec(buttonUrl)) !== null) {
    //                                     dynamicVariables.push(match[1]); // Capture the variable index (e.g., "1")
    //                                 }
        
    //                                 // Update queries with dynamic variables for each button
    //                                 dynamicVariables.forEach((variableIndex) => {
    //                                     const updatedQueries = [...queries];
    //                                     console.log(updatedQueries);
    //                                     updatedQueries.push({
    //                                         index: updatedQueries.length + 1,
    //                                         Variable: `button${buttonIndex + 1}_dynamic_var${variableIndex}`, // Unique variable identifier
    //                                         parameters_webhook_value: buttonUrl.replace(`{{${variableIndex}}}`, `{{button${buttonIndex + 1}_dynamic_var${variableIndex}}}`)
    //                                     });
    //                                     setQueries(updatedQueries);
    //                                 });
    //                             }
        
    //                             // Check if the button is a CATALOG type
    //                             if (button.type.toLowerCase() === "catalog") {
    //                                 const updatedQueries = [...queries];
    //                                 updatedQueries.push({
    //                                     index: updatedQueries.length + 1,
    //                                     Variable: `button${buttonIndex + 1}_contentid`, // Variable for contentid
    //                                     dataSource1: 'data_source1_HardCoded',  // Assuming HardCoded value for contentid
    //                                     parameters_hardCoded_Text: "" // You can dynamically replace "contentid_value" with actual content ID
    //                                 });
    //                                 setQueries(updatedQueries); // Add the catalog contentid variable to queries
    //                             }
    //                         });
    //                     }
    //                 });
    //             }
    //             console.log("Template fetched by ID:", fetchedTemplate);
    //         } catch (error) {
    //             console.error('Error fetching template by ID:', error);
    //         }
    //     };
       
    //     if (templateId) {
    //         fetchTemplateById(templateId);
    //     }
    // }, [templateId, organization]);


    useEffect(() => {
        const fetchTemplateById = async (templateId) => {
            try {
                const response = await axios.post(
                    'https://gambot.azurewebsites.net/api/Webhooks/GetTemplateById',
                    { organizationId: organization, templateId: templateId },
                    { headers: { 'Content-Type': 'application/json' } }
                );
                const fetchedTemplate = response.data;
                console.log('fetchedTemplate', fetchedTemplate);
                setTemplate({ 'label': fetchedTemplate.name });
                setTemplateObj(fetchedTemplate);
                if (fetchedTemplate.components) {
                    fetchedTemplate.components.forEach(component => {
                        if (component.type === "BODY") {
                            setVariablesArr(component.example);
                        }
                        if (component.type === "BUTTONS" && component.buttons && component.buttons.length > 0) {
                            component.buttons.forEach((button, buttonIndex) => {
                                if (button?.example && button?.url) {
                                    const buttonUrl = button.url;
                                    const regex = /{{(\d+)}}/g;
                                    let match;
                                    const dynamicVariables = [];
                            
                                    while ((match = regex.exec(buttonUrl)) !== null) {
                                        dynamicVariables.push(match[1]);
                                    }
                            
                                    dynamicVariables.forEach((variableIndex) => {
                                        const variableName = `button${buttonIndex + 1}_dynamic_var${variableIndex}`;
                            
                                        const alreadyExists = queries.some(q => q.Variable === variableName);
                                        if (!alreadyExists) {
                                            setQueries(prev => [
                                                ...prev,
                                                {
                                                    index: prev.length + 1,
                                                    Variable: variableName,
                                                    parameters_webhook_value: buttonUrl.replace(`{{${variableIndex}}}`, `{{${variableName}}}`)
                                                }
                                            ]);
                                        }
                                    });
                                }
                            
                                if (button.type?.toLowerCase() === "catalog") {
                                    const variableName = `button${buttonIndex + 1}_contentid`;
                                    const alreadyExists = queries.some(q => q.Variable === variableName);
                                    if (!alreadyExists) {
                                        setQueries(prev => [
                                            ...prev,
                                            {
                                                index: prev.length + 1,
                                                Variable: variableName,
                                                dataSource1: 'data_source1_HardCoded',
                                                parameters_hardCoded_Text: ""
                                            }
                                        ]);
                                    }
                                }
                            });
                            
                        }
                    });
                }
                console.log("Template fetched by ID:", fetchedTemplate);
            } catch (error) {
                console.error('Error fetching template by ID:', error);
            }
        };
       
        if (templateId) {
            fetchTemplateById(templateId);
        }
    }, [templateId, organization]);

    
    const handleTemplateChange = (selectedTemplate) => {
        if (selectedTemplate.length > 0) {
            const selectedTemplateLabel = selectedTemplate[0].label;
            const template = allTemplates.find(template => template.name === selectedTemplateLabel);
            setTemplateObj(template);
    
            // Process template components
            if (template && template.components) {
                template.components.forEach(component => {
                    // Handle BODY components for body_text
                    if (component.type === "BODY") {
                        setVariablesArr(component.example); // Assuming example contains the variables
                    }
    
                    // Handle BUTTON components with dynamic URLs
                    if (component.type === "BUTTONS" && component.buttons && component.buttons.length > 0) {
                        component.buttons.forEach((button, buttonIndex) => {
                            if (button?.example && button?.url) {
                                const buttonUrl = button.url; // Use button.url instead of button.example.url
                                const regex = /{{(\d+)}}/g; // Find all {{index}} placeholders in the URL
                                let match;
                                const dynamicVariables = [];
    
                                // Extract dynamic variables from the button URL
                                while ((match = regex.exec(buttonUrl)) !== null) {
                                    dynamicVariables.push(match[1]); // Capture the variable index (e.g., "1")
                                }
    
                                // Update queries with dynamic variables for each button
                                dynamicVariables.forEach((variableIndex) => {
                                    const updatedQueries = [...queries];
                                    console.log(updatedQueries);
                                    updatedQueries.push({
                                        index: updatedQueries.length + 1,
                                        Variable: `button${buttonIndex + 1}_dynamic_var${variableIndex}`, // Unique variable identifier
                                        parameters_webhook_value: buttonUrl.replace(`{{${variableIndex}}}`, `{{button${buttonIndex + 1}_dynamic_var${variableIndex}}}`)
                                    });
                                    setQueries(updatedQueries);
                                });
                            }
    
                            // Check if the button is a CATALOG type
                            if (button.type.toLowerCase() === "catalog") {
                                const updatedQueries = [...queries];
                                updatedQueries.push({
                                    index: updatedQueries.length + 1,
                                    Variable: `button${buttonIndex + 1}_contentid`, // Variable for contentid
                                    dataSource1: 'data_source1_HardCoded',  // Assuming HardCoded value for contentid
                                    parameters_hardCoded_Text: "" // You can dynamically replace "contentid_value" with actual content ID
                                });
                                setQueries(updatedQueries); // Add the catalog contentid variable to queries
                            }
                        });
                    }
                });
            }
    
            console.log("Template changed:", template);
        }
        setTemplate(selectedTemplate);
    };
    
    console.log(queries);
    console.log(parent);
    return (
        <div className='templatesHandler-container'>
        <div className={`TemplatesHandler-container-select-template ${parent === 'campaignForm' || parent=== 'OutboundMessageAction' ? 'full-width' : 'min-width'}`}>
        <LookUp
                    options={allTemplates.map(template => ({ value: template?.id, label: template.name }))}
                    onChange={handleTemplateChange}
                    placeholder='Select template'
                    value={template.label}
                    defaultValue={Object.keys(template).length === 0 ? null : template}
                    allowAddNew={false}
                />

            </div>
            {templateObj && templateObj.components.some(component => component.type === 'HEADER' && component.format === 'LOCATION') && (
                <div className='templatesHandler-location-container'>
                    <h3>Location details</h3>
                    <div className='templatesHandler-location-fields'>
                        <div className='templatesHandler-location-field'>
                            <label>Location name:</label>
                            <ActionInputField
                                label=""
                                value={locationName}
                                onChange={(value) => setLocationName(value)}
                                dynamicContent={dynamicContent}
                                type="input"
                                readOnly={historyMode}
                            />
                        </div>

                        <div className='templatesHandler-location-field'>
                            <label>Location address:</label>
                            <ActionInputField
                                label=""
                                value={locationAddress}
                                onChange={(value) => setLocationAddress(value)}
                                dynamicContent={dynamicContent}
                                type="input"
                                readOnly={historyMode}
                            />
                        </div>

                        <div className='templatesHandler-location-field'>
                            <label>Longitude:</label>
                            <ActionInputField
                                label=""
                                value={longitude}
                                onChange={(value) => setLongitude(value)}
                                dynamicContent={dynamicContent}
                                type="input"
                                readOnly={historyMode}
                            />
                        </div>

                        <div className='templatesHandler-location-field'>
                            <label>Latitude:</label>
                            <ActionInputField
                                label=""
                                value={latitude}
                                onChange={(value) => setLatitude(value)}
                                dynamicContent={dynamicContent}
                                type="input"
                                readOnly={historyMode}
                            />
                        </div>
                    </div>

                </div>
            )}

            <div className='TemplatesHandler-container-tbl'>
                {templateObj && (
                    <table className='templatesHandler-tbl'>
                        <thead>
                            <tr className='templatesHandler-tbl-header'>
                                <th>Index</th>
                                <th>Variable</th>
                                <th>Data source</th>
                                <th>Paramaters</th>
                            </tr>
                        </thead>
                        <tbody>
                            {queries.map((query, index) => (
                                <tr key={index}>
                                    <td>{query.index}</td>
                                    <td>{query.Variable}</td>
                                    <td>
                                        <select className='SelectDataTemplate' onChange={(e) => handleSelectChange(index, 'dataSource1', e.target.value)} value={query.dataSource1} disabled={historyMode} >
                                            <option value="">Select data source</option>
                                            <option value="data_source1_recipientSource">Recipient source</option>
                                            <option value="data_source1_HardCoded">Hard coded</option>
                                            <option value="data_source1_integratedDB">Integrated-DB</option>
                                            {parent === "OutboundMessageAction" ?
                                                <option value="data_source1_webhook">Webhook</option>
                                                : ''
                                            }
                                        </select>
                                    </td>
                                    {query.dataSource1 === 'data_source1_recipientSource' && (
                                        <td className='templatesHandler-tbl-parmeter-select'>
                                            <select onChange={(e) => handleSelectChange(index, 'parameters_ContactsColumns_Select', e.target.value)} value={query.parameters_ContactsColumns_Select} disabled={historyMode}>
                                                <option value="">Select column</option>
                                                {contactsColumns && contactsColumns.map((column, colIndex) => (
                                                    <option key={colIndex} value={column}>{column}</option>
                                                ))}
                                            </select>
                                        </td>
                                    )}
                                    {query.dataSource1 === 'data_source1_HardCoded' && (
                                        <td className="templatesHandler-tbl-parmeter-text">
                                            {parent === 'OutboundMessageAction' || parent === 'OutboundMessageTrigger' || parent === 'ReminderAction' ? (
                                                <ActionInputField
                                                    label=""
                                                    value={query.parameters_hardCoded_Text || ''}
                                                    onChange={(value) =>
                                                        handleSelectChange(index, 'parameters_hardCoded_Text', value)
                                                    }
                                                    dynamicContent={dynamicContent}
                                                    type="input"
                                                    readOnly={historyMode}
                                                />
                                            ) : (
                                                <input
                                                    type="text"
                                                    readOnly={historyMode}
                                                    placeholder="Enter hardCoded value"
                                                    onChange={(e) =>
                                                        handleSelectChange(index, 'parameters_hardCoded_Text', e.target.value)
                                                    }
                                                    value={query.parameters_hardCoded_Text}
                                                    disabled={historyMode}
                                                />
                                            )}
                                        </td>
                                    )}

                                    {query.dataSource1 === 'data_source1_webhook' && (
                                        <td className="templatesHandler-tbl-parmeter-webhook">
                                            {parent === 'OutboundMessageAction' || parent === 'OutboundMessageTrigger' || parent === 'ReminderAction' ? (
                                                <ActionInputField
                                                    label=""
                                                    value={query.parameters_webhook_value || ''}
                                                    onChange={(value) =>
                                                        handleSelectChange(index, 'parameters_webhook_value', value)
                                                    }
                                                    dynamicContent={dynamicContent}
                                                    type="input"
                                                    readOnly={historyMode}
                                                />
                                            ) : (
                                                <input
                                                    type="text"
                                                    readOnly={historyMode}
                                                    placeholder="Enter webhook path (e.g., lead.Name)"
                                                    onChange={(e) =>
                                                        handleSelectChange(index, 'parameters_webhook_value', e.target.value)
                                                    }
                                                    value={query.parameters_webhook_value}
                                                />
                                            )}
                                        </td>
                                    )}

                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default TemplatesHandler;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ChatTimeline.css'; // Add appropriate styles for the timeline
const ChatTimeline = ({ currentUser, contactId, timelineEntriesProps }) => {
    const [timelineEntries, setTimelineEntries] = useState([]);
    const [newNote, setNewNote] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // Sync timelineEntries with timelineEntriesProps
    useEffect(() => {
        setTimelineEntries(timelineEntriesProps || []);
    }, [timelineEntriesProps]);

    const handleAddNote = async () => {
        if (!newNote.trim()) return;
    
        const user = {
            userId: currentUser.userId,
            userName: currentUser.fullname,
        };
    
        try {
            // Add the new timeline entry to the backend
            const response = await axios.post(
                'https://gambot.azurewebsites.net/api/Webhooks/AddTimelineEntry',
                {
                    organization: currentUser.organization,
                    contactId,
                    note: newNote,
                    user: user,
                }
            );
    
            if (response.data?.Success) {
                // Update the timeline entries locally
                setTimelineEntries((prev) => [response.data?.Data, ...prev].sort(
                    (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
                ));
                setNewNote('');
            } else {
                console.error('Error adding timeline note:', response.data?.message);
            }
        } catch (error) {
            console.error('Error adding timeline note:', error);
        }
    };
    
    const buildTimelineMessage = (item) => {
        switch (item?.TimelineType) {
            case 'assign':
                return `Assigned to: ${item?.assignToName || 'Unknown'}`;
            case 'open conversation':
                return `Opened conversation`;
            case 'note':
                return `Note added: ${item?.note || 'No details provided'}`;
            case 'status change':
                return `${item?.note || 'No details provided'}`;
            case 'close':
                return `Conversation closed`;
    
            case 'botomation send message': // Add new case for Botomation Send Message
                return `Botomation message sent with template name: ${item?.note || 'No Template ID'}`;
    
            case 'external webhook request creates message': // Add new case for External Webhook Request
                return `External Webhook request created template name: ${item?.note || 'No Template'}`;
    
            case 'start conversation manually': // Add new case for Start Conversation
                return `Start Conversation manually -> Sent template name: ${item?.note || 'No Template ID'}`;
    
            case 'campaign message sent': // Add new case for Campaign Message Sent
                return `Campaign ${item?.note} message sent by: ${item?.sentByName || 'Unknown'}`;
            case 'category change': // New case for Category Change
                return `${item?.note || 'No details provided'} by: ${item?.createdByName || 'Unknown'}`;
            default:
                return `Timeline event: ${item?.TimelineType || 'Unknown'}`;
        }
    };
    
    

    return (
        <div className="timeline-container">
            <h3>Timeline</h3>
            <div className="add-timeline-note">
                <textarea
                    placeholder="Add a new note..."
                    value={newNote}
                    onChange={(e) => setNewNote(e.target.value)}
                />
                <button className="add-timeline-button" onClick={handleAddNote} disabled={!newNote.trim()}>
                    Add note
                </button>
            </div>

            <div className="timeline-list">
                {isLoading ? (
                    <p>Loading...</p>
                ) : (
                    timelineEntries.map((entry, index) => (
                        <div key={index} className="timeline-entry">
                            <div className="timeline-header">
                                <div className="timeline-icon">
                                    <i className="fas fa-sticky-note"></i>
                                </div>
                                <div className="timeline-info">
                                    <p className="timeline-note">{buildTimelineMessage(entry) || 'No details available'}</p>
                                    <p className="timeline-meta">
                                        By: {entry.createdByName || 'Unknown'} | On:{' '}
                                        {new Date(entry.createdOn).toLocaleString()}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default ChatTimeline;
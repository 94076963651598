import React from 'react';
import { Settings, Contacts, ChatBubbleOutline, Timeline, Storage } from '@mui/icons-material'; // Import MUI Icons
import SchemaIcon from '@mui/icons-material/Schema';
import ChatIcon from '@mui/icons-material/Chat';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import CampaignIcon from '@mui/icons-material/Campaign';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { GrCatalog } from "react-icons/gr";
import { MdSmartToy } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { PiSignOut } from "react-icons/pi";

export const MenuDATA = [
  {
    path: '/', 
    icon: <PiSignOut className="menuIconPersonalArea" />,
    cName: 'nav-text',
    signOut: true, 
  },
  {
    title: 'Dashbord',
    path: '/dashbord',
    icon: <DashboardIcon className="menuIconPersonalArea" />,
    cName: 'nav-text'
  },
  {
    title: 'Contacts',
    path: '/contacts',
    icon: <Contacts className="menuIconPersonalArea" />,
    cName: 'nav-text'
  },
  {
    title: 'Chats',
    path: '/chat',
    icon: <ChatBubbleOutline className="menuIconPersonalArea" />,
    cName: 'nav-text'
  },
  {
    title: 'Campaign',
    path: '/Campaign',
    icon: <CampaignIcon className="menuIconPersonalArea" />,
    cName: 'nav-text'
  },
  {
    title: 'Templates',
    path: '/Templates',
    icon: <ChatIcon className="menuIconPersonalArea" />,
    cName: 'nav-text'
  },
  {
    title: 'ActivityLog',
    path: '/activityLog',
    icon: <ChatIcon className="menuIconPersonalArea" />,
    cName: 'nav-text'
  },
  {
    title: 'Botomations',
    path: '/Botomations',
    icon: <SchemaIcon  className="menuIconPersonalArea" />,
    cName: 'nav-text'
  },
  {
    title: 'FormFlows',
    path: '/FormFlows',
    icon: <DynamicFormIcon className="menuIconPersonalArea" />,
    cName: 'nav-text'
  },
  {
    title: 'Gambot AI',
    path: '/GambotAi',
    icon: <MdSmartToy className="menuIconPersonalArea" />,
    cName: 'nav-text'
  },

  
  {
    title: 'Users',
    path: '/users',
    icon: <FaUsers className="menuIconPersonalArea" />,
    cName: 'nav-text'
  },
  // {
  //   title: 'Integrations',
  //   path: '/Integrations',
  //   icon: <IntegrationInstructionsIcon />,
  //   cName: 'nav-text'
  // }
  // ,
  {
    title: 'Widget',
    path: '/ChatWidget',
    icon: <WidgetsIcon className="menuIconPersonalArea" />,
    cName: 'nav-text'
  },
  {
    title: 'Catalog',
    path: '/catalog',
    icon: <GrCatalog className="menuIconPersonalArea" />,
    cName: 'nav-text'
  },
  {
    title: 'Settings',
    path: '/settings',
    icon: <Settings className="menuIconPersonalArea" />,
    cName: 'nav-text'
  },
];

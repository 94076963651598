
import React from 'react';
import './HomePageMainSection.css';
import GambotHomePage from '../../../assets/logoGam.png';
import { CiVideoOn } from "react-icons/ci";
import { PiVideo } from "react-icons/pi";

const HomePageMainSection = () => {
  
  const scrollToNextSection = () => {
    const nextSection = document.getElementById('why-whatsapp');
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: 'smooth' });
    }
  };



  return (
    <div id="HomePageMainSection">
      <main className="mainHhomePage">
        <section className="section banner banner-section">
          <div className="container banner-column">
            <div className="banner-inner">
            <p className="PHomePageMainSection"> 
                <img className="logomainHhomePage" src={GambotHomePage} alt="GambotHomePageImage"/>
                <span className="boldTextMainHhomePage">Gambot</span> End-to-end WhatsApp Api Solution
            </p>
              <h1 className="heading-xl">Let Gambot work for you.</h1>
              <p className="heading-x">הפלטפורמה המתקדמת לניהול תקשורת ארגונית בוואטסאפ</p>
              <p className="paragraph">
               גמבוט מאפשרת לעסקים לשדרג את התקשורת עם הלקוחות, להגדיל את המכירות 📈 ולספק שירות אוטומטי 🤖, ללא צורך בגורם אנושי.
              באמצעות גמבוט - תוכלו לחסוך בעלויות 💸, בזמן ⏳ ולשדרג משמעותית את איכות השירות 🏅 שהעסק שלכם נותן ללקוחות.
              הצטרפו אלינו והתחילו להשתמש בוואטסאפ API כדי לייעל את העסק שלכם עכשיו! 🚀
            </p>
              {/* <p className="paragraph">
                גמבוט מאפשרת לארגונים לנהל את התקשורת שלהם בוואטסאפ בצורה
                חכמה, אוטומטית ויעילה. בעזרת כלים מתקדמים כגון ניהול אנשי קשר
                עם תיוגים, שליחת קמפיינים מפולטרים, יצירת תהליכי עבודה ושיחה
                אוטומטיים, ותמיכה במודלים מבוססי AI - תוכלו להפוך עד 80% מהתקשורת
                שלכם לאוטומטית.
              </p> */}
              <div className="btn-container">
                <button className="btn btn-darken btn-inline">סרטון הסבר <PiVideo /></button>
                <button className="btn btn-secondary btn-inline">
                  תיאום פגישת דמו <i className="bx bx-left-arrow-alt"></i>
                </button>
              </div>
            </div>
          </div>
          {/* <div className="scroll-arrow" onClick={scrollToNextSection}>⬇</div> */}
        </section>
      </main>
    </div>

  );
};

export default HomePageMainSection;








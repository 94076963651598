// import React, { useState } from 'react';
// import './OurFeatures.css';
// import GamBotLogo from '../../../assets/GamBot_Logo.png'; 
// import i1 from '../../../assets/1.png'; 
// import i2 from '../../../assets/2.png'; 
// import i3 from '../../../assets/3.png'; 
// import i4 from '../../../assets/4.png';
// import i5 from '../../../assets/5.png'; 
// import i6 from '../../../assets/6.png'; 
// import i7 from '../../../assets/7.png'; 
// import i8 from '../../../assets/8.png';
// import i9 from '../../../assets/9.png'; 


// import { FaArrowCircleLeft } from "react-icons/fa";
// import { FaArrowCircleRight } from "react-icons/fa";

// const features = [
//   {
//     title: 'ניהול אנשי קשר',
//     description: 'כולל תגיות לפילטור.',
//     image: i1,
//   },
//   {
//     title: 'יצירה וניהול תבניות הודעה',
//     description: 'טמפלטים מותאמות אישית עם משתנים.',
//     image: i2,
//   },
//   {
//     title: 'יצירה וניהול של טפסים בוואטסאפ',
//     description: 'כולל קבלת ושליחת נתונים בבקשות HTTP.',
//     image: i3,
//   },
//   {
//     title: 'אוטומציה לשיחות אוטומטיות',
//     description: 'ניתן להרחיב את היכולות בעזרת מודל AI של הארגון שלכם שידבר מטעמכם עם הלקוחות.',
//     image: i4,
//   },
//   {
//     title: 'יצירה וניהול של קטלוגי מוצרים',
//     description: 'ניהול פריטים בצורה נוחה ומהירה.',
//     image: i5,
//   },
//   {
//     title: 'יצירה וניהול של מודל AI מותאם אישית',
//     description: 'אתם מעלים נתונים, מגדירים לו מה אתם רוצים שהוא יעשה ומי הוא יהיה.',
//     image: i6,
//   },
//   {
//     title: 'יצירה וניהול של הארגון שלכם',
//     description: 'משתמשים, מדיה, תשובות מהירות, נתוני חשבון ועוד.',
//     image: i7,
//   },
//   {
//     title: 'יצירה וניהול של ווידג\'ט',
//     description: 'להטמעת קישור להתכתבות מהאתר שלכם - תומך במספר אפשרויות מדפים שונים.',
//     image: i8,
//   },
//   {
//     title: 'התכתבות בזמן אמת',
//     description: 'כמו וואטסאפ להתכתבות בזמן אמת שאפשר להתחבר מספר נציגים במקביל ולתת שירות לאותו לקוח / מספר לקוחות, ניתן לשייך שיחה לנציג אחר, לסווג את השיחה, לתעד דברים חשובים ועוד.',
//     image: i9,
//   },
// ];

// const OurFeatures = () => {
//   const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);

//   const handlePrev = () => {
//     setCurrentFeatureIndex(
//       (prevIndex) => (prevIndex - 1 + features.length) % features.length
//     );
//   };

//   const handleNext = () => {
//     setCurrentFeatureIndex((prevIndex) => (prevIndex + 1) % features.length);
//   };

//   const getClassName = (index) => {
//     if (index === currentFeatureIndex) return 'center';
//     if (
//       (index === currentFeatureIndex - 1) ||
//       (currentFeatureIndex === 0 && index === features.length - 1)
//     )
//       return 'left';
//     if (
//       (index === currentFeatureIndex + 1) ||
//       (currentFeatureIndex === features.length - 1 && index === 0)
//     )
//       return 'right';
//     return 'hidden';
//   };

//   return (
//     <div id="our-features">
//       <h2 className="title">הפיצ'רים שלנו</h2>
//       <div className="carousel">
//         {features.map((feature, index) => (
//           <div key={index} className={`carousel-item ${getClassName(index)}`}>
//             {/* <img
//               src={feature.image}
//               alt={feature.title}
//               className="feature-image"
//             /> */}
//             {/* הצגת התוכן רק עבור התמונה המרכזית */}
//             {index === currentFeatureIndex && (
//               <div className="content">
//                 <div className="content-template">
//                   <h3 className="h3title">{feature.title}</h3>
//                   <p>{feature.description}</p>
//                 </div>
//               </div>
//             )}
//           </div>
//         ))}
//         <button className="carousel-button prev" onClick={handlePrev}>
//           <FaArrowCircleLeft />
//         </button>
//         <button className="carousel-button next" onClick={handleNext}>
//           <FaArrowCircleRight />
//         </button>
//       </div>
//     </div>
//   );  
// };

// export default OurFeatures;



// import React, { useState } from 'react';
// import './OurFeatures.css';


// const OurFeatures = () => {
 

//   return (
//     <div id="our-features">
//       <h2 className="title">הפיצ'רים שלנו</h2>
     
     
//      </div>
//   );  
// };


// export default OurFeatures;


import React from 'react';
import './OurFeatures.css';
import { FaWpforms } from "react-icons/fa";
import { TiMessages } from "react-icons/ti";
import { IoIosContacts } from "react-icons/io";
import { GiArtificialIntelligence } from "react-icons/gi";
import { GrCatalog } from "react-icons/gr";
import { TbSettingsAutomation } from "react-icons/tb";
import { RiCustomerService2Line } from "react-icons/ri";
import { TfiWidget } from "react-icons/tfi";
import { SiAwsorganizations } from "react-icons/si";

const OurFeatures = () => {
  return (
    <div id="our-features">
      <h2 className="our-features-title">הפיצ'רים שלנו</h2>
      <main className="our-features-main">
        <section className="our-features-section">
        
          <div className="our-features-cards">
            <div className="our-feature-card our-feature-one">
              <h2 className="our-feature-title">ניהול אנשי קשר</h2>
              <p className="our-feature-desc">כולל תגיות לפילטור</p>
               <p className="our-feature-icon" ><IoIosContacts /></p>
            </div>
            <div className="our-features-wrapper">
              <div className="our-feature-card our-feature-two">
                <h2 className="our-feature-title">יצירה וניהול תבניות הודעה</h2>
                <p className="our-feature-desc">טמפלטים מותאמות אישית עם משתנים</p>
                <p className="our-feature-icon" ><TiMessages />
                </p>
              </div>
              <div className="our-feature-card our-feature-three">
                <h2 className="our-feature-title">יצירה וניהול של טפסים בוואטסאפ </h2>
                <p className="our-feature-desc"> כולל קבלת ושליחת נתונים בבקשות HTTP </p>
                <p className="our-feature-icon" >< FaWpforms/></p>
              </div>
            </div>
            <div className="our-feature-card our-feature-four">
              <h2 className="our-feature-title">יצירה וניהול אוטומציה</h2>
              <p className="our-feature-desc">
              לשיחות אוטומטיות שבה או שמגדירים את המקרים והתגובות או שניתן להרחיב את היכולות בעזרת מודל AI של הארגון שלכם שידבר מטעמכם עם הלקוחות              </p>
              <p className="our-feature-icon" ><TbSettingsAutomation /></p>
            </div>
            <div className="our-feature-card our-feature-five">
              <h2 className="our-feature-title">יצירה וניהול Catalog </h2>
              <p className="our-feature-desc">של קטלוגי מוצרים </p>
              <p className="our-feature-icon" ><GrCatalog /></p>
            </div>
            <div className="our-feature-card our-feature-six">
              <h2 className="our-feature-title">יצירה וניהול של מודל AI  מותאם אישית</h2>
              <p className="our-feature-desc"> אתם מעלים נתונים , מגדירים לו מה אתם רוצים שהוא יעשה ומי הוא יהיה. </p>
              <p className="our-feature-icon" ><GiArtificialIntelligence /></p>
            </div>
            <div className="our-feature-card our-feature-seven">
              <h2 className="our-feature-title">יצירה וניהול של הארגון שלכם</h2>
              <p className="our-feature-desc"> משתמשים , מדיה , תשובות מהירות, נתוני חשבון ועוד.</p>
              <p className="our-feature-icon" ><SiAwsorganizations /></p>
            </div>
            <div className="our-feature-card our-feature-eight">
              <h2 className="our-feature-title">יצירה וניהול של ווידג'ט</h2>
              <p className="our-feature-desc">להטמעת קישור להתכתבות מהאתר שלכם - תומך במספר אפשרויות מדפים שונים </p>
              <p className="our-feature-icon" ><TfiWidget /></p>
            </div>
            <div className="our-feature-card our-feature-nine">
              <h2 className="our-feature-title">התכתבות בזמן אמת</h2>
              <p className="our-feature-desc">
              כמו וואטסאפ להתכתבות בזמן אמת שאפשר להתחבר מספר נציגים במקביל ולתת שירות לאותו לקוח / מספר לקוחות , ניתן לשייך שיחה לנציג אחר , לסווג את השיחה , לתעד דברים חשובים ועוד              </p>
              <p className="our-feature-icon" ><RiCustomerService2Line />
              </p>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default OurFeatures;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './GambotAi.css';
import { IoAddCircleOutline, IoTrashOutline } from 'react-icons/io5';
import { MdOutlineModeEdit } from 'react-icons/md';
import ConfirmationDialog from '../General/ConfirmationDialog';

const GambotAi = ({ currentUser }) => {
    const [gambotAiList, setGambotAiList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedGambotAi, setSelectedGambotAi] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGambotAi = async () => {
            let timeZone = currentUser?.timeZone; // Get the user's time zone
            try {
                const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetAllGambotAi', {
                    organization: currentUser?.organization 
                });
                const sortedGambotAi = response?.data
                    .sort((a, b) => new Date(b.modifiedOn) - new Date(a.modifiedOn)) // Sort by modifiedOn descending
                    .map(gambotAi => ({
                        ...gambotAi,
                        modifiedOn: formatToUserTimezone(gambotAi.ModifiedOn, timeZone), // Convert modifiedOn to user timezone
                        createdOn: formatToUserTimezone(gambotAi.CreatedOn, timeZone) // Convert createdOn to user timezone
                    }));
                setGambotAiList(sortedGambotAi); // Set sorted list
            } catch (error) {
                console.error('Error fetching GambotAi data:', error);
            }
        };

        if (currentUser?.organization) {
            fetchGambotAi();
        }
    }, [currentUser?.organization]);

    const formatToUserTimezone = (utcDate, timezone) => {
        if (!utcDate) return 'No date available';
      
        try {
          return new Intl.DateTimeFormat('en-GB', {
            timeZone: timezone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
          }).format(new Date(utcDate));
        } catch (error) {
          console.error('Error formatting date:', error, 'Timezone:', timezone);
          return 'Invalid date';
        }
      };
    const handleEditClick = (gambotAi) => {
        navigate(`/GambotAiForm/?gambotAiId=${gambotAi.Id}`);
    };

    const handleDeleteClick = (gambotAi) => {
        setSelectedGambotAi(gambotAi);
        setOpenDialog(true);
    };

    const handleConfirmDelete = () => {
        const payload = {
            organization: currentUser?.organization,
            gambotAiId: selectedGambotAi.Id
        };

        axios.post('https://gambot.azurewebsites.net/api/Webhooks/DeleteGambotAiById', payload)
            .then(() => {
                setGambotAiList(prevList => prevList.filter(item => item.Id !== selectedGambotAi.Id));
                setOpenDialog(false);
            })
            .catch((error) => {
                console.error('Error deleting GambotAi:', error);
            });
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredGambotAi = gambotAiList.filter((gambotAi) =>
        (gambotAi.botName || '').toLowerCase().includes(searchQuery.toLowerCase())
    );
    console.log(filteredGambotAi);
    return (
        <div className='gambot-ai-list-container'>
            <h1 className='gambot-ai-list-header'>Gambot AI List</h1>
            <div className='search-bar'>
                <input
                    type="text"
                    placeholder="Search Gambot AI by Name"
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
            </div>

            <div className='gambot-ai-action-buttons'>
                <button className="add-new-gambot-ai-button" onClick={() => navigate('/GambotAiForm')}>
                    Add New Gambot AI <IoAddCircleOutline />
                </button>
            </div>

            <div className='gambot-ai-list-table-container'>
                <table className='gambot-ai-list-table'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Purpose</th>
                            <th>Status</th>
                            <th>Last Modified</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredGambotAi.map((gambotAi) => (
                            <tr key={gambotAi.Id}>
                                <td>{gambotAi.BotName}</td>
                                <td>{gambotAi.BotPurpose}</td>
                                <td>{gambotAi.botStatus}</td>
                                <td>{gambotAi.modifiedOn} <br></br> by {gambotAi?.modifiedByName || 'Unknown'}</td>

                                <td>
                                    <button className="gambot-ai-edit-button" onClick={() => handleEditClick(gambotAi)}>
                                        <MdOutlineModeEdit />
                                    </button>
                                    <button className="gambot-ai-delete-button" onClick={() => handleDeleteClick(gambotAi)}>
                                        <IoTrashOutline />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <ConfirmationDialog
                open={openDialog}
                onClose={handleCloseDialog}
                onConfirm={handleConfirmDelete}
            />
        </div>
    );
};

export default GambotAi;

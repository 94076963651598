import React, { useState, useEffect, useCallback } from 'react';
import TemplatesHandler from '../../Templates/TemplatesHandler';
import ConditionGroup from './ConditionGroup';
import './OutboundMessageTrigger.css';

const OutboundMessageTrigger = ({
  organization,
  onChange,
  initialConfig = {},
  parent,
  historyMode,
  initialValue,
}) => {
  const [messageType, setMessageType] = useState(initialConfig.messageType || 'regular');
  const [templateId, setTemplateId] = useState(initialConfig.templateId || null);
  const [templateVariableQuery, setTemplateVariableQuery] = useState(initialConfig.templateVariableQuery || []);
  const [messageContent, setMessageContent] = useState(initialConfig.messageContent || '');
  const [conditionGroups, setConditionGroups] = useState(
    initialConfig.conditionGroups || [{ logicOperator: 'AND', conditions: [{ operator: 'equals', value: '' }] }]
  );
  const [groupRelations, setGroupRelations] = useState(initialConfig.groupRelations || []);
  const [selectedTemplates, setSelectedTemplates] = useState(initialConfig.selectedTemplates || []);
  const [selectedButtons, setSelectedButtons] = useState(initialConfig.selectedButtons || []);

  // Populate fields with `initialValue` in history mode
  useEffect(() => {
    if (historyMode && initialValue) {
      setMessageType(initialValue.messageType || 'regular');
      setTemplateId(initialValue.templateId || null);
      setTemplateVariableQuery(initialValue.templateVariableQuery || []);
      setMessageContent(initialValue.messageContent || '');
      setConditionGroups(initialValue.conditionGroups || []);
      setGroupRelations(initialValue.groupRelations || []);
    }
  }, [historyMode, initialValue]);
  

  // Trigger onChange when the state changes
  const triggerOnChange = useCallback(() => {
    if (!historyMode) {
      const newConfig = {
        messageType,
        templateId,
        templateVariableQuery,
        conditionGroups,
        groupRelations,
        selectedTemplates,
        selectedButtons,
      };
      if (JSON.stringify(newConfig) !== JSON.stringify(initialConfig)) {
        onChange(newConfig);
      }
    }
  }, [
    messageType,
    templateId,
    templateVariableQuery,
    conditionGroups,
    groupRelations,
    selectedTemplates,
    selectedButtons,
    onChange,
    initialConfig,
    historyMode,
  ]);

  useEffect(() => {
    triggerOnChange();
  }, [triggerOnChange]);

  const handleGroupChange = (index, updatedGroup) => {
    setConditionGroups((prevGroups) => {
      const newGroups = [...prevGroups];
      newGroups[index] = updatedGroup;
      return newGroups;
    });
  };

  const addGroup = () => {
    if (!historyMode) {
      setConditionGroups((prevGroups) => [
        ...prevGroups,
        { logicOperator: 'AND', conditions: [{ operator: 'equals', value: '' }] },
      ]);
      setGroupRelations((prevRelations) => [...prevRelations, 'AND']);
    }
  };

  const handleDeleteGroup = (index) => {
    if (!historyMode) {
      setConditionGroups((prevGroups) => prevGroups.filter((_, i) => i !== index));
      setGroupRelations((prevRelations) => prevRelations.filter((_, i) => i !== index - 1));
    }
  };

  const handleAddCondition = (groupIndex) => {
    if (!historyMode) {
      setConditionGroups((prevGroups) => {
        const newGroups = [...prevGroups];
        newGroups[groupIndex].conditions.push({ operator: 'equals', value: '' });
        return newGroups;
      });
    }
  };

  const handleGroupRelationChange = (index, value) => {
    if (!historyMode) {
      setGroupRelations((prevRelations) => {
        const newRelations = [...prevRelations];
        newRelations[index] = value;
        return newRelations;
      });
    }
  };

  const handleTemplatesChange = (selectedOptions, buttons) => {
    if (!historyMode) {
      setSelectedTemplates(selectedOptions);
      setSelectedButtons(buttons);
    }
  };

  const handleTemplatesVariableChange = (template, variables, queries) => {
    if (!historyMode) {
      setTemplateId(template?.Id);
      setTemplateVariableQuery(queries);
    }
  };

  return (
    <div className="outbound-message-trigger">
      <label>
        Message Type:
        <select value={messageType} onChange={(e) => setMessageType(e.target.value)} disabled={historyMode}>
          <option value="regular">Regular</option>
          <option value="template">Template</option>
        </select>
      </label>

      {/* Regular Message Filtering */}
      {/* Regular Message Filtering */}
{messageType === 'regular' && (
  <div className="condition-groups">
    {conditionGroups.map((group, index) => (
      <div key={index} className="condition-group-wrapper">
        <ConditionGroup
          group={group}
          onGroupChange={(updatedGroup) => handleGroupChange(index, updatedGroup)}
          onDeleteGroup={() => handleDeleteGroup(index)}
          onAddCondition={() => handleAddCondition(index)}
          isRemovable={index > 0}
          parent={parent}
          readOnly={historyMode}
        />
      </div>
    ))}
    {!historyMode && (
      <div className="add-group-wrapper">
        <button type="button" onClick={addGroup} className="condition-action-add-group-wrapper">
          Add Group
        </button>
        {conditionGroups.length > 0 && (
          <select
            value={groupRelations[groupRelations.length - 1] || 'AND'}
            onChange={(e) => handleGroupRelationChange(groupRelations.length - 1, e.target.value)}
            className="group-relation-select"
          >
            <option value="AND">AND</option>
            <option value="OR">OR</option>
          </select>
        )}
      </div>
    )}

    {historyMode && (
      <div className="message-content-history">
        <label>
          Message Content:
          <textarea
            value={messageContent}
            readOnly={historyMode}
            className="history-message-content"
          />
        </label>
      </div>
    )}
  </div>
)}

      {/* Template Message Handling */}
      {messageType === 'template' && (
        <TemplatesHandler
          organization={organization}
          onTemplatesVariableChange={handleTemplatesVariableChange}
          templateId={templateId}
          templateVariableQuery={templateVariableQuery}
          recipientSource={'Contacts'}
          parent={'OutboundMessageTrigger'}
          historyMode={historyMode}
          initialValue={initialValue}
        />
      )}
    </div>
  );
};

export default OutboundMessageTrigger;

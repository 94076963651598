// import React, { useState } from 'react';
// import './PlanSelection.css'; // Import CSS for PlanSelection

// const PlanSelection = ({ plan, setPlan, nextStep }) => {

    
//     const [isChecked, setIsChecked] = useState(false); // State to track checkbox selection
//     const [isMonthly , setIsMonthly] = useState(true);
//     const planNamesInHebrew = {
//         Basic: "בסיס",
//         Premium: "פרימיום",
//         Enterprise: "ארגונים",
//     };

//     // Handle checkbox change
//     const handleCheckboxChange = () => {
//         setIsChecked(!isChecked); // Toggle checkbox state
//     };

//     return (
//         <div className="plan-selection-wrapper">
//         <div className="plan-selection-container" dir="rtl">
//             <h2 className="plan-selection-container-h2">בחירת מסלול</h2>
//             <div className="plan-buttons">
//                 <button className="plan-button" onClick={() => setPlan('Basic')}>חבילת בסיס</button>
//                 <button className="plan-button" onClick={() => setPlan('Premium')}>חבילת פרימיום</button>
//                 <button className="plan-button" onClick={() => setPlan('Enterprise')}>חבילת ארגונים</button>
//             </div>

//             {plan && (
//                 <div className="plan-selected">
//                     <h3>בחרת: {planNamesInHebrew[plan]}</h3>
//                 </div>
//             )}

//             {/* Checkbox and link for Terms of Use and Privacy Policy */}
//             <div className="terms-checkbox-container">
//             <label htmlFor="terms-checkbox" className="terms-label">
//                     אני מסכים ל
//                     <a href="/TermOfUse" target="_blank" rel="noopener noreferrer" className="terms-link">
//                         EULA (הסכם רישוי למשתמש)
//                     </a>
//                 </label>
//                 <input 
//                     type="checkbox" 
//                     id="terms-checkbox" 
//                     checked={isChecked} 
//                     onChange={handleCheckboxChange} 
//                     className="terms-checkbox"
//                 />
           
//             </div>
//             <br></br>
//             {/* Next Button */}
//             <button 
//                 className="next-button" 
//                 onClick={nextStep} 
//                 disabled={!plan || !isChecked} // Disable if no plan is selected or terms aren't agreed to
//             >
//                 הבא
//             </button>
//         </div>
//         </div>

//     );
// };

// export default PlanSelection;



import React, { useState, useRef } from "react";
import "./PlanSelection.css"; // קובץ עיצוב
import { FaCheck } from "react-icons/fa";

const PlanSelection = ({ plan, setPlan, nextStep }) => {
  const [billingCycle, setBillingCycle] = useState("yearly");
  const [isChecked, setIsChecked] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("פרימיום"); // ברירת מחדל: פרימיום
  const termsRef = useRef(null);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handlePlanSelection = (selectedPlan) => {
    setPlan(selectedPlan);
    setSelectedPlan(selectedPlan); // שמירת התכנית שנבחרה

    // setTimeout(() => {
    //   window.scrollBy({
    //     top: 250, 
    //     behavior: "smooth",
    //   });
    // }, 200);
  };

  const plans = {
    monthly: [
      {
        name: "בסיס",
        price: 199,
        yearlyPrice: 179 * 12,
        description: "חבילת הבסיס לעסק שלכם",
        features: [
          "עד 5 משתמשים - כל משתמש נוסף 30 ש\"ח",
          "עד 5000 אנשי קשר",
          "עד 15 אלף רשומות דיוור בחודש",
          "AI עד 120 תשובות ",
          "עד 1000 שיחות בחודש",
        ],
      },
      {
        name: "פרימיום",
        price: 399,
        yearlyPrice: 379 * 12,
        recommended: true, // החבילה המומלצת כברירת מחדל
        description: "חבילת הפרימיום לעסק שלכם",
        features: [
          "עד 5 משתמשים - כל משתמש נוסף 30 ש\"ח",
          "עד 15,000 אנשי קשר",
          "עד 32 אלף רשומות דיוור בחודש",
          "AI עד 300 תשובות ",
          "עד 2000 שיחות בחודש",
        ],
      },
      {
        name: "ארגונים",
        price: 1199,
        yearlyPrice: 1179 * 12,
        description: "חבילה מותאמת לארגון גדול",
        features: [
          "עד 5 משתמשים - כל משתמש נוסף 30 ש\"ח",
          "עד 35,000 אנשי קשר",
          "עד 80 אלף רשומות דיוור בחודש",
          "AI עד 700 תשובות ",
          "עד 4000 שיחות בחודש",
        ],
      },
    ],
  };

  return (
    <div className="plan-selection-wrapper">
      <div className="plan-selection-container" dir="rtl">
        <h2 className="plan-selection-container-h2">בחירת מסלול</h2>

        {/* בורר סוג תשלום */}
        <div className="billing-toggle">
          <button
            className={billingCycle === "monthly" ? "active" : ""}
            onClick={() => setBillingCycle("monthly")}
          >
            תשלום חודשי
          </button>
          <button
            className={billingCycle === "yearly" ? "active" : ""}
            onClick={() => setBillingCycle("yearly")}
          >
            תשלום שנתי
          </button>
        </div>

        {/* כרטיסיות מחירים */}
        <div className="plans-container-selection">
          {plans.monthly.map((planItem, index) => (
            <div
              key={index}
              className={`plan-card-selection ${
                selectedPlan === planItem.name ? "recommended-selection" : ""
              }`}
            >
             {planItem.name === "פרימיום" && (
    <div className="recommended-badge-selection">המומלץ ביותר</div>
)}

              <h3 className="plan-name-selection">{planItem.name}</h3>
              <p className="plan-description-selection">{planItem.description}</p>
              <p className="plan-price-selection">
                ₪
                {billingCycle === "monthly"
                  ? planItem.price
                  : (planItem.yearlyPrice / 12).toFixed(0)}
                <span> לחודש</span>
              </p>

              <p className="plan-yearly-selection">
                {billingCycle === "yearly" && (
                  <span>
                    בחשבון אחד של ₪ {planItem.yearlyPrice.toLocaleString()} (לפני
                    מע"מ)
                  </span>
                )}
              </p>

              <button
                className="try-button-selection"
                onClick={() => handlePlanSelection(planItem.name)}
              >
                בחירת תכנית
              </button>
            </div>
          ))}
        </div>

        {/* Checkbox and link for Terms of Use */}
        <div ref={termsRef} className="terms-checkbox-container">
          <input
            type="checkbox"
            id="terms-checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
            className="terms-checkbox"
          />
          <label htmlFor="terms-checkbox" className="terms-label">
            אני מסכים ל
            <a
              href="/TermOfUse"
              target="_blank"
              rel="noopener noreferrer"
              className="terms-link"
            >
              {" "}
              EULA (הסכם רישוי למשתמש)
            </a>
          </label>
        </div>

        {plan && (
          <div className="plan-selected">
            <h3>
              איזה כיף ! בחרת בחבילת: 
              <span className="plan-selected-highlight"> {plan} ✅</span>
            </h3>
          </div>    
        )}
        
        <br />

        {/* כפתור הבא */}
        <button
          className="next-button"
          onClick={nextStep}
          disabled={!plan || !isChecked}
        >
          הבא
        </button>
      </div>
    </div>
  );
};

export default PlanSelection;



import React, { useState, useEffect, useRef } from "react";
import { FaTrash } from "react-icons/fa";
import ActionInputField from "../WorkflowDesigner/ActionInputField";
import "./ConditionRow.css";

const operators = [
  "equals",
  "not equals",
  "contains",
  "does not contain",
  "begins with",
  "does not begin with",
  "ends with",
  "does not end with",
  "today's date?",
  "is First Message?",
  "Is Conversation Live?",
  "Is Answer To Last Sent Message?",
  "AI"
];

const booleanOperators = [
  "today's date?",
  "is First Message?",
  "Is Conversation Live?",
  "Is Answer To Last Sent Message?"

];

const ConditionRow = ({
  condition,
  onConditionChange,
  onDelete,
  parent,
  buttonLabels = [],
  templateIds = [],
  dynamicContent,
  historyMode,
  ConditionData, // Data from history mode
}) => {
  console.log(condition);
  const initialSet = useRef(false);
  const [field, setField] = useState(condition.field || "");

  useEffect(() => {
    if (!historyMode && parent === "ConditionAction" && !initialSet.current && !condition.field) {
      const defaultField = "message";
      onConditionChange({
        ...condition,
        field: defaultField,
        operator: "equals",
        value: "",
      });
      setField(defaultField);
      initialSet.current = true;
    }
  }, [parent, condition, onConditionChange, historyMode]);

  useEffect(() => {
    if (!historyMode && condition.field && condition.field !== field) {
      setField(condition.field);
    }
  }, [condition.field, field, historyMode]);

  const handleFieldChange = (newField) => {
    setField(newField);

    if (newField === "ResponseLastSentTemplateMessageButtonClicked") {
      const defaultValue =
        buttonLabels?.length > 0 && templateIds?.length > 0
          ? `${buttonLabels[0]} + ${templateIds[0]}`
          : "";
      onConditionChange({
        ...condition,
        field: newField,
        value: defaultValue,
        templateId: templateIds[0] || "",
      });
    } else if (newField === "hardCoded") {
      onConditionChange({
        ...condition,
        field: newField,
        value: "",
        templateId: null,
      });
    } else {
      onConditionChange({ ...condition, field: newField, value: "" });
    }
  };

  const handleOperatorChange = (e) => {
    onConditionChange({ ...condition, operator: e.target.value });
  };

  const handleValueChange = (e) => {
    const newValue = e.target ? e.target.value : e;
    onConditionChange({ ...condition, value: newValue });
  };

  /**
   * Renders the left field - `FieldValue` in history mode
   */
  const renderLeftField = () => {
    if (historyMode) {
      return <div className="history-mode-field">{ConditionData?.FieldValue || "N/A"}</div>;
    }

    if (field === "hardCoded") {
      return (
        <ActionInputField
          label=""
          value={condition.fieldValue || ""}
          onChange={(newValue) =>
            onConditionChange({ ...condition, fieldValue: newValue })
          }
          dynamicContent={dynamicContent}
          type="input"
        />
      );
    }

    return (
      <select
        value={field}
        onChange={(e) => handleFieldChange(e.target.value)}
        disabled={historyMode}
      >
        <option value="message">Message</option>
        {parent === "ConditionAction" && (
          <>
            <option value="ResponseLastSentTemplateMessageButtonClicked">
              Response Last Sent Message - Template Button Clicked
            </option>
            <option value="hardCoded">Hard Coded</option>
          </>
        )}
      </select>
    );
  };

  /**
   * Renders the value field - `Value` in history mode
   */
  const renderValueField = () => {
    if (historyMode) {
      return <div className="history-mode-value">{ConditionData?.Value || "N/A"}</div>;
    }

    if (booleanOperators.includes(condition.operator)) {
      // Check if condition.value is set, otherwise set a default value ("false")
      const conditionValue = condition.value || "false";
  
      return (
        <select
          value={conditionValue}  // Set value to the condition's value
          onChange={handleValueChange}
          disabled={historyMode}
        >
          <option value="true">True</option>
          <option value="false">False</option>
        </select>
      );
    }

    if (field === "ResponseLastSentTemplateMessageButtonClicked") {
      return (
        <select
          value={condition.value || ""}
          onChange={(e) => handleValueChange(e.target.value)}
          disabled={historyMode}
        >
          {buttonLabels.map((label, index) => (
            <option key={index} value={`${label} + ${templateIds[index]}`}>
              {`${label} + ${templateIds[index]}`}
            </option>
          ))}
        </select>
      );
    }

    return (
      <div className="action-input-wrapper">
        <ActionInputField
          label=""
          value={condition.value}
          onChange={handleValueChange}
          dynamicContent={dynamicContent}
          type="input"
          readOnly={historyMode}
        />
      </div>
    );
  };

  return (
    <div className="condition-row">
      {/* Field Value on the Left */}
      {renderLeftField()}

      {/* Condition Operator in the Middle */}
      <select
        value={historyMode ? ConditionData?.Operator || condition.operator : condition.operator}
        onChange={handleOperatorChange}
        disabled={historyMode}
      >
        {operators.map((op) => (
          <option key={op} value={op}>
            {op}
          </option>
        ))}
      </select>

      {/* Value on the Right */}
      {renderValueField()}

      {!historyMode && <FaTrash onClick={onDelete} className="fa-trash" />}
    </div>
  );
};

export default ConditionRow;

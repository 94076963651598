import React, { useState, useEffect } from 'react';
import PlanSelection from './PlanSelection';
import SimSelection from './SimSelection';
import BasicInfo from './BasicInfo';
import Payment from './Payment';
import ProgressBar from './ProgressBar';
import WabaOnboarding from './WabaOnboarding';
import './OnboardingProcess.css';

const OnboardingProcess = () => {
    const [step, setStep] = useState(1);
    const [plan, setPlan] = useState(null);
    const [hasSim, setHasSim] = useState(false);
    const [organizationPhoneNumber, setOrganizationPhoneNumber] = useState('');
    const [isPhoneNumberSelected, setIsPhoneNumberSelected] = useState(false);
    const [forwardingPhoneNumber, setForwardingPhoneNumber] = useState('');
    const [companyInfo, setCompanyInfo] = useState({
        companyName: '',
        organizationName: '',
        companyUrl: '',
        contactFullName: '',
        contactEmail: '',
        timezone: '',
        contactPhoneNumber: '',
    });

    const videoUrl = 'path_to_video.mp4';

    // גלילה למעלה בכל שינוי של השלב
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [step]);

    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    return (
        <div className="onboarding-process-container">
            <h1 className="onboarding-process-title">יצירת חשבון</h1>
            <ProgressBar step={step} />
            <div className="onboarding-process-step-content">
                {step === 1 && (
                    <PlanSelection 
                        plan={plan}
                        setPlan={setPlan}
                        nextStep={nextStep}
                    />
                )}
                {step === 2 && (
                    <SimSelection 
                        hasSim={hasSim}
                        setHasSim={setHasSim}
                        organizationPhoneNumber={organizationPhoneNumber}
                        setOrganizationPhoneNumber={setOrganizationPhoneNumber}
                        isPhoneNumberSelected={isPhoneNumberSelected}
                        setForwardingPhoneNumber={setForwardingPhoneNumber}
                        forwardingPhoneNumber={forwardingPhoneNumber}
                        setIsPhoneNumberSelected={setIsPhoneNumberSelected}
                        nextStep={nextStep}
                        prevStep={prevStep}
                    />
                )}
                {step === 3 && (
                    <BasicInfo 
                        companyInfo={companyInfo}
                        setCompanyInfo={setCompanyInfo} 
                        nextStep={nextStep}
                        prevStep={prevStep}
                    />
                )}
                {step === 4 && (
                    <Payment 
                        plan={plan}
                        hasSim={hasSim}
                        organizationPhoneNumber={organizationPhoneNumber}
                        companyInfo={companyInfo} 
                        forwardingPhoneNumber={forwardingPhoneNumber}
                        nextStep={nextStep}
                        prevStep={prevStep}
                    />
                )}
                {step === 5 && (
                    <WabaOnboarding 
                        videoUrl={videoUrl}
                        onPrevStep={prevStep}
                        organization={companyInfo?.organizationName}
                        email={companyInfo?.contactEmail}     
                        hasSim={hasSim}
                        contactPhoneNumber={companyInfo?.contactPhoneNumber}
                        simPhoneNumber={organizationPhoneNumber}         
                    />
                )}
            </div>
        </div>
    );
};

export default OnboardingProcess;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ActionInputField from './ActionInputField';
import './GambotAiAction.css';

const GambotAiAction = ({ organization, onChange, initialConfig = {}, dynamicContent, historyMode, initialValue }) => {
    const [selectedGambotAi, setSelectedGambotAi] = useState(initialConfig.selectedGambotAi || ''); // Store selected AI model
    const [botInstructions, setBotInstructions] = useState(initialConfig.botInstructions || ''); // Bot instructions
    const [phoneNumber, setPhoneNumber] = useState(initialConfig.phoneNumber || ''); // Phone number state
    const [gambotAiList, setGambotAiList] = useState([]); // List of all Gambot AI models

    useEffect(() => {
        const fetchGambotAi = async () => {
            try {
                const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetAllGambotAi', {
                    organization: organization
                });
                setGambotAiList(response.data);
            } catch (error) {
                console.error('Error fetching GambotAi data:', error);
            }
        };

        fetchGambotAi();
    }, [organization]);

    useEffect(() => {
        if (historyMode) {
            setSelectedGambotAi(initialValue?.selectedGambotAi || '');
            setBotInstructions(initialValue?.botInstructions || '');
            setPhoneNumber(initialValue?.phoneNumber || ''); // Set initial phone number from history
        } else {
            const config = {
                selectedGambotAi,
                botInstructions,
                phoneNumber,
            };

            // Only call onChange if the config has changed
            if (JSON.stringify(initialConfig) !== JSON.stringify(config)) {
                onChange(config); // Trigger the onChange callback
            }
        }
    }, [selectedGambotAi, botInstructions, phoneNumber, onChange, historyMode, initialValue, initialConfig]);

    return (
        <div className="gambot-ai-action">
            <label>
                Select Gambot AI Model:
                <select
                    value={selectedGambotAi}
                    onChange={(e) => setSelectedGambotAi(e.target.value)}
                    disabled={historyMode}
                >
                    <option value="">Select AI Model</option>
                    {gambotAiList.map((ai) => (
                        <option key={ai.Id} value={ai.Id}>
                            {ai.BotName}
                        </option>
                    ))}
                </select>
            </label>
            <label>
                Phone Number:
                <ActionInputField
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    dynamicContent={dynamicContent}
                    type="input"
                    readOnly={historyMode}
                />
            </label>
            <label>
                Bot Instructions:
                <ActionInputField
                    label=""
                    value={botInstructions}
                    onChange={setBotInstructions}
                    dynamicContent={dynamicContent}
                    type="textarea"
                    readOnly={historyMode}
                />
            </label>

            {/* Phone Number Field */}
           
        </div>
    );
};

export default GambotAiAction;

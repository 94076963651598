import React, { useState } from "react";
import "./IntroductionForm.css";

const IntroductionForm = () => {
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [fullName, setFullName] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent form submission from reloading the page
    console.log('Form submitted with:', { email, phoneNumber, fullName });

    // Prepare data to send
    const requestData = {
      email: email,
      phoneNumber: phoneNumber,
      fullName: fullName,
      'ClientId': 'R9f6r4oe5PSCLr6CnYRQ', // Add ClientId header
      'source' : 'Gambot Website - Home Page'
    };

    try {
      // Send request to Azure Logic App endpoint
      const response = await fetch("https://prod-63.northeurope.logic.azure.com:443/workflows/ddf6cff7db1b4d438d460e8c4221e768/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ll3QhRKnU64AkU4nk7kF31ob5ZrQzdJElP_ubUtKM5o", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      // Check if the request was successful
      if (response.ok) {
        alert('פנייתך התקבלה בהצלחה. נציגנו יצרו איתך קשר בהקדם האפשרי');
        // You can redirect to the approve page or handle the success here
        // window.location.href = '/approve'; // Navigate to approve page
      } else {
        console.error('Request failed with status:', response.status);
      }
    } catch (error) {
      console.error('Error during request:', error);
    }
  };

  return (
    <div className="IntroductionFormContainer-IntroductionForm">
      <p className="IntroductionHeader">בואו נכיר</p>
      <h1 className="IntroductionTitle">
        נשמח לדבר אתכם, השאירו פרטים ונחזור אליכם בהקדם.
      </h1>
      <p className="IntroductionSubtitle">
        גמבוט מאפשרת ניהול תקשורת חכמה ואוטומטית בוואטסאפ, עם כלים מתקדמים שחוסכים זמן וממקדים במשימות החשובות.
      </p>
      <form className="IntroductionForm" onSubmit={handleSubmit}>
        <button type="submit" className="SubmitButton-IntroductionForm">
          שליחה
        </button>
    
        <input
          type="email"
          className="InputField-IntroductionForm"
          placeholder="אימייל"
          value={email}
          onChange={(e) => setEmail(e.target.value)} // Update email state on change
        />
        <input
          type="tel"
          className="InputField-IntroductionForm"
          placeholder="טלפון"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)} // Update phoneNumber state on change
        />
        <input
          type="text"
          className="InputField-IntroductionForm"
          placeholder="שם"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)} // Update fullName state on change
        />
      </form>
    </div>
  );
};

export default IntroductionForm;

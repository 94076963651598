import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ConfirmationDialog from '../General/ConfirmationDialog';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PreviewRun from './PreviewRun';
import CampaignResult from './CampaignResult';
import './Campaigns.css';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { IoAddCircleOutline, IoTrashOutline } from "react-icons/io5";
import { MdOutlineModeEdit } from "react-icons/md";
import { VscDebugRerun } from "react-icons/vsc";
import { LiaListAltSolid } from "react-icons/lia";


const Campaigns = ({ currentUser }) => {
    const [contacts, setContacts] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]);
    const navigate = useNavigate();
    const [campaigns, setCampaigns] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogPreviewRun, setOpenDialogPreviewRun] = useState(false);
    const [selectedCampaignID, setSelectedCampaignID] = useState('');
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [campaignResults, setCampaignResults] = useState([]);
    const [openDialogResult, setOpenDialogResult] = useState(false);
    const [scheduledCampaigns, setScheduledCampaigns] = useState([]);
    const [showScheduledCampaigns, setShowScheduledCampaigns] = useState(false);
    const [campaignScheduledStatus, setCampaignScheduledStatus] = useState({});
    const [isRun, setIsRun] = useState(false);

    useEffect(() => {   
        console.log(currentUser);
        const fetchCampaigns = async () => {
            try {
                const response = await axios.get('https://gambot.azurewebsites.net/api/Webhooks/GetAllCampaigns', {
                    params: {
                        organizationID: currentUser?.organization
                    }
                });
                let timeZone = currentUser?.timeZone;
                // const sortedData = response.data.sort((a, b) => {
                //     const dateA = a.createdOn ? new Date(a.createdOn) : new Date(0);
                //     const dateB = b.createdOn ? new Date(b.createdOn) : new Date(0);
                //     return dateB - dateA;
                // });
                console.log(response.data);
                const sortedData = response.data
                .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn)) // Sort by createdOn descending
                .map(campaign => ({
                  ...campaign,
                  createdOn: formatToUserTimezone(campaign?.createdOn, timeZone), // Convert createdOn to user timezone
                  modifiedOn: formatToUserTimezone(campaign?.modifiedOn, timeZone) // Convert modifiedOn to user timezone
                }));
                console.log(sortedData);
                setCampaigns(sortedData);
            } catch (error) {
                console.log(error);
            }
        };

        const formatToUserTimezone = (utcDate, timezone) => {
            if (!utcDate) return 'No date available';
          
            try {
              return new Intl.DateTimeFormat('en-GB', {
                timeZone: timezone,
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false
              }).format(new Date(utcDate));
            } catch (error) {
              console.error('Error formatting date:', error, 'Timezone:', timezone);
              return 'Invalid date';
            }
          };
        const fetchScheduledCampaigns = async () => {
            try {
                const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetWaitingScheduledCampaignsByOrganization', {
                    organization: currentUser?.organization
                });
                console.log('GetWaitingScheduledCampaignsByOrganization-res' , response.data);
                // const scheduledStatusObj = response.data.reduce((acc, campaign) => {
                //     acc[campaign.campaignId] = true;
                //     return acc;
                // }, {});

                setCampaignScheduledStatus(response.data);
            } catch (error) {
                console.error('Error fetching scheduled campaigns:', error);
            }
        };

        if (currentUser?.organization) {
            fetchCampaigns();
            fetchScheduledCampaigns();
        }
    }, [currentUser?.organization]);
    const isCampaignScheduled = (campaignId) => {
        return Array.isArray(campaignScheduledStatus) && campaignScheduledStatus.some(scheduledCampaign => scheduledCampaign.CampaignId === campaignId);
    };
    const handleResultClick = (campaign) => {
        setSelectedCampaign(campaign);
        axios.post("https://gambot.azurewebsites.net/api/Webhooks/GetCampaignResultByCmpaignId", {
            organization: currentUser?.organization,
            campaignId: campaign.campaingId
        })
        .then((response) => {
            const sortedResults = response.data.sort((a, b) => new Date(b.RunsAt ) - new Date(a.RunsAt ));
            setCampaignResults(sortedResults);
            setOpenDialogResult(true);
        })
        .catch((error) => {
            console.error('Error fetching campaign result:', error);
        });
    };
    

    const handlePlayClick = (campaign) => {
        setSelectedCampaign(campaign);
        if (campaign.recipientSource === "Contacts") {
            axios.post("https://gambot.azurewebsites.net/api/Webhooks/GetAllContactsByOrg", {
                organizationiD: currentUser?.organization
            })
            .then((response) => {
                const data = response.data;
                const newContacts = data.map((contact) => ({
                    id: contact.id,
                    ...contact,
                    isEditing: false,
                }));
                console.log(newContacts);
                console.log(campaign.ContactsQuery);
                if (campaign.ContactsQuery) {
                    const filterCriteria = campaign.ContactsQuery.map(query => {
                        const { selectedgroup, selectedoperator } = query[0];
                        return {
                            selectedoperator,
                            selectedgroup: selectedgroup.map(group => group.value)
                        };
                    });

                    const filteredContacts = newContacts.filter(contact => {
                        return filterCriteria.every(criteria => {
                            const { selectedoperator, selectedgroup } = criteria;
                            const contactKeys = contact.keys || [];
                            console.log(selectedoperator);
                            if (selectedoperator === "equals") {
                                return selectedgroup.some(group => contactKeys.includes(group));
                            } else if (selectedoperator === "notEquals") {
                                return !selectedgroup.some(group => contactKeys.includes(group));
                            }
                            return false;
                        });
                    });

                    setContacts(filteredContacts);
                    setFilteredContacts(filteredContacts);
                    setOpenDialogPreviewRun(true);
                    setSelectedCampaignID(campaign.campaingId);
                    setIsRun(true);
                } else {
                    console.error('campaign.ContactsQuery is undefined');
                }
            })
            .catch((error) => {
                console.error('Error fetching contacts from backend:', error);
            });
        }
    };

    const handleDeleteClick = (campaign) => {
        setSelectedCampaign(campaign);
        setOpenDialog(true);
    };

    const handleConfirmDelete = () => {
        const payload = {
            organization: currentUser?.organization,
            CampaignId: selectedCampaign.campaingId
        };
        
        // Send a request to delete the campaign
        axios.post("https://gambot.azurewebsites.net/api/Webhooks/DeleteCampaign", payload)
            .then((response) => {
                console.log(response);
                // After deleting, filter out the deleted campaign from the campaigns list
                setCampaigns((prevCampaigns) => 
                    prevCampaigns.filter(campaign => campaign.campaingId !== selectedCampaign.campaingId)
                );
                setOpenDialog(false); // Close the confirmation dialog
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setOpenDialogPreviewRun(false);
        setOpenDialogResult(false);
        setShowScheduledCampaigns(false);
    };

    const HandleNewCampaign = () => {
        const thisCampaignQueryString = new URLSearchParams({
            organization: currentUser?.organization,
            userEmail: currentUser?.Email,
            mode: "Create"
        });
        navigate(`/NewCampaign/?${thisCampaignQueryString}`);
    };

    const handleConfirmSend = () => {
        const payload = {
            organization: currentUser?.organization,
            runCampaignData: {
                recipientSource: selectedCampaign.recipientSource,
                recipientSourceTable: selectedCampaign.recipientSourceTable || "Gambot_Contacts",
                recipientPhoneNumbers: filteredContacts.map(contact => contact.phoneNumber),
                messageType: selectedCampaign.messageType || "template",
                templateId: selectedCampaign.wabaTemplateId,
                campaignId: selectedCampaign.campaingId,
                runById: currentUser?.userId,
                message: selectedCampaign?.message
            }
        };
        console.log('payload');
        console?.log(payload);

        axios.post("https://gambot.azurewebsites.net/api/Webhooks/RunCampaign", payload)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.error('Error running campaign:', error);
            });
        setOpenDialogPreviewRun(false);
    };

    const handleEditClick = (campaign) => {
        const thisCampaignQueryString = new URLSearchParams({
            organization: currentUser?.organization,
            ...campaign
        });
        navigate(`/UpdateCampaign/?${thisCampaignQueryString}`);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleString('en-GB', { timeZone: 'Asia/Jerusalem', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
    };

    const handleScheduledCampaignClick = async (campaignId) => {
        try {
            const response = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/GetActiveScheduledCampaignById", {
                organization: currentUser?.organization,
                campaignId: campaignId
            });
            setScheduledCampaigns(response.data);
            setShowScheduledCampaigns(true);
        } catch (error) {
            console.error("Error fetching scheduled campaigns:", error);
        }
    };

    const filteredCampaigns = campaigns.filter(campaign => 
        campaign?.campaignName?.toLowerCase().includes(searchQuery?.toLowerCase())
    );
    console.log(campaignScheduledStatus);
    const handleRemoveRecipient = (recipientId) => {
        setFilteredContacts((prevContacts) => prevContacts.filter(contact => contact.id !== recipientId));
    };



    return (
        <div className='campaign-list-container'>
            <h1 className='campaign-list-header'>Campaigns</h1>
            <div className='search-bar'>
                    <input 
                        type="text" 
                        placeholder="Search Campaigns by Name" 
                        value={searchQuery} 
                        onChange={handleSearchChange} 
                    />
                </div>
                <div className='campaigns-action-buttons'>
                <button className="add-new-campaign-button" onClick={() => HandleNewCampaign()}>Add new campaign <IoAddCircleOutline /></button>
            </div>
            <div className='campaign-list-table-container'>
                <table className='campaign-list-table'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Trigger</th>
                            <th>Last Modifide</th>
                            <th>Recipient Source</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredCampaigns.map((campaign) => (
                            <tr key={campaign.id}>
                                <td>{campaign.campaignName}</td>
                                <td>{campaign.campaignTrigger}</td>
                                {/* <td>{formatDate(campaign.createdOn)}</td> */}

                                <td>{campaign?.modifiedOn}<br></br> by {campaign?.modifiedByName || 'Unknown'}</td>

                                <td>{campaign.recipientSource}</td>
                                <td>
                                <button className="campaign-edit-button" onClick={() => handleEditClick(campaign)} title="Edit campaign">
                                    <MdOutlineModeEdit />
                                </button>
                                <button className="campaign-delete-button" onClick={() => handleDeleteClick(campaign)} title="Deleting campaign">
                                    <IoTrashOutline />
                                </button>
                                <button className="campaign-play-button" onClick={() => handlePlayClick(campaign)} title="Running campaign">
                                    <VscDebugRerun />
                                </button>
                                <button className="campaign-result-button" onClick={() => handleResultClick(campaign)} title="Campaign results">
                                    <LiaListAltSolid />
                                </button> 
                                    {isCampaignScheduled(campaign.campaingId) && (
                                    <ScheduleIcon className="campaign-scheduled-button"/>
                                                                                // <ScheduleIcon className="campaign-scheduled-button" onClick={() => handleScheduledCampaignClick(campaign.campaingId)} />

                                        // <div className='campaign-scheduled-icon'>
                                        // </div>
                                        
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <ConfirmationDialog
                open={openDialog}
                onClose={handleCloseDialog}
                onConfirm={handleConfirmDelete}
            />
            <PreviewRun
                open={openDialogPreviewRun}
                recipients={filteredContacts}
                campaign={selectedCampaign}
                onConfirm={handleConfirmSend}
                onClose={() => setOpenDialogPreviewRun(false)}
                onRemoveRecipient={handleRemoveRecipient} // Pass the function to PreviewRun
                organization={currentUser?.organization}

            />
            <CampaignResult
                open={openDialogResult}
                onClose={handleCloseDialog}
                campaignResult={campaignResults.length > 0 ? campaignResults : null}
                currenUser={currentUser}
            />

            {showScheduledCampaigns && (
                <div className='scheduled-campaigns-popup'>
                    <div className='scheduled-campaigns-popup-inner'>
                        <h3>Scheduled Campaigns</h3>
                        <ul>
                            {scheduledCampaigns.map((scheduledCampaign) => (
                                <li key={scheduledCampaign.ScheduledCampaignId}>
                                    {scheduledCampaign.campaignName} - {scheduledCampaign.status}
                                </li>
                            ))}
                        </ul>
                        <button onClick={() => setShowScheduledCampaigns(false)}>Close</button>
                    </div>
                </div>
            )}

          
        </div>
    );
};

export default Campaigns;

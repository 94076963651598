

import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { SidebarData } from './SidebarData.js';
import { IoIosArrowDown } from "react-icons/io";
import './NavBarMenu.css';
import GambotLogo from '../../../../src/assets/Gambot_Logo_rounded.png'; 


function NavbarMenu() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isHomeDropdownOpen, setHomeDropdownOpen] = useState(false);

  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleHomeDropdown = () => {
    setHomeDropdownOpen(!isHomeDropdownOpen);
  };

  const handleDropdownClick = (path, scrollTo) => {
    navigate(path, { state: { scrollTo } });
    setMobileMenuOpen(false); // סגור את תפריט המובייל
    setHomeDropdownOpen(false); // סגור את הדרופ-דאון
  };

  // סגירת הדרופ-דאון כשיש לחיצה מחוץ
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setHomeDropdownOpen(false);
      }
    };

    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="NavbarMenu">
      {/* כפתור המבורגר */}
      <div className="hamburger-menu" onClick={toggleMobileMenu}>
        <span className="icon-hamburger" style={{ display: isMobileMenuOpen ? 'none' : 'block' }}>☰</span>
        <span className="icon-close" style={{ display: isMobileMenuOpen ? 'block' : 'none' }}>✖</span>
      </div>

      {/* תפריט */}
      <ul className={`nav-menu-items ${isMobileMenuOpen ? 'mobile open' : 'desktop'}`}>
        {SidebarData.map((item, index) => (
          item.title === 'דף בית' ? (
            <li key={index} className="nav-text dropdown" ref={dropdownRef}>
              <div onClick={toggleHomeDropdown} className="nav-link">
                <span>{item.title}</span>
                <span className={`dropdown-arrow ${isHomeDropdownOpen ? 'open' : ''}`}><IoIosArrowDown /></span>
              </div>
              {isHomeDropdownOpen && (
                <ul className="dropdown-menu">
                  <li>
                    <button className="dropdown-link" onClick={() => navigate('/', { state: { scrollTo: 'HomePageMainSection' } })}>
                    דף הבית
                  </button>
                  <button className="dropdown-link" onClick={() => navigate('/', { state: { scrollTo: 'why-whatsapp' } })}>
                    למה וואטסאפ?
                  </button>
                  <button className="dropdown-link" onClick={() => navigate('/', { state: { scrollTo: 'our-features' } })}>
                    הפיצ’רים שלנו
                  </button>
                  <button className="dropdown-link" onClick={() => navigate('/', { state: { scrollTo: 'Examples' } })}>
                    דוגמאות
                  </button>
                  <button className="dropdown-link" onClick={() => navigate('/', { state: { scrollTo: 'HowWorks' } })}>
                    איך זה עובד
                  </button>
                  <button className="dropdown-link" onClick={() => navigate('/', { state: { scrollTo: 'QuestionsAnswers' } })}>
                    שאלות תשובות
                  </button>
                  </li>
                </ul>
              )}
            </li>
          ) : (
            <li key={index} className={item.cName}>
              <NavLink
                to={item.path}
                className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
                onClick={() => {
                  setMobileMenuOpen(false); // סגור תפריט מובייל
                  setHomeDropdownOpen(false); // סגור דרופ-דאון
                }}
              >
                {/* {item.logo && (
                  <img
                    src={item.logo}
                    alt={item.title}
                    className="menu-logo"
                  />
                )} */}
                <span>{item.title}</span>
              </NavLink>
            </li>
          )
        ))}
      </ul>
      <NavLink to="/" className="navbar-mobile-logo">
        <img src={GambotLogo} alt="Logo" className="logo" />
      </NavLink>
    </div>
  );
}

export default NavbarMenu;

import React, { useState, useEffect, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './WorkflowDesigner.css';
import IncomingMessageTrigger from './IncomingMessageTrigger';
import OutboundMessageTrigger from './OutboundMessageTrigger';
import Scheduled from '../../General/Scheduled';
import ConditionAction from './ConditionAction';
import OutboundMessageAction from './OutboundMessageAction';
import SendHttpRequestAction from './SendHttpRequestAction';
import WebhookTrigger from './WebhookTrigger';
import { FaTrash, FaChevronDown, FaChevronUp, FaEnvelope, FaPaperPlane, FaGlobe, FaQuestionCircle } from 'react-icons/fa';
import GambotAction from './GambotAction';
import ReminderAction from './ReminderAction';
import { IoAddCircleOutline } from "react-icons/io5";
import { IoIosAddCircleOutline } from "react-icons/io";
import GambotAiAction from './GambotAiAction';
import { json } from 'react-router-dom';


const generateDynamicContent = (steps, currentIndex) => {
  const dynamicFields = [];
  console.log(currentIndex);
  console.log(JSON.stringify(steps));
  console.log(steps.length);

  // Helper function to recursively process steps
  const processSteps = (steps, parentPrefix = '') => {
    steps.forEach((step, index) => {
      const action = getStepProperty(step, 'action'); // Normalize 'action'
      const stepPrefix = parentPrefix ? `${parentPrefix}_Step_${index + 1}` : `Step_${index + 1}`;

      switch (action) {
        case 'IncomingMessage': {
          dynamicFields.push(
            { name: `${stepPrefix}_PhoneNumber`, label: `${stepPrefix} - Phone Number` },
            { name: `${stepPrefix}_Message`, label: `${stepPrefix} - Message` }
          );
          break;
        }

        case 'OutboundMessage':
        case 'SendMessage': {
          const config = getStepProperty(step, 'Config'); // Normalize 'Config'
          if (config?.messageType === 'regular') {
            dynamicFields.push(
              { name: `${stepPrefix}_MessageContent`, label: `${stepPrefix} - Message Content` },
              { name: `${stepPrefix}_PhoneNumber`, label: `${stepPrefix} - Phone Number` } // Add PhoneNumber
            );
          } else if (config?.messageType === 'template') {
            dynamicFields.push(
              { name: `${stepPrefix}_TemplateName`, label: `${stepPrefix} - Template Name` },
              { name: `${stepPrefix}_TemplateVariables`, label: `${stepPrefix} - Template Variables` },
              { name: `${stepPrefix}_PhoneNumber`, label: `${stepPrefix} - Phone Number` } // Add PhoneNumber
            );
          }
          break;
        }

        case 'SendHttpRequest': {
          dynamicFields.push(
            { name: `${stepPrefix}_RequestBody`, label: `${stepPrefix} - Request Body` },
            { name: `${stepPrefix}_ResponseBody`, label: `${stepPrefix} - Response Body` },
            { name: `${stepPrefix}_ResponseStatus`, label: `${stepPrefix} - Response Status` }
          );

          const config = getStepProperty(step, 'config'); // Normalize 'config'

          // Add request body fields if manually built and in JSON format
          if (config?.bodyBuildOption === 'json' && config?.body) {
            try {
              const requestBody = typeof config.body === 'string' ? JSON.parse(config.body) : config.body;

              const extractRequestFields = (obj, prefix = `${stepPrefix}_Request`) => {
                return Object.entries(obj).flatMap(([key, value]) => {
                  const fieldKey = `${prefix}_${key}`;
                  const fieldLabel = `${prefix}: ${key}`;
                  if (typeof value === 'object' && value !== null) {
                    return [
                      { name: fieldKey, label: fieldLabel },
                      ...extractRequestFields(value, fieldKey),
                    ];
                  } else {
                    return { name: fieldKey, label: fieldLabel };
                  }
                });
              };

              dynamicFields.push(...extractRequestFields(requestBody));
            } catch (err) {
              console.error('Invalid manually built JSON request body:', err);
            }
          }

          // Add fields from response JSON schema
          if (config?.handleResponse && config?.responseJsonSchema) {
            try {
              const responseSchema =
                typeof config.responseJsonSchema === 'string'
                  ? JSON.parse(config.responseJsonSchema)
                  : config.responseJsonSchema;

              const extractResponseFields = (obj, prefix = `${stepPrefix}_Response`) => {
                return Object.entries(obj).flatMap(([key, value]) => {
                  const fieldKey = `${prefix}_${key}`;
                  const fieldLabel = `${prefix}: ${key}`;
                  if (typeof value === 'object' && value !== null) {
                    return [
                      { name: fieldKey, label: fieldLabel },
                      ...extractResponseFields(value, fieldKey),
                    ];
                  } else {
                    return { name: fieldKey, label: fieldLabel };
                  }
                });
              };

              dynamicFields.push(...extractResponseFields(responseSchema));
            } catch (err) {
              console.error('Invalid response JSON schema:', err);
            }
          }
          break;
        }


        case 'Condition': {
          const config = getStepProperty(step, 'config'); // Access the config property
          const conditionIndex = 0; // Assuming stepIndex is passed or calculated for the parent step

          // Add a field to indicate the condition result
          dynamicFields.push({
            name: `${stepPrefix}_ConditionResult`,
            label: `${stepPrefix} - Condition Result (True/False)`,
          });

          // Process yesActions recursively with unique indices
          if (Array.isArray(config?.yesActions)) {
            config.yesActions.forEach((yesAction, yesIndex) => {
              //const yesStepPrefix = `${stepPrefix}_Yes_${conditionIndex}_${yesIndex}`;
              const yesStepPrefix = `${stepPrefix}_Yes_${yesIndex}`;

              // dynamicFields.push({
              //   name: `${yesStepPrefix}_Action`,
              //   label: `${yesStepPrefix} - Yes Action`,
              // });
              processSteps([yesAction], yesStepPrefix); // Recursive call for sub-step
            });
          }

          // Process noActions recursively with unique indices
          if (Array.isArray(config?.noActions)) {
            config.noActions.forEach((noAction, noIndex) => {
              // const noStepPrefix = `${stepPrefix}_No_${conditionIndex}_${noIndex}`;
              const noStepPrefix = `${stepPrefix}_No_${noIndex}`;
              processSteps([noAction], noStepPrefix); // Recursive call for sub-step
            });
          }
          break;
        }

        case 'webhook': {
          const webhookConfig = getStepProperty(step, 'Config'); // Normalize 'Config'

          // Add the Webhook's name and URL
          dynamicFields.push(
            { name: `${stepPrefix}_WebhookName`, label: `${stepPrefix} - Webhook Name` },
            { name: `${stepPrefix}_WebhookURL`, label: `${stepPrefix} - Webhook URL` }
          );

          // Add the full JSON schema as a single selectable option
          if (webhookConfig?.jsonSchema) {
            dynamicFields.push({
              name: `${stepPrefix}_WebhookFullJSON`,
              label: `${stepPrefix} - Webhook Full JSON`,
            });

            // Parse JSON schema to extract individual keys
            try {
              const parsedSchema = JSON.parse(webhookConfig.jsonSchema);

              const extractFields = (obj, prefix = `${stepPrefix}_Webhook`) => {
                return Object.entries(obj).flatMap(([key, value]) => {
                  const fieldKey = `${prefix}_${key}`;
                  const fieldLabel = `${prefix}: ${key}`;
                  if (typeof value === 'object' && value !== null) {
                    return [
                      { name: fieldKey, label: fieldLabel },
                      ...extractFields(value, fieldKey),
                    ];
                  } else {
                    return { name: fieldKey, label: fieldLabel };
                  }
                });
              };

              dynamicFields.push(...extractFields(parsedSchema));
            } catch (err) {
              console.error('Invalid JSON schema in webhook step:', err);
            }
          }
          break;
        }

        default:
          console.warn(`Unhandled action type: ${action} at ${stepPrefix}`);
          break;
      }
    });
  };

  // Start processing steps
  processSteps(steps.slice(0, currentIndex));
  return dynamicFields;
};

const getStepProperty = (step, property) => {
  const normalizedStep = Object.keys(step).reduce((acc, key) => {
    acc[key.toLowerCase()] = step[key];
    return acc;
  }, {});
  return normalizedStep[property.toLowerCase()];
};

const deepEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};


const WorkflowDesigner = ({ currentUser, steps, setSteps, historyMode }) => {
  const [expanded, setExpanded] = useState({});
  const yesActionInProgress = useRef(false);  // Flag for "if yes" actions
  const noActionInProgress = useRef(false);   // Flag for "if no" actions
  // Utility function to normalize step properties

  const normalizeStep = (step, index) => {
    console.log("Processing Step:", step); // Debugging: Log entire step structure

    const findDataForStep = (stepList, stepId) => {
      console.log("Finding Data for StepId:", stepId, "in", stepList); // Debugging
      if (!stepList || !Array.isArray(stepList)) return [];

      return stepList.find(s => String(s.StepId || "").trim().toLowerCase() === String(stepId || "").trim().toLowerCase())?.Data || [];
    };

    return {
      ...step,
      StepId: step.StepId || `Step_${index + 1}`, // Assign main StepId if missing

      action: step.Action || step.action,
      config: step.Config || step.config,
      type: step.Type || step.type,
      Data: step.Data || [], // Ensure main step includes its own Data

      // Normalize Yes Actions (Ensure Data is assigned)
      yesActions: step.Config?.yesActions?.map((yesStep, index) => {
        const stepId = `1.yes.${index}`;
        console.log("Processing Yes Action:", yesStep, "StepId:", stepId);

        return {
          ...yesStep,
          StepId: stepId,
          Data: findDataForStep(step.YesActions || [], stepId) // Correctly fetch Data
        };
      }) || [],

      // Normalize No Actions (Ensure Data is assigned)
      noActions: step.Config?.noActions?.map((noStep, index) => {
        const stepId = `1.no.${index}`;
        console.log("Processing No Action:", noStep, "StepId:", stepId);

        return {
          ...noStep,
          StepId: stepId,
          Data: findDataForStep(step.NoActions || [], stepId) // Correctly fetch Data
        };
      }) || [],

      // Ensure config.yesActions also contain Data
      config: {
        ...step.Config,
        yesActions: step.Config?.yesActions?.map((yesStep, index) => {
          const stepId = `1.yes.${index}`;
          return {
            ...yesStep,
            StepId: stepId,
            Data: findDataForStep(step.YesActions || [], stepId)
          };
        }) || [],
        noActions: step.Config?.noActions?.map((noStep, index) => {
          const stepId = `1.no.${index}`;
          return {
            ...noStep,
            StepId: stepId,
            Data: findDataForStep(step.NoActions || [], stepId)
          };
        }) || [],
      }
    };
  };

  // Normalize all steps
  const normalizedSteps = steps.map((step, index) => normalizeStep(step, index));
  console.log("Normalized Steps:", JSON.stringify(normalizedSteps, null, 2)); // Debugging output



  const handleAddTrigger = () => {
    setSteps([...steps, { type: 'trigger', action: null, config: {} }]);
    setExpanded({ [steps.length]: true });
  };

  const handleAddAction = (index) => {
    console.log("Adding new action at index:", index);

    // Clone existing steps
    const newSteps = [...steps];

    // Determine new StepId based on the highest current StepId
    const newStepId = `Step_${newSteps.length + 1}`;

    // Insert the new action step with StepId
    newSteps.splice(index + 1, 0, { 
        StepId: newStepId, 
        type: 'action', 
        action: null, 
        config: {} 
    });

    // Update state
    setSteps(newSteps);
    setExpanded({ [index + 1]: true });

    console.log("Updated Steps:", newSteps);
};

  // const handleAddAction = (index) => {
  //   console.log(index);
  //   const newSteps = [...steps];
  //   console.log(newSteps);
  //   newSteps.splice(index + 1, 0, { type: 'action', action: null, config: {} });
  //   setSteps(newSteps);
  //   setExpanded({ [index + 1]: true });
  // };

  const handleSelectAction = (index, action) => {
    const newSteps = [...steps];
    newSteps[index].action = action;
    setSteps(newSteps);
    setExpanded({ [index]: true });
  };

  const handleDeleteAction = (index) => {
    const newSteps = steps.filter((_, i) => i !== index);
    setSteps(newSteps);
    const newExpanded = { ...expanded };
    delete newExpanded[index];
    setExpanded(newExpanded);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedSteps = Array.from(steps);
    const [movedStep] = reorderedSteps.splice(result.source.index, 1);
    reorderedSteps.splice(result.destination.index, 0, movedStep);

    setSteps(reorderedSteps);
  };

  const handleTriggerConfigChange = (index, config) => {
    const newSteps = [...steps];
    newSteps[index].config = config;
    setSteps(newSteps);
  };

  const handleActionConfigChange = (index, config) => {
    const newSteps = [...steps];
    newSteps[index].config = config;
    setSteps(newSteps);
  };


  const handleHttpRequestResponse = (index, config) => {
    const newSteps = [...steps];
    newSteps[index].config = config;
    setSteps(newSteps);
  };

  const toggleExpand = (index) => {
    setExpanded((prevExpanded) => ({ ...prevExpanded, [index]: !prevExpanded[index] }));
  };

  const getButtonLabelsAndTemplateIds = (steps) => {
    const buttonLabels = [];
    const templateIds = [];

    const processSteps = (stepList) => {
      stepList.forEach((step) => {
        if (step.type === 'action' && step.action === 'SendMessage' && step.config?.templateConfig?.template?.components) {
          const templateName = step.config.templateConfig.template.name || 'Unnamed Template';
          const templateId = step.config.templateConfig.template.Id;
          step.config.templateConfig.template.components.forEach((component) => {
            if (component.type === 'BUTTONS' && component.buttons) {
              component.buttons.forEach((button) => {
                if (button.text) {
                  buttonLabels.push(templateName + " + " + button.text);
                  templateIds.push(templateId);
                }
              });
            }
          });
        }
        if (step.type === 'action' && step.action === 'Condition') {
          processSteps(step.config?.yesActions || []);
          processSteps(step.config?.noActions || []);
        }
      });
    };

    processSteps(steps);

    return { buttonLabels, templateIds };
  };
  const formatActionName = (action) => {
    if (!action) return '';
    return action
      .replace(/([A-Z])/g, ' $1')  // Add a space before each uppercase letter
      .replace(/(^\w|\s\w)/g, m => m.toUpperCase())  // Capitalize the first letter of each word
      .trim();  // Remove any leading/trailing whitespace
  };

  const getActionIcon = (action) => {
    switch (action) {
      case 'IncomingMessage':
        return <FaEnvelope />;
      case 'SendMessage':
        return <FaPaperPlane />;
      case 'SendHttpRequest':
        return <FaGlobe />;
      case 'Condition':
        return <FaQuestionCircle />;
      default:
        return null;
    }
  };
  console.log(steps);
  return (
    <div className="workflow-designer">
      {steps.length === 0 ? (
        <button onClick={handleAddTrigger} className="add-trigger-button" type="button">
        <IoAddCircleOutline />  Add trigger 
        </button>
      ) : (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="workflow-steps">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps} className="workflow-steps-container">
                {normalizedSteps.map((step, index) => {
                  const dynamicContent = step.type === 'action' ? generateDynamicContent(steps, index) : null;

                  return (
                    <Draggable key={index} draggableId={index.toString()} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="workflow-step"
                        >
                          <div className="step-controls">
                            <div className="expand-delete-container">
                              <button onClick={() => toggleExpand(index)} className="expand-button" type="button">
                                {expanded[index] ? <FaChevronUp /> : <FaChevronDown />}
                              </button>
                            </div>
                            <span className="step-header">
                              {step.type === 'trigger'
                                ? (step.action ? formatActionName(step.action) : 'Manage adding a trigger')
                                : (step.action ? formatActionName(step.action) : 'Management select action')}
                            </span>
                            {historyMode ? (
                              <span>{step.Status === 'Completed' ? '✔️ Completed' : '⏳ In Progress'}</span>
                            ) : (
                              <FaTrash onClick={() => handleDeleteAction(index)} className="delete-action-button" />
                            )}
                            {/* <FaTrash onClick={() => handleDeleteAction(index)} className="delete-action-button" /> */}
                          </div>

                          {expanded[index] && (
                            <div className="workflow-designer-step-content">
                              {/* Trigger Configuration */}
                              {step.type === 'trigger' || step.StepId === '0' ? (
                                <div className="trigger-selector">
                                 <h3>Select trigger</h3> 
                                  <select
                                    onChange={(e) => handleSelectAction(index, e.target.value)}
                                    value={step.action || ''}
                                  >
                                    <option value="">Select Trigger Type</option>
                                    <option value="IncomingMessage">Incoming Message</option>
                                    <option value="OutboundMessage">Outbound Message</option>
                                    {/* <option value="Scheduled">Scheduled</option> */}
                                    {/* <option value="DbOperation">DB Operation</option> */}
                                    <option value="webhook">Webhook</option>
                                    <option value="Gambot">Gambot</option>
                                  </select>
                                  {step.action === 'IncomingMessage' && (
                                    <IncomingMessageTrigger
                                      organization={currentUser?.organization}
                                      onChange={(config) => handleTriggerConfigChange(index, config)}
                                      initialConfig={step.config}
                                      parent={'incomingMessageTrigger'}
                                      historyMode={historyMode}
                                      initialValue={step.Data}
                                    />
                                  )}
                                  {step.action === 'OutboundMessage' && (
                                    <OutboundMessageTrigger
                                      organization={currentUser?.organization}
                                      onChange={(config) => handleTriggerConfigChange(index, config)}
                                      initialConfig={step.config}
                                      parent={'incomingMessageTrigger'}
                                      historyMode={historyMode}
                                      initialValue={step.Data}

                                    />
                                  )}
                                  {/* {step.action === 'Scheduled' && (
                                    <Scheduled
                                      onChange={(config) => handleTriggerConfigChange(index, config)}
                                      initialValue={step.config.date}
                                    />
                                  )} */}

                                  {step.action === 'webhook' && (
                                    <WebhookTrigger
                                      onChange={(config) => handleTriggerConfigChange(index, config)}
                                      initialValue={step.Data}
                                      currentUser={currentUser}
                                      initialConfig={step.config}
                                      historyMode={historyMode}
                                    />
                                  )}
                                </div>
                              ) : (
                                /* Action Configuration */
                                <div className="action-selector">
                                  <h3>Select Action</h3>
                                  <select
                                    onChange={(e) => handleSelectAction(index, e.target.value)}
                                    value={step.action || ''}
                                  >
                                    <option value="">Select Action Type</option>
                                    <option value="Condition">Condition</option>
                                    <option value="SendMessage">Send Message</option>
                                    <option value="Reminder">Reminder</option>
                                    <option value="SendHttpRequest">Send HTTP Request</option>
                                    <option value="IncomingMessage">Incoming Message</option>
                                    <option value="GambotAction">Gambot Action</option>
                                    <option value="GambotAi">Gambot AI</option>
                                  </select>

                                  {/* Dynamic Content Picker Integration */}
                                  {step.action === 'Condition' && (
                                    <ConditionAction
                                      onChange={(config) => handleActionConfigChange(index, config)}
                                      previousQuestions={getButtonLabelsAndTemplateIds(steps)}
                                      steps={steps}
                                      currentUser={currentUser}
                                      conditionId={index}
                                      initialConfig={step.config}
                                      dynamicContent={dynamicContent}
                                      parent={'workflowDesigner'}
                                      generateDynamicContent={generateDynamicContent} // Pass the function
                                      historyMode={historyMode}
                                      initialValue={step.Data}
                                    />
                                  )}
                                  {step.action === 'IncomingMessage' && (
                                    <IncomingMessageTrigger
                                      organization={currentUser?.organization}
                                      onChange={(config) => handleActionConfigChange(index, config)}
                                      initialConfig={step.config}
                                      parent={'IncomingMessagetriggerAction'}
                                      dynamicContent={dynamicContent}
                                      historyMode={historyMode}
                                      initialValue={step?.Data}
                                    />
                                  )}
                                  {step.action === 'SendMessage' && (
                                    <OutboundMessageAction
                                      dynamicContent={dynamicContent}
                                      onChange={(config) => handleActionConfigChange(index, config)}
                                      initialConfig={step.config}
                                      steps={steps}
                                      organization={currentUser?.organization}
                                      parent={'OutboundMessageAction'}
                                      historyMode={historyMode}
                                      initialValue={step.Data}
                                    />
                                  )}
                                  {step.action === 'SendHttpRequest' && (
                                    <SendHttpRequestAction
                                      steps={steps}
                                      historyMode={historyMode}
                                      initialValue={step.Data}
                                      dynamicContent={dynamicContent}
                                      onChange={(config) => handleActionConfigChange(index, config)}
                                      initialConfig={step.config}
                                    />

                                  )}
                                  {step.action === 'GambotAction' && (
                                    <GambotAction
                                      historyMode={historyMode}
                                      onChange={(config) => handleActionConfigChange(index, config)}
                                      organization={currentUser?.organization}
                                      initialConfig={step.config}
                                      dynamicContent={dynamicContent}
                                    />
                                  )}

                                  {step.action === 'Reminder' && (
                                    <ReminderAction
                                      onChange={(config) => handleActionConfigChange(index, config)}
                                      steps={steps}
                                      currentUser={currentUser}
                                      initialConfig={step.config}
                                      dynamicContent={dynamicContent}
                                      historyMode={historyMode}
                                      initialValue={step.Data}

                                    />
                                  )}
                                  {step.action === 'GambotAi' && (
                                    <GambotAiAction
                                    organization={currentUser?.organization}
                                    onChange={(config) => handleActionConfigChange(index, config)}
                                    initialConfig={step.config}
                                    dynamicContent={dynamicContent}
                                    historyMode={historyMode}
                                  />
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {steps.length > 0 && (
        <button onClick={() => handleAddAction(steps.length - 1)} className="add-action-button" type="button">
           Add action <IoIosAddCircleOutline />
        </button>
      )}
    </div>
  );
};

export default WorkflowDesigner;

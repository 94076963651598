import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-timezone-select';
import './Scheduled.css';
import { allTimezones } from 'react-timezone-select';


const parseDate = (dateString) => {
    const [day, month, yearAndTime] = dateString.split('/');
    const [year, time] = yearAndTime.split(' ');
    const [hours, minutes, seconds] = time.split(':');
    return new Date(
        parseInt(year, 10),
        parseInt(month, 10) - 1, // Month is zero-based
        parseInt(day, 10),
        parseInt(hours, 10),
        parseInt(minutes, 10),
        parseInt(seconds, 10)
    );
};


const Scheduled = ({ onChange, initialValue }) => {
    const [scheduleType, setScheduleType] = useState('once');
    console.log(initialValue);
    const [startDate, setStartDate] = useState(() => {
        if (initialValue) {
            try {
                return parseDate(initialValue);
            } catch (err) {
                console.error('Invalid initialValue format:', initialValue);
                return new Date();
            }
        }
        return new Date();
    });
    const [interval, setInterval] = useState('Minute');
    const [intervalNumber, setIntervalNumber] = useState(1);
    const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

    useEffect(() => {
        if (initialValue) {
            try {
                setStartDate(parseDate(initialValue));
            } catch (err) {
                console.error('Invalid initialValue format:', initialValue);
            }
        }
    }, [initialValue]);

    const handleDateChange = (date) => {
        setStartDate(date);
        const formattedDate = formatDateTime(date);
        onChange({ scheduleType, date: formattedDate, interval, intervalNumber, timezone });
    };

    const handleIntervalChange = (e) => {
        setInterval(e.target.value);
        onChange({ scheduleType, date: formatDateTime(startDate), interval: e.target.value, intervalNumber, timezone });
    };
    console.log(allTimezones);

    const handleIntervalNumberChange = (e) => {
        setIntervalNumber(e.target.value);
        onChange({ scheduleType, date: formatDateTime(startDate), interval, intervalNumber: e.target.value, timezone });
    };

    const handleScheduleTypeChange = (e) => {
        setScheduleType(e.target.value);
        onChange({ scheduleType: e.target.value, date: formatDateTime(startDate), interval, intervalNumber, timezone });
    };

    const handleTimezoneChange = (tz) => {
        setTimezone(tz.value);
        onChange({ scheduleType, date: formatDateTime(startDate), interval, intervalNumber, timezone: tz.value });
    };

    const formatDateTime = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    };
    console.log(startDate);
    return (
        <div className='scheduled-container'>
            <div className='schedule-type-selector'>
                <label>
                    Schedule type:
                    <br></br>
                    <select value={scheduleType} onChange={handleScheduleTypeChange}>
                        <option value="once">Occurrence once</option>
                        <option value="repeated">Repeated</option>
                    </select>
                </label>
            </div>

            <div className='scheduled-starting'>
                <label>
                    Start date and time:
                    <DatePicker
                        selected={startDate}
                        onChange={handleDateChange}
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeSelect
                        timeIntervals={30}
                        timeFormat="HH:mm"
                    />
                </label>
            </div>

            <div className='scheduled-timezone'>
                <label>Time zone:</label>
                <Select value={timezone} onChange={handleTimezoneChange} />
            </div>

            {scheduleType === 'repeated' && (
                <div className='scheduled-repeat'>
                    <label>
                        Repeat every:
                        <div className='scheduled-input-Repeat'>
                            <input
                                type="number"
                                value={intervalNumber}
                                onChange={handleIntervalNumberChange}
                                min={1}
                            />
                        </div>

                        <div className='scheduled-interval'>
                            <label>
                                Interval unit:
                                <br></br>
                                <select value={interval} onChange={handleIntervalChange}>
                                    <option value="Second">Second</option>
                                    <option value="Minute">Minute</option>
                                    <option value="Hour">Hour</option>
                                    <option value="Day">Day</option>
                                    <option value="Week">Week</option>
                                    <option value="Month">Month</option>
                                    <option value="Year">Year</option>
                                </select>
                            </label>
                        </div>
                    </label>
                </div>
            )}
        </div>
    );
};

export default Scheduled;

import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HeaderTypeSelector from './HeaderTypeSelector';
import MediaOptions from './MediaOptions';
import VariableInput from './VariableInput';
import MessagePreview from './MessagePreview';
import ButtonOptions from './ButtonOptions';
import FooterText from './FooterText';
import BodyText from './BodyText';
import axios from 'axios';
import ConfirmationDialog from '../General/ConfirmationDialog';
import LoadingOverlay from '../General/LoadingOverlay';


const TemplateUpdate = (currentUser) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const templateId = useMemo(() => searchParams.has('Id') ? searchParams.get('Id') : null, [searchParams]);
    const templateName = useMemo(() => searchParams.has('name') ? searchParams.get('name') : null, [searchParams]);
    const status = useMemo(() => searchParams.has('status') ? searchParams.get('status') : null, [searchParams]);
    const category = useMemo(() => searchParams.has('category') ? searchParams.get('category') : null, [searchParams]);
    const language = useMemo(() => searchParams.has('language') ? searchParams.get('language') : null, [searchParams]);
    const components = useMemo(() => searchParams.has('components') ? JSON.parse(searchParams.get('components')) : null, [searchParams]);
    console.log(JSON.parse(searchParams.get('components')));
    const error = useMemo(() => searchParams.has('error') ? searchParams.get('error') : null, [searchParams]);
    const organization = useMemo(() => searchParams.has('organization') ? searchParams.get('organization') : 'No organization specified', [searchParams]);
    const navigate = useNavigate();
    const [headerType, setHeaderType] = useState('media');
    const [mediaType, setMediaType] = useState('none');
    const [mediaContent, setMediaContent] = useState(null);
    const [mediaUrl, setMediaUrl] = useState(null);
    const [variables, setVariables] = useState([]);
    const [footerText, setFooterText] = useState('');
    const [bodyContent, setBodyContent] = useState('');
    const [variableSamples, setVariableSamples] = useState({});
    const [buttons, setButtons] = useState([]);
    const [headerTxt, setHeaderTxt] = useState('');
    const [mediaFile, setMediaFile] = useState('');
    const [mediaPublicUrl, setMediaPublicUrl] = useState('');
    const [flag, setFlag] = useState(false);
    const [changed, setChanged] = useState(false);
    const [gmbtMedia , setGmbtMedia] = useState({});
    const [mediaSessionId, setMediaSessionId] = useState('');
    const [fileLength, setFileLength] = useState(0);
    const [gmbtMediaId, setGmbtMediaId] = useState('');
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [openConfirmationErrorMessage, setOpenConfirmationErrorMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    
    const handleAddButton = (button) => {
        setButtons([...buttons, button]);
    };

    const handleRemoveButton = (index) => {
        const updatedButtons = [...buttons];
        updatedButtons.splice(index, 1);
        setButtons(updatedButtons);
    };

    const handleBackButtonClick = () => {
        navigate('/Templates');
    };

    const handleBodyContentChange = (newBodyContent) => {
        setBodyContent(newBodyContent);
    };

    const handleHeaderTypeChange = (type) => {
        setHeaderType(type);
    };

    const handleMediaTypeChange = (type, fileType, fileContent, mediaUrl, mediaFile) => {
        
        setMediaType(type !== 'none' ? type : mediaFile?.type); // Updated from fileType to type
        setMediaContent(fileContent);
        setMediaUrl(mediaUrl);
        setMediaFile(mediaFile);
        // setFileLength(mediaFile?.size);
    };

    const handleAddVariable = (variable) => {
        setVariables([...variables, variable]);
    };

    const handleRemoveVariable = (index) => {
        const updatedVariables = [...variables];
        updatedVariables.splice(index, 1);
        setVariables(updatedVariables);
    };

    const handleFooterTextChange = (text) => {
        setFooterText(text);
    };

    const handleVariableSampleChange = (variable, sample) => {
        setVariableSamples({ ...variableSamples, [variable]: sample });
    };

    const handleHeaderTxt = (txt) => {
        setHeaderTxt(txt);
    };

    const handleAddVariableToBody = (updatedContent) => {
        setBodyContent(updatedContent);
    };
     
    const getGmbtMediaUrlByTemplateId = async (templateId) => {
        try {
            const response = await axios.post('https://gambot.azurewebsites.net/api/webhooks/GetMediaByTemplateId', {
                organization: organization,
                templateId: templateId
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setGmbtMedia(response.data);
            const mediaUrl = response.data.mediaUrl;
            const encodedMediaUrl = encodeURI(mediaUrl); // Encode the URL
            setMediaUrl(encodedMediaUrl);
            console.log(encodedMediaUrl);

        } catch (error) {
            console.error('Error fetching media URL:', error);
        }
    };

    useEffect(() => {
        if (components) {
            const headerComponent = components.find(component => component.type === 'HEADER');
            if (headerComponent) {
                switch (headerComponent.format) {
                    case 'IMAGE':
                        setHeaderType('media');
                        setMediaUrl(getGmbtMediaUrlByTemplateId(templateId));
                        setMediaType(headerComponent.format.toLowerCase()); // Set media type
                    case 'VIDEO':
                        setHeaderType('media');
                        setMediaType(headerComponent.format.toLowerCase()); // Set media type
                    case 'DOCUMENT':
                        setHeaderType('media');
                        setMediaUrl(getGmbtMediaUrlByTemplateId(templateId));
                        setMediaType(headerComponent.format.toLowerCase()); // Set media type
                    break;
                    case 'LOCATION':
                        setHeaderType('media');
                        setMediaUrl('');
                        setMediaType('location'); // Set media type
                    break;
                    case 'TEXT':
                        setHeaderType('text');
                        setHeaderTxt(headerComponent.text);
                    break;
                    default:
                        setHeaderType('media');
                    break;
                }
            }

            const bodyComponent = components.find(component => component.type === 'BODY');
            if (bodyComponent) {
                const bodyText = bodyComponent.text || '';
                const bodyVariables = bodyComponent.example?.body_text?.map(variable => [...variable]) || [];
                setBodyContent(bodyText);
                setVariables(bodyVariables.length >0 ? bodyVariables[0] : bodyVariables);
                const variableSamples = {};
                bodyComponent.example?.body_text?.forEach((variable, index) => {
                    variableSamples[variable[0]] = variable[1] || '';
                });
                setVariableSamples(variableSamples);
            } else {
                console.error("No body component found");
            }

            const footerComponent = components.find(component => component.type === 'FOOTER');
            if (footerComponent && footerComponent.text) {
                setFooterText(footerComponent.text);
            }
            const buttonsComponent = components.find(component => component.type === 'BUTTONS');
            if (buttonsComponent) {
                const buttonsData = buttonsComponent.buttons.map(button => ({
                    ...(button.type === 'FLOW' && { text: button.text }),  // Include 'text' only if type is 'FLOW'
                    ...(button.type === 'CATALOG' && { text: button.text }),  // Include 'text' only if type is 'CATALOG'
                    ...(button.type === 'QUICK_REPLY' && { text: button.text }),  // Include 'text' only if type is 'quick reply
                    type: button.type,  // Add button type (e.g., FLOW, URL, PHONE_NUMBER)
                    ...(button.phone_number && { phone_number: button.phone_number, text: button?.text }),  // Conditionally include phone number
                    ...(button.url && { url: button.url , text: button?.text}),  // Conditionally include URL
                    ...(button.flow_id && { flow_id: button.flow_id }),  // Conditionally include flow ID
                    ...(button.navigate_screen && { navigate_screen: button.navigate_screen })  // Conditionally include navigate screen
                }));
                // const buttonsData = buttonsComponent.buttons.map(button => ({
                //     label: button.text,
                //     value: button.text
                // }));
                setButtons(buttonsData);
            }
        }
    }, [templateId]);

    // const buildHeader = (mediaPublicUrl, newMediaSessionId) => {
    //     let headerComponent = null;
    //     console.log(mediaPublicUrl);
    
    //     switch (headerType) {
    //       case 'media':
    //         headerComponent = {
    //           type: "HEADER",
    //           format: mediaType.startsWith('video') ? 'VIDEO' : mediaType.startsWith('image') ? 'IMAGE' : 'DOCUMENT',
    //           example: {
    //             header_handle: [newMediaSessionId]
    //           }
    //         };
    //         break;


    const buildHeader = (mediaPublicUrl, newMediaSessionId) => {
        let headerComponent = null;
        console.log(mediaPublicUrl);
    
        switch (headerType) {
          case 'media':
            headerComponent = {
              type: "HEADER",
              format: mediaType.startsWith('video') ? 'VIDEO' : mediaType.startsWith('image') ? 'IMAGE' : 'DOCUMENT',
              example: {
                header_handle: [newMediaSessionId]
              }
            };
            break;
          case 'location':
            headerComponent = {
              type: "HEADER",
              format: "LOCATION"
            };
            break;
          case 'text':
            headerComponent = {
              type: "HEADER",
              format: "TEXT",
              text: headerTxt,
              example: {
                header_text: [headerTxt]
              }
            };
            break;
          case 'document':
            headerComponent = {
              type: "HEADER",
              format: "DOCUMENT",
              example: {
                header_handle: [mediaFile]
              }
            };
            break;
          default:
            break;
        }
    
        return headerComponent;
      };
    const handleConfirmTemplateClick = () => {
        setOpenConfirmation(true);
    };

    const handleCloseConfirmation = () => {
        setOpenConfirmation(false);
    };
    
    const handleCloseConfirmationErrorMessage = () => {
        setOpenConfirmationErrorMessage(false);
    };
    const [newGmbtMediaId , setNewGmbtMediaId] = useState(searchParams.get('gmbtMdiaId')); 
    console.log(newGmbtMediaId);
    let updatedGmbotMediaId = null;
    const handleConfirmFailsTemplate = ()=>{
        navigate('/Templates')
    }
    const handleConfirmTemplate = async () => {
        setOpenConfirmation(false);
        setLoading(true); // Start loading
        setErrorMessage(''); // Clear any previous errors
        const template = {
            name: templateName.toLowerCase(),
            language: language,
            category: category,
            components: []
        };

        if (headerType === 'text' && headerTxt !== '') {
            template.components.push({
                type: "HEADER",
                format: "TEXT",
                text: headerTxt,
                example: {
                    header_text: headerTxt
                }
            });
        } else if (headerType === 'media' && mediaContent !== null && mediaType !== 'location') {
            try {
                // Validate the media file and type
                if (!mediaFile || !mediaType) {
                    throw new Error('Media file or type is missing.');
                }
        
                const formData = new FormData();
                formData.append('File', mediaFile);
                formData.append('Org', organization);
                formData.append('file_type', mediaType.toString());
                formData.append('file_length', fileLength.toString());
        
                console.log("FormData details:");
                formData.forEach((value, key) => console.log(`${key}: ${value}`));
                console.log("Media Type:", mediaType);
        
                // Perform the API request
                const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/CreateWabaMedia', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Important for form data
                    },
                });
        
                // Handle the response
                console.log('Response from CreateWabaMedia:', response.data);
        
                const newMediaPublicUrl = response.data.mediaUrl;
                const newGmbtMediaId = response.data.gmbtMediaId;
                const newMediaSessionId = response.data.mediaSessionId;
        
                setMediaPublicUrl(newMediaPublicUrl);
                setMediaSessionId(newMediaSessionId);
                setGmbtMediaId(newGmbtMediaId);
        
                console.log('New Gmbt Media ID:', newGmbtMediaId);
                console.log('New Media Session ID:', newMediaSessionId);
        
                const headerComponent = buildHeader(newMediaPublicUrl, newMediaSessionId);
                if (headerComponent) {
                    template.components.push(headerComponent);
                }
        
            } catch (error) {
                // Log error and provide feedback
                console.error('Error uploading media file:', error.response?.data || error.message);
            }
        }
        else if(headerType === 'media' && mediaContent === null && mediaType !== 'location'){
            const existingHeaderComponent = components.find(component => component.type === 'HEADER');
    
            if (existingHeaderComponent) {
                const headerComponent = buildHeader(existingHeaderComponent.example?.header_handle[0], existingHeaderComponent.example?.header_handle[0]);
                
                // Push the existing header to the template
                if (headerComponent) {
                    template.components.push(headerComponent);
                }
            }
        }
        else if(headerType === 'media' && mediaType === 'location'){
            const headerComponent = {
                type: "HEADER",
                format: "LOCATION"
              };
              template.components.push(headerComponent);
        }
        if (bodyContent !== '') {
            let text = bodyContent;
        
            // Replace variables in body content if they exist
            if (variables.length > 0) {
                variables.forEach((variable, index) => {
                    const placeholder = `{{${index + 1}}}`;
                    const regex = new RegExp(`{{\\s*${variable.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}\\s*}}`, 'g');
                    text = text.replace(regex, placeholder);
                });
            }
        
            // Construct the BODY component
            const bodyComponent = {
                type: "BODY",
                text: text
            };

            // Only add example field if variables exist
            if (variables.length > 0) {
                bodyComponent.example = {
                    body_text: [variables] // Wrap variables in an array to make them inline
                };
            }
            template.components.push(bodyComponent);
        }

        if (buttons.length > 0) {
            const buttonsData = buttons.map(button => {
              if (button.type === 'QUICK_REPLY' || button.type === "Quick Reply") {
                return {
                  type: "QUICK_REPLY",
                  text: button.label || button?.text || ''
                };
              } else if (button.type === 'PHONE_NUMBER') {
                return {
                  type: "PHONE_NUMBER",
                  text: button?.label ?? button?.text,
                  phone_number: button.phone_number
                };
              } else if (button.type === 'URL') {
                return {
                  type: "URL",
                  text: button?.label ?? button?.text,
                  url: button.url
                };
              }
              else if (button.type === 'CATALOG') {
                // Create the button object with the URL
                const buttonData = {
                  type: "CATALOG",
                  text: button.label,
                };
                return buttonData;
              }
              else if(button?.type === 'FLOW'){
                // return{
                //   button
                // }
                return{
                    type: "FLOW",
                    flow_action : "navigate",
                    flow_id : button?.flow_id,
                    navigate_screen : button?.navigate_screen,
                    text : button?.text
                  }
              }
              return {};
            });
            template.components.push({
              type: "BUTTONS",
              buttons: buttonsData
            });
          }
        if (footerText !== '') {
            template.components.push({
                type: "FOOTER",
                text: footerText
            });
        }
        // Ensure the state is updated before proceeding
    // const waitForGmbtMediaId = () => new Promise((resolve) => {
    //     const interval = setInterval(() => {
    //         if (newGmbtMediaId) {
    //             clearInterval(interval);
    //             resolve(newGmbtMediaId);
    //         }
    //     }, 100);
    // });

    // const resolvedGmbtMediaId = await waitForGmbtMediaId();
        let templateJson = JSON.stringify(template, null, 2);
        console.log(templateJson);
        const jsonDataToCreateTemplate = {
            organization: organization,
            templateId : templateId,
            template: templateJson,
            gmbtMediaId: updatedGmbotMediaId != null? updatedGmbotMediaId : newGmbtMediaId  || '',
            user:{
                userId : currentUser?.currentUser?.userId,
                userName: currentUser?.currentUser?.fullname
            }
        };
        console.log(jsonDataToCreateTemplate);
        axios.post("https://gambot.azurewebsites.net/api/Webhooks/UpdateTemplate", jsonDataToCreateTemplate)
            .then((response) => {
                console.log(response);
                setLoading(false); // Stop loading
                if(response?.data?.Success === true){
                    navigate('/Templates'); // Redirect to templates page

                }
                else{
                    setErrorMessage(response?.data?.Message);
                    setOpenConfirmationErrorMessage(true);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    };

    return (
        <div className="template-generator-container">
            <LoadingOverlay loading={loading} /> {/* Loading overlay */}
            <ConfirmationDialog
                open={openConfirmation}
                onClose={handleCloseConfirmation}
                onConfirm={handleConfirmTemplate}
                message="Are you sure? You can only update the template once in 24 hours."
                actionType="Confirm"
            />
            <div className="left-side">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        onClick={handleBackButtonClick}
                        style={{ color: 'var(--LogoColor)' }}
                        aria-label="back"
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <HeaderTypeSelector selectedType={headerType} onChange={handleHeaderTypeChange} />
                </div>
                {headerType === 'media' && (
                    <MediaOptions selectedType={mediaType} onChange={handleMediaTypeChange} mediaurl={mediaUrl}/>
                )}
                {headerType === "text" && (
                    <input
                        type="text"
                        placeholder='Set Text For Header'
                        value={headerTxt}
                        onChange={(e) => handleHeaderTxt(e.target.value)}
                        className="variable-input-header-txt"
                    />
                )}
                <BodyText
                    bodyText={bodyContent}
                    variables={variables}
                    onAddSample={handleBodyContentChange}
                    onVariableSampleChange={handleVariableSampleChange}
                    isChanged={!changed}
                />
                <VariableInput
                    variables={variables}
                    onAddVariable={handleAddVariable}
                    onRemoveVariable={handleRemoveVariable}
                    onAddVariableToBody={handleAddVariableToBody}
                    bodyText={bodyContent}
                />
                <FooterText text={footerText} onChange={handleFooterTextChange} />
                <ButtonOptions organization={organization} buttons={buttons} onAddButton={handleAddButton} onRemoveButton={handleRemoveButton} />
            </div>
            <div className="right-side">
            <button className='template-generator-container-confirm' onClick={handleConfirmTemplateClick}>Update Template</button>
            <ConfirmationDialog
                open={openConfirmationErrorMessage}
                onClose={handleCloseConfirmationErrorMessage}
                onConfirm={handleConfirmFailsTemplate}
                message={errorMessage}
                actionType="Close"
            />

            {/* {erro}
            <ConfirmationDialog
                open={openConfirmation}
                onClose={handleCloseConfirmation}
                onConfirm={handleConfirmFailsTemplate}
                message={errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                actionType="Confirm"
            />
             */}
                <MessagePreview
                    headerType={headerType}
                    mediaType={mediaType}
                    mediaContent={mediaContent}
                    mediaUrl={mediaUrl}
                    variables={variables}
                    footerText={footerText}
                    variableSamples={variableSamples}
                    bodyContent={bodyContent}   
                    buttons={buttons}
                    headerTxt={headerTxt}
                />
            </div>
        </div>
    );
};

export default React.memo(TemplateUpdate);

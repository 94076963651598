import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ChatWidget.css'; // Change the file name as necessary
import { IoAddCircleOutline, IoTrashOutline } from 'react-icons/io5';
import { MdOutlineModeEdit } from 'react-icons/md';
import ConfirmationDialog from '../General/ConfirmationDialog'; // Assuming this is your dialog component

const ChatWidget = ({ currentUser }) => {
    const [chatWidgetList, setChatWidgetList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedWidget, setSelectedWidget] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const navigate = useNavigate();

    // Fetch the list of widgets when the component mounts
    useEffect(() => {
        const fetchChatWidgets = async () => {
            try {
                const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetAllWabaWidgets', {
                    organization: currentUser?.organization
                });
                setChatWidgetList(response.data);
            } catch (error) {
                console.error('Error fetching chat widgets:', error);
            }
        };

        if (currentUser?.organization) {
            fetchChatWidgets();
        }
    }, [currentUser?.organization]);

    const handleEditClick = (widget) => {
        navigate(`/ChatWidgetForm/?widgetId=${widget.Id}`);
    };

    const handleDeleteClick = (widget) => {
        setSelectedWidget(widget);
        setOpenDialog(true);
    };

    const handleConfirmDelete = () => {
        const payload = {
            organization: currentUser?.organization,
            wabaWidgetId: selectedWidget.Id
        };

        axios.post('https://gambot.azurewebsites.net/api/Webhooks/DeleteWabaWidgetById', payload)
            .then(() => {
                setChatWidgetList(prevList => prevList.filter(item => item.Id !== selectedWidget.Id));
                setOpenDialog(false);
            })
            .catch((error) => {
                console.error('Error deleting widget:', error);
            });
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredWidgets = chatWidgetList.filter((widget) =>
        (widget.botName || '').toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className='chat-widget-list-container'>
            <h1 className='chat-widget-list-header'>Chat Widget List</h1>
            <div className='search-bar'>
                <input
                    type="text"
                    placeholder="Search Widget by Name"
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
            </div>

            <div className='chat-widget-action-buttons'>
                <button className="add-new-widget-button" onClick={() => navigate('/ChatWidgetForm')}>
                    Add New Widget <IoAddCircleOutline />
                </button>
            </div>

            <div className='chat-widget-list-table-container'>
                <table className='chat-widget-list-table'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Purpose</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredWidgets.map((widget) => (
                            <tr key={widget.Id}>
                                <td>{widget.WidgetName}</td>
                                <td>{widget.botPurpose}</td>
                                <td>{widget.WidgetStatus}</td>
                                <td>
                                    <button className="widget-edit-button" onClick={() => handleEditClick(widget)}>
                                        <MdOutlineModeEdit />
                                    </button>
                                    <button className="widget-delete-button" onClick={() => handleDeleteClick(widget)}>
                                        <IoTrashOutline />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <ConfirmationDialog
                open={openDialog}
                onClose={handleCloseDialog}
                onConfirm={handleConfirmDelete}
            />
        </div>
    );
};

export default ChatWidget;

import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import posts from "./posts";
import "./BlogPostPage.css";

const BlogPostPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const post = posts.find((p) => p.id === parseInt(id));

  
  if (!post) {
    return <div className="blog-post-page">הפוסט לא נמצא 😕</div>;
  }

  return (
    <div className="blog-post-page">
      {/* 🔗 Breadcrumb */}
      <nav className="breadcrumb">
        <span onClick={() => navigate("/")}>דף הבית</span> &gt;
        <span onClick={() => navigate("/blog")}> בלוג</span> &gt;
        <span className="current">{post.title}</span>
      </nav>

      <button onClick={() => navigate("/blog")} className="back-button">
          ← חזרה לבלוג
        </button>

      {/* 🔲 תוכן הפוסט */}
      <div className="post-content-wrapper">
        <h1>{post.title}</h1>
        <p className="post-meta">
          <span>{post.date}</span> | <span>{post.author} ✍🏻</span>
        </p>


        <p className="post-description">{post.description}</p>
        <div className="post-full-content">
          {(post.content || "").split('\n').map((paragraph, idx) => (
            <p key={idx}>{paragraph}</p>
          ))}
        </div>

        <div className="post-tags">
          {post.tags.map((tag, index) => (
            <span key={index} className="tag">#{tag}</span>
          ))}
        </div>

     
      </div>
    </div>
  );
};

export default BlogPostPage;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { MenuDATA } from './MenuData';
import './Menu.css';
import GambotLogo from '../../../src/assets/GamBot_Logo.png'; 

const Menu = ({ user, signOut }) => {
    const [isExpanded, setIsExpanded] = useState(true);

    const toggleMenu = () => {
        setIsExpanded(!isExpanded);
    };

    const handleMenuClick = (item) => {
        if (item.signOut) {
            signOut(); 
        }
    };

    return (
        <div className={`Menu ${isExpanded ? '' : 'collapsed'}`}>
            <div className="menuLogoContainer">
                <img src={GambotLogo} alt="Gambot Logo" className="menuLogo" />
            </div>

            <div className="Menu-items">
                <ul>
                    {MenuDATA.map((item, index) => (
                        <li key={index} className={item.cName}>
                            {item.signOut ? (
                                <button className="logoutBtn">
                                    <span onClick={() => handleMenuClick(item)} className="logoutIcon">
                                        {item.icon}
                                    </span>
                                    <span className="userMenu">Hi, {user?.fullname || 'User'}</span>
                                </button>
                            ) : (
                                // <Link to={item.path}>
                                //     {item.icon}
                                //     <span>{item.title}</span>
                                // </Link>
                                <Link to={item.path} onClick={() => setIsExpanded(false)}>
                                {item.icon}
                                <span>{item.title}</span>
                            </Link>
                            )}
                        </li>
                    ))}
                    <li className="menu-spacer"></li>
                </ul>
            </div>
            <button onClick={toggleMenu} className="CloseMenubtn">
                {isExpanded ? <CloseIcon /> : <MenuIcon />}
            </button>
        </div>
    );
};

export default Menu;

// src/components/Website/TermOfPayments/TermOfPayments.js
import React from 'react';
import './TermOfPayments.css'; // אם צריך סגנונות מותאמים אישית

const TermOfPayments = () => {
    return (
        <div className="terms-of-payments">
            <h1>תנאי תשלום</h1>

            <h2>תשלום עבור מסלול מנוי:</h2>
            <p>
                הארגון המשתמש בגמבוט כמערכת לניהול וואטסאפ מתחייב לשלם עבור השימוש במערכת בהתאם למסלול המנוי שבחר בעת ההרשמה.
                התשלום יגבה באופן קבוע **חודשי** או **שנתי**, בהתאם לבחירת המשתמש.
                המשתמש יכול לשנות את המסלול בכל עת, בהתאם לזמינות ולתעריפים החלים.
                כל שינוי במסלול יתבצע על פי התנאים המעודכנים, והמשתמש יקבל עדכון בנוגע לתשלום או להטבות תחת המסלול החדש.
            </p>

            <h2>תשלום עבור שירותים נוספים (Add-ons):</h2>
            <p>
                המשתמש מסכים כי יחויב בתשלום אוטומטי עבור שירותים או שימושים נוספים מחוץ למסלול הנבחר,
                במידה והוא עבר את המגבלה הכלולה במסלול שבחר. לדוגמה, אם החבילה הבסיסית כוללת **1000 שיחות**,
                כל שימוש נוסף מעבר לכך יחויב בתוספת תשלום בהתאם לתעריף הנוכחי המוצג במערכת.
                המשתמש אחראי לעקוב ולוודא אם הוא עדיין במסגרת המסלול שבחר, ואם עבר את המגבלה, הוא יחויב אוטומטית על השירותים הנוספים.
                המשתמש יכול לבחור להוסיף שירותים נוספים כגון שיחות נוספות, העברת נתונים ושירותים ייחודיים נוספים,
                אשר יחויבו על פי התעריפים הקיימים.

                התוספים הנפוצים ביותר:
                <ul className="term-of-payment-add-on-rtl-list">
                    <li>שיחות נוספות</li>
                    <li>תשובות Gambot AI</li>
                    <li>משתמשים נוספים</li>
                </ul>
            </p>

            <h2>תשלום למטא עבור הודעות:</h2>
            <p>
                המשתמש מאשר שהוא מודע לכך שעבור הודעות / שיחות המבוצעות מסוג חשבון וואטסאפ זה, מטא / פייסבוק גובות תשלום נוסף.
                התשלום מבוצע ישירות לחשבון של מטא / פייסבוק דרך אמצעי התשלום המוגדר באיזור הניהול העסקי של המשתמש במטא / פייסבוק ובמטבע שנבחר. 
                המשתמש מאשר שהוא מבין שאין לגמבוט קשר לתשלום אך על מנת שהדברים יעבדו כשורה בגמבוט חייב להיות אמצעי תשלום תקין באיזור הניהול העסקי של מטא / פייסבוק של הארגון אותו משייכים לגמבוט.
            </p>
            <p>
                למידע נוסף על תעריפי ההודעות של מטא ופרטים נוספים אודות השירותים המוצעים, ניתן לעיין בהסבר של מטא
                <a href="https://business.whatsapp.com/products/platform-pricing" target="_blank" rel="noopener noreferrer" className="link-meta">
                    WhatsApp Business API Pricing - Meta
                </a>
            </p>

            <h2>שירותי סליקה של צד שלישי:</h2>
            <p>
                התשלומים מנוהלים דרך ספק סליקה חיצוני אשר אחראי על שמירת פרטי כרטיסי האשראי.
                **אנו לא שומרים את פרטי כרטיסי האשראי**. כל פרטי האשראי המתקבלים בעת ההרשמה או עדכון פרטי תשלום נשמרים רק אצל ספק הסליקה.
                אנו מעבירים את פרטי התשלום לספק לצורך עיבוד התשלומים החודשיים, כולל הצטרפות מנויים חדשים, עדכונים וביטולים.
                ספק הסליקה אחראי על כל היבטי החיוב החודשי, כולל טיפול בשגיאות חיוב ובעיות הקשורות לכרטיסי אשראי.
            </p>

            <h2>חשבוניות:</h2>
            <p>
                חשבונית עבור כל חיוב תישלח אוטומטית אחת לחודש למייל שהוזן בעת ההרשמה או למייל המעודכן באזור הניהול.
                המשתמש מסכים לקבל את החשבונית באופן אלקטרוני ומוודא שכתובת המייל שלו נכונה ומעודכנת.
                בחשבונית זו יצויין מספר תיק ברשויות המסים בישראל של ניר סגס מפתח ובעל התוכנה
            </p>

            <h2>מע"מ:</h2>
            <p>
                המחירים המוצגים באתר אינם כוללים מע"מ. המע"מ יתווסף למחיר בהתאם לשיעור המע"מ הנכון ביום החיוב בישראל.
            </p>

            <h2>ביטול מנוי:</h2>
            <p>
                מרגע ביטול החשבון, יחויב המשתמש בעבור החודש האחרון באופן יחסי. לאחר מכן, החשבון יבוטל ותחסם הגישה לחשבון. 
                כל המידע והנתונים הקשורים לחשבון יימחקו ולא יהיו נגישים יותר.
            </p>

            <h2>היעדר תשלום:</h2>
            <p>
                היה וחלפו 3 נסיונות תשלום כושלים - ניסיון אחד בכל חודש , ייחסם החשבון ונתוני הארגון ייאבדו ומאותו רגע אנו רואים בחשבון זה בטל ומבוטל
                זאת כנ"ל לגבי הסים במידה ונרכש מאיתנו.
                היה ומבסגרת ה-3 חודשים חיובים כושלים שלאחר מכן צלחו , יחוייב החשבון במעמד החיוב המצליח כמספר הפעמים שכשלו כולל החודש הנוכחי על מנת ליישר קו ולסגור את החשבון הפתוח
            </p>
        </div>
    );
}

export default TermOfPayments;

import React, { useState, useEffect } from 'react';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ConfirmationDialog from '../General/ConfirmationDialog'
import './FormFlows.css';
import './FormFlowRunHistory'
import FormFlowRunHistory from './FormFlowRunHistory';
import { IoTrashOutline } from "react-icons/io5";
import { MdOutlineModeEdit } from "react-icons/md";
import { MdHistory } from "react-icons/md";
import { MdOutlinePublishedWithChanges } from "react-icons/md";

const FormFlows = ({ currentUser }) => {
  const navigate = useNavigate();
  const [formFlows, setFormFlows] = useState([]); // State for form flows data
  const [openDialog, setOpenDialog] = useState(false); // State for delete confirmation dialog
  const [openPublishDialog, setOpenPublishDialog] = useState(false); // State for publish confirmation dialog
  const [openDeprecateDialog, setOpenDeprecateDialog] = useState(false); // State for deprecate confirmation dialog
  const [selectedFlow, setSelectedFlow] = useState(null); // State for the selected form flow
  const [flowName, setFlowName] = useState(''); // State for the form flow name
  const [status, setStatus] = useState(''); // State for the form flow status
  const [flowType, setFlowType] = useState(''); // State for the form flow type
  const [formFlowId, setFormFlowId] = useState(null);

  console.log(currentUser);
  const [showHistory, setShowHistory] = useState(false);
  const [searchQuery, setSearchQuery] = useState(''); // Search query state for filtering form flows by name

  const handleHistoryClick = (id) => {
    setFormFlowId(id);
    setShowHistory(true);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value); // Update search query
  };

  const filteredFormFlows = formFlows.filter(flow =>
    flow.flowName.toLowerCase().includes(searchQuery.toLowerCase()) // Filter by flow name
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetAllFormFlows', {
          organization: currentUser.organization
        });

        console.log('Fetched Form Flows (raw):', response.data);

        const sortedFlows = response.data
          .filter(flow => flow.modifiedOn) // Ensure modifiedOn exists
          .sort((a, b) => new Date(convertToISODate(b.modifiedOn)) - new Date(convertToISODate(a.modifiedOn))) // Sort by modifiedOn
          .map(formFlow => ({
            ...formFlow,
            modifiedOn: formatToUserTimezone(convertToISODate(formFlow.modifiedOn), currentUser?.timeZone || 'UTC') // Convert to user timezone or default to UTC
          }));

        console.log('Sorted and Formatted Form Flows:', sortedFlows);
        setFormFlows(sortedFlows);
      } catch (error) {
        console.error('Error fetching form flows:', error);
      }
    };

    fetchData();
  }, [currentUser]);

  // Convert 'dd/MM/yyyy HH:mm:ss' to 'yyyy-MM-ddTHH:mm:ssZ'
  const convertToISODate = (dateString) => {
    if (dateString.includes('T')) {
      // Already in ISO format
      return dateString;
    } else {
      // Convert 'dd/MM/yyyy HH:mm:ss' to ISO format
      const [datePart, timePart] = dateString.split(' ');
      const [day, month, year] = datePart.split('/');
      return `${year}-${month}-${day}T${timePart}Z`;
    }
  };

  const formatToUserTimezone = (utcDate, timezone) => {
    if (!utcDate) return 'No date available';
  
    try {
      return new Intl.DateTimeFormat('en-GB', {
        timeZone: timezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).format(new Date(utcDate));
    } catch (error) {
      console.error('Error formatting date:', error, 'Timezone:', timezone);
      return 'Invalid date';
    }
  };
  


  const handlePublishFlowClick = (flow) => {
    setSelectedFlow(flow);
    setOpenPublishDialog(true);
  };

  const handleDeprecateFlowClick = (flow) => {
    setSelectedFlow(flow);
    setOpenDeprecateDialog(true);
  };

  const handleDeprecateConfirm = async () => {
    try {
      const payload = {
        organization: currentUser.organization,
        flowId: selectedFlow.flow.Id
      };
      const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/deprecateFlow', payload);
      console.log(response);

      // Update the flow status to "Deprecated" in the UI
      setFormFlows((prevFlows) =>
        prevFlows.map((flow) =>
          flow.flow.Id === selectedFlow.flow.Id ? { ...flow, status: 'Deprecated' } : flow
        )
      );
    } catch (error) {
      console.error('Error deprecating flow:', error);
    } finally {
      setOpenDeprecateDialog(false);
    }
  };

  const handlePublishConfirm = async () => {
    try {
      const payload = {
        organization: currentUser.organization,
        flowId: selectedFlow.flow.Id
      };
      const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/PublishFlow', payload);
      console.log(response);

      // Update the flow status to "Published" in the UI
      setFormFlows((prevFlows) =>
        prevFlows.map((flow) =>
          flow.flow.Id === selectedFlow.flow.Id ? { ...flow, status: 'Published' } : flow
        )
      );
    } catch (error) {
      console.error('Error publishing flow:', error);
    } finally {
      setOpenPublishDialog(false);
    }
  };

  const handleDeleteClick = (flow) => {
    setSelectedFlow(flow);
    setOpenDialog(true);
  };

  const handleEditClick = (flow) => {
    console.log(flow);
    const thisFlowQueryString = new URLSearchParams({
      organization: currentUser.organization,
      ...flow,
      components: JSON.stringify(flow.components)
    });
    navigate('/NewFormFlow', { state: { flow } });
  };

  const handleConfirmDelete = () => {
    const payload = {
      organization: currentUser.organization,
      formFlowId: selectedFlow.flow.Id
    };

    axios.post("https://gambot.azurewebsites.net/api/Webhooks/DeleteFormFlow", payload)
      .then((response) => {
        console.log(response);
        setFormFlows(formFlows.filter(flow => flow.id !== selectedFlow.id));
      })
      .catch((error) => {
        console.error(error);
      });

    setOpenDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseDeprecateDialog = () => {
    setOpenDeprecateDialog(false);
  };

  const handleClosePublishDialog = () => {
    setOpenPublishDialog(false);
  };

  console.log(formFlows);
  return (
    <div className="form-flow-list-container">
      <h1 className="form-flow-list-header">Form Flows</h1>
       {/* Search input field */}
       <div className="form-flow-list-search-bar">
        <input
          type="text"
          placeholder="Search form flows by name..."
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>
      <div className='form-flows-action-buttons'>
        <Link to="/NewFormFlow">
          <button className="add-new-form-flow-button">Add New Form Flow</button>
        </Link>
      </div>
      <div className='form-flow-list-table-container'>
        <table className="form-flow-list-table">
          <thead>
            <tr>
              <th>Flow Name</th>
              <th>Flow ID</th>
              <th>Status</th>
              <th>Last Modified</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
  {filteredFormFlows.filter(flow => flow.status !== 'Deprecated') // Exclude Deprecated flows
    .map((flow) => (
      <tr key={flow?.Id}>
        <td>{flow.flowName}</td>
        <td>{flow.flow?.Id}</td>
        <td>{flow.status}</td>
        <td>{flow?.modifiedOn} <br></br> by {flow?.modifiedByName || "Unknown"}</td>
        <td>
          <button className="form-flow-edit-button" onClick={() => handleEditClick(flow)} title="Edit"><MdOutlineModeEdit /></button>

          {/* Conditionally render Delete button based on flow status */}
          {flow.status !== 'Published' && (
            <button className="form-flow-delete-button" onClick={() => handleDeleteClick(flow)} title="Delete"><IoTrashOutline /></button>
          )}

          {/* Conditionally render Publish button based on flow status */}
          {flow.status !== 'Published' && (
            <button className="form-flow-publish-button" onClick={() => handlePublishFlowClick(flow)} title="Publishe"><MdOutlinePublishedWithChanges /></button>
          )}

          {/* Add a "View History" button for published flows */}
          {flow.status === 'Published' && (
            <button className="form-flow-history-button" onClick={() => handleHistoryClick(flow.Id)} title="History"><MdHistory /></button>
          )}

          
          {/* Optionally, add a "Deprecate" button if already published */}
          {flow.status === 'Published' && (
            <button className="form-flow-deprecate-button" onClick={() => handleDeprecateFlowClick(flow)}>Deprecate</button>
          )} 
        </td>
      </tr>
    ))}
</tbody>
        </table>
      </div>
      {showHistory && formFlowId && (
  <FormFlowRunHistory 
    formFlowId={formFlowId} 
    onClose={() => setShowHistory(false)} 
    currenUser={currentUser}
  />
)}
      {/* Delete Confirmation Dialog */}
      <ConfirmationDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        message={`Are you sure you want to delete the flow: "${selectedFlow?.flowName}"?`}
        actionType="Delete"
      />

      {/* Deprecate Confirmation Dialog */}
      <ConfirmationDialog
        open={openDeprecateDialog}
        onClose={handleCloseDeprecateDialog}
        onConfirm={handleDeprecateConfirm}
        message={`Are you sure you want to deprecate the flow: "${selectedFlow?.flowName}"?`}
        actionType='Deprecate'
      />

      {/* Publish Confirmation Dialog */}
      <ConfirmationDialog
        open={openPublishDialog}
        onClose={handleClosePublishDialog}
        onConfirm={handlePublishConfirm}
        message={`Are you sure you want to publish the flow: "${selectedFlow?.flowName}"?`}
        actionType='Publish'
      />

   
    </div>
  );
};

export default FormFlows;

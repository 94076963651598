


import './Footer.css';
import React, { useState } from 'react';

import { FaFacebookF, FaInstagram, FaYoutube, FaLinkedinIn } from "react-icons/fa";


const Footer = () => {
  const [phoneNumber, setPhoneNumber] = useState(''); // State for phone number input
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    if (!phoneNumber) {
      alert('אנא הזן מספר טלפון');
      return;
    }

    setIsSubmitting(true); // Set loading state

    const data = { "phoneNumber" : phoneNumber,
      'ClientId': 'R9f6r4oe5PSCLr6CnYRQ', // Add ClientId header
    };

    const url = "https://prod-00.northeurope.logic.azure.com:443/workflows/24826d9f1f30448cb12884561d7bcc2b/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=RMrmjA9SPjryV5VE5iP8elY_V6tFdxhMgjs-zQI8FPQ";

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
        

      },
      body: JSON.stringify(data),
    })
      .then(response => {
        if (response.status === 200 || response.status === 202) {
          return response.json(); // Parse JSON if the response is successful
        } else {
          throw new Error('Request failed with status ' + response.status);
        }
      })
      .then(data => {
        // Check if the ClientId matches the expected value
        if (data.success === true) {
          alert('ההרשמה בוצעה בהצלחה!');
          // window.location.href = "/approve"; // Redirect to approve page
        } else {
          alert('Something went wrong!');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        alert('There was an error submitting your request.');
      })
      .finally(() => setIsSubmitting(false)); // Reset loading state
  };

  return (
    <>

<div class="pg-footer">
  <footer class="footer">
    {/* <svg class="footer-wave-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 100" preserveAspectRatio="none">
      <path class="footer-wave-path" d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"></path>
    </svg> */}
    <div class="footer-content">
      <div class="footer-content-column">
        <div class="footer-menu">
          <h2 class="footer-menu-name">צעדים ראשונים אצלנו?</h2>
          <ul id="menu-get-started" class="footer-menu-list">
            <li class="menu-item menu-item-type-post_type menu-item-object-product">
              <a href="#">למה ווצאפ?</a>
            </li>
            <li class="menu-item menu-item-type-post_type menu-item-object-product">
              <a href="#">הפיצ'רים שלנו</a>
            </li>
            <li class="menu-item menu-item-type-post_type menu-item-object-product">
              <a href="#">מדריך למשתמש</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer-content-column">
        <div class="footer-menu">
          <h2 class="footer-menu-name">מידע</h2>
          <ul id="menu-company" class="footer-menu-list">
            <li class="menu-item menu-item-type-post_type menu-item-object-page">
              <a href="#">המלצות</a>
            </li>
            <li class="menu-item menu-item-type-taxonomy menu-item-object-category">
              <a href="#">עדכונים אחרונים</a>
            </li>
            <li class="menu-item menu-item-type-post_type menu-item-object-page">
              <a href="#">הצוות שלנו</a>
            </li>
          </ul>
        </div>
        <div class="footer-menu">
          <h2 class="footer-menu-name">תקנון</h2>
          <ul id="menu-legal" class="footer-menu-list">
            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-170434">
              <a href="#">תנאי פרטיות</a>
            </li>
            <li class="menu-item menu-item-type-post_type menu-item-object-page">
              <a href="#">תנאי שימוש</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer-content-column">
        <div class="footer-menu">
          <h2 class="footer-menu-name">לינקים מהירים</h2>
          <ul id="menu-quick-links" class="footer-menu-list">
            <li class="menu-item menu-item-type-custom menu-item-object-custom">
              <a target="_blank" rel="noopener noreferrer" href="#">צור קשר</a>
            </li>
            <li class="menu-item menu-item-type-post_type menu-item-object-page">
              <a href="#">צור חשבון</a>
            </li>
            <li class="menu-item menu-item-type-post_type menu-item-object-page">
              <a href="#">בלוג</a>
            </li>
            <li class="menu-item menu-item-type-post_type_archive menu-item-object-customer">
              <a href="#">מחירון</a>
            </li>
            <li class="menu-item menu-item-type-post_type menu-item-object-page">
              <a href="#">ביקורות</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer-content-column">
        <div class="footer-call-to-action">
          <h2 class="footer-call-to-action-title">בואו לווצאפ שלנו</h2>
          <br></br>
          {/* <p class="footer-call-to-action-description"> ⬇ שאלות תמיכה </p> */}
          <a class="footer-call-to-action-button button" href="#" target="_self"> קליק ואתם איתנו בשיחה </a>
        </div>
        <div class="footer-call-to-action">
          <h2 class="footer-call-to-action-title">חייגו אלינו</h2>
          <p class="footer-call-to-action-link-wrapper"> <a class="footer-call-to-action-link" href="tel:0124-64XXXX" target="_self"> 055-977-6699 </a></p>
        </div>
      </div>



      <div class="footer-newsletter-wrapper">
        <hr class="footer-divider" />
        <div class="footer-newsletter">
          <h2 class="footer-newsletter-title">הרשמו לניוזלטר שלנו</h2>
          <p class="footer-newsletter-description">קבלו עדכונים חמים, חדשות ומבצעים ישירות לוואטסאפ שלכם</p>
          <form className="footer-newsletter-form" onSubmit={handleSubmit}>
                  <button type="submit" className="footer-newsletter-button" disabled={isSubmitting}>
                    {isSubmitting ? 'בבקשה המתן...' : 'הרשמה'}
                  </button>
                  <input
                    type="tel"
                    className="footer-newsletter-input"
                    placeholder="הכנס את מספר הטלפון שלך"
                    pattern="\d*"
                    required
                    title="אנא הזן מספרים בלבד"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)} // Update phone number state
                  />
                </form>
        </div>
      </div>

      <div className="footer-social-links">
      <a 
    className="footer-social-link linkedin" 
    href="https://www.linkedin.com/company/gambot-platform" 
    target="_blank" 
    rel="noopener noreferrer"
  >
    <FaLinkedinIn className="footer-social-icon" />
  </a>
  <a 
    className="footer-social-link facebook" 
    href="https://www.facebook.com/profile.php?id=61553659007668" 
    target="_blank" 
    rel="noopener noreferrer"
  >
    <FaFacebookF className="footer-social-icon" />
  </a>

  <a 
    className="footer-social-link instagram" 
    href="https://www.instagram.com/gambot_il/" 
    target="_blank" 
    rel="noopener noreferrer"
  >
    <FaInstagram className="footer-social-icon" />
  </a>

  <a 
    className="footer-social-link youtube" 
    href="https://www.youtube.com/channel/@Gambot-IL" 
    target="_blank" 
    rel="noopener noreferrer"
  >
    <FaYoutube className="footer-social-icon" />
  </a>
    </div>

    </div>
    <div class="footer-copyright">
      <div class="footer-copyright-wrapper">
        <p class="footer-copyright-text">
          <a class="footer-copyright-link" href="#" target="_self"> 2025 |   פותח ע"י: צוות פיתוח גמבוט | © GamBot כל הזכויות שמורות </a>
        </p>
      </div>
    </div>
  </footer>
</div>

    </>

 );
};

export default Footer;
import React from 'react';
import './Catalog.css';  // We'll add custom CSS styles for layout and presentation

const Catalog = () => {
    return (
        <div className="catalog-container">
            {/* Left section: Header with instructions */}
            <div className="catalog-left">
                <h2>How to Create a Catalog</h2>
                <ol>
                    <li>You must have <a href='https://developers.facebook.com/docs/whatsapp/cloud-api/guides/sell-products-and-services/upload-inventory'>inventory uploaded to Meta in an ecommerce catalog </a> </li>
                    <li><a href='https://www.facebook.com/business/help/158662536425974'>connected to your WhatsApp Business Account.</a></li>
                    <li>Upload the products or items that will be listed in your catalog.</li>
                    <li>Create Template Catalog and send to your audience</li>
                    <li>Create Automation to process orders to money </li>

                </ol>
            </div>

            {/* Right section: Video tutorial */}
            <div className="catalog-right">
                <h3>Video Tutorial: How to Create a Catalog</h3>
                <iframe 
                    width="560" 
                    height="315" 
                    src="https://www.youtube.com/embed/dQw4w9WgXcQ" // Sample video, replace with actual tutorial URL
                    title="Video tutorial on creating a catalog"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
        </div>
    );
};

export default Catalog;

import React, { useState, useEffect, useRef } from 'react';
import ConditionGroup from './ConditionGroup';
import './ConditionAction.css';
import OutboundMessageAction from './OutboundMessageAction';
import SendHttpRequestAction from './SendHttpRequestAction';
import IncomingMessageTrigger from './IncomingMessageTrigger';
import { json } from 'react-router-dom';
import GambotAction from './GambotAction';
import ReminderAction from './ReminderAction';
import GambotAiAction from './GambotAiAction';

const ConditionAction = ({ onChange, steps, currentUser, conditionId, initialConfig, dynamicContent, parent, generateDynamicContent, historyMode, initialValue }) => {
  const [conditionGroups, setConditionGroups] = useState([{ logicOperator: 'AND', conditions: [{ field: '', operator: 'equals', value: '' }] }]);
  const [groupRelations, setGroupRelations] = useState([]);
  const [yesActions, setYesActions] = useState([]);
  const [noActions, setNoActions] = useState([]);
  const [isGeneral, setIsGeneral] = useState(false);
  const initialized = useRef(false);

  const applyDefaultValuesToConditionGroups = (groups) => {
    return groups.map((group) => ({
      ...group,
      conditions: group.conditions.map((condition, index) => {
        if (index === 0 && !condition.value && condition.field === 'ResponseLastSentTemplateMessageButtonClicked') {
          const defaultValue =
            buttonLabels?.length > 0 && templateIds?.length > 0
              ? `${buttonLabels[0]} + ${templateIds[0]}` // Use first button and template ID
              : ''; // Fallback
          return {
            ...condition,
            value: defaultValue,
            templateId: templateIds[0] || '', // First template ID if available
          };
        }
        return condition;
      }),
    }));
  };

  // Initialize state from initialConfig if provided
  // Initialize state from initialConfig if provided
  useEffect(() => {
    if (initialConfig && !initialized.current) {
      setConditionGroups(
        initialConfig.conditionGroups || [
          { logicOperator: "AND", conditions: [{ field: "message", operator: "equals", value: "" }] }
        ]
      );
      setGroupRelations(initialConfig.groupRelations || []);
      setYesActions(initialConfig.yesActions || []);
      setNoActions(initialConfig.noActions || []);
      setIsGeneral(initialConfig.isGeneral || false);
      initialized.current = true;
    }
  }, [initialConfig]);
  // useEffect(() => {
  //   const payload = { conditionId, conditionGroups, groupRelations, yesActions, noActions, isGeneral };
  //   if (JSON.stringify(payload) !== JSON.stringify(initialConfig)) {
  //     onChange(payload);
  //   }
  // }, [conditionGroups, groupRelations, yesActions, noActions, isGeneral, onChange, initialConfig]);
  useEffect(() => {
    // Regenerate dynamic content when conditionGroups, yesActions, or noActions change
    const dynamicFields = generateDynamicContent(steps, steps.length);
    console.log('Dynamic Content on Update:', dynamicFields);

    const payload = { conditionId, conditionGroups, groupRelations, yesActions, noActions, isGeneral };
    if (JSON.stringify(payload) !== JSON.stringify(initialConfig)) {
      onChange(payload);
    }
  }, [conditionGroups, yesActions, noActions, steps, onChange, conditionId, dynamicContent]);
  const previousPayload = useRef(null);
  useEffect(() => {
    if (!initialized.current) return;

    const payload = {
      conditionId,
      conditionGroups,
      groupRelations,
      yesActions,
      noActions,
      isGeneral,
    };

    if (JSON.stringify(payload) !== JSON.stringify(previousPayload.current)) {
      previousPayload.current = payload;
      onChange(payload);
    }
  }, [conditionGroups, groupRelations, yesActions, noActions, isGeneral, conditionId, onChange]);


  const normalizeStep = (step) => ({
    ...step,
    action: step.Action || step.action,
    config: step.Config || step.config,
    type: step.Type || step.type,
  });

  const getButtonLabelsAndTemplateIds = () => {
    const buttonLabels = [];
    const templateIds = [];

    const processSteps = (stepList) => {
      stepList?.forEach((step) => {
        // Normalize the step to ensure consistent property access
        step = normalizeStep(step);
        console.log(step);
        // Check for SendMessage actionsstill
        if (step.type === 'action' && step.action === 'SendMessage' && step.config?.templateConfig?.template?.components) {
          const templateName = step.config.templateConfig.template.name || 'Unnamed Template';
          const templateId = step.config.templateConfig.template.Id;

          // Extract button texts and template IDs
          step.config.templateConfig.template.components?.forEach((component) => {
            if (component.type === 'BUTTONS' && component.buttons) {
              component.buttons.forEach((button) => {
                if (button.text) {
                  buttonLabels.push(`${templateName} + ${button.text}`);
                  templateIds.push(templateId);
                }
              });
            }
          });
        }

        // Process nested actions in conditions
        if (step.type === 'action' && step.action === 'Condition') {
          // Process yesActions in config
          if (Array.isArray(step.config?.yesActions)) {
            processSteps(step.config.yesActions);
          }
          // Process noActions in config
          if (Array.isArray(step.config?.noActions)) {
            processSteps(step.config.noActions);
          }
        }
      });
    };

    // Start processing with the main steps array
    processSteps(steps);

    return { buttonLabels, templateIds };
  };

  console.log(JSON.stringify(steps));
  const { buttonLabels, templateIds } = getButtonLabelsAndTemplateIds();
  console.log(buttonLabels);
  const handleGroupChange = (index, updatedGroup) => {
    const newGroups = [...conditionGroups];
    newGroups[index] = updatedGroup;
    setConditionGroups(newGroups);
  };

  const addGroup = () => {
    setConditionGroups([...conditionGroups, { logicOperator: 'AND', conditions: [{ field: '', operator: 'equals', value: '' }] }]);
    setGroupRelations([...groupRelations, 'AND']);
  };

  const handleDeleteGroup = (index) => {
    const newGroups = conditionGroups.filter((_, i) => i !== index);
    const newRelations = groupRelations.filter((_, i) => i !== index - 1);
    setConditionGroups(newGroups);
    setGroupRelations(newRelations);
  };

  const handleAddCondition = (groupIndex) => {
    const newGroups = [...conditionGroups];
    newGroups[groupIndex].conditions.push({ field: '', operator: 'equals', value: '' });
    setConditionGroups(newGroups);
  };

  const handleGroupRelationChange = (index, value) => {
    const newRelations = [...groupRelations];
    newRelations[index] = value;
    setGroupRelations(newRelations);
  };

  // const addYesAction = () => {
  //   setYesActions([...yesActions, { type: 'action', action: null, config: {}, parentConditionId: conditionId }]);
  // };

  // const addNoAction = () => {
  //   setNoActions([...noActions, { type: 'action', action: null, config: {}, parentConditionId: conditionId }]);
  // };
  // const addYesAction = () => {
  //   const newAction = {
  //     type: 'action',
  //     action: null,
  //     config: {},
  //     parentConditionId: conditionId,
  //   };
  //   const updatedYesActions = [...yesActions, newAction];
  //   setYesActions(updatedYesActions);

  //   // Generate dynamic content with updated yesActions
  //   const dynamicFields = generateDynamicContent([...steps, ...updatedYesActions], steps.length);
  //   console.log('Updated Dynamic Content for Yes Actions:', dynamicFields);
  // };

  // const addNoAction = () => {
  //   const newAction = {
  //     type: 'action',
  //     action: null,
  //     config: {},
  //     parentConditionId: conditionId,
  //   };
  //   const updatedNoActions = [...noActions, newAction];
  //   setNoActions(updatedNoActions);

  //   // Generate dynamic content with updated noActions
  //   const dynamicFields = generateDynamicContent([...steps, ...updatedNoActions], steps.length);
  //   console.log('Updated Dynamic Content for No Actions:', dynamicFields);
  // };


  const addYesAction = () => {
    const newStepId = `${conditionId}.yes.${yesActions.length}`;

    const newAction = {
      StepId: newStepId,
      type: 'action',
      action: null,
      config: {},
      parentConditionId: conditionId,
    };

    const updatedYesActions = [...yesActions, newAction];
    setYesActions(updatedYesActions);

    // Generate dynamic content with updated yesActions
    const dynamicFields = generateDynamicContent([...steps, ...updatedYesActions], steps.length);
    console.log('Updated Dynamic Content for Yes Actions:', dynamicFields);
  };

  const addNoAction = () => {
    const newStepId = `${conditionId}}.no.${noActions.length}`;

    const newAction = {
      StepId: newStepId,
      type: 'action',
      action: null,
      config: {},
      parentConditionId: conditionId,
    };

    const updatedNoActions = [...noActions, newAction];
    setNoActions(updatedNoActions);

    // Generate dynamic content with updated noActions
    const dynamicFields = generateDynamicContent([...steps, ...updatedNoActions], steps.length);
    console.log('Updated Dynamic Content for No Actions:', dynamicFields);
  };

  const handleSelectAction = (index, action, isYesAction = true) => {
    const newActions = isYesAction ? [...yesActions] : [...noActions];
    newActions[index].action = action;
    isYesAction ? setYesActions(newActions) : setNoActions(newActions);
  };

  const handleDeleteAction = (index, isYesAction = true) => {
    const newActions = isYesAction ? yesActions.filter((_, i) => i !== index) : noActions.filter((_, i) => i !== index);
    isYesAction ? setYesActions(newActions) : setNoActions(newActions);
  };

  const handleActionConfigChange = (index, config, isYesAction = true) => {
    const actions = isYesAction ? [...yesActions] : [...noActions];

    if (actions[index]) {
      actions[index] = { ...actions[index], config };
      isYesAction ? setYesActions(actions) : setNoActions(actions);

      // Regenerate dynamic content
      const dynamicFields = generateDynamicContent(steps, steps.length);
      console.log('Updated Dynamic Content:', dynamicFields);
    } else {
      console.error(`Cannot update config for action at index ${index}. Action is undefined.`);
    }
  };


  useEffect(() => {
    const updatedConditionGroups = applyDefaultValuesToConditionGroups(conditionGroups);

    // Only update state or trigger onChange if the groups actually change
    if (JSON.stringify(updatedConditionGroups) !== JSON.stringify(conditionGroups)) {
      setConditionGroups(updatedConditionGroups);

      // Trigger onChange with the updated groups
      onChange({
        conditionId,
        conditionGroups: updatedConditionGroups,
        groupRelations,
        yesActions,
        noActions,
        isGeneral,
      });
    }
  }, [conditionGroups, groupRelations, yesActions, noActions, isGeneral, onChange, conditionId, buttonLabels, templateIds]);


  return (
    <div className="condition-action">
      <div className="condition-groups">
        {conditionGroups.map((group, index) => (
          <div key={index} className="condition-group-wrapper">
            <ConditionGroup
              group={group}
              onGroupChange={(updatedGroup) => handleGroupChange(index, updatedGroup)}
              onDeleteGroup={() => handleDeleteGroup(index)}
              onAddCondition={() => handleAddCondition(index)}
              isRemovable={index > 0}
              buttonLabels={buttonLabels}
              templateIds={templateIds}
              parent={'ConditionAction'}
              isGeneral={isGeneral}
              onToggleGeneral={() => setIsGeneral(!isGeneral)}
              dynamicContent={dynamicContent}
              historyMode={historyMode}
              ConditionData={initialValue?.ConditionData}
            />
          </div>
        ))}
        <div className="add-group-wrapper">
          <button className='condition-action-add-group-wrapper' type="button" onClick={addGroup}>Add Group</button>
          {conditionGroups.length > 0 && (
            <select
              value={groupRelations[groupRelations.length - 1] || 'AND'}
              onChange={(e) => handleGroupRelationChange(groupRelations.length - 1, e.target.value)}
              className="group-relation-select"
            >
              <option value="AND">AND</option>
              <option value="OR">OR</option>
            </select>
          )}
        </div>
      </div>

      <div className="condition-scopes">
        <div className="scope">
          <h4>If yes</h4>
          {yesActions.map((action, index) => {
            const nestedDynamicContent = generateDynamicContent(
              steps,
              steps.length + yesActions.length + noActions.length
            );
            return (
              <div key={index} className="scope-action">
                <div className="step-controls">
                  <button
                    onClick={() => handleDeleteAction(index, true)}
                    className="delete-action-button"
                  >
                    Delete
                  </button>
                </div>

                {!action.action && <h3>Select Action</h3>}

                <select
                  onChange={(e) => handleSelectAction(index, e.target.value, true)}
                  value={action.action || ''}
                >
                  <option value="">Select Action Type</option>
                  <option value="Condition">Condition</option>
                  <option value="SendMessage">Send Message</option>
                  <option value="IncomingMessage">Incoming Message</option>
                  <option value="SendHttpRequest">Send HTTP Request</option>
                  <option value="Reminder">Reminder</option>
                  <option value="GambotAction">Gambot Action</option>
                  <option value="GambotAi">Gambot AI</option>
                </select>

                {/* Pass Nested Dynamic Content */}
                {action.action === 'Condition' && (
                  <ConditionAction
                    onChange={(config) => handleActionConfigChange(index, config, true)}
                    steps={steps.concat(yesActions)} // Include Yes Actions in steps
                    currentUser={currentUser}
                    conditionId={`${conditionId}-yes-${index}`}
                    initialConfig={action.config}
                    dynamicContent={nestedDynamicContent} // Pass nested dynamic content
                    generateDynamicContent={generateDynamicContent}
                    historyMode={historyMode}
                    ConditionData={initialValue?.ConditionData}
                  />
                )}

                {action.action === 'SendMessage' && (
                  <OutboundMessageAction
                    onChange={(config) => handleActionConfigChange(index, config, true)}
                    initialConfig={action.config}
                    dynamicContent={nestedDynamicContent} // Pass nested dynamic content
                    parent={'OutboundMessageAction'}
                    historyMode={historyMode}
                    initialValue={action?.Data}
                  />
                )}

                {action.action === 'SendHttpRequest' && (
                  <SendHttpRequestAction
                    onChange={(config) => handleActionConfigChange(index, config, true)}
                    initialConfig={action.config}
                    dynamicContent={nestedDynamicContent} // Pass nested dynamic content
                    historyMode={historyMode}
                    initialValue={action?.Data}

                  />
                )}

                {action.action === 'IncomingMessage' && (
                  <IncomingMessageTrigger
                    onChange={(config) => handleActionConfigChange(index, config, true)}
                    initialConfig={action.config}
                    dynamicContent={nestedDynamicContent} // Pass nested dynamic content
                    historyMode={historyMode}
                    initialValue={action?.Data}

                  />
                )}
                {action.action === 'GambotAction' && (
                  <GambotAction
                    historyMode={historyMode}
                    onChange={(config) => handleActionConfigChange(index, config)}
                    organization={currentUser?.organization}
                    initialConfig={action.config}
                    dynamicContent={dynamicContent}
                  />
                )}

                {action.action === 'Reminder' && (
                  <ReminderAction
                    onChange={(config) => handleActionConfigChange(index, config)}
                    steps={steps}
                    currentUser={currentUser}
                    initialConfig={action.config}
                    dynamicContent={dynamicContent}
                    historyMode={historyMode}
                    initialValue={action.Data}
                  />
                )}
                {action.action === 'GambotAi' && (
                  <GambotAiAction
                    organization={currentUser?.organization}
                    onChange={(config) => handleActionConfigChange(index, config)}
                    initialConfig={action.config}
                    dynamicContent={dynamicContent}
                    historyMode={historyMode}
                  />
                )}
              </div>
            );
          })}
          <button className='condition-action-add-action' type="button" onClick={addYesAction}>+ Add Action</button>
        </div>

        <div className="scope">
          <h4>If no</h4>
          <div className="scope">
            <h4>If No</h4>
            {noActions.map((action, index) => {
              // Generate dynamic content specific to this action and its steps
              const nestedDynamicContent = generateDynamicContent(
                steps,
                steps.length + yesActions.length + noActions.length
              );
              console.log(action);
              return (
                <div key={index} className="scope-action">
                  <div className="step-controls">
                    <button
                      onClick={() => handleDeleteAction(index, false)} // Handle delete action for "if no"
                      className="delete-action-button"
                    >
                      Delete
                    </button>
                  </div>

                  {!action.action && <h3>Select Action</h3>}

                  <select
                    onChange={(e) => handleSelectAction(index, e.target.value, false)} // Select action for "if no"
                    value={action.action || ''}
                  >
                    <option value="">Select Action Type</option>
                    <option value="Condition">Condition</option>
                    <option value="SendMessage">Send Message</option>
                    <option value="IncomingMessage">Incoming Message</option>
                    <option value="SendHttpRequest">Send HTTP Request</option>
                  </select>

                  {/* Render the appropriate component based on the selected action */}
                  {action.action === 'Condition' && (
                    <ConditionAction
                      onChange={(config) => handleActionConfigChange(index, config, false)} // Handle action configuration for "if no"
                      steps={steps.concat(noActions)} // Include "if no" actions in steps
                      currentUser={currentUser}
                      conditionId={`${conditionId}-no-${index}`}
                      initialConfig={action.config}
                      dynamicContent={nestedDynamicContent} // Pass nested dynamic content
                      parent={'ConditionAction'}
                      isYesAction={false} // Explicitly mark as "if no"
                      generateDynamicContent={generateDynamicContent} // Pass the function for dynamic content generation
                      historyMode={historyMode}
                    />
                  )}

                  {action.action === 'SendMessage' && (
                    <OutboundMessageAction
                      organization={currentUser?.organization}
                      onChange={(config) => handleActionConfigChange(index, config, false)}
                      initialConfig={action.config}
                      dynamicContent={nestedDynamicContent} // Pass nested dynamic content
                      parent={'OutboundMessageAction'}
                      historyMode={historyMode}
                      initialValue={action?.Data}

                    />
                  )}

                  {action.action === 'SendHttpRequest' && (
                    <SendHttpRequestAction
                      organization={currentUser?.organization}
                      onChange={(config) => handleActionConfigChange(index, config, false)}
                      initialConfig={action.config}
                      dynamicContent={nestedDynamicContent} // Pass nested dynamic content
                      historyMode={historyMode}
                      initialValue={action?.Data}

                    />
                  )}

                  {action.action === 'IncomingMessage' && (
                    <IncomingMessageTrigger
                      organization={currentUser?.organization}
                      onChange={(config) => handleActionConfigChange(index, config, false)}
                      initialConfig={action.config}
                      dynamicContent={nestedDynamicContent} // Pass nested dynamic content
                      historyMode={historyMode}
                      initialValue={action?.Data}

                    />
                  )}

                  {action.action === 'GambotAction' && (
                    <GambotAction
                      historyMode={historyMode}
                      onChange={(config) => handleActionConfigChange(index, config)}
                      organization={currentUser?.organization}
                      initialConfig={action.config}
                      dynamicContent={dynamicContent}
                    />
                  )}

                  {action.action === 'Reminder' && (
                    <ReminderAction
                      onChange={(config) => handleActionConfigChange(index, config)}
                      steps={steps}
                      currentUser={currentUser}
                      initialConfig={action.config}
                      dynamicContent={dynamicContent}
                      historyMode={historyMode}
                      initialValue={action.Data}

                    />
                  )}
                  {action.action === 'GambotAi' && (
                    <GambotAiAction
                      organization={currentUser?.organization}
                      onChange={(config) => handleActionConfigChange(index, config)}
                      initialConfig={action.config}
                      dynamicContent={dynamicContent}
                      historyMode={historyMode}
                    />
                  )}
                </div>
              );
            })}

          </div>

          <button className='condition-action-add-action' type="button" onClick={addNoAction}>+ Add Action</button>
        </div>
      </div>
    </div>
  );
};

export default ConditionAction;

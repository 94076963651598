import React, { useState } from "react";
import "./Examples.css";
import { RiComputerLine } from "react-icons/ri";
import mainVideo from "../../../assets/Main.mp4"; // ייבוא הסרטון

const Examples = () => {
  // State to toggle between "מחלקה" and "תחום"
  const [categoryType, setCategoryType] = useState("department"); // "department" or "field"
  const [selectedCard, setSelectedCard] = useState(0); // Track selected card index

  // Data for cards
  const departmentData = [
    {
      title: "מכירות",
      content: "תוכן לכרטיסיית איקומרס",
      video: mainVideo,
    },
    {
      title: "שירות",
      content: "תוכן לכרטיסיית שיווק ופרסום",
      video: mainVideo,
    },
    {
      title: "שיווק",
      content: "תוכן לכרטיסיית שירות לקוחות",
      video: mainVideo,
    },
    {
      title: "איקומרס",
      content: "תוכן לכרטיסיית מכירות ו-CRM",
      video: mainVideo,
    },
    {
      title: "כספים",
      content: "תוכן לכרטיסיית מחלקה 5",
      video: mainVideo,
    },
    {
      title: "H&R",
      content: "תוכן לכרטיסיית מחלקה 6",
      video: mainVideo,
    },
    {
      title: "AI",
      content: "תוכן לכרטיסיית מחלקה 7",
      video: mainVideo,
    },
  ];

  const fieldData = [
    {
      title: "נדל\"ן",
      content: "תוכן לכרטיסיית תחום 1",
      video: mainVideo,
    },
    {
      title: "מרפאות",
      content: "תוכן לכרטיסיית תחום 2",
      video: mainVideo,
    },
    {
      title: "ביטוח",
      content: "תוכן לכרטיסיית תחום 3",
      video: mainVideo,
    },
    {
      title: "רו\"ח",
      content: "תוכן לכרטיסיית תחום 4",
      video: mainVideo,
    },
    {
      title: "מסעדות ושירותי משלוחים",
      content: "תוכן לכרטיסיית תחום 5",
      video: mainVideo,
    },
    {
      title: "תיירות ונופש",
      content: "תוכן לכרטיסיית תחום 6",
      video: mainVideo,
    },
  ];

  const currentCards = categoryType === "department" ? departmentData : fieldData;

  return (
    <div className="ExamplesContainer-Examples">
<p className="ExamplesContainerP1">
  <span className="HighlightedText-Examples">הדוגמאות שלנו</span> לפתרונות הווצאפ והאוטומציה
  <br />
  
  לפי <span className="UnderlineText-Examples">מחלקה</span> או <span className="UnderlineText-Examples">תחום</span>
</p>

      <p className="ExamplesContainerP2">
        לא מצאתם את הדוגמא של העסק שלכם? צרו איתנו קשר ונעשה לכם הדגמה אישית <RiComputerLine />
        <br></br>
      </p>
      <br></br>
      {/* Toggle Buttons */}
      <div className="ToggleContainer-Examples">
        <button
          className={`ToggleButton-Examples ToggleButton-Department ${
            categoryType === "department" ? "active" : ""
          }`}
          onClick={() => {
            setCategoryType("department");
            setSelectedCard(0); // Reset to first card
          }}
        >
          לפי מחלקה
        </button>
        <button
          className={`ToggleButton-Examples ToggleButton-Field ${
            categoryType === "field" ? "active" : ""
          }`}
          onClick={() => {
            setCategoryType("field");
            setSelectedCard(0); // Reset to first card
          }}
        >
          לפי תחום
        </button>
      </div>


      {/* Header */}
      <div className="HeaderContainer-Examples">
        {currentCards.map((card, index) => (
          <div
            key={index}
            className={`HeaderOption-Examples ${
              selectedCard === index ? "HeaderOptionActive-Examples" : ""
            }`}
            onClick={() => setSelectedCard(index)} // Set selected card
          >
            {card.title}
          </div>
        ))}
      </div>

      {/* Content */}
      <section className="ContentSection-Examples">
        <div className="Content-Examples">
          <h1>{currentCards[selectedCard].title}</h1>
          <p>{currentCards[selectedCard].content}</p>
        </div>

        <div className="ExampleBox-Examples">
          <video
            src={currentCards[selectedCard].video}
            autoPlay
            muted
            loop
            className="VideoBox-Examples"
          ></video>
        </div>
      </section>
    </div>
  );
};

export default Examples;

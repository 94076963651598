// import React, { useState } from 'react';
// import TimezoneSelect from 'react-timezone-select'; // Assuming you're using this library for timezone selection
// import PhoneInput from 'react-phone-input-2';  // Import the phone input component
// import 'react-phone-input-2/lib/style.css';   // Import the necessary styles for the component
// import './BasicInfo.css';  // Import your custom CSS
// import Swal from 'sweetalert2';


// const BasicInfo = ({ companyInfo, setCompanyInfo, nextStep, prevStep }) => {

//     // Regex to ensure organization name is in English and contains no spaces
//     const orgNameRegex = /^[a-zA-Z0-9]+$/;

//     // Regex for validating URL (allowing urls like: gambot.co.il, www.gambot.co.il, or https://gambot.co.il)
//     const urlRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z0-9]{2,}(\/.*)?$/;

//     // Regex for validating company ID or personal ID (ח.פ/תעודת זהות)
//     const idRegex = /^[0-9]{9}$/; // Example: Validates a 9-digit number

//     // const handleCompanyChange = (e) => {
//     //     const { name, value } = e.target;
//     //     setCompanyInfo({ ...companyInfo, [name]: value });
//     // };
//     const handleCompanyChange = (e) => {
//         const { name, value } = e.target;

//         // If the change is for the 'organizationName', validate the value
//         if (name === "organizationName") {
//             if (value && orgNameRegex.test(value)) {
//                 // Only update the state if the value is valid
//                 setCompanyInfo({ ...companyInfo, [name]: value });
//             } else {
//                 // Do nothing if the value is invalid (e.g., contains spaces or non-English characters)
//                 console.log("Invalid organization name. It must be in English with no spaces.");
//             }
//         } else {
//             // For all other fields, update the state normally
//             setCompanyInfo({ ...companyInfo, [name]: value });
//         }
//     };
     

//     // Correctly handle timezone change
//     const handleTimezoneChange = (timezone) => {
//         setCompanyInfo({ ...companyInfo, timezone: timezone.value });
//     };

//     // Handle phone number change
//     const handlePhoneNumberChange = (phone) => {
//         setCompanyInfo({ ...companyInfo, contactPhoneNumber: phone });
//     };

//     // Validate the URL only when the user leaves the field (onBlur event)
//     const handleUrlBlur = (e) => {
//         const value = e.target.value;

//         if (value && !urlRegex.test(value)) {
//             alert("Please enter a valid URL (e.g., gambot.co.il or https://gambot.co.il).");
//         }
//     };

//     // Validate organization name only when the user leaves the field (onBlur event)
//     const handleOrgNameBlur = (e) => {
//         const value = e.target.value;

//         if (value && !orgNameRegex.test(value)) {
//             alert("Organization name must be in English with no spaces.");
//         }
//     };

//     // Validate the business or identity number
//     const handleIdBlur = (e) => {
//         const value = e.target.value;

//         if (value && !idRegex.test(value)) {
//             alert("Please enter a valid ח.פ or תעודת זהות (9 digits).");
//         }
//     };

//     return (
//         <div className="basic-info-form">
//             <h2>פרטים אישיים</h2>

//             {/* Row with company name and id number */}
//             <div className="basic-info-form-input-row">
//                 <div className="basic-info-form-input-field">
//                     <label>שם החברה / עוסק לחשבונית</label>
//                     <input
//                         type="text"
//                         name="companyName"
//                         value={companyInfo?.companyName || ''}
//                         onChange={handleCompanyChange}
//                         required
//                     />
//                 </div>
//             </div>

//             <div className="basic-info-form-input-row">
//                 <div className="basic-info-form-input-field">
//                     <label>מספר ח.פ או תעודת זהות</label>
//                     <input
//                         type="text"
//                         name="idNumber"
//                         value={companyInfo?.idNumber || ''}
//                         onChange={handleCompanyChange}
//                         onBlur={handleIdBlur} // Add onBlur event for validation
//                         required
//                         maxLength="9" // Limit to 9 characters
//                         placeholder="הזן ח.פ או תעודת זהות"
//                     />
//                 </div>
//             </div>

//             {/* Row with organization name and company URL */}
//                {/* Row with organization name */}
//                <div className="basic-info-form-input-row">
//                 <div className="basic-info-form-input-field">
//                     <label>שם ארגון (באנגלית ללא רווחים)</label>
//                     <input
//                         type="text"
//                         name="organizationName"
//                         value={companyInfo?.organizationName || ''}
//                         onChange={handleCompanyChange}
//                         required
//                     />
//                 </div>
//             </div>
//             <div className="basic-info-form-input-row">
//                 <div className="basic-info-form-input-field">
//                     <label>כתובת אתר החברה</label>
//                     <input
//                         type="url"
//                         name="companyUrl"
//                         value={companyInfo?.companyUrl || ''}
//                         onChange={handleCompanyChange}
//                         required
//                         onBlur={handleUrlBlur} // Add onBlur event for validation
//                         pattern="https?://.+" 
//                         title="Please enter a valid URL starting with 'http://' or 'https://' (e.g., gambot.co.il or https://gambot.co.il)."
//                     />
//                 </div>
//             </div>

//             {/* Row with contact name and email */}
//             <div className="basic-info-form-input-row">
//                 <div className="basic-info-form-input-field">
//                     <label>שם מלא של איש קשר ראשי</label>
//                     <input
//                         type="text"
//                         name="contactFullName"
//                         value={companyInfo?.contactFullName || ''}
//                         onChange={handleCompanyChange}
//                         required
                        
//                     />
//                 </div>
//             </div>

//             <div className="basic-info-form-input-row">
//                 <div className="basic-info-form-input-field">
//                     <label>אימייל של איש קשר ראשי</label>
//                     <input
//                         type="email"
//                         name="contactEmail"
//                         value={companyInfo?.contactEmail || ''}
//                         onChange={handleCompanyChange}
//                         required
//                     />
//                 </div>
//             </div>

//             {/* Row with phone number and timezone */}
//             <div className="basic-info-form-input-row">
//             <div className="basic-info-form-input-field">
//                     <label>מספר טלפון של איש קשר ראשי</label>
//                     <PhoneInput
//                         country={'il'} // Default country set to Israel
//                         value={companyInfo?.contactPhoneNumber || ''}
//                         onChange={handlePhoneNumberChange}  // Handle phone number changes
//                         inputProps={{
//                             name: 'phoneNumber',
//                             required: true,
//                             placeholder: "הזן מספר טלפון",
//                         }}
//                         enableSearch
//                         disableDropdown={false}
//                         containerClass="basicinfo-phone-input-container"  // Unique class for BasicInfo
//                         inputClass="basicinfo-phone-input-field"  // Unique class for BasicInfo
//                     />
//                 </div>
//             </div>

//             <div className="basic-info-form-input-row">
//                 <div className="basic-info-form-input-field">
//                     <label>אזור זמן</label>
//                     <TimezoneSelect
//                         value={companyInfo?.timezone}
//                         onChange={handleTimezoneChange}
//                         className="timezone-select"
//                     />
//                 </div>
//             </div>

//             {/* Navigation Buttons */}
//             <div className="button-container">
//                 <button className="prev-button" onClick={prevStep}>הקודם</button>
//                 <button className="next-button" onClick={nextStep}>הבא</button>
//             </div>  
//         </div>
//     );
// };

// export default BasicInfo;


import React, { useState } from 'react';
import TimezoneSelect from 'react-timezone-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './BasicInfo.css';
import Swal from 'sweetalert2';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const BasicInfo = ({ companyInfo, setCompanyInfo, nextStep, prevStep }) => {
    const [errors, setErrors] = useState({});

    const orgNameRegex = /^[a-zA-Z0-9]+$/; 
    const urlRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z0-9]{2,}(\/.*)?$/;
    const idRegex = /^[0-9]{9}$/; 
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 

    const showAlert = (title, text, icon = "warning") => {
        return Swal.fire({
            title,
            text,
            icon,
            confirmButtonText: "אישור",
            customClass: {
                popup: "rtl-popup",
                confirmButton: "swal2-confirm"
            }
        });
    };

    const validatePhoneNumber = (phoneNumber) => {
        const parsedNumber = parsePhoneNumberFromString(phoneNumber);
        return parsedNumber ? parsedNumber.isValid() : false;
    };

    const handleCompanyChange = (e) => {
        const { name, value } = e.target;
        setCompanyInfo({ ...companyInfo, [name]: value });

        // בדיקה רק לאחר שהמשתמש עובר לשדה הבא
        setErrors({ ...errors, [name]: validateField(name, value) });
    };

    const handlePhoneNumberChange = (phone) => {
        setCompanyInfo({ ...companyInfo, contactPhoneNumber: phone });
        setErrors({ ...errors, contactPhoneNumber: validatePhoneNumber(`+${phone}`) ? '' : 'מספר הטלפון אינו תקין' });
    };

    const handleTimezoneChange = (timezone) => {
        setCompanyInfo({ ...companyInfo, timezone: timezone.value });
        setErrors({ ...errors, timezone: timezone ? '' : 'אנא בחר אזור זמן' });
    };

    const validateField = (name, value) => {
        switch (name) {
            case 'companyName':
                return value ? '' : 'שם החברה הוא שדה חובה.';
            case 'idNumber':
                return idRegex.test(value) ? '' : 'אנא הזן מספר ח.פ או תעודת זהות תקינים (9 ספרות).';
            case 'organizationName':
                return orgNameRegex.test(value) ? '' : 'שם הארגון חייב להיות באנגלית וללא רווחים.';
            case 'companyUrl':
                return urlRegex.test(value) ? '' : 'אנא הזן כתובת אתר תקינה.';
            case 'contactFullName':
                return value ? '' : 'שם מלא של איש קשר הוא שדה חובה.';
            case 'contactEmail':
                return emailRegex.test(value) ? '' : 'אנא הזן כתובת אימייל תקינה.';
            default:
                return '';
        }
    };

    const validateForm = () => {
        const newErrors = {};
        Object.keys(companyInfo).forEach((key) => {
            newErrors[key] = validateField(key, companyInfo[key]);
        });

        if (!companyInfo.contactPhoneNumber || !validatePhoneNumber(`+${companyInfo.contactPhoneNumber}`)) {
            newErrors.contactPhoneNumber = 'מספר הטלפון אינו תקין לפי המדינה שנבחרה.';
        }
        if (!companyInfo.timezone) {
            newErrors.timezone = 'אנא בחר אזור זמן.';
        }

        setErrors(newErrors);

        const hasErrors = Object.values(newErrors).some(error => error);
        if (hasErrors) {
            showAlert("שגיאה", "אנא תקן את השגיאות בטופס לפני המעבר לשלב הבא.", "error");
            return false;
        }
        return true;
    };

    const handleNextStep = () => {
        if (validateForm()) {
            nextStep();
        }
    };

    return (
        <div className="basic-info-form">
            <h2>פרטים אישיים</h2>

            {[
                { label: "שם החברה / עוסק לחשבונית", name: "companyName", type: "text", placeholder: "הזן את שם החברה" },
                { label: "מספר ח.פ או תעודת זהות", name: "idNumber", type: "text", placeholder: "על מספר הח.פ או תעודת הזהות להכיל 9 ספרות", maxLength: 9 },
                { label: "שם ארגון (באנגלית ללא רווחים)", name: "organizationName", type: "text", placeholder: "לדוגמה: MyCompany" },
                { label: "כתובת אתר החברה", name: "companyUrl", type: "url", placeholder: "לדוגמה: https://example.com" },
                { label: "שם מלא של איש קשר ראשי", name: "contactFullName", type: "text", placeholder: "לדוגמה: ישראל ישראלי" },
                { label: "אימייל של איש קשר ראשי", name: "contactEmail", type: "email", placeholder: "לדוגמה: example@email.com" }
            ].map(({ label, name, type, placeholder, maxLength }) => (
                <div key={name} className="basic-info-form-input-row">
                    <div className="basic-info-form-input-field">
                        <label>{label}</label>
                        <input
                            type={type}
                            name={name}
                            value={companyInfo[name] || ''}
                            onChange={handleCompanyChange}
                            onBlur={handleCompanyChange}
                            placeholder={placeholder}
                            maxLength={maxLength}
                            required
                        />
                        {errors[name] && <p className="error-text">{errors[name]}</p>}
                    </div>
                </div>
            ))}

            <div className="basic-info-form-input-row">
                <div className="basic-info-form-input-field">
                    <label>מספר טלפון של איש קשר ראשי</label>
                    <PhoneInput     containerClass="sim-selection-phone-number-input-container"
                                inputClass="sim-selection-phone-number-input-field" country={'il'} value={companyInfo?.contactPhoneNumber || ''} onChange={handlePhoneNumberChange} required />
                    {errors.contactPhoneNumber && <p className="error-text">{errors.contactPhoneNumber}</p>}
                </div>
            </div>

            <div className="basic-info-form-input-row">
                <div className="basic-info-form-input-field">
                    <label>אזור זמן</label>
                    <TimezoneSelect value={companyInfo?.timezone} onChange={handleTimezoneChange} className="timezone-select" />
                    {errors.timezone && <p className="error-text">{errors.timezone}</p>}
                </div>
            </div>

            <div className="button-container">
            <button className="prev-button" onClick={prevStep}>הקודם</button>
                <button className="next-button" onClick={handleNextStep}>הבא</button>
            </div>
        </div>
    );
};

export default BasicInfo;


import React, { useState } from "react";
import PostCard from "./PostCard";
import posts from "./posts";
import "./Blog.css";
import BlogHeader from '../../../assets//blogHeader.jpg';

const POSTS_PER_PAGE = 6;

const Blog = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filterTags, setFilterTags] = useState([]); 
  const [sortOrder, setSortOrder] = useState("");

  // רשימת תגיות ייחודיות
  const tags = [...new Set(posts.flatMap(post => post.tags))];

  // פונקציה לטיפול בבחירת תגיות
  const toggleTag = (tag) => {
    setFilterTags(prevTags =>
      prevTags.includes(tag)
        ? prevTags.filter(t => t !== tag) // אם כבר נבחרה - מסירים
        : [...prevTags, tag] // אחרת מוסיפים
    );
  };
  
                 
// סינון הפוסטים
let filteredPosts = posts.filter(post => {
  // אם אין בכלל בחירה של תגיות, מציגים הכל
  if (filterTags.length === 0) return true;
  
  // מחזירים רק פוסטים שיש להם לפחות תגית אחת שנבחרה
  return filterTags.some(tag => post.tags.includes(tag));
});

  // מיון לפי תאריך
  filteredPosts.sort((a, b) => {
    return sortOrder === "newest"
      ? new Date(b.date) - new Date(a.date) // מהחדש לישן
      : new Date(a.date) - new Date(b.date); // מהישן לחדש
  });

  // חישוב מספר העמודים הכולל
  const totalPages = Math.ceil(filteredPosts.length / POSTS_PER_PAGE);

  // חישוב הפוסטים שמוצגים בהתאם לעמוד הנוכחי
  const startIndex = (currentPage - 1) * POSTS_PER_PAGE;
  const visiblePosts = filteredPosts.slice(startIndex, startIndex + POSTS_PER_PAGE);

  // מעבר לעמוד הבא
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // חזרה לעמוד הקודם
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  
  return (
    <div className="blog-container">
      <div className="blog-header">
        <div className="blog-header-content">
          <h1>בלוג טיפים ולמידה</h1>
          <h2>כל מה שצריך לדעת על <span className="highlight">WhatsApp-API</span> לעסקים וארגונים</h2>
        </div>
        
        <div className="blog-header-image">
          <img src={BlogHeader} alt="Blog Header"/>
        </div>

        {/* אלמנטים גרפיים */}
        <div className="graphic-element circle"></div>
        <div className="graphic-element wave"></div>
      </div>


  {/* כותרת בלוגים פופולריים */}
  <div className="trending-title-container">
          <p className="trending-title">בלוגים פופולריים</p>
      </div>


      {/* מערכת הפילטור */}
      <div className="filter-container">
        
         {/* כפתור איפוס */}
         <button className="reset-button" onClick={() => { setFilterTags([]); setSortOrder("newest"); }}>
          איפוס סינון
        </button>

        {/* מיון לפי תאריך */}
        <select onChange={(e) => setSortOrder(e.target.value)} value={sortOrder}>
          <option value="" disabled>מיין לפי</option> 
          <option value="newest">מיין מהחדש לישן</option>
          <option value="oldest">מיין מהישן לחדש</option>
        </select>

        {/* סינון לפי תגיות */}
        <div className="tags-filter">
        {tags.map((tag, index) => (
          <button
            key={index}
            className={`tag-button ${filterTags.includes(tag) ? "active" : ""}`}
            onClick={() => toggleTag(tag)}
          >
            <span className="tag-icon">{filterTags.includes(tag) ? "✖️" : "➕"}</span> {tag}
          </button>
        ))}
      </div>

      </div>
    
      {/* רשימת הפוסטים */}
      <div className="posts-grid">
        {visiblePosts.length > 0 ? (
          visiblePosts.map((post) => <PostCard key={post.id} {...post} />)
        ) : (
          <p className="no-results">לא נמצאו תוצאות.</p>
        )}
      </div>

      {/* ניווט בין עמודים */}
      {totalPages > 1 && (
        <div className="pagination">
          <button onClick={prevPage} disabled={currentPage === 1}>
            הקודם 
          </button>

      {/* כפתורי מספרי העמודים */}
      {Array.from({ length: totalPages }, (_, index) => (
        <button
          key={index + 1}
          onClick={() => setCurrentPage(index + 1)}
          className={currentPage === index + 1 ? "active" : ""}
        >
          {index + 1}
        </button>
      ))}

      <button onClick={nextPage} disabled={currentPage === totalPages}>
        הבא
      </button>
    </div>
  )}
    </div>
  );
};

export default Blog;

import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login'; // אייקון מתאים לכניסה
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'; // אייקון מתאים לשאלות נפוצות
import PriceCheckIcon from '@mui/icons-material/PriceCheck'; // אייקון מתאים למחירון
import HelpCenterIcon from '@mui/icons-material/HelpCenter'; // אייקון מתאים לאיך זה עובד
import ArticleIcon from '@mui/icons-material/Article'; // אייקון מתאים לבלוג
import ContactMailIcon from '@mui/icons-material/ContactMail'; // אייקון מתאים לצור קשר
import GambotLogo from '../../../assets/GamBot_Logo.png'; 


export const SidebarData = [
  {
    path: '/',
    logo: GambotLogo 
  },
  {
    title: 'דף בית',
    path: '/',
    cName: 'nav-text'
  },
  {
    title: 'מחירון',
    path: '/PriceList',
    cName: 'nav-text'
  },
  {
    title: 'בלוג',
    path: '/Blog',
    cName: 'nav-text'
  },
  {
    title: 'מדריך למשתמש',
    path: '/UserGuide',
    cName: 'nav-text'
  },
  {
    title: 'צור קשר',
    path: '/ContactUs',
    cName: 'nav-text ContactUs'
  },
  {
    title: 'צור חשבון',
    path: '/OnboardingProcess',
    cName: 'nav-text CreateAccount'
  },
  {
    title: 'כניסה',
    path: '/Login',
    cName: 'nav-text login'
  }
];


import React from 'react';
import './ProgressBar.css';

const ProgressBar = ({ step }) => {
    const steps = ["בחירת מסלול", "בחירת SIM", "מילוי פרטים אישיים", "תשלום", "WABA Onboarding"];

    return (
        <div className="onborarding-progress-container">
            <div className="onborarding-progress-wrap">
                {steps.map((label, index) => (
                    <div className={`onborarding-step ${index + 1 <= step ? 'active' : ''}`} key={index}>
                        <div className="onborarding-circle">{index + 1}</div>
                        <div className="onborarding-label">{label}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProgressBar;

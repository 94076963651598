import React, { useState, useEffect } from 'react';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
import './Botomation.css';
import axios from 'axios';
import ConfirmationDialog from '../General/ConfirmationDialog'
import BotomationHistoryRunList from './BotomationHistoryRunList';
import { useHistory } from 'react-router-dom';
import { IoAddCircleOutline } from "react-icons/io5";
import { IoTrashOutline } from "react-icons/io5";
import { MdOutlineModeEdit } from "react-icons/md";
import { MdHistory } from "react-icons/md";
import { CiBoxList } from "react-icons/ci";

const Botomation = ({ currentUser }) => {
  console.log(currentUser.organization);

  const navigate = useNavigate();

  const [botomations, setBotomation] = useState([]);
  const [selectedBotomation, setSelectedBotomation] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [selectedBotomationId, setSelectedBotomationId] = useState(null);
  const [searchQuery, setSearchQuery] = useState(''); // Search query state for filtering botomations by name

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value); // Update search query
  };
  const filteredBotomations = botomations.filter(botomation =>
    botomation?.Name?.toLowerCase().includes(searchQuery?.toLowerCase()) // Filter by botomation name
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetAllBotomations', {
          organization: currentUser.organization
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        const sortedBotomations = response.data
          .sort((a, b) => new Date(b.ModifiedOn) - new Date(a.ModifiedOn)) // Sort by ModifiedOn descending
          .map(botomation => ({
            ...botomation,
            ModifiedOn: formatToUserTimezone(botomation.ModifiedOn, currentUser.timeZone),
            CreatedOn: formatToUserTimezone(botomation.CreatedOn, currentUser.timeZone)
          }));

        setBotomation(sortedBotomations);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [currentUser.organization]);

  const formatToUserTimezone = (utcDate, timezone) => {
    if (!utcDate) return 'No date available';

    try {
      return new Intl.DateTimeFormat('en-GB', {
        timeZone: timezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).format(new Date(utcDate));
    } catch (error) {
      console.error('Error formatting date:', error, 'Timezone:', timezone);
      return 'Invalid date';
    }
  };

  const handleDeleteClick = (botomation) => {
    console.log(botomation);
    setSelectedBotomation(botomation);
    setOpenDialog(true);
  };
  const handleNewBotomation = () => {
    const thisIntegrationQueryString = new URLSearchParams(
      {
        "organization": currentUser.organization,
        "userEmail": currentUser.Email,
        "mode": "Create"
      }
    )
    navigate(`/NewBotomation/?${thisIntegrationQueryString}`);

  }
  const handleEditClick = (integration) => {
    const thisIntegrationQueryString = new URLSearchParams({
      "organization": currentUser.organization,
      ...integration
    })
    navigate(`/UpdateBotomation/?${thisIntegrationQueryString}`);
  };

  const handleHistory = (botomationId) => {
    setSelectedBotomationId(botomationId);
    setHistoryDialogOpen(true);
  };
  const handleConfirmDelete = () => {
    console.log(selectedBotomation);
    // Call your delete integration API here with selectedIntegration.id
    var payload = {
      "organization": currentUser.organization,
      "BotomationId": selectedBotomation.Id

    }
    console.log(payload);
    axios.post("https://gambot.azurewebsites.net/api/Webhooks/DeleteBotomation", payload)
      .then((response) => {
        console.log(response);
        // Remove the deleted botomation from the list
        setBotomation((prevBotomations) =>
          prevBotomations.filter((botomation) => botomation.Id !== selectedBotomation.Id)
        );

      })
      .catch((error) => {
        console.log(error);
      })

    setOpenDialog(false);
  };

  return (
    <div className='botomation-list-container'>
      <h1 className='botomation-list-header'>Botomations</h1>

      <div className="botomation-list-search-bar">
        <input
          type="text"
          placeholder="Search botomations by name..."
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>
      <div className='botomation-action-buttons'>
        <button className="add-new-botomation-button" onClick={() => handleNewBotomation()}>Add New Botomation <IoAddCircleOutline /></button>
      </div>
      <div className='botomation-list-table-container'>
        <table className='botomation-list-table'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Modified On</th>
              <th>Trigger</th>
              <th>Templates</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredBotomations.map((botomation) => (
              <tr key={botomation.id}>
                <td>{botomation.Name}</td>
                <td>{botomation.Status}</td>
                <td>{botomation.ModifiedOn} <br></br> by {botomation?.ModifiedByName || 'Unknown'}</td>
                <td>{botomation.Steps?.[0]?.Action || 'N/A'}</td> {/* Extract Trigger from Steps[0].Action */}
                <td className="templates-column" title={
                  JSON.stringify(
                    botomation.Steps
                      ?.flatMap(step => {
                        const selectedTemplates = step.Config?.selectedTemplates?.map(template => template.label) || [];
                        const templateConfigName = step.Config?.templateConfig?.template?.name ? [step.Config.templateConfig.template.name] : [];
                        return [...selectedTemplates, ...templateConfigName];
                      }) || []
                  )
                }>
                  {
                    JSON.stringify(
                      botomation.Steps
                        ?.flatMap(step => {
                          const selectedTemplates = step.Config?.selectedTemplates?.map(template => template.label) || [];
                          const templateConfigName = step.Config?.templateConfig?.template?.name ? [step.Config.templateConfig.template.name] : [];
                          return [...selectedTemplates, ...templateConfigName];
                        }) || []
                    )
                  }
                </td>
                <td>
                  <button className="botomation-edit-button" onClick={() => handleEditClick(botomation)} title="Edit botomation"><MdOutlineModeEdit /></button>
                  <button className="botomation-history-button" onClick={() => handleHistory(botomation.Id)} title="Botomation runs history"><MdHistory /></button>
                  <button className="botomation-delete-button" onClick={() => handleDeleteClick(botomation)} title="Delete botomation"><IoTrashOutline /></button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ConfirmationDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleConfirmDelete}
        message={`Are you sure you want to delete the flow: "${selectedBotomation?.Name}"?`}
        actionType='Delete'
      />

      <BotomationHistoryRunList
        botomationId={selectedBotomationId}
        open={historyDialogOpen}
        onClose={() => setHistoryDialogOpen(false)}
        currenUser={currentUser}
      />
    </div>
  );

};

export default Botomation;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { TextField, Container, Grid, Paper } from '@mui/material';
import './ActivityLogForm.css'; // Add styles for this form
import { useNavigate, useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Import the back icon
const ActivityLogForm = ({ currentUser }) => {
  const { logId } = useParams(); // Get logId from URL
  const [activityLog, setActivityLog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
    const navigate = useNavigate();

  useEffect(() => {
    // Function to fetch the activity log by logId
    const fetchActivityLog = async () => {
      try {
        const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/getActivityLogById', {
          organization: currentUser?.organization,
          logId: logId,
        });

        if (response.data.Success) {
          setActivityLog(response.data.Data); // Store the fetched activity log
        } else {
          setError('Activity log not found');
        }
      } catch (err) {
        setError('Error fetching activity log: ' + err.message);
      } finally {
        setLoading(false); // Set loading to false once the API call is done
      }
    };

    if (logId) {
      fetchActivityLog();
    }
  }, [logId, currentUser]);

  if (loading) {
    return <div>Loading...</div>; // Display loading state
  }

  if (error) {
    return <div>{error}</div>; // Display error state
  }
  const handleBackButtonClick = () => {
    navigate('/ActivityLog');
};
  return (
    <Container className="activity-log-form-container">
        <div className="activity-log__header">
                        <IconButton onClick={handleBackButtonClick} aria-label="back" className="IconButtonGambotAi">
                            <ArrowBackIcon />
                        </IconButton>
                        <h1>Activity Log Details</h1>
                        </div>
      {activityLog && (
        <Paper className="activity-log-form-paper">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                value={activityLog.description}
                disabled
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Action"
                value={activityLog.action}
                disabled
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Status"
                value={activityLog.status}
                disabled
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Created On"
                value={activityLog.createdOn}
                disabled
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Created By"
                value={activityLog.createdByName}
                disabled
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Modified On"
                value={activityLog.modifiedOn}
                disabled
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Modified By"
                value={activityLog.modifiedByName}
                disabled
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Paper>
      )}
    </Container>
  );
};

export default ActivityLogForm;

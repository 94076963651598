import React, { useState ,useEffect} from 'react';
import FormParam from './FormParam';
import FormParamContent from './FormParamContent'; // Ensure this is imported
import './FormFlowsEditContent.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const FormFlowsEditContent = ({ selectedScreen, onContentChange, onTitleChange, onButtonChange, currentUser, isEditing ,flow , setFlow, isTemplate}) => {
  const [siblings, setSiblings] = useState([]);

  const [buttonLabel, setButtonLabel] = useState(''); // Initially set to an empty string

  useEffect(() => {
    if (isTemplate && selectedScreen?.content) {
      // Log the selected screen content for debugging
      console.log('selectedScreen.content:', selectedScreen.content);
  
      // Update the find logic to handle various types (e.g., type === 'Footer' or 0)
      const footerItem = selectedScreen.content.find(item => item.type === 0);  
  
      // Update the button label if footerItem is found
      setButtonLabel(footerItem?.label || '');
    } else {
      // Fallback to the screen's button label if the footer item is not found
      setButtonLabel(selectedScreen?.button || '');
    }
  }, [isTemplate, selectedScreen]);
  const handleContentChange = (id, updatedContent) => {
    const updatedContents = selectedScreen.content.map(contentItem =>
      contentItem.id === id ? updatedContent : contentItem // Only update the matching item
    );
    console.log(updatedContent);
    console.log(id);
    onContentChange(selectedScreen.id, updatedContents); // Ensure this updates the parent state
  };
  const handleAddContent = (subOption, option) => {
    setSiblings(option);  // Store the sibling options
    const newContentItem = { id: Date.now().toString(), name: subOption.name, type: subOption.id, content: '', isRequired: false };
    const updatedContents = [...selectedScreen.content, newContentItem];
    onContentChange(selectedScreen.id, updatedContents);
  };
  const handleDeleteContent = (id) => {
    const updatedContents = selectedScreen.content.filter(contentItem => contentItem.id !== id);
    onContentChange(selectedScreen.id, updatedContents);
  };

  const handleTitleChange = (e) => {
    onTitleChange(selectedScreen.id, e.target.value);
  };

  const handleButtonChange = (e) => {
    setButtonLabel(e.target.value); // Update the button label state
    onButtonChange(selectedScreen.id, e.target.value); // Send the updated label back to parent component
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const updatedContents = Array.from(selectedScreen.content);
    const [movedItem] = updatedContents.splice(result.source.index, 1);
    updatedContents.splice(result.destination.index, 0, movedItem);

    onContentChange(selectedScreen.id, updatedContents);
  };
  const handleVariablesUpdate = (screenVariables) => {
    // Handle logic for storing screen variables, like:
    console.log('Screen Variables:', screenVariables);
    
  };
  const getSiblingOptions = (contentItem) => {
    // Check if contentItem.type is a string, if not, parse it as a number
    const typeValue = isNaN(contentItem.type) ? contentItem.type : parseFloat(contentItem.type);
  
    if (typeValue === 'TextHeading' || (typeValue >= 1.1 && typeValue < 2)) {
      return [
        { id: 1.1, name: 'Large Heading', namingType: 'TextHeading' },
        { id: 1.2, name: 'Small Heading', namingType: 'TextSubheading' },
        { id: 1.3, name: 'Caption', namingType: 'TextCaption' },
        { id: 1.4, name: 'Body', namingType: 'TextBody' },
      ];
    } else if (typeValue === 'Image' || (typeValue >= 2.1 && typeValue < 3)) {
      return [{ id: 2.1, name: 'Image', namingType: 'Image' }];
    } else if (typeValue === 'TextInput' || typeValue === 'TextArea' || typeValue === 'DatePicker' || (typeValue >= 3.1 && typeValue < 4)) {
      return [
        { id: 3.1, name: 'Short Answer', namingType: 'TextInput' },
        { id: 3.2, name: 'Paragraph', namingType: 'TextArea' },
        { id: 3.3, name: 'Date Picker', namingType: 'DatePicker' },
      ];
    } else if (typeValue === 'RadioButtonsGroup' || typeValue === 'CheckboxGroup' || typeValue === 'Dropdown' || typeValue === 'OptIn' || (typeValue >= 4.1 && typeValue < 5)) {
      return [
        { id: 4.1, name: 'Single Choice', namingType: 'RadioButtonsGroup' },
        { id: 4.2, name: 'Multiple Choice', namingType: 'CheckboxGroup' },
        { id: 4.3, name: 'Dropdown', namingType: 'Dropdown' },
        { id: 4.4, name: 'OptIn', namingType: 'OptIn' },
      ];
    } else if (typeValue === 'PhotoPicker' || typeValue === 'DocumentPicker' || (typeValue >= 5.1 && typeValue < 6)) {
      return [
        { id: 5.1, name: 'Photo Picker', namingType: 'PhotoPicker' },
        { id: 5.2, name: 'Document Picker', namingType: 'DocumentPicker' },
      ];
    }
    
    return [];
  };
  

  
const addNewReadMoreScreenToFlow = (parentScreenId, contentItem) => {
  console.log("Parent Screen ID:", parentScreenId);
  console.log("Content Item:", contentItem);

  const title = `${contentItem?.readMoreText ? contentItem.readMoreText : 'Read More'}`;
  const newScreen = {
    id: Date.now(),
    title: title, // Set the title using the desired format
    content: [],
    isReadMoreScreen: true,
    parentScreenId: parentScreenId,
    optInContentId: contentItem?.id // Link to the opt-in content item
  };

  setFlow(prevFlow => {
    const updatedScreens = [...prevFlow.screens, newScreen];
    return {
      ...prevFlow,
      screens: updatedScreens,
    };
  });
};


const onRemoveReadMoreScreen = (contentId) => {
  console.log('flow before setting:', flow);
  setFlow(prevFlow => {
    const updatedScreens = prevFlow.screens.filter(screen => screen.optInContentId !== contentId);
    console.log("Updated screens:", updatedScreens); // Check if this is logged correctly
    const newFlow = {
      ...prevFlow,
      screens: updatedScreens,
    };
    return newFlow;
  });
};


  return (
    <div className="FormFlowsEdit-content-section">
      <h2 className='FormFlowsEdit-content-section-h2'>Manage edit content</h2>
      <input
        type="text"
        className="FormFlowsEdit-content-section-screen-title"
        placeholder="Screen title"
        value={selectedScreen.title}
        onChange={handleTitleChange}
      />
      {!selectedScreen?.isReadMoreScreen && (
      <input
        type="text"
        className="FormFlowsEdit-content-section-button-label"
        placeholder="Button label"
        value={buttonLabel}
        onChange={handleButtonChange}
      />
      )}
      
      {/* Add input for button label */}


      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="formParamContents">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps} className="droppable-container">
              {selectedScreen.content
                .filter(contentItem => contentItem.type !== 0  && contentItem?.type !== '"Footer"') // Filter out items with type 0
                .map((contentItem, index) => (
                  <Draggable key={contentItem.id} draggableId={contentItem.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="content-item"
                      >
                        <FormParamContent
                        contentItem={contentItem}
                        onUpdateContent={handleContentChange}
                        onDeleteContent={handleDeleteContent}
                        siblingOptions={getSiblingOptions(contentItem)}
                        currentUser={currentUser}
                        onVariablesUpdate={handleVariablesUpdate}
                        addNewScreenToFlow={addNewReadMoreScreenToFlow}
                        onRemoveReadMoreScreen={onRemoveReadMoreScreen}
                        selectedScreen={selectedScreen}
                      
                      />
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <FormParam onAddContent={handleAddContent} selectedScreen={selectedScreen}  />

    </div>

  );
};

export default FormFlowsEditContent;

import React, { useState, useEffect } from "react";
import axios from "axios";
import CreditCardInfo from "./CreditCardInfo";
import "./Payment.css";
import { FaCcVisa, FaCcMastercard } from 'react-icons/fa'; // FontAwesome Icons
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { MdOutlinePayment } from "react-icons/md";
import Swal from 'sweetalert2';
import LoadingOverlay from '../../General/LoadingOverlay'; // Adjust the path if needed

const Payment = ({ plan, hasSim, nextStep, prevStep, userEmail, organizationPhoneNumber, companyInfo, forwardingPhoneNumber }) => {
  const [isLoading, setIsLoading] = useState(false);


  const [totalAmount, setTotalAmount] = useState(0);
  const [showModal, setShowModal] = useState(false); // Control modal visibility
  const [cardDetails, setCardDetails] = useState(null);
  const [onboardData, setOnboardData] = useState({}); // Initialize as an empty object to avoid overwriting
  console.log(onboardData);
  // Mapping Prices
  const planPrices = {
    Basic: 199,
    Premium: 399,
    Enterprise: 1199,
  };

  const simPrice = 35;
  console.log()
  // Calculate Total Price Correctly
  useEffect(() => {
    const basePlanPrice = planPrices[translatePlanName(plan)] || 0;
    const simCharge = !hasSim ? simPrice : 0;
    setTotalAmount(basePlanPrice + simCharge);
  }, [plan, hasSim]);
  const translatePlanName = (planName) => {
    switch (planName) {
      case "בסיס":
        return "Basic";
      case "פרימיום":
        return "Premium";
      case "ארגונים":
        return "Enterprise";
      default:
        return planName;  // If no match, return the original name
    }
  };
  // Construct onboardData once all necessary information is available
  useEffect(() => {
    if (plan && companyInfo && organizationPhoneNumber) {
      const data = {
        plan : translatePlanName(plan),
        forwardingPhoneNumber: forwardingPhoneNumber,
        totalAmount,
        planPrice: planPrices[translatePlanName(plan)] || 0, 
        customerEmail: companyInfo.contactEmail,
        companyInfo: {
          companyName: companyInfo.companyName,
          organizationName: companyInfo.organizationName,
          companyUrl: companyInfo.companyUrl,
          companyPhoneNumber: companyInfo.contactPhoneNumber,
          companyId: companyInfo.idNumber,
          timezone: companyInfo.timezone,
        },
        contactInfo: {
          contactFullName: companyInfo.contactFullName,
          contactEmail: companyInfo.contactEmail,
          contactPhoneNumber: companyInfo.contactPhoneNumber,
        },
        simInfo: {
          hasSim,
          selectedSimNumber: !hasSim ? organizationPhoneNumber.replace('+', '') : '',
          simNumberEntered: organizationPhoneNumber.replace('+', ''),
        },
      };
      setOnboardData(data); // Merge new data with the existing onboardData
    }
  }, [plan, hasSim, companyInfo, organizationPhoneNumber, totalAmount, userEmail, forwardingPhoneNumber]);

  const handleModalClose = () => setShowModal(false);
  const handleModalOpen = () => setShowModal(true);
  console.log(onboardData);
  const handleSubmit = async (cardDetails, onboardData) => {
    // if (!onboardData || Object.keys(onboardData).length === 0) {
    //   alert('onboardData is not populated');
    //   return;
    // }

    setCardDetails(cardDetails);
    setShowModal(false); // Close modal after submission

    // Merge the card details into onboardData before sending to backend
    const dataToSubmit = { ...onboardData, cardDetails };
    setIsLoading(true); // Start loading

    console.log(dataToSubmit); // For debugging
    //nextStep();
    // Call backend to process payment and complete the onboarding
    try {
      const response = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/createNewSubscription", dataToSubmit);
      console.log(response);
      if (response.data.creditCardResponse?.message === "Success") {
        alert("Payment Successful!");
        nextStep(); // Go to next step after payment
      } else {
        //alert("Payment failed. Please try again.");
        nextStep();
      }
    } catch (error) {
      console.error("Payment Error:", error);
      nextStep();
      // alert("Payment failed. Please try again.");
    }finally {
      setIsLoading(false); // Stop loading
    }

     
  };
  const handleNextStep = () => {
  nextStep();  
};

  return (
    <div className="payment-container">
        <LoadingOverlay loading={isLoading}>
        </LoadingOverlay>
      <h2 className="payment-container-header">סיכום תשלום</h2>

        <p className="payment-container-plan-info">
          <IoIosCheckmarkCircleOutline className="checkmark-icon" />
          <strong>התכנית הנבחרת:</strong> <u>.{plan}</u>
        </p>
        <p className="payment-container-plan-info">
          <IoIosCheckmarkCircleOutline className="checkmark-icon" />
          <strong>עלות התכנית:</strong>
          <span className="plan-price-info">{planPrices[translatePlanName(plan)] || 0}₪ לחודש.</span>
        </p>
        {!hasSim && (
          <p className="payment-container-sim-price">
            <IoIosCheckmarkCircleOutline className="checkmark-icon" />
            <strong>עלות סים:</strong> <span>{simPrice}₪</span> לחודש.
          </p>
        )}
        <p className="payment-container-total-amount">
          <IoIosCheckmarkCircleOutline className="checkmark-icon" />
          <strong>סה"כ לתשלום:</strong> {totalAmount}₪ לחודש.
        </p>

        <button className="payment-container-purchase-btn" onClick={handleModalOpen}>
            <span>הזנת פרטי תשלום</span>
            <MdOutlinePayment className="payment-icon" />
        </button>

        {showModal && (
        <CreditCardInfo
          handleClose={handleModalClose}
          handleSubmit={handleSubmit} // מעביר את הפונקציה לקומפוננטה
          onboardData={onboardData} // ✅ This was missing!

        />
      )}

      <div className="payment-container-button-container">
        {/* <button className="payment-container-next-button" onClick={nextStep}>
          הבא
        </button> */}
        <button className="payment-container-prev-button" onClick={prevStep}>
          הקודם
        </button>
        
        <button className="next-button" onClick={handleNextStep}>הבא</button>

      </div>
    </div>
  );
};

export default Payment;

import React, { useEffect } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { CiChat1 } from "react-icons/ci";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { FaRocketchat } from "react-icons/fa";
import { TbMessageChatbot } from "react-icons/tb";
import { CiSquareRemove } from "react-icons/ci";
import { IoIosArrowDown } from "react-icons/io"; // Import the down arrow icon
import { FaArrowRight } from "react-icons/fa";
import "./WabaChatWidget.css";
import GambotLogo from '../../../src/assets/logoGam.png'; 

const WabaChatWidget = ({
  brandColor,
  chatBubbleText,
  position,
  chatButton,
  phoneNumber,
  welcomeText,
  openWidgetByDefault,
  isOpen,
  toggleWidget,
  isMinimized,
  toggleMinimize,
  removeChatBubbleText,
  font,
  textColor,
  iconColor,
  chatBubbleTextFont,
  welcomeTextColor,
  welcomeTextFont,
  openFormButtonText,
  openFormButtonFont,
  openFormButtonColor,
  chatBubbleTextColor,
  openFormButtonTextColor,
  openFormButtonAlignTo,
  openFormBodyBackgroundColor,
  openFormBodyBorderLineColor,
  preview,
  prefilledMessage
}) => {
  console.log(prefilledMessage);
  const isHebrewText = (text) => {
    return /[\u0590-\u05FF]/.test(text);  // Check if the text contains Hebrew characters
  };
  useEffect(() => {
    if (openWidgetByDefault && !isOpen && !preview) {
      // When openWidgetByDefault is true, ensure the form stays closed on page load
      toggleWidget(false);  // Ensure the form does not open by default
    }
  }, [openWidgetByDefault, isOpen, preview, toggleWidget]);

  const handleChatButtonClick = () => {
    if(!openWidgetByDefault){
      toggleWidget(!isOpen);
    }
    else{
    // Replace the placeholder with the actual page link and page title
    let dynamicMessage = prefilledMessage;
    console.log(dynamicMessage);

    // Check if the message contains {{page_link}} and replace it with the parent window's URL
    if (dynamicMessage?.includes("{{page_link}}")) {
      const parentPageLink = window.top.location.href;  // Get the parent page URL
      dynamicMessage = dynamicMessage.replace("{{page_link}}", parentPageLink); // Replace the page link placeholder
    }

    // Check if the message contains {{page_title}} and replace it with the parent window's title
    if (dynamicMessage?.includes("{{page_title}}")) {
      const parentPageTitle = window.top.document.title;  // Get the parent page title
      dynamicMessage = dynamicMessage.replace("{{page_title}}", parentPageTitle); // Replace the page title placeholder
    }

    console.log(dynamicMessage);
    const encodedMessage = encodeURIComponent(dynamicMessage); // Ensure the message is URL encoded

    // Open WhatsApp with prefilled message
    window.open(`https://wa.me/${phoneNumber}?text=${encodedMessage}`, "_blank");
    }
    
  };
  
  
  const renderChatButton = (buttonType, parent) => {
    const iconProps = { style: { color: iconColor } };
    if (isOpen && parent !== "body") {
      return <IoIosArrowDown className="waba-whatsapp-icon" {...iconProps} />;
    }
    switch (buttonType) {
      case "whatsapp":
        return <FaWhatsapp className="waba-whatsapp-icon" {...iconProps} />;
        case "ci1":
          return <CiChat1 className="waba-whatsapp-icon" {...iconProps} />;
        case "chatbubble":
          return <IoChatbubbleEllipsesOutline className="waba-whatsapp-icon" {...iconProps} />;
        case "rocket":
          return <FaRocketchat className="waba-whatsapp-icon" {...iconProps} />;
        case "chatbot":
          return <TbMessageChatbot className="waba-whatsapp-icon" {...iconProps} />;
      default:
        return <p>{chatBubbleText}</p>;
    }
  };
  console.log(isOpen);
  return (
    <div className={`waba-chat-widget-container ${position === "right" ? "right-position" : "left-position"}`}>
      {/* Chat bubble header - displayed when the widget is not open */}
      {!isOpen && chatBubbleText && (
        <div className={`waba-chat-bubble-header ${position === "left" ? "left-position-header" : "right-position"}`}>
          {/* Remove button should be aligned with the text */}
          <div className="waba-chat-bubble-text" style={{
            fontFamily: chatBubbleTextFont,
            color: chatBubbleTextColor,
            direction: isHebrewText(chatBubbleText) ? "rtl" : "ltr",  // Set direction based on text

          }}>
            <span>{chatBubbleText}</span>
          </div>
          <CiSquareRemove onClick={removeChatBubbleText} className="remove-chat-bubble" />
        </div>
      )}
  
      {/* Chat button */}
      <div
        className={`waba-chat-widget-button ${isOpen ? "open" : ""} ${position === "right" ? "right-position" : "left-position"}`}
        onClick={handleChatButtonClick} // Clicking this button will toggle the widget or open WhatsApp
        style={{ backgroundColor: brandColor }}
      >
        {renderChatButton(chatButton, "button")}
      </div>
  
      {isOpen && (
        <div className={`waba-chat-widget-open ${position === "right" ? "right-position" : "left-position"}`} style={{
          backgroundColor: openFormBodyBackgroundColor,
          border: `2px solid ${openFormBodyBorderLineColor}`,
        }}>
          <div className={`waba-chat-header ${position === "right" ? "right-position" : "left-position"}`}>
            <p
              style={{
                fontFamily: welcomeTextFont,
                color: welcomeTextColor,
                direction: isHebrewText(welcomeText) ? "rtl" : "ltr",  // Set direction based on text

              }}
            >
              {welcomeText}
            </p>
          </div>
  
          {/* Open form button */}
          <div className={`waba-chat-body ${position === "right" ? "right-position" : "left-position"}`} style={{
            display: 'flex',
            justifyContent: openFormButtonAlignTo === "center" ? "center" : openFormButtonAlignTo === "right" ? "flex-end" : "flex-start"
          }}>
             <button
            onClick={() => {
              // Replace {{page_link}} and {{page_title}} in the prefilled message with their actual values
              let dynamicMessage = prefilledMessage;

              // Replace {{page_link}} with the current page URL
              if (dynamicMessage.includes("{{page_link}}")) {
                const pageLink =  window.top.location.href;  // Get the current page URL
                dynamicMessage = dynamicMessage.replace("{{page_link}}", pageLink); // Replace the placeholder
              }

              // Replace {{page_title}} with the current page title
              if (dynamicMessage.includes("{{page_title}}")) {
                const pageTitle = window.top.document.title;  // Get the current page title
                dynamicMessage = dynamicMessage.replace("{{page_title}}", pageTitle); // Replace the placeholder
              }

              const encodedMessage = encodeURIComponent(dynamicMessage); // URL encode the message
              // Open WhatsApp with the prefilled message
              window.open(`https://wa.me/${phoneNumber}?text=${encodedMessage}`, "_blank");
            }}
              className="open-form-button"
              style={{
                backgroundColor: openFormButtonColor,
                padding: "10px 20px",
                borderRadius: "5px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "auto",
              }}
            >
              <FaWhatsapp style={{ marginLeft: "auto", fontSize: "20px" }} className="open-form-button-icon" />
              <span
                className="open-form-button-text"
                style={{
                  color: openFormButtonTextColor,
                  fontFamily: openFormButtonFont,
                }}
              >
                {openFormButtonText}
              </span>
              <FaArrowRight style={{ marginLeft: "auto", fontSize: "20px" }} />
            </button>
          </div>
  
          {/* Powered by Gambot section */}
          <div className="powered-by-section">
            <span>Powered by <a href="https://gambot.co.il" target="_blank" rel="noopener noreferrer">Gambot</a></span>
            <img src={GambotLogo} alt="Gambot Logo" className="powered-by-gambot-logo" />
          </div>
        </div>
      )}
  
      {/* Minimized widget state */}
      {isMinimized && !isOpen && (
        <div className="waba-chat-widget-minimized" style={{ backgroundColor: brandColor }}>
          <div className="waba-chat-bubble">
            <p>{chatBubbleText}</p>
          </div>
          <button className="waba-expand-button" onClick={toggleWidget}>
            <span>+</span>
          </button>
        </div>
      )}
    </div>
  );
  
  
};

export default WabaChatWidget;

import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import WorkflowDesigner from './WorkflowDesigner';

const WorkflowHistoryPage = ({ currentUser }) => {
  const [steps, setSteps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchParams] = useSearchParams();

  const botomationRunId = searchParams.get('botomationRunId');

  useEffect(() => {
    const fetchWorkflowRun = async () => {
      try {
        if (!currentUser?.organization) {
          throw new Error('Organization is missing');
        }

        const response = await axios.post(
          'https://gambot.azurewebsites.net/api/Webhooks/GetBotomationRunById',
          {
            organization: currentUser.organization, // Use direct organization path
            BotomationRunId: botomationRunId,
          }
        );

        const runData = response.data;

        const processSteps = (steps) => {
          return steps.map((step) => {
            const processedStep = {
              ...step,
              isCompleted: step.Status === 'Completed', // Add completion status
              requestData: step.Config?.requestBody || null, // Add request data
              responseData: step.Config?.responseBody || null, // Add response data
            };

            // Recursively process YesActions and NoActions
            if (step.YesActions) {
              processedStep.YesActions = processSteps(step.YesActions);
            }
            if (step.NoActions) {
              processedStep.NoActions = processSteps(step.NoActions);
            }

            return processedStep;
          });
        };

        const mappedSteps = processSteps(runData.Steps);
        setSteps(mappedSteps);
      } catch (err) {
        setError('Failed to load workflow data');
        console.error('Error fetching workflow data:', err);
      } finally {
        setLoading(false);
      }
    };

    if (botomationRunId) {
      fetchWorkflowRun();
    }
  }, [botomationRunId, currentUser]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h1>Workflow History</h1>
      <WorkflowDesigner
        steps={steps}
        setSteps={() => {}} // Disable step updates in history mode
        currentUser={currentUser}
        historyMode={true} // Enable history mode
      />
    </div>
  );
};

export default WorkflowHistoryPage;

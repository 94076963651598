import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './ActivityLog.css';

const ActivityLog = ({ currentUser }) => {
  const [activityLogs, setActivityLogs] = useState([]);
  const navigate = useNavigate(); // Initialize navigate function

  useEffect(() => {
    const fetchActivityLogs = async () => {
      try {
        const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/getAllActivityLogs', {
          organization: currentUser?.organization
        });
        // Sort logs by 'modifiedOn' (newest first)
        const sortedActivityLogs = response?.data.Data
          .sort((a, b) => new Date(b.modifiedOn) - new Date(a.modifiedOn)) // Sort by modifiedOn descending
          .map(log => ({
            ...log,
            modifiedOn: formatToUserTimezone(log.modifiedOn, currentUser?.timeZone), // Convert modifiedOn to user timezone
            createdOn: formatToUserTimezone(log.createdOn, currentUser?.timeZone) // Convert createdOn to user timezone
          }));
        
        setActivityLogs(sortedActivityLogs);
      } catch (error) {
        console.error('Error fetching activity logs:', error);
      }
    };

    if (currentUser?.organization) {
      fetchActivityLogs();
    }
  }, [currentUser?.organization]);

  const handleViewLog = (logId) => {
    navigate(`/ActivityLogForm/${logId}`); // Navigate to activity log form by id
  };

  // Function to convert UTC date to user time zone
  const formatToUserTimezone = (utcDate, timezone) => {
    if (!utcDate) return 'No date available';

    try {
      const date = new Date(utcDate); // Create a Date object from the UTC string
      if (isNaN(date.getTime())) return 'Invalid date'; // Check if the date is valid
      
      return new Intl.DateTimeFormat('en-GB', {
        timeZone: timezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).format(date); // Format the date according to the user's timezone
    } catch (error) {
      console.error('Error formatting date:', error, 'Timezone:', timezone);
      return 'Invalid date';
    }
  };

  return (
    <div className="activity-log-container">
      <h1 className="activity-log-header">Activity Logs</h1>

      {/* Display existing Activity Logs */}
      <div className="activity-log-list">
        <div className="activity-log-table-container">
          <table className="activity-log-table">
            <thead>
              <tr>
                <th>Description</th>
                <th>Action</th>
                <th>Status</th>
                <th>Created On</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {activityLogs.map((log) => (
                <tr key={log.Id}>
                  <td>{log.description}</td>
                  <td>{log.action}</td>
                  <td>{log.status}</td>
                  <td>{log?.modifiedOn} <br></br> by {log?.modifiedByName || 'Unknown'}</td>
                  <td>
                    <button className="activity-log-view-button" onClick={() => handleViewLog(log.Id)}>
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ActivityLog;

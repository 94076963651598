import React, { useState, useEffect } from "react";
import axios from "axios";
import "./GambotAction.css";
import ActionInputField from "./ActionInputField";

const GambotAction = ({
  organization,
  onChange,
  initialConfig = {},
  dynamicContent = [],
  resolvePlaceholder,
  historyMode,
  initialValue
}) => {
  const [table, setTable] = useState(initialConfig.table || "Contact");
  const [actionType, setActionType] = useState(initialConfig.actionType || "create");
  const [contactsColumns, setContactsColumns] = useState([]);
  const [fieldValues, setFieldValues] = useState({});
  const [keyOption, setKeyOption] = useState(initialConfig.keyOption || "add");
  const [keyValue, setKeyValue] = useState(initialConfig.keyValue || "");
  const [phoneNumber, setPhoneNumber] = useState(initialConfig.phoneNumber || "");
  const [spamValue, setSpamValue] = useState(
    initialConfig.spamValue !== undefined ? initialConfig.spamValue : ""
  );

  // Normalize field names to match the backend format
  const normalizeFieldName = (field) => {
    return field
      .replace(/\t/g, "") // Remove tabs
      .replace(/\s+/g, "") // Remove spaces
      .replace(/[^\w]/g, "") // Remove non-alphanumeric characters
      .replace(/^[A-Z]/, (char) => char.toLowerCase()); // Lowercase the first letter
  };

  useEffect(() => {
    const fetchContactsColumns = async (organization) => {
      try {
        const response = await axios.post(
          "https://gambot.azurewebsites.net/api/Webhooks/GetContactsColumnsByOrg",
          {
            organization,
          }
        );

        const normalizedColumns = response.data.map(normalizeFieldName);
        setContactsColumns(normalizedColumns);

        // Initialize field values
        const initialValues = normalizedColumns.reduce((acc, column) => {
          const rawValue = initialConfig?.fieldValues?.[column] || "";
          acc[column] = resolvePlaceholder ? resolvePlaceholder(rawValue) : rawValue;
          return acc;
        }, {});

        setFieldValues(initialValues);
      } catch (error) {
        console.error("Error fetching contacts columns:", error);
      }
    };

    if (organization) {
      fetchContactsColumns(organization);
    }

    // Set initial values for spam and keys
    setKeyOption(initialConfig.keyOption || "add");
    setKeyValue(initialConfig.keyValue || "");
    setPhoneNumber(initialConfig.phoneNumber || "");
    setSpamValue(
      initialConfig.spamValue !== undefined ? initialConfig.spamValue : ""
    );
  }, [organization, initialConfig, resolvePlaceholder]);

  const handleFieldChange = (field, value) => {
    setFieldValues((prev) => ({
      ...prev,
      [field]: value,
    }));

    // Prepare the payload for the parent component
    onChange({
      table,
      actionType,
      fieldValues: {
        ...fieldValues,
        [field]: value,
      },
      keyOption,
      keyValue,
      phoneNumber,
      spamValue,
    });
  };

  const handleKeyOptionChange = (e) => {
    setKeyOption(e.target.value);
    onChange({
      table,
      actionType,
      keyOption: e.target.value,
      keyValue,
      phoneNumber,
    });
  };

  const handleKeyValueChange = (value) => {
    setKeyValue(value);
    onChange({
      table,
      actionType,
      keyOption,
      keyValue: value,
      phoneNumber,
    });
  };

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    onChange({
      table,
      actionType,
      phoneNumber: value,
      spamValue,
    });
  };

  const handleSpamValueChange = (value) => {
    setSpamValue(value);
    onChange({
      table,
      actionType,
      phoneNumber,
      spamValue: value,
    });
  };

  return (
    <div className="gambot-action">
      <div className="form-group">
        <label htmlFor="table">Table:</label>
        <input id="table" type="text" value={table} disabled className="form-control" />
      </div>

      <div className="form-group">
        <label htmlFor="actionType">Action Type:</label>
        <select
          id="actionType"
          value={actionType}
          onChange={(e) => setActionType(e.target.value)}
          className="form-control"
        >
          <option value="create">Create New Contact</option>
          <option value="update">Update Contact</option>
          <option value="spam">Set as Spam</option>
          <option value="keys">Manage Keys</option>
        </select>
      </div>

      {(actionType === "create" || actionType === "update") && (
        <div className="form-group">
          <h3>{actionType === "create" ? "Create Contact Fields" : "Update Contact Fields"}</h3>
          {contactsColumns.map((field) => (
            <div key={field} className="form-group">
              <label htmlFor={field}>{field}:</label>
              <ActionInputField
                label=""
                value={fieldValues[field] || ""}
                onChange={(value) => handleFieldChange(field, value)}
                dynamicContent={dynamicContent}
                type="input"
              />
            </div>
          ))}
        </div>
      )}

      {actionType === "spam" && (
        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number:</label>
          <ActionInputField
            label=""
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            dynamicContent={dynamicContent}
            type="input"
          />

          <label htmlFor="spamValue">Spam Value (true/false):</label>
          <ActionInputField
            label=""
            value={spamValue}
            onChange={handleSpamValueChange}
            dynamicContent={dynamicContent}
            type="input"
          />
        </div>
      )}

      {actionType === "keys" && (
        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number:</label>
          <ActionInputField
            label=""
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            dynamicContent={dynamicContent}
            type="input"
          />

          <label htmlFor="keyOption">Key Option:</label>
          <select
            id="keyOption"
            value={keyOption}
            onChange={handleKeyOptionChange}
            className="form-control"
          >
            <option value="add">Add Key</option>
            <option value="delete">Delete Key</option>
          </select>

          <label htmlFor="keyValue">Key Value:</label>
          <ActionInputField
            label=""
            value={keyValue}
            onChange={handleKeyValueChange}
            dynamicContent={dynamicContent}
            type="input"
          />
        </div>
      )}
    </div>
  );
};

export default GambotAction;


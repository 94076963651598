import React, { useState, useEffect  } from 'react';
import ContactsKeys from '../Contacts/ContactsKeys'; // Import the ContactsKeys component
import './ChatContactsTimeline.css'; // Optional for styling
import axios from 'axios';
import ChatTimeline from './ChatTimeline';
import { useParams } from 'react-router-dom';

const ChatContactsTimeline = ({ currentUser, contactId, timelineWebSocket }) => {
    const [contactsKeys, setContactsKeys] = useState([]);
    const [timelineEntries, setTimelineEntries] = useState([]);
    const [newTimelineNote, setNewTimelineNote] = useState('');
    const [isKeysUpdated, setIsKeysUpdated] = useState(false);
    const { contactphonenumber } = useParams();

    // Fetch existing contact keys on mount
    useEffect(() => {
        const fetchContactsKeys = async () => {
            try {
                const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetContactKeys', {
                    organization: currentUser.organization,
                    contactID: contactphonenumber, // Adjusted to match backend parameter
                });
                setContactsKeys(response.data || []);
            } catch (error) {
                console.error('Error fetching contact keys:', error);
            }
        };

        const fetchTimelineEntries = async () => {
            try {
                const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetTimelineByPhoneNumber', {
                    organization: currentUser.organization,
                    phoneNumber: contactphonenumber, // Adjusted to match backend parameter
                });
                // setTimelineEntries(response.data || []);
                // Sort the timeline entries by createdOn in descending order (newest first)
                const sortedEntries = (response.data || []).sort((a, b) => {
                    const dateA = new Date(a.createdOn);
                    const dateB = new Date(b.createdOn);
                    return dateB - dateA; // Descending order
                });
                setTimelineEntries(sortedEntries);

            } catch (error) {
                console.error('Error fetching timeline entries:', error);
            }
        };

        fetchContactsKeys();
        fetchTimelineEntries();
    }, [currentUser.organization, contactphonenumber, contactId]);
    console.log(contactphonenumber);
    // Handle WebSocket for timeline events
    useEffect(() => {
        const handleTimelineEvent = (event) => {
            try {
                const data = JSON.parse(event.data);

                // Ensure that the event contains timeline data
                if (data.type === 'timelines') {
                    const newTimelines = JSON.parse(data.data); // Parse as an array or object from WebSocket
                    setTimelineEntries((prev) => {
                        const existingIds = prev.map((tl) => tl.TimelineId); // Assuming 'TimelineId' is the correct field

                        // Filter out existing timeline entries
                        const filteredTimelines = newTimelines.filter(
                            (tl) => !existingIds.includes(tl.TimelineId)
                        );

                        if (filteredTimelines.length > 0) {
                            // Combine the previous and new timelines, and sort them by 'createdOn' (ascending)
                            return [...prev, ...filteredTimelines].sort(
                                (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
                            );
                        }

                        // If no new timeline entries, return the previous state
                        return prev;
                    });
                }
            } catch (error) {
                console.error('Error processing timeline WebSocket data:', error);
            }
        };

        // Listen to WebSocket messages if it's available
        if (timelineWebSocket) {
            timelineWebSocket.addEventListener('message', handleTimelineEvent);
        }

        // Clean up WebSocket listener on unmount
        return () => {
            if (timelineWebSocket) {
                timelineWebSocket.removeEventListener('message', handleTimelineEvent);
            }
        };
    }, [timelineWebSocket]);

    // Handle changes to contact keys locally
    const handleContactsKeysChange = (updatedKeys) => {
        setContactsKeys(updatedKeys);
        setIsKeysUpdated(true); // Mark keys as updated
    };

    // Submit updated contact keys
    const handleUpdateKeys = async () => {
        try {
            await axios.post('https://gambot.azurewebsites.net/api/Webhooks/UpdateContactKeysById', {
                organization: currentUser.organization,
                contactID: contactId, // Adjusted to match backend parameter
                keys: contactsKeys,
            });
            setIsKeysUpdated(false); // Reset the updated status
        } catch (error) {
            console.error('Error updating contact keys:', error);
        }
    };

    // Handle adding a new timeline note
    const handleAddTimelineNote = async () => {
        if (!newTimelineNote.trim()) return;

        try {
            const newEntry = {
                note: newTimelineNote,
                type: 'note',
                createdById: currentUser.userId,
                createdByName: currentUser.fullname,
                createdOn: new Date().toISOString(),
            };

            await axios.post('https://gambot.azurewebsites.net/api/Webhooks/AddTimelineEntry', {
                organization: currentUser.organization,
                contactID: contactId, // Adjusted to match backend parameter
                entry: newEntry,
            });

            setTimelineEntries((prev) => [...prev, newEntry]);
            setNewTimelineNote('');
        } catch (error) {
            console.error('Error adding timeline note:', error);
        }
    };

    return (
        <div className="chat-contacts-timeline">
            <div className="contacts-keys-section">
                <h3 className='chat-contacts-timeline-contactskeys-header'>Contact Keys</h3>
                <div className='chat-contacts-timeline-contactskeys'>
                    <ContactsKeys
                        onChange={handleContactsKeysChange}
                        initialTags={contactsKeys}
                        className="small-input"
                    />
                    <button
                        className="update-keys-button"
                        onClick={handleUpdateKeys}
                        disabled={!isKeysUpdated} 
                    >
                       Click here to save your keys update
                    </button>
                </div>

            </div>
            <div className='chat-contacts-timeline-chattimeline'>
                <ChatTimeline
                    contactId={contactId}
                    currentUser={currentUser}
                    timelineEntriesProps={timelineEntries}
                >

                </ChatTimeline>
            </div>

        </div>
    );
};

export default ChatContactsTimeline;

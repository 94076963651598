import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './GambotAiForm.css';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Import the back icon
import LookUp from '../General/Lookup'; // Assuming LookUp is your language selector

const GambotAiForm = ({ currentUser }) => {
    const [formData, setFormData] = useState({
        botName: '',
        botDescription: '',
        botPurpose: '',
        purposeDescription: '',
        knowledgeBaseFile: null,  // Store the uploaded file here
        knowledgeBaseFilePath: '', // Store the existing file path (if any)
        qnaPairs: [],
        botTone: 'formal',
        personalizedResponses: false,
        handleMultipleLanguages: false,
        quickReplies: false,
        escalateToHuman: false,
        customActions: '',
        Id: '',
        botInstructions: '',
        botLanguage : '',
        isFileNew: false // New flag to check if the file is new
    });

    const handleLanguageChange = (selectedOption) => {
        setFormData((prev) => ({ ...prev, selectedLanguage: selectedOption.value }));
    };

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const [successMessage, setSuccessMessage] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [gambotAiId, setGambotAiId] = useState(null);

    useEffect(() => {
        const gambotAiId = searchParams.get('gambotAiId');
        if (gambotAiId) {
            setIsEdit(true);
            setGambotAiId(gambotAiId);
            // Fetch existing data for update
            fetchGambotAiData(gambotAiId);
        }
    }, []);

    const fetchGambotAiData = async (id) => {
        try {
            const response = await axios.post(`https://gambot.azurewebsites.net/api/Webhooks/GetGambotAiById`, {
                organization: currentUser?.organization,
                gambotAiId: id
            });
            setFormData({
                botName: response.data.BotName || '',
                botDescription: response.data.BotDescription || '',
                botPurpose: response.data.BotPurpose || '',
                purposeDescription: response.data.PurposeDescription || '',
                knowledgeBaseFile: null, // Reset file field (in case it was uploaded previously)
                knowledgeBaseFilePath: response.data.KnowledgeBaseFilePath || '', // Set the existing file path
                qnaPairs: response.data.QnAPairs || [],
                botTone: response.data.BotTone || 'formal',
                personalizedResponses: response.data.PersonalizedResponses || false,
                handleMultipleLanguages: response.data.HandleMultipleLanguages || false,
                quickReplies: response.data.QuickReplies || false,
                escalateToHuman: response.data.EscalateToHuman || false,
                customActions: response.data.CustomActions || '',
                botInstructions: response?.data?.botInstructions, // Include BotInstructions
                Id: response?.data?.Id,
                botLanguage : response?.data?.BotLanguage
            });
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching GambotAi data:', error);
        }
    };
    console.log(formData);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
        console.log(formData);
    };

    const handleFileUpload = (e) => {
        setFormData((prev) => ({
            ...prev,
            knowledgeBaseFile: e.target.files[0],
            isFileNew: true // Set this to true when a new file is uploaded
        }));
    };

    const handleQnAChange = (index, e) => {
        const { name, value } = e.target;
        const newQnA = [...formData.qnaPairs];
        newQnA[index][name] = value;
        setFormData((prev) => ({ ...prev, qnaPairs: newQnA }));
    };

    const addNewQnA = () => {
        setFormData((prev) => ({
            ...prev,
            qnaPairs: [...prev.qnaPairs, { question: '', answer: '' }]
        }));
    };

    const removeQnA = (index) => {
        const updatedQnA = formData.qnaPairs.filter((_, i) => i !== index);
        setFormData((prev) => ({ ...prev, qnaPairs: updatedQnA }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // If there's a file, we need to upload it
        let fileBase64 = '';
        if (formData.knowledgeBaseFile instanceof Blob) {
            const fileReader = new FileReader();
            fileReader.onloadend = async () => {
                fileBase64 = fileReader.result.split(',')[1]; // Get base64 string (without the 'data:image/png;base64,' prefix)

                const payload = {
                    organization: currentUser?.organization,
                    user: JSON.stringify({
                        userId: currentUser?.userId,
                        userName: currentUser?.userName
                    }),
                    gambotAiData: JSON.stringify({
                        botName: formData.botName,
                        botDescription: formData.botDescription,
                        botPurpose: formData.botPurpose,
                        purposeDescription: formData.purposeDescription,
                        knowledgeBaseFilePath: formData.knowledgeBaseFilePath,  // Ensure knowledgeBaseFilePath is included
                        qnaPairs: formData.qnaPairs,
                        botTone: formData.botTone,
                        customBotTone: formData.customBotTone,
                        personalizedResponses: formData.personalizedResponses,
                        handleMultipleLanguages: formData.handleMultipleLanguages,
                        quickReplies: formData.quickReplies,
                        escalateToHuman: formData.escalateToHuman,
                        customActions: formData.customActions,
                        botInstructions: formData.botInstructions, // Include BotInstructions
                        botLanguage : formData?.botLanguage,
                        Id: formData?.Id // Include ID to update the correct record
                    }),
                    fileBase64: fileBase64, // Base64 file content if a file is uploaded
                    fileName: formData.knowledgeBaseFile?.name,
                    fileType: formData.knowledgeBaseFile?.type,
                    isFileNew: formData.isFileNew // Send the flag for whether the file is new
                };


                console.log(payload);
                try {
                    const endpoint = isEdit ? 'UpdateGambotAi' : 'CreateGambotAi';
                    const response = await axios.post(`https://gambot.azurewebsites.net/api/Webhooks/${endpoint}`, payload, {
                        headers: {
                            'Content-Type': 'application/json' // Send as JSON
                        }
                    });

                    setSuccessMessage(`Gambot AI ${isEdit ? 'updated' : 'created'} successfully!`);
                    navigate('/GambotAi'); // Navigate to the list page after submission
                } catch (error) {
                    console.error('Error submitting Gambot AI:', error);
                }
            };

            fileReader.readAsDataURL(formData.knowledgeBaseFile);
        } else {
            // If no file was uploaded, continue with the submission
            const payload = {
                organization: currentUser?.organization,
                user: JSON.stringify({
                    userId: currentUser?.userId,
                    userName: currentUser?.userName
                }),
                gambotAiData: JSON.stringify({
                    botName: formData.botName,
                    botDescription: formData.botDescription,
                    botPurpose: formData.botPurpose,
                    purposeDescription: formData.purposeDescription,
                    knowledgeBaseFilePath: formData.knowledgeBaseFilePath,  // Ensure knowledgeBaseFilePath is included
                    qnaPairs: formData.qnaPairs,
                    botTone: formData.botTone,
                    customBotTone: formData.customBotTone,
                    personalizedResponses: formData.personalizedResponses,
                    handleMultipleLanguages: formData.handleMultipleLanguages,
                    quickReplies: formData.quickReplies,
                    escalateToHuman: formData.escalateToHuman,
                    customActions: formData.customActions,
                    botInstructions: formData.botInstructions, // Include BotInstructions
                    botLanguage : formData?.botLanguage,

                    Id: formData?.Id // Include ID to update the correct record
                }),
                fileBase64: fileBase64, // Base64 file content if a file is uploaded
                fileName: formData.knowledgeBaseFile?.name,
                fileType: formData.knowledgeBaseFile?.type,
                isFileNew: formData.isFileNew // Send the flag for whether the file is new

            };

            try {
                const endpoint = isEdit ? 'UpdateGambotAi' : 'CreateGambotAi';
                const response = await axios.post(`https://gambot.azurewebsites.net/api/Webhooks/${endpoint}`, payload, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                setSuccessMessage(`Gambot AI ${isEdit ? 'updated' : 'created'} successfully!`);
                navigate('/GambotAi');
            } catch (error) {
                console.error('Error submitting Gambot AI:', error);
            }
        }
    };

    // Function to handle back button click
    const handleBackButtonClick = () => {
        navigate('/GambotAi');
    };

    return (
        <div className="GambotAiForm">
            <form onSubmit={handleSubmit} className="GambotAiForm__form">
                <div className="GambotAiForm__header">
                    <IconButton onClick={handleBackButtonClick} aria-label="back" className="IconButtonGambotAi">
                        <ArrowBackIcon />
                    </IconButton>
                    <h2>{isEdit ? 'Update' : 'Create'} Gambot AI</h2>
                </div>

                <div className="GambotAiForm__botName">
                    <label htmlFor="botName">Bot Name:</label>
                    <input
                        type="text"
                        id="botName"
                        name="botName"
                        value={formData.botName}
                        onChange={handleInputChange}
                        required
                        placeholder="Enter bot name..."
                    />
                </div>

                <div className="GambotAiForm__botDescription">
                    <label htmlFor="botDescription">Bot Description:</label>
                    <textarea
                        id="botDescription"
                        name="botDescription"
                        value={formData.botDescription}
                        onChange={handleInputChange}
                        required
                        placeholder="Describe the bot..."
                    />
                </div>

                <div className="GambotAiForm__botPurpose">
                    <label htmlFor="botPurpose">Bot Purpose:</label>
                    <select
                        id="botPurpose"
                        name="botPurpose"
                        value={formData.botPurpose}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="">Select Purpose</option>
                        <option value="customerSupport">Customer Support</option>
                        <option value="faqBot">FAQ Bot</option>
                        <option value="salesAssistant">Sales Assistant</option>
                        <option value="appointmentScheduler">Appointment Scheduler</option>
                        <option value="leadGeneration">Lead Generation</option>
                        <option value="other">Other</option>
                    </select>
                    {formData.botPurpose === 'other' && (
                        <input
                            type="text"
                            name="purposeDescription"
                            value={formData.purposeDescription}
                            onChange={handleInputChange}
                            placeholder="Specify custom purpose"
                        />
                    )}
                </div>

                <div className="GambotAiForm__knowledgeBase">
                    <label htmlFor="knowledgeBaseFile">Upload Knowledge Base File (Optional):</label>
                    {formData.knowledgeBaseFilePath ? (
                        <div>
                            <a href={formData.knowledgeBaseFilePath} target="_blank" rel="noopener noreferrer">
                                View Existing File
                            </a>
                        </div>
                    ) : null}
                    <input
                        type="file"
                        id="knowledgeBaseFile"
                        name="knowledgeBaseFile"
                        onChange={handleFileUpload}
                    />
                </div>

                <div className="GambotAiForm__qnaPairs">
                    <label>Question-Answer Pairs:</label>
                    {formData.qnaPairs.map((pair, index) => (
                        <div key={index} className="GambotAiForm__qnaItem">
                            <input
                                type="text"
                                name="question"
                                placeholder="Question"
                                value={pair.question}
                                onChange={(e) => handleQnAChange(index, e)}
                            />
                            <textarea
                                name="answer"
                                placeholder="Answer"
                                value={pair.answer}
                                onChange={(e) => handleQnAChange(index, e)}
                            />
                            <button type="button" onClick={() => removeQnA(index)} className="gambotaiform-remove-btn">
                                Remove Q&A
                            </button>
                        </div>
                    ))}
                    <button type="button" onClick={addNewQnA} className="gambotaiform-add-btn">
                        Add New Q&A Pair
                    </button>
                </div>

                <div className="GambotAiForm__botBehavior">
                    <label htmlFor="botTone">Bot Tone:</label>
                    <select
                        id="botTone"
                        name="botTone"
                        value={formData.botTone}
                        onChange={handleInputChange}
                    >
                        <option value="formal">Formal</option>
                        <option value="casual">Casual</option>
                        <option value="friendly">Friendly but Professional</option>
                        <option value="custom">Custom</option>
                    </select>
                    {formData.botTone === 'custom' && (
                        <div>
                            <label htmlFor="customBotTone">Custom Bot Tone:</label>
                            <input
                                type="text"
                                id="customBotTone"
                                name="customBotTone"
                                value={formData.customBotTone}
                                onChange={handleInputChange}
                                placeholder="Describe your custom bot tone"
                            />
                        </div>
                    )}

                </div>
                 {/* Language Selection */}
                 <div className="GambotAiForm__language">
                    <label htmlFor="selectedLanguage">Language:</label>
                    <LookUp
                        options={languages.map((language) => ({ value: language, label: language }))}
                        onChange={handleLanguageChange}
                        placeholder="Select a language..."
                        value={formData.selectedLanguage} // Pass the selected value
                    />
                </div>
                {/* <div className="GambotAiForm__botInstructions">
                    <label htmlFor="botInstructions">Bot Instructions (Optional):</label>
                    <textarea
                        id="botInstructions"
                        name="botInstructions"
                        value={formData.botInstructions}
                        onChange={handleInputChange}
                        placeholder="Enter instructions for the bot (e.g., 'I want you to greet the user with a friendly tone.')"
                    />
                </div> */}
                <button type="submit" className="GambotAiForm__submit">Submit</button>
            </form>
        </div>
    );
};

export default GambotAiForm;




  // Define your list of languages
  const languages = [
    'Afrikaans',
    'Albanian',
    'Amharic',
    'Arabic',
    'Armenian',
    'Azerbaijani',
    'Basque',
    'Belarusian',
    'Bengali',
    'Bosnian',
    'Bulgarian',
    'Catalan',
    'Cebuano',
    'Chichewa',
    'Chinese (Simplified)',
    'Chinese (Traditional)',
    'Corsican',
    'Croatian',
    'Czech',
    'Danish',
    'Dutch',
    'English',
    'Esperanto',
    'Estonian',
    'Filipino',
    'Finnish',
    'French',
    'Frisian',
    'Galician',
    'Georgian',
    'German',
    'Greek',
    'Gujarati',
    'Haitian Creole',
    'Hausa',
    'Hawaiian',
    'Hebrew',
    'Hindi',
    'Hmong',
    'Hungarian',
    'Icelandic',
    'Igbo',
    'Indonesian',
    'Irish',
    'Italian',
    'Japanese',
    'Javanese',
    'Kannada',
    'Kazakh',
    'Khmer',
    'Kinyarwanda',
    'Korean',
    'Kurdish (Kurmanji)',
    'Kyrgyz',
    'Lao',
    'Latin',
    'Latvian',
    'Lithuanian',
    'Luxembourgish',
    'Macedonian',
    'Malagasy',
    'Malay',
    'Malayalam',
    'Maltese',
    'Maori',
    'Marathi',
    'Mongolian',
    'Myanmar (Burmese)',
    'Nepali',
    'Norwegian',
    'Odia (Oriya)',
    'Pashto',
    'Persian',
    'Polish',
    'Portuguese',
    'Punjabi',
    'Romanian',
    'Russian',
    'Samoan',
    'Scots Gaelic',
    'Serbian',
    'Sesotho',
    'Shona',
    'Sindhi',
    'Sinhala',
    'Slovak',
    'Slovenian',
    'Somali',
    'Spanish',
    'Sundanese',
    'Swahili',
    'Swedish',
    'Tajik',
    'Tamil',
    'Tatar',
    'Telugu',
    'Thai',
    'Turkish',
    'Turkmen',
    'Ukrainian',
    'Urdu',
    'Uyghur',
    'Uzbek',
    'Vietnamese',
    'Welsh',
    'Xhosa',
    'Yiddish',
    'Yoruba',
    'Zulu',
    // Add more languages as needed
  ];


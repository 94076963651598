import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import Switch from 'react-switch';
import './SendHttpRequestAction.css';
import ActionInputField from "../WorkflowDesigner/ActionInputField";

const SendHttpRequestAction = ({ steps, onChange, initialConfig = {}, dynamicContent = [], historyMode, initialValue }) => {
  const [endpoint, setEndpoint] = useState(initialConfig.endpoint || '');
  const [method, setMethod] = useState(initialConfig.method || 'POST');
  const [headers, setHeaders] = useState(
    initialConfig.headers
      ? Object.entries(initialConfig.headers).map(([key, value]) => ({ key, value }))
      : [{ key: '', value: '' }]
  );
  const [bodyOption, setBodyOption] = useState(initialConfig.bodyOption || '');
  const [bodyBuildOption, setBodyBuildOption] = useState(initialConfig.bodyBuildOption || 'steps');
  const [body, setBody] = useState(
    typeof initialConfig.body === 'object' && initialConfig.body !== null
      ? JSON.stringify(initialConfig.body, null, 2)
      : initialConfig.body || ''
  );
  const [selectedQuestions, setSelectedQuestions] = useState(initialConfig.selectedQuestions || []);
  const [handleResponse, setHandleResponse] = useState(initialConfig.handleResponse || false);
  const [responseJsonSchema, setResponseJsonSchema] = useState(
    typeof initialConfig.responseJsonSchema === 'object' && initialConfig.responseJsonSchema !== null
      ? JSON.stringify(initialConfig.responseJsonSchema, null, 2)
      : initialConfig.responseJsonSchema || ''
  );
  const [isResponseJsonValid, setIsResponseJsonValid] = useState(true);
  const [isBodyJsonValid, setIsBodyJsonValid] = useState(true);
  const initialLoad = useRef(true);

  // Handle initialValue in historyMode
  useEffect(() => {
    if (historyMode && initialValue) {
      setEndpoint(initialValue?.Request?.Endpoint || '');
      setMethod(initialValue?.Request?.method || 'POST');
      setHeaders(
        initialValue?.Request?.Headers
          ? Object.entries(initialValue.Request.Headers).map(([key, value]) => ({ key, value }))
          : [{ key: '', value: '' }]
      );
      setBody(initialValue?.Request?.Body || '');
      setResponseJsonSchema(initialValue?.ResponseBody || '{}');
      setHandleResponse(true); // Enable response handling in history mode
    }
  }, [historyMode, initialValue]);

  useEffect(() => {
    if (historyMode) return; // Skip config updates in historyMode

    const config = {
      endpoint,
      method,
      headers: headers.reduce((acc, { key, value }) => {
        if (key) acc[key] = value;
        return acc;
      }, {}),
      body: bodyOption === 'manual' ? body : bodyOption === 'allAnswers' ? getAllAnswers() : selectedQuestions,
      bodyOption,
      bodyBuildOption,
      selectedQuestions: bodyBuildOption === 'steps' ? selectedQuestions : [],
      handleResponse,
      responseJsonSchema: handleResponse ? parseBody(responseJsonSchema) : null,
    };

    onChange(config);
  }, [
    endpoint,
    method,
    headers,
    bodyOption,
    bodyBuildOption,
    body,
    selectedQuestions,
    handleResponse,
    responseJsonSchema,
    onChange,
    historyMode,
  ]);

  const parseBody = (bodyString) => {
    try {
      return JSON.parse(bodyString);
    } catch {
      return {};
    }
  };

  const getAllAnswers = () => {
    const answers = {};
    steps?.forEach((step) => {
      if (step?.type === 'trigger' && step?.action === 'IncomingMessage') {
        answers[step?.config?.question] = step?.config?.answer;
      }
    });
    return answers;
  };

  const handleJsonBodyChange = (value) => {
    setBody(value);
    if (value) {
      try {
        JSON.parse(value);
        setIsBodyJsonValid(true);
      } catch {
        setIsBodyJsonValid(false);
      }
    }
  };

  const handleResponseJsonSchemaChange = (e) => {
    const value = e.target.value;
    setResponseJsonSchema(value);
    if (value) {
      try {
        JSON.parse(value); // Validate JSON format
        setIsResponseJsonValid(true);
      } catch {
        setIsResponseJsonValid(false);
      }
    } else {
      setIsResponseJsonValid(true);
    }
  };

  const handleHeaderChange = (index, field, value) => {
    const newHeaders = [...headers];
    newHeaders[index][field] = value;
    setHeaders(newHeaders);
  };

  const handleAddHeader = () => {
    setHeaders([...headers, { key: '', value: '' }]);
  };

  const handleRemoveHeader = (index) => {
    setHeaders(headers.filter((_, i) => i !== index));
  };

  return (
    <div className="send-http-request-action">
      <label>
        Endpoint:
        <ActionInputField
          label=""
          value={endpoint}
          onChange={(value) => setEndpoint(value)}
          dynamicContent={dynamicContent}
          type="input"
          readOnly={historyMode}
        />
      </label>
      <label>
        Method:
        <select value={method} onChange={(e) => setMethod(e.target.value)} disabled={historyMode}>
          <option value="GET">GET</option>
          <option value="POST">POST</option>
          <option value="PUT">PUT</option>
          <option value="DELETE">DELETE</option>
        </select>
      </label>
      <label>
        Headers:
        {headers.map((header, index) => (
          <div key={index} className="send-http-request-action-header-row">
            <input
              type="text"
              placeholder="Key"
              value={header.key}
              onChange={(e) => handleHeaderChange(index, 'key', e.target.value)}
              readOnly={historyMode}
            />
            <input
              type="text"
              placeholder="Value"
              value={header.value}
              onChange={(e) => handleHeaderChange(index, 'value', e.target.value)}
              readOnly={historyMode}
            />
            {!historyMode && (
              <button
                className="send-http-request-action-remove"
                type="button"
                onClick={() => handleRemoveHeader(index)}
              >
                Remove
              </button>
            )}
          </div>
        ))}
        {!historyMode && <button type="button" onClick={handleAddHeader}>Add Header</button>}
      </label>
      {method !== 'GET' && (
        <>
          <label>
            Body Option:
            <select value={bodyOption} onChange={(e) => setBodyOption(e.target.value)} disabled={historyMode}>
              <option value="">Empty</option>
              <option value="allAnswers">All Answers</option>
              <option value="manual">Build Manually</option>
            </select>
          </label>
          {bodyOption === 'manual' && (
            <>
              <label>
                Build Body:
                <ActionInputField
                  label=""
                  value={body}
                  onChange={handleJsonBodyChange}
                  dynamicContent={dynamicContent}
                  type="textarea"
                  readOnly={historyMode}
                />
              </label>
              {!isBodyJsonValid && <small>Invalid JSON format.</small>}
            </>
          )}
        </>
      )}
      <label>
        Handle Response:
        <Switch
          checked={handleResponse}
          onChange={setHandleResponse}
          height={20}
          width={48}
          disabled={historyMode}
        />
      </label>
      {handleResponse && (
        <>
          <label>
            Response JSON Schema:
            <textarea
              value={responseJsonSchema}
              onChange={handleResponseJsonSchemaChange}
              placeholder="Enter JSON schema for response"
              readOnly={historyMode}
              className={`json-schema-textarea ${isResponseJsonValid ? '' : 'invalid'}`}
            />
          </label>
          {!isResponseJsonValid && <small>Invalid JSON schema.</small>}
        </>
      )}
    </div>
  );
};

export default SendHttpRequestAction;

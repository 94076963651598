import React from 'react';
import './TermOfUse.css';

const TermOfUse = () => {
  return (
    <div className="TermOfUse-container">
      <h1 className="TermOfUse-header">תנאי שימוש</h1>

      <section className="TermOfUse-section">
        <h2 className="TermOfUse-subheader">הסכם רישיון משתמש קצה:</h2>
        <p className="TermOfUse-paragraph">
           ניר סגס הוא המפתח והבעלים הבלעדי של התוכנה הדיגיטלית "גמבוט" שניתן למצוא בכתובת 
          <a href="https://gambot.co.il" target="_blank" rel="noopener noreferrer" className="TermOfUse-link">
           -  https://gambot.co.il 
          </a>, לרבות כל זכויות הקניין הרוחני הכלולות בה.
        </p>
        <p className="TermOfUse-paragraph">
          תוכנה זו מאפשרת למשתמשים לנהל את חשבון הוואטסאפ הארגוני שלהם באמצעות סוג חשבון WhatsApp API המוכר, ניתן על ידי Meta.
        </p>
      </section>

      <section className="TermOfUse-section">
        <h2 className="TermOfUse-subheader">השירותים המוצעים:</h2>
        <ul className="TermOfUse-list">
          <li className="TermOfUse-list-item">רכישת סים מספר וירטואלי</li>
          <li className="TermOfUse-list-item">ניהול אנשי קשר</li>
          <li className="TermOfUse-list-item">יצירה וניהול טמפלטים בוואטסאפ במטא</li>
          <li className="TermOfUse-list-item">יצירה וניהול קמפיינים לדיוור הודעות בתפוצה מירבית, בכפוף לכמות המאושרת על ידי Meta.</li>
          <li className="TermOfUse-list-item">ניהול הגדרות חשבון WhatsApp API במטא</li>
          <li className="TermOfUse-list-item">ניהול טפסים בוואטסאפ ("WhatsApp flows")</li>
          <li className="TermOfUse-list-item">יצירת קישור לאתר חיצוני ("widget") לחיבור המבקרים באתר שלכם לחשבון גמבוט.</li>
          <li className="TermOfUse-list-item">יצירת אוטומציות וחיבור למערכות חיצוניות באמצעות בקשות HTTP</li>
          <li className="TermOfUse-list-item">ועוד</li>
        </ul>
      </section>

      <section className="TermOfUse-section">
        <h2 className="TermOfUse-subheader">תנאים כלליים:</h2>
        <p className="TermOfUse-paragraph">
          החברה שומרת לעצמה את הזכות לבצע שינויים בכל אחד מהשירותים המפורטים.
          הסכם זה מסדיר את תנאי השימוש בגמבוט, והוא בתוקף מרגע השימוש בפלטפורמה.
        </p>
        <p className="TermOfUse-paragraph">
          אם אינך מסכים לכל תנאי ההסכם, עליך להפסיק את השימוש בגמבוט מידית.
        </p>
      </section>

      <section className="TermOfUse-section">
        <h2 className="TermOfUse-subheader">הבהרות נוספות:</h2>
        <p className="TermOfUse-paragraph">
          * 'במטא' הכוונה שזה מבוצע דרכנו ועובר גם למטא ולכן כפוף גם לתנאים ולהסכמות שלהם.
        </p>
        <p className="TermOfUse-paragraph">
          החברה רשאית לבצע כל שינוי שתרצה בקשר לכל אחד מהשירותים המפורטים.
        </p>
        <p className="TermOfUse-paragraph">
          הסכם רישיון שימוש זה הינו בין "המשתמש" / "המנוי" –שזה בעצם החברה / הארגון המשתמש בגמבוט לבין ניר סגס ("גמבוט").
        </p>
        <p className="TermOfUse-paragraph">
          הסכם זה מסדיר את תנאי השימוש בגמבוט. על ידי גישה לפלטפורמה או שימוש בה / בחלקה אתה מקבל ומסכים להיות מחויב על פי הסכם זה, החל מהמועד שבו גישתך או השימוש שלך בפלטפורמה החל ("תאריך תחולה").
        </p>
        <p className="TermOfUse-paragraph">
          היה ואתה לא מסכים או מקבל את כל תנאי ההסכם עלייך להפסיק מיד את הגישה והשימוש בגמבוט.
        </p>
        <p className="TermOfUse-paragraph">
          במקרה ותפר את ההסכם לפי ראות עינינו אנחנו שומרים לעצמנו את הזכות לבטל חשבונך ו/או לחסום את הגישה לחשבונך ללא הודעה ו/או אחריות כלפיך.
        </p>
      </section>

      <section className="TermOfUse-section">
        <h2 className="TermOfUse-subheader">אחריות משתמש הקצה:</h2>
        <ul className="TermOfUse-list">
          <li className="TermOfUse-list-item">
            המשתמש מתחייב שהוא עסק / ארגון מכובד הפועל למטרות לגיטימיות ואינו עובר על החוק.
          </li>
          <li className="TermOfUse-list-item">
            על המשתמש להשתמש בצורה הוגנת בשירותים המוצעים מטעמנו בכל האפשרויות, המכשירים כולל Api.
          </li>
          <li className="TermOfUse-list-item">
            המשתמש מתחייב לנהל מערך ניהול ספאם למול לקוחותיו כמו לדוגמא Botomation שהטריגר בו הוא הודעה נכנסת == '0' להוסיף פעולה של עדכון אותו מספר טלפון כספאם בדאטה בייס.
          </li>
          <li className="TermOfUse-list-item">
            מספר שיוגדר כספאם לא ניתן יהיה לשלוח לו הודעות נוספות לרבות כאשר שולחים קמפיין דיוור לכל אנשי הקשר, למשתמשים שמוגדרים כספאם הודעה זו לא תשלח.
          </li>
          <li className="TermOfUse-list-item">
            לציית לכל שינוי מדיניות ו/או תנאי השירות של הכלים ו/או השירותים שניתנים על ידי גמבוט לרבות שינוי מדיניות ו/או תנאי השירות של גורמים צד שלישי כמו וואטסאפ ו/או ספקי מספרים וירטואליים.
          </li>
          <li className="TermOfUse-list-item">
            גמבוט מעניקה לך רישיון מוגבל, ניתן לביטול, לא בלעדי ושאינו ניתן להעברה – לגשת ולהשתמש בפלטפורמה כדי להשתמש בשירותים המוצעים מעת לעת על ידי גמבוט.
          </li>
        </ul>
      </section>

      <section className="TermOfUse-section">
  <h2 className="TermOfUse-subheader">מנוי:</h2>
  <ul className="TermOfUse-list">
    <li className="TermOfUse-list-item">
      <strong>מנוי ניסיון</strong> - היה ויש למשתמש אפשרות שמוצעת על ידי גמבוט בעת הצטרפותו למסלול זמני לתקופת ניסיון קצרה שלא ניתנת לחידוש ובו יתכן וחלק מהפונקציונליות לא נתמכת בסוג מנוי זה.
    </li>
    <li className="TermOfUse-list-item">
      <strong>מנוי בתשלום</strong> – מחירי המסלול , תיאורי המסלול והפונקציונליות כמו גם כמות המגבלות הנתמכות בכל מסלול ומסלול ניתן למצוא בכתובת 
      <a href="https://www.gambot.co.il/PriceList" target="_blank" rel="noopener noreferrer" className="TermOfUse-link">
      -  https://www.gambot.co.il/PriceList
      </a> ובכפוף לשינויים ללא הודעה מוקדמת.
    </li>
    <li className="TermOfUse-list-item">
      <strong>דמי מנוי</strong> - מידע על כל העמלות, המסים והעלויות החלים שיש לשלם על ידך בעת רכישת תכנית המנוי בתהליך הרכישה ו/או ההצטרפות.
    </li>
    <li className="TermOfUse-list-item">
      <strong>תשלום במועד ההצטרפות</strong> - תספק אמצעי תשלום. את אמצעי התשלום ניתן יהיה לשנות בהמשך.
    </li>
    <li className="TermOfUse-list-item">
      <strong>חידוש אוטומטי</strong> - כל המנויים מתחדשים אוטומטית לפי המסלול שנבחר – חודשי / שנתי.
    </li>
    <li className="TermOfUse-list-item">
      <strong>סיום מנוי</strong> – ניתן לסיים מנוי בתשלום בכל עת במהלך תקופת המנוי על ידי שליחת הודעה כתובה וברורה ל-info@gambot.co.il.
    </li>
  </ul>
</section>

      <section className="TermOfUse-section">
        <h2 className="TermOfUse-subheader">משפטי:</h2>
        <ul className="TermOfUse-list">
          <li className="TermOfUse-list-item">
            <strong>שיפוי</strong> – המשתמש יגן וישפה וישמור על גמבוט ללא פגיעה וזאת על חשבונו הבלעדי מכל נזק, תביעה, דרישה, פסק דין, קנס ו/או עונש לרבות ריביות והוצאות משפט ונזקים אגביים שיכולים לעלות משימוש ו/או כל טענה שיכולה להעלות בשל הסכם זה לרבות טענות צד שלישי יהיו באחריותו הבלעדית של המשתמש.
          </li>
          <li className="TermOfUse-list-item">
            <strong>הגבלת אחריות</strong> – המשתמש מאשר ומסכים שגמבוט אינה אחראית בשום מקרה ו/או מצב לאובדן הכנסות ו/או רווחים ו/או נזקים כאלה ואחרים ו/או עונשים ו/או הזדמנויות לרבות נזקי סייבר, באגים וכל נזק שיכול להיות קשור ו/או לא קשור לשימוש בגמבוט. לרבות נזקים שנוצרו לצד שלישי.
          </li>
          <li className="TermOfUse-list-item">
            <strong>זכויות הקניין הרוחני במערכת</strong> – המשתמש מאשר ומסכים שגמבוט היא הבעלים הבלעדי והיחידי לכל זכויות הקניין הרוחני שעולה מהפלטפורמה ו/או השימוש בה וזה לרבות השירותים המוצעים באתר וכל מה שיוצא מבית היוצר של גמבוט באתרה הראשי ו/או באיזור האישי למשתמשים מאושרים.
          </li>
          <li className="TermOfUse-list-item">
            <strong>החוק החל</strong> – ההסכם יהיה כפוף לחוקי מדינת ישראל ויפורש בהתאם. כל סכסוך ו/או מחלוקת ייושב באיזור השרון של מדינת ישראל.
          </li>
          <li className="TermOfUse-list-item">
            <strong>ציות לחוקים</strong> – המשתמש מאשר שהוא וכל המשתמשים מטעם ארגונו לא ייצרו קשר עם מדינות אויב למדינת ישראל ולא יעשה שימוש בניגוד לחוקי מדינת ישראל ו/או טובתה של מדינת ישראל. לא יעשה שימוש שפוגע באדם בכל תצורה ישירה ו/או עקיפה על ידי השימוש בגמבוט ויעשה שימוש אך ורק בהתאם לחוק ובמטרה טובה לספק שירות וחווית שימוש טובה לאנשי הקשר של המשתמש.
          </li>
          <li className="TermOfUse-list-item">
            <strong>כוח עליון</strong> – אף אחד מהצדדים לא יהיה אחראי לנזקים שיווצרו מכוח עליון שאינו בשליטתו הסבירה של אותו צד (בכינויו כוח עליון).
          </li>
        </ul>
      </section>
      <section className="TermOfUse-privacy-section">
        <h2 className="TermOfUse-privacy-header">תנאי פרטיות</h2>

        <div className="TermOfUse-privacy-subsection">
          <h3 className="TermOfUse-subheader">איסוף ושימוש בנתונים אישיים:</h3>
          <p className="TermOfUse-privacy-paragraph">
            ניר סגס וגמבוט אוספים נתונים אישיים של המשתמשים לצורך מתן השירותים. נתונים אלה עשויים לכלול פרטי קשר, מידע על פעילויות בפלטפורמה, ומידע על השימוש בשירותים (כגון הודעות שנשלחו, מידע על תשלום, ועוד).
          </p>
        </div>

        <div className="TermOfUse-privacy-subsection">
          <h3 className="TermOfUse-subheader">שימוש בנתונים:</h3>
          <p className="TermOfUse-privacy-paragraph">
            נתונים אלה עשויים לשמש לצורך שיפור השירותים, עדכון המשתמשים על שינויים בשירותים, תמיכה טכנית, אופטימיזציה של השירותים, ותקשורת כללית עם המשתמשים.
            בנוסף, הנתונים עשויים להישמר במערכות של צדדים שלישיים (כגון שירותי ענן), שהאחריות אליהם אינה נמצאת בידיים של גמבוט.
          </p>
        </div>

        <div className="TermOfUse-privacy-subsection">
          <h3 className="TermOfUse-subheader">שמירה על סודיות:</h3>
          <p className="TermOfUse-privacy-paragraph">
            גמבוט מתחייבת לשמור על סודיות הנתונים האישיים של המשתמשים ולנקוט בכל הצעדים הנדרשים כדי להגן עליהם, אולם לא ניתן להבטיח אבטחה מוחלטת של הנתונים.
          </p>
        </div>

        <div className="TermOfUse-privacy-subsection">
          <h3 className="TermOfUse-subheader">שיתוף עם צדדים שלישיים:</h3>
          <p className="TermOfUse-privacy-paragraph">
            במקרים מסוימים, גמבוט עשויה לשתף את הנתונים עם צדדים שלישיים לצורך מתן שירותים (כגון ספקי שירותי ענן, תמיכה טכנית, ספקי סים וירטואלי, ועוד).
            השיתוף יתבצע בהתאם לתנאים אלה ולפי הצורך למתן השירותים, והנתונים לא יימסרו לשימוש צדדים שלישיים לצורכי שיווק.
          </p>
        </div>

        <div className="TermOfUse-privacy-subsection">
          <h3 className="TermOfUse-subheader">שימוש בקובצי Cookies:</h3>
          <p className="TermOfUse-privacy-paragraph">
            גמבוט עשויה להשתמש בקובצי Cookies כדי לשפר את חוויית המשתמש. קובצי ה-Cookies משמשים לאיסוף מידע על האינטראקציה עם הפלטפורמה ושיפור השירות.
          </p>
        </div>

        <div className="TermOfUse-privacy-subsection">
          <h3 className="TermOfUse-subheader">שינויים במדיניות:</h3>
          <p className="TermOfUse-privacy-paragraph">
            גמבוט שומרת לעצמה את הזכות לשנות את תנאי השימוש ומדיניות הפרטיות בכל עת. כל שינוי ייכנס לתוקף ברגע שהמשתמש ימשיך להשתמש בשירותים לאחר פרסום השינויים.
          </p>
        </div>

        <div className="TermOfUse-privacy-subsection">
          <h3 className="TermOfUse-subheader">לקבלת פרטים נוספים:</h3>
          <p className="TermOfUse-privacy-paragraph">
            לקבלת פרטים נוספים אודות השימוש בנתונים, או כדי לממש את זכויותיך בהתאם לחוק הגנת הפרטיות, אנא צור קשר עם גמבוט דרך הדואר האלקטרוני: 
            <a href="mailto:info@gambot.co.il" className="TermOfUse-link"> info@gambot.co.il</a>.
          </p>
        </div>
      </section>
    </div>
  );
};

export default TermOfUse;


import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import ChatContainer from './ChatContainer';
import Sidebar from '../General/Sidebar';
import './ChatPage.css';
import WebSocketInstance from '../General/WebSocketInstance';
import axios from 'axios';
import ChatContactsTimeline from './ChatContactTimeline';

function ChatPage({ currentUser, signOut }) {
    const { contactphonenumber, name } = useParams();
    const decodedName = decodeURIComponent(name);
    const contactsWebSocketRef = useRef(null);
    const chatWebSocketRef = useRef(null);
    const timelineWebSocketRef = useRef(null);
    const [categories, setCategories] = useState([]);
    const [users, setUsers] = useState([]);
    const [contacts, setContacts] = useState([]);

    const [selectedContact, setSelectedContact] = useState(() => {
        const savedContact = JSON.parse(localStorage.getItem('selectedContact'));
        return savedContact || null;
    });
    const [currentChatRecipient, setCurrentChatRecipient] = useState(() => {
        return localStorage.getItem('currentChatRecipient') || null;
    });

    const fetchCategories = async () => {
        try {
            const response = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/getConversationCategories", {
                organization: currentUser.organization,
            });
            setCategories(response.data || []);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    const fetchUsers = async () => {
        try {
            const response = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/GetAllUsersByOrg", {
                organization: currentUser.organization,
            });
            setUsers(response.data);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    const fetchContacts = async () => {
        try {
            const response = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/GetAllContactsByOrg", {
                organizationiD: currentUser.organization,
            });
            setContacts(response.data || []);
        } catch (error) {
            console.error("Error fetching contacts:", error);
        }
    };

    const handleCategoriesUpdate = (updatedCategories) => {
        setCategories(updatedCategories);
    };

    useEffect(() => {
        const fetchData = async () => {
            await fetchCategories();
            await fetchUsers();
            await fetchContacts();
        };

        fetchData();
    }, [currentUser.organization]);

    useEffect(() => {
        if (contactphonenumber) {
            const contact = contacts.find((c) => c.phoneNumber === contactphonenumber);
            setSelectedContact(contact || null);
            if (contact) {
                localStorage.setItem('selectedContact', JSON.stringify(contact));
            }
        }
    }, [contactphonenumber, contacts]);

    const handleContactsUpdate = useCallback((updatedContacts) => {
        setContacts(updatedContacts); // update the contacts list
    }, []);

    const handleContactClick = useCallback((contact) => {
        setSelectedContact(contact);
        setCurrentChatRecipient(contact.phoneNumber);
        localStorage.setItem('selectedContact', JSON.stringify(contact));
    }, []);

    // WebSocket initialization for the chat recipient when `contactphonenumber` changes
    useEffect(() => {
        if (contactphonenumber && currentUser) {
            if (chatWebSocketRef.current) {
                chatWebSocketRef.current.close();
            }
            chatWebSocketRef.current = new WebSocketInstance(currentUser.organization, contactphonenumber);
            setCurrentChatRecipient(contactphonenumber);
        }
    }, [contactphonenumber, currentUser]);

    useEffect(() => {
        if (contactphonenumber && currentUser) {
            if (timelineWebSocketRef.current) {
                timelineWebSocketRef.current.close();
            }
            timelineWebSocketRef.current = new WebSocketInstance(currentUser.organization, contactphonenumber);
            setCurrentChatRecipient(contactphonenumber);
        }
    }, [contactphonenumber, currentUser]);

    return (
        <div className="chatpage">
            <div className="chatpage-container">
                <Sidebar
                    currentUser={currentUser}
                    signOut={signOut}
                    currentChatRecipient={currentChatRecipient}
                    webSocket={contactsWebSocketRef.current}
                    onContactsUpdate={handleContactsUpdate}
                    onContactClick={handleContactClick}
                    categories={categories}
                    users={users}
                />
                <ChatContainer
                    currentUser={currentUser}
                    setCurrentChatRecipient={setCurrentChatRecipient}
                    messageWebSocket={chatWebSocketRef.current}
                    timelineWebSocket={timelineWebSocketRef.current}
                    selectedContact={selectedContact}
                    categories={categories}
                    users={users}
                    onCategoriesUpdate={handleCategoriesUpdate}
                />
                <ChatContactsTimeline
                    currentUser={currentUser}
                    contactId={selectedContact?.id}
                    timelineWebSocket={timelineWebSocketRef.current}
                />
            </div>
        </div>
    );
}

export default React.memo(ChatPage);

import React, { useState, useEffect, useRef } from 'react';
import './Contacts.css';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import NewContactForm from './NewContactForm';
import ImportContacts from '../Contacts/ImportContacts';
import ImportResultsPopup from '../General/ImportResultsPopup';
import { FaFileExcel } from 'react-icons/fa';
import axios from 'axios';
import * as XLSX from 'xlsx';
import firebase from 'firebase';
import { X } from '@mui/icons-material';
import UpdateKeysForm from '../Contacts/UpdateKeysForm';
import { GrLinkNext } from "react-icons/gr";
import { GrLinkPrevious } from "react-icons/gr";
import LoadingOverlay from '../General/LoadingOverlay';
import { FaFilter } from "react-icons/fa";
import { FiRefreshCw } from "react-icons/fi";
import { IoAddCircleOutline } from "react-icons/io5";
import { CiImport } from "react-icons/ci";
import { LiaFileExcelSolid } from "react-icons/lia";
import { IoTrashOutline } from "react-icons/io5";
import { MdOutlineModeEdit } from "react-icons/md";
import { TiDeleteOutline } from "react-icons/ti";
import { RxUpdate } from "react-icons/rx";


const extractTagsFromKeys = (keys) => {
  return keys.split("#").filter(tag => tag.trim() !== "").map(tag => tag.trim());
};

const formatTime = (firebaseTimestamp) => {
  if (!firebaseTimestamp) return '--:--';
  const date = new Date(firebaseTimestamp.seconds * 1000);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return `${hours}:${minutes}`;
};

function formatPhoneNumber(phoneNumber) {
  // Remove all non-numeric characters
  let cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");

  // Handle phone numbers starting with '972'
  if (cleanedPhoneNumber.startsWith("972")) {
    return cleanedPhoneNumber;
  }

  // Handle phone numbers starting with '0' and length 10
  if (cleanedPhoneNumber.startsWith("0") && cleanedPhoneNumber.length === 10) {
    return "972" + cleanedPhoneNumber.slice(1);
  }

  // Handle phone numbers that are 9 digits long (like 505278310)
  if (cleanedPhoneNumber.length === 9) {
    return "972" + cleanedPhoneNumber;
  }

  // If already valid (for example, 972505278310)
  return cleanedPhoneNumber;
}

const Contacts = ({ currentUser, signOut, contact }) => {
  console.log(currentUser);
  const userTimeZone = currentUser?.timeZone;
  const [isLoading, setIsLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showNewContactForm, setShowNewContactForm] = useState(false);
  const [importResults, setImportResults] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedContacts, setSelectedContacts] = useState(new Set());
  const [editedName, setEditedName] = useState("");
  const [editedPhoneNumber, setEditedPhoneNumber] = useState("");
  const [editedEmail, setEditedEmail] = useState("");
  const [contactKeys, setContactKeys] = useState([]);
  const [sortConfig, setSortConfig] = useState(null);
  const [showKeysFilterDropdown, setShowKeysFilterDropdown] = useState(false);
  const [keysDropdownOpen, setKeysDropdownOpen] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [showUpdateKeysForm, setShowUpdateKeysForm] = useState(false);
  const [allContacts, setAllContacts] = useState([]); // All contacts for search
  const [editContactData, setEditContactData] = useState(null); // Contact to edit
  const [keysSearchTerm, setKeysSearchTerm] = useState('');
  const handleKeysSearch = (e) => {
    setKeysSearchTerm(e.target.value.toLowerCase());
  };
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalContacts, setTotalContacts] = useState(0);
  const pageSize = 100; // Number of contacts per page
  const totalPages = Math.ceil(totalContacts / pageSize);
  const formatToUserTimezone = (utcDate, timezone) => {
    if (!utcDate) return 'No date available';

    try {
      return new Intl.DateTimeFormat('en-GB', {
        timeZone: timezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).format(new Date(utcDate));
    } catch (error) {
      console.error('Error formatting date:', error, 'Timezone:', timezone);
      return 'Invalid date';
    }
  };
  const handleUpdateKeysClick = () => {
    setShowUpdateKeysForm(true);
  };
  const handleUpdateKeysSave = (operation, keys) => {
    const updatedContacts = contacts.map(contact => {
      if (selectedContacts.has(contact.id)) {
        if (operation === 'add') {
          const newKeys = Array.isArray(keys) ? keys : [keys];
          return { ...contact, keys: [...new Set([...contact.keys, ...newKeys])] };
        } else if (operation === 'delete') {
          return { ...contact, keys: contact.keys.filter(k => k !== keys) };
        }
      }
      return contact;
    });
    setContacts(updatedContacts);
    // Send the updated contacts to the backend
    selectedContacts.forEach(contactId => {
      const contactToUpdate = updatedContacts.find(contact => contact.id === contactId);
      axios.post("https://gambot.azurewebsites.net/api/Webhooks/UpdateContactById", {
        dataToSend: contactToUpdate
      }).catch(error => {
        console.error("Error updating contact:", error);
      });
    });

    setSelectedContacts(new Set()); // Clear the selection after updating
  };

  const handleKeySelectionChange = (event) => {
    const value = event.target.value;
    setSelectedKeys(prevState =>
      prevState.includes(value)
        ? prevState.filter(key => key !== value)
        : [...prevState, value]
    );
  };
  const [keysFilter, setKeysFilter] = useState([]);
  const templateURL = 'https://storage.googleapis.com/gambot_src/Gambot_Import_Contacts_Template_New.xlsx';
  const toggleKeysDropdown = () => {
    setShowKeysFilterDropdown(!showKeysFilterDropdown);
  };

  // Fetch paginated contacts from backend
  // const fetchContacts = async (page) => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.post(
  //       'https://gambot.azurewebsites.net/api/Webhooks/GetAllContactsByOrg_Pagination', // Corrected endpoint URI
  //       {
  //         organizationiD: currentUser.organization, // Ensure lowercase 'i' in 'organizationiD'
  //         pageNumber: page,
  //         pageSize,
  //       }
  //     );
  //     // After receiving the contacts, sort by modifiedOn in descending order (most recent first)
  //     const sortedContacts = response.data.Contacts.sort((a, b) => {
  //       const dateA = new Date(a.modifiedOn);
  //       const dateB = new Date(b.modifiedOn);
  //       return dateB - dateA; // Sort descending, newer dates first
  //     });

  //     setContacts(sortedContacts);
  //     //setContacts(response.data.Contacts);
  //     //setTotalContacts(response.data.TotalCount); // Ensure backend returns total contacts count
  //   } catch (error) {
  //     console.error('Error fetching paginated contacts:', error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  const fetchContacts = async (page) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        'https://gambot.azurewebsites.net/api/Webhooks/GetAllContactsByOrg_Pagination',
        {
          organizationiD: currentUser.organization,
          pageNumber: page,
          pageSize,
        }
      );
  
      // מיון אנשי הקשר לפי תאריך שינוי (modifiedOn) מהחדש לישן
      const sortedContacts = response.data.Contacts.sort((a, b) => 
        new Date(b.modifiedOn) - new Date(a.modifiedOn)
      );
  
      setContacts(sortedContacts); // ✅ עדכון אנשי הקשר בטבלה
      setTotalContacts(response.data.TotalCount); // ✅ עדכון מספר כללי של אנשי קשר
    } catch (error) {
      console.error('Error fetching paginated contacts:', error);
    } finally {
      setIsLoading(false);
    }
  };
   

  // רענון הנתונים בכל פעם שמשהו משתנה (יבוא או הוספה ידנית)
  useEffect(() => {
    fetchContacts(1); // הבאת הנתונים בעמוד הראשון כברירת מחדל
  }, [importResults, showNewContactForm]); // ✅ יעדכן את הטבלה ברגע שנעשה שינוי
  

  // Fetch all contacts once for search purposes
  const fetchAllContacts = async () => {
    try {
      const response = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/GetAllContactsByOrg", {
        organizationiD: currentUser.organization
      })
      setTotalContacts(response.data.length);
      console.log(response);
      console.log(response.data.length);
      setAllContacts(response.data || []); // Store all contacts for searching
    } catch (error) {
      console.error('Error fetching all contacts:', error);
    }
  };

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchContacts(page);
  };

  // Initial load and whenever currentPage changes
  useEffect(() => {
    fetchContacts(currentPage);
    fetchAllContacts();
  }, []);

  // Handle search to display filtered results or paginated results
  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term) {
      setContacts(
        allContacts.filter(contact => {
          const name = contact.name || '';
          const phone = contact.phoneNumber || '';
          const email = contact.email || '';
          return (
            name.toLowerCase().includes(term.toLowerCase()) ||
            phone.includes(term) ||
            email.toLowerCase().includes(term.toLowerCase())
          );
        })
      );
    } else {
      // Reset to paginated contacts if search is cleared
      fetchContacts(currentPage);
    }
  };

  const handleSort = (columnName) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === columnName && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key: columnName, direction });
  };

  const sortedContacts = [...contacts].sort((a, b) => {
    if (sortConfig) {
      if (sortConfig.direction === 'ascending') {
        return a[sortConfig.key].localeCompare(b[sortConfig.key]);
      } else {
        return b[sortConfig.key].localeCompare(a[sortConfig.key]);
      }
    }
    return 0;
  });

  const handleKeysFilterChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
    setKeysFilter(selectedOptions);
  };

  // const handleSearch = (e) => {
  //   setSearchTerm(e.target.value);
  // };

  const filteredContacts = sortedContacts.filter(contact => {
    const name = contact.name || "";
    const phone = contact.phoneNumber || "";
    const email = contact.email || "";
    const searchTermLowerCase = searchTerm.toLowerCase();

    // Filter by search term and keys
    return (
      (name.toLowerCase().includes(searchTermLowerCase) ||
        phone.toLowerCase().includes(searchTermLowerCase) ||
        email.toLowerCase().includes(searchTermLowerCase)) &&
      (selectedKeys.length === 0 || selectedKeys.every(key => contact.keys.includes(key)))
    );
  });
  const handleEdit = (id) => {
    setContacts(contacts.map(contact =>
      contact.id === id ? { ...contact, isEditing: true } : contact
    ));
  };
  const handleSave = (id) => {
    const contactToUpdate = contacts.find((contact) => contact.id === id);

    if (contactToUpdate) {
      // Update contact details only if necessary
      if (editedName !== "")
        contactToUpdate.name = contactToUpdate.name !== editedName ? editedName : contactToUpdate.name;
      if (editedPhoneNumber !== "")
        contactToUpdate.phoneNumber = contactToUpdate.phoneNumber !== editedPhoneNumber ? editedPhoneNumber : contactToUpdate.phoneNumber;
      if (editedEmail !== "")
        contactToUpdate.email = contactToUpdate.email !== editedEmail ? editedEmail : contactToUpdate.email;
      if (contactKeys !== "")
        contactToUpdate.keys = contactKeys.keys !== contactKeys ? contactKeys : contactToUpdate.keys;

      const dataToSend = {
        email: contactToUpdate.email,
        from: contactToUpdate.from,
        id: contactToUpdate.id,
        isEditing: true,
        isSpam: contactToUpdate.isSpam || false,
        keys: contactToUpdate.keys || [],
        lastMessage: contactToUpdate.lastMessage || "",
        name: contactToUpdate.name,
        organization: contactToUpdate.organization,
        phoneNumber: contactToUpdate.phoneNumber,
        photoURL: contactToUpdate.photoURL || "",
        time: contactToUpdate.time,
        to: contactToUpdate.to || "",
        createdOn: contactToUpdate.createdOn // Ensure createdOn is retained for sorting
      };

      // Make the API request to update the contact
      axios.post("https://gambot.azurewebsites.net/api/Webhooks/UpdateContactById", { dataToSend })
        .then((response) => {
          if (response.data && response.data.Success) {
            // Update the contact in the state
            setContacts((contacts) => {
              // Sort the updated contacts by createdOn before returning the new state
              const updatedContacts = contacts.map((contact) =>
                contact.id === id ? { ...contact, isEditing: false, ...dataToSend } : contact
              );

              return updatedContacts.sort((a, b) => {
                // Sort by 'createdOn' (ascending)
                return new Date(a.createdOn) - new Date(b.createdOn);
              });
            });
          } else {
            console.error("Error updating contact:", response.data.Message);
          }
        })
        .catch((error) => {
          console.error("Error updating contact:", error);
        });
    } else {
      console.error("Contact not found with ID:", id);
    }
  };


  const handleCancel = (id) => {
    setContacts(contacts.map(contact =>
      contact.id === id ? { ...contact, isEditing: false } : contact
    ));
  };

  const handleDelete = (id) => {
    // Show confirmation dialog
    const isConfirmed = window.confirm("Are you sure you want to delete this contact? This action cannot be undone.");

    // Proceed with deletion only if the user confirms
    if (isConfirmed) {
      axios.post("https://gambot.azurewebsites.net/api/Webhooks/DeleteContactById", {
        contactID: id,
        organization: currentUser.organization,
      })
        .then((response) => {
          // Update the contacts state after deletion
          setContacts((prevContacts) => {
            const updatedContacts = prevContacts.filter((contact) => contact.id !== id);
            // Sort the contacts by 'modifiedOn' (newest first)
            return updatedContacts.sort((a, b) => new Date(b.modifiedOn) - new Date(a.modifiedOn));
          });
        })
        .catch((error) => {
          console.error("Error deleting contact:", error);
        });
    }
  };


  const handleAddContactClick = () => {
    setEditContactData(null); // Clear any existing contact data
    setShowNewContactForm(true); // Show the form
  };

  const handleCloseForm = () => {
    setShowNewContactForm(false);
  };

  // const handleImportContactsClick = () => {
  //   fileInputRef.current.click();
  // };
  const handleImportContactsClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // ✅ איפוס הערך לפני פתיחת הדיאלוג
    }
    fileInputRef.current.click();
  };

  
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsLoading(true);

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      let contactsData = XLSX.utils.sheet_to_json(sheet);

      // Filter out empty rows
      contactsData = contactsData.filter(row => {
        // Check if the row contains any non-empty values
        return Object.values(row).some(value => value !== null && value !== undefined && value !== '');
      });
      console.log(contactsData);
      await processContactsData(contactsData);
      setIsLoading(false);
    };

    reader.readAsArrayBuffer(file);
  };

  const processContactsData = async (contactsData) => {
    console.log(contactsData);
    const importResults = [];
    const promises = contactsData.map(async (row) => {
      const name = row['Full Name'] || "";
      let phoneNumber = row['Phone Number'] || "";
      const email = row['Email'] || "";
      const keys = row['Tags'] || "";

      // Convert phoneNumber to string if it's not already
      phoneNumber = phoneNumber.toString().replace(/\D/g, '');
      console.log(phoneNumber);
      // Regular expression to match a valid 9 or 10 digit phone number
      const phoneNumberRegex = /^\d{9,10}$/;
      if (!phoneNumber.match(phoneNumberRegex)) {
        importResults.push({
          summary: `Skipped: Invalid phone number format for ${name}`,
          name,
          number: phoneNumber,
          email,
          status: "Failed",
        });
      } else {
        const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
        const newTags = extractTagsFromKeys(keys);
        const contactExists = await checkContactExistsByPhoneNumber(formattedPhoneNumber);
        if (contactExists) {
          // Fetch the existing contact 
          const existingContact = contacts.find(contact => contact.phoneNumber === formattedPhoneNumber);

          // Find tags that are in the new list but not in the existing contact's tags
          const tagsToAdd = newTags.filter(tag => !existingContact.keys.includes(tag));

          if (tagsToAdd.length > 0) {
            // Add the new tags to the contact's existing tags
            const updatedKeys = [...new Set([...existingContact.keys, ...tagsToAdd])];

            // Update the contact in the database
            const updatedContact = {
              ...existingContact,
              keys: updatedKeys
            };
            await updateContactInDatabase(updatedContact);

            // Update the contacts state
            setContacts(prevContacts => prevContacts.map(contact =>
              contact.phoneNumber === formattedPhoneNumber ? updatedContact : contact
            ));

            importResults.push({
              summary: `Exist: New tags added to ${name}`,
              name,
              number: formattedPhoneNumber,
              email,
              status: "Exist - New Tags Added",
            });
          } else {
            importResults.push({
              summary: `Skipped: Contact with phone number ${formattedPhoneNumber} already exists with the same tags for ${name}`,
              name,
              number: formattedPhoneNumber,
              email,
              status: "Exist - No New Tags",
            });
          }
        } else {
          const contact = {
            name,
            phoneNumber: formattedPhoneNumber,
            email,
            keys: newTags
          };

          try {
            await insertContactIntoDatabase(contact);
            importResults.push({
              summary: `Imported: ${name}`,
              name,
              number: formattedPhoneNumber,
              email,
              status: "Succeeded",
            });
          } catch (error) {
            console.error("Error inserting contact into database:", error);
            importResults.push({
              summary: `Skipped: Error inserting contact for ${name}`,
              name,
              number: formattedPhoneNumber,
              email,
              status: "Failed",
            });
          }
        }
      }
    });

    Promise.all(promises).then(() => {
      setImportResults(importResults);
    });
  };

  const updateContactInDatabase = async (contact) => {
    try {
      await axios.post("https://gambot.azurewebsites.net/api/Webhooks/UpdateContactById", {
        dataToSend: contact
      });
    } catch (error) {
      console.error("Error updating contact in database:", error);
    }
  };

  const checkContactExistsByPhoneNumber = async (phoneNumber) => {
    try {
      const response = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/GetContactById", {
        contactID: phoneNumber,
        organization: currentUser.organization,
      });
      return response.data !== false;
    } catch (error) {
      console.error("Error checking contact existence:", error);
      return false;
    }
  };

  const insertContactIntoDatabase = async (contact) => {
    const newContactJson = {
      organization: currentUser.organization,
      email: contact.email,
      name: contact.name,
      photoURL: '',
      lastMessage: 'New Contact Created',
      time: formatTime(firebase.firestore.Timestamp.now()),
      from: currentUser.wabaNumber,
      to: contact.phoneNumber,
      phoneNumber: contact.phoneNumber,
      id: contact.phoneNumber,
      keys: contact.keys
    };
    const requestData = {
      organization: currentUser.organization,
      contactData: newContactJson,
      user: {
        userId: currentUser?.userId,
        userName: currentUser?.fullname || 'Gambot', // Default value if fullname is undefined
      },
    };
    console.log(requestData);
    axios.post("https://gambot.azurewebsites.net/api/Webhooks/CreateNewContact", requestData)
      .then((response) => {
        // handle success
      })
      .catch((error) => {
        console.error("Error creating contact:", error);
      });
  };

  const handleImportResultsClose = () => {
    setImportResults(null);
  };

  const handleEditKeys = (e) => {
    const keysArray = e.split(",").map(tag => tag.trim());
    setContactKeys(keysArray);
  };

  const toggleContactSelection = (id) => {
    setSelectedContacts(prevSelected => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  const selectAllContacts = () => {
    setSelectedContacts(new Set(filteredContacts.map(contact => contact.id)));
  };

  const deselectAllContacts = () => {
    setSelectedContacts(new Set());
  };

  const isContactSelected = (id) => {
    return selectedContacts.has(id);
  };
  const handleDeleteSelectedClick = () => {
    if (window.confirm("Are you sure you want to delete the selected contacts? This action cannot be undone.")) {
      // Proceed with deletion
      const updatedContacts = contacts.filter(contact => !selectedContacts.has(contact.id));

      // Update the contacts in the state
      setContacts(updatedContacts);

      // Send the delete requests to the backend for each selected contact
      selectedContacts.forEach(contactId => {
        axios.post("https://gambot.azurewebsites.net/api/Webhooks/DeleteContactById", {
          contactID: contactId,
          organization: currentUser.organization,
        }).catch(error => {
          console.error("Error deleting contact:", error);
        });
      });

      // Clear the selection after deletion
      setSelectedContacts(new Set());
    }
  };
  const handleEditContact = (contact) => {
    setEditContactData(contact);
    setShowNewContactForm(true);
  };

  const onUpdate = (contactData) => {
    setContacts((prevContacts) => {
      // Check if contact exists based on the phone number or another unique identifier
      const contactIndex = prevContacts.findIndex(contact => contact.id === contactData.id);

      let updatedContacts;

      if (contactIndex !== -1) {
        // Contact exists, update it
        updatedContacts = prevContacts.map((contact) =>
          contact.id === contactData.id ? { ...contact, ...contactData } : contact
        );
      } else {
        // Contact doesn't exist, add the new contact
        updatedContacts = [...prevContacts, contactData];
      }

      // Sort the contacts by 'modifiedOn' (newest first)
      const sortedContacts = updatedContacts.sort((a, b) => new Date(b.modifiedOn) - new Date(a.modifiedOn));

      // Return the sorted contacts array
      return sortedContacts;
    });

  };



  console.log(sortConfig);


  return (
    <div className="contacts-container">
      {/* {isLoading && <div className="contacts-container-loading-indicator">Loading contacts...</div>} */}
      <LoadingOverlay loading={isLoading} /> 

      <h1 className='contacts-header'>Contacts</h1>
      {/* <input type="text" placeholder="Search contacts by name, email or phone" onChange={handleSearch} value={searchTerm} className="contacts-search-input" /> */}

      <div className="contact-action-buttons">

      <input type="text" placeholder="Search contacts by name, email or phone" onChange={handleSearch} value={searchTerm} className="contacts-search-input" />
        <br></br>
        <br></br>
        <div className="contact-buttons-container">
          <button className="template-contact-button"><a href={templateURL} target="_blank" rel="noopener noreferrer">Download template</a> <LiaFileExcelSolid /></button>
          <button className="contact-button" onClick={handleAddContactClick}>Add contact <IoAddCircleOutline /></button>
          <button className="contact-button" onClick={handleImportContactsClick}>Import contacts <CiImport /></button>
        </div>
        {/* Bulk action button for updating keys */}
        {selectedContacts.size > 0 && (
          <div className="contact-button-bulk-actions">
            <button className="contact-button-update-keys" onClick={handleUpdateKeysClick}>
              Update Keys for Selected <RxUpdate />
            </button>
            <button className="contact-button-delete-button" onClick={handleDeleteSelectedClick}>
              Delete Selected <TiDeleteOutline />
            </button>
          </div>
        )}
        <div className="contact-counts">
          <span> {selectedContacts.size > 0 ? ' Selected: ' + selectedContacts.size + '' : ''}</span>
          <span><br></br>Total: {totalContacts}</span>
        </div>
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          ref={fileInputRef}
        />
      </div>

      {importResults && (
        <ImportResultsPopup results={importResults} onClose={() => setImportResults(null)} />
      )}

      {showNewContactForm && (
        <NewContactForm
          onAddContact={handleAddContactClick}
          currentUser={currentUser}
          showForm={showNewContactForm}
          closeForm={handleCloseForm}
          setContacts={setContacts}
          initialData={editContactData} 
          isEditing={!!editContactData} 
          onUpdate={onUpdate}
        />
      )}

      {showUpdateKeysForm && (
        <UpdateKeysForm
          onClose={() => setShowUpdateKeysForm(false)}
          onSave={handleUpdateKeysSave}
          showForm={showUpdateKeysForm}
          contacts={contacts.filter(contact => selectedContacts.has(contact.id))}
        />
      )}





      {/* <span className="refresh-icon"><FiRefreshCw /></span> */}
      <div className='contacts-table-container'>
        <table className="contacts-table">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  className="custom-checkbox"
                  onChange={e => e.target.checked ? selectAllContacts() : deselectAllContacts()}
                  checked={selectedContacts.size === filteredContacts.length && filteredContacts.length > 0}
                  ref={inputRef => {
                    if (inputRef) {
                      inputRef.indeterminate = selectedContacts.size > 0 && selectedContacts.size < filteredContacts.length;
                    }
                  }}
                />
              </th>
              <th onClick={() => handleSort('name')}>
                Name
                <span className="sort-icon">
                  {sortConfig?.key === 'name'
                    ? (sortConfig.direction === 'ascending' ? '▲' : '▼')
                    : '▲'} {/* Default to ascending arrow */}
                </span>
              </th>

              <th onClick={() => handleSort('phoneNumber')}>
                Phone Number
                <span className="sort-icon">
                  {sortConfig?.key === 'phoneNumber'
                    ? (sortConfig.direction === 'ascending' ? '▲' : '▼')
                    : '▲'} {/* Default to ascending arrow */}
                </span>
              </th>

              <th onClick={() => handleSort('email')}>
                Email
                <span className="sort-icon">
                  {sortConfig?.key === 'email'
                    ? (sortConfig.direction === 'ascending' ? '▲' : '▼')
                    : '▲'} {/* Default to ascending arrow */}
                </span>
              </th>

              <th onClick={() => handleSort('modifiedOn')}>
                Modified On
                <span className="sort-icon">
                  {sortConfig?.key === 'modifiedOn'
                    ? (sortConfig.direction === 'ascending' ? '▲' : '▼')
                    : '▲'} {/* Default to ascending arrow */}
                </span>
              </th>
              <th>
                <div className="keys-filter-container" onClick={toggleKeysDropdown}>
                  Keys <FaFilter />
                  <span className="sort-icon">
                    {sortConfig?.key === 'keys' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                  </span>
                </div>
                {showKeysFilterDropdown && (
                  <div className="keys-filter-dropdown-container">
                    <button className="close-dropdown-btn" onClick={toggleKeysDropdown}>
                      ✖
                    </button>
                    <input
                      type="text"
                      placeholder="Search keys"
                      className="search-keys-input"
                      value={keysSearchTerm}
                      onChange={handleKeysSearch}
                    />
                    <div className="keys-filter-options">
                      <label>
                        <input
                          type="checkbox"
                          value="(Select All)"
                          checked={selectedKeys.length === contacts.flatMap(contact => contact.keys).length}
                          onChange={(e) => {
                            setSelectedKeys(e.target.checked ? contacts.flatMap(contact => contact.keys) : []);
                          }}
                        />
                        ( Select All )
                      </label>
                      {contacts
                        .flatMap(contact => contact.keys)
                        .filter((key, index, self) => self.indexOf(key) === index) // Remove duplicates
                        .filter(key => key.toLowerCase().includes(keysSearchTerm)) // Filter by search term
                        .map((key, index) => (
                          <label key={index}>
                            <input
                              type="checkbox"
                              value={key}
                              checked={selectedKeys.includes(key)}
                              onChange={handleKeySelectionChange}
                            />
                            {key}
                          </label>
                        ))}
                    </div>
                  </div>
                )}

              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredContacts.map(contact => (
              <tr key={contact.id}>
                {contact.isEditing ? (
                  <>
                    <td>
                      <input
                        type="checkbox"
                        checked={isContactSelected(contact.id)}
                        onChange={() => toggleContactSelection(contact.id)}
                      />
                    </td>
                    <td><input className='contact-name' type="text" defaultValue={contact.name} onChange={(e) => setEditedName(e.target.value)} /></td>
                    <td><input className='contact-to' type="text" defaultValue={contact.phoneNumber} onChange={(e) => setEditedPhoneNumber(e.target.value)} /></td>
                    <td><input className='contact-email' type="text" defaultValue={contact.email} onChange={(e) => setEditedEmail(e.target.value)} /></td>
                    <td><input style={{ 'backgroundColor': 'grey' }} className='contact-createdOn' type="text" defaultValue={contact.time} readOnly /></td>
                    <td><input className='contact-keys' type="text" defaultValue={contact.keys} onChange={(e) => handleEditKeys(e.target.value)} /></td>
                    <td>
                      <div className="contact-edit-buttons">
                        <button className="contact-edit-button" onClick={() => handleSave(contact.id)}>Save</button>
                        <button className="contact-cancel-button" onClick={() => handleCancel(contact.id)}>Cancel</button>
                      </div>
                    </td>
                  </>
                ) : (
                  <>
                    <td>
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        checked={isContactSelected(contact.id)}
                        onChange={() => toggleContactSelection(contact.id)}
                      />
                    </td>
                    <td>{contact.name}</td>
                    <td>{contact.phoneNumber}</td>
                    <td>{contact.email}</td>
                    <td>{formatToUserTimezone(contact.modifiedOn, currentUser?.timeZone)}</td>
                    {/* <td>{contact.time}</td> */}
                    {/* <td className="contact-keys-wrapper">
                      <div className="contact-keys-container">
                        {contact.keys.slice(0, 7).map((key, index) => (
                          <span key={index}>#{key} </span>
                        ))}
                        {contact.keys.length > 7 && <span>...</span>}
                      </div>
                      {contact.keys.length > 7 && (
                        <div className="contact-keys-tooltip">
                          <ul>
                            {contact.keys.map((key, index) => (
                              <li key={index}>#{key}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </td> */}
                    <td className="contact-keys-wrapper">
                      <div className="contact-keys-container">
                        {contact.keys.slice(0, 7).map((key, index) => (
                          <span key={index}>#{key} </span>
                        ))}
                        {contact.keys.length > 7 && <span>...</span>}
                      </div>
                      {contact.keys.length > 7 && (
                        <div className="contact-keys-tooltip">
                          {contact.keys.map((key, index) => (
                            <span key={index}>#{key}</span>
                          ))}
                        </div>
                      )}
                    </td>
                    <td>
                      <button className='contacts-edit-button' onClick={() => handleEditContact(contact)} title="Edit contact"><MdOutlineModeEdit /></button>
                      {/* <button className='contacts-edit-button' onClick={() => handleEdit(contact.id)}><EditIcon /></button> */}
                      <button className='contacts-delete-button' onClick={() => handleDelete(contact.id)} title="delete contact"><IoTrashOutline /></button>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <br></br>
      <div className="contact-pagination-controls">
        <GrLinkPrevious
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          <GrLinkPrevious /> Previous
        </GrLinkPrevious>

        <span className='ContactPageOf'>Page {currentPage} Of {totalPages} </span>

        <GrLinkNext
          onClick={() => handlePageChange(currentPage + 1)}
          // disabled={currentPage * pageSize >= totalContacts}
          disabled={currentPage === totalPages}
          className="pagination-button"
        >
          Next <GrLinkNext />
        </GrLinkNext>

      </div>

      {/* <div className="contact-action-buttons">
        <button className='template-contact-button'>
          <a href={templateURL} target="_blank" rel="noopener noreferrer" className="download-contacts-template-button">
            <FaFileExcel size={'18px'} /> Download Template
          </a>
        </button>
        <button className="contact-button" onClick={handleAddContactClick}>Add Contact</button>
        <button className="contact-button" onClick={handleImportContactsClick}>Import Contacts</button>
        {selectedContacts.size > 0 && (
          <div className="contact-button-bulk-actions">
            <button className="contact-button-update-keys" onClick={handleUpdateKeysClick}>
              Update Keys for Selected
            </button>
            <button className="contact-button-delete-button" onClick={handleDeleteSelectedClick}>
              Delete Selected
            </button>
          </div>
        )}
        <div className="contact-counts">
          <span> {selectedContacts.size > 0 ? ' Selected: ' + selectedContacts.size + '' : ''}</span>
          <span>Total: {totalContacts}</span>
        </div>
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          ref={fileInputRef}
        />
      </div>

      {importResults && (
        <ImportResultsPopup results={importResults} onClose={() => setImportResults(null)} />
      )}

      {showNewContactForm && (
        <NewContactForm
          onAddContact={handleAddContactClick}
          currentUser={currentUser}
          showForm={showNewContactForm}
          closeForm={handleCloseForm}
          setContacts={setContacts}
          initialData={editContactData} 
          isEditing={!!editContactData} 
          onUpdate={onUpdate}
        />
      )}

      {showUpdateKeysForm && (
        <UpdateKeysForm
          onClose={() => setShowUpdateKeysForm(false)}
          onSave={handleUpdateKeysSave}
          showForm={showUpdateKeysForm}
          contacts={contacts.filter(contact => selectedContacts.has(contact.id))}
        />
      )} */}
    </div>
  );
};

export default Contacts;

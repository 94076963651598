import React, { useState, useEffect, useRef } from 'react';
import TemplatesHandler from '../../Templates/TemplatesHandler';
import ActionInputField from '../WorkflowDesigner/ActionInputField';
import './OutboundMessageAction.css';
import DeleteIcon from '@mui/icons-material/Delete';

const OutboundMessageAction = ({ organization, onChange, initialConfig = {}, dynamicContent, parent , historyMode , initialValue}) => {
    const [messageType, setMessageType] = useState(initialConfig.messageType || 'regular');
    const [messageContent, setMessageContent] = useState(initialConfig.messageContent || '');
    const [templateConfig, setTemplateConfig] = useState(initialConfig.templateConfig || {});
    const [phoneNumber, setPhoneNumber] = useState(initialConfig.phoneNumber || ''); // New state for phone number
    const prevConfigRef = useRef(initialConfig); // Initialize with initialConfig
    const [locationName, setLocationName] = useState('');

    const [locationAddress, setLocationAddress] = useState('');
    const [longitude, setLongitude] = useState('');
    const [latitude, setLatitude] = useState('');
    // useEffect(() => {
    //     if (historyMode) {
    //         // In history mode, use initialValue data
    //         setPhoneNumber(initialValue?.to || '');

    //         if (messageType === 'regular') {
    //             setMessageContent(initialValue?.MessageSent  || '');
    //         }
    //     } else {
    //         // In regular mode, handle configurations dynamically
    //         const config = {
    //             messageType,
    //             messageContent: messageType === 'regular' ? messageContent : undefined,
    //             templateConfig: messageType === 'template' ? templateConfig : undefined,
    //             phoneNumber: parent === 'OutboundMessageAction' ? phoneNumber : undefined,
                
    //         };

    //         // Only call onChange if the config has changed
    //         if (JSON.stringify(prevConfigRef.current) !== JSON.stringify(config)) {
    //             onChange(config); // Trigger the onChange callback
    //             prevConfigRef.current = config; // Update the ref to the new config
    //         }
    //     }
    // }, [messageType, messageContent, templateConfig, phoneNumber, parent, onChange, historyMode, initialValue]);
    
    useEffect(() => {
        if (historyMode) {
            setPhoneNumber(initialValue?.to || '');
            if (messageType === 'regular') {
                setMessageContent(initialValue?.MessageSent || '');
            }
        } else {
            const config = {
                messageType,
                messageContent: messageType === 'regular' ? messageContent : undefined,
                phoneNumber: parent === 'OutboundMessageAction' ? phoneNumber : undefined,
                templateConfig:
                    messageType === 'template'
                        ? {
                              ...templateConfig,
                              locationDetails: {
                                  locationName,
                                  locationAddress,
                                  longitude,
                                  latitude
                              }
                          }
                        : undefined
            };
    
            if (JSON.stringify(prevConfigRef.current) !== JSON.stringify(config)) {
                onChange(config);
                prevConfigRef.current = config;
            }
        }
    }, [
        messageType,
        messageContent,
        templateConfig,
        phoneNumber,
        parent,
        onChange,
        historyMode,
        initialValue,
        locationName,
        locationAddress,
        longitude,
        latitude
    ]);
    

    return (
        <div className="outbound-message-action">
            <label>
                Message Type:
                <select value={messageType} onChange={(e) => setMessageType(e.target.value)} disabled={historyMode}>
                    <option value="regular">Regular</option>
                    <option value="template">Template</option>
                </select>
            </label>

            {parent === 'OutboundMessageAction' && (
                <div>
                    <label>
                        Phone Number:
                        <ActionInputField
                            label=""
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                            dynamicContent={dynamicContent}
                            type="input"
                            readOnly={historyMode}

                        />
                    </label>
                </div>
            )}

            {messageType === 'regular' && (
                <div>
                    <label>
                        Message:
                        <ActionInputField
                            label=""
                            value={messageContent}
                            onChange={setMessageContent}
                            dynamicContent={dynamicContent}
                            type="textarea"
                            readOnly={historyMode}

                        />
                    </label>
                </div>
            )}

            {messageType === 'template' && (
                <TemplatesHandler
                    organization={organization}
                    onTemplatesVariableChange={(template, variables, queries) => setTemplateConfig({ template, variables, queries })}
                    templateId={templateConfig.template?.Id}
                    templateVariableQuery={templateConfig.queries}
                    parent={'OutboundMessageAction'}
                    dynamicContent={dynamicContent}
                    initialValue={initialValue}
                    historyMode={historyMode}
                    setLocationName={setLocationName}
                    locationAddress={locationAddress}
                    setLocationAddress={setLocationAddress}
                    longitude={longitude}
                    setLongitude={setLongitude}
                    latitude={latitude}
                    setLatitude={setLatitude}
                    locationName={locationName}
                />
            )}
        </div>
    );
};

export default OutboundMessageAction;

import { Link } from "react-router-dom";
import "./PostCard.css";

const PostCard = ({ id, title, description, date, author, tags, image }) => {
  return (
    <Link to={`/blog/${id}`} className="post-card-link">
      <div className="post-card">
        {image && <img src={image} alt={title} className="post-image" />}
        <div className="post-content">
          <h3 className="post-title">{title}</h3>
          <p className="post-description">{description}</p>
          <div className="post-footer">
            <span className="post-date">{date}</span>
            <span className="post-author">{author} ✍🏻</span>
          </div>
          <div className="post-tags">
            {tags.map((tag, index) => (
              <span key={index} className="tag">#{tag}</span>
            ))}
          </div>
          <div className="read-more-wrapper">
            <span className="read-more">קרא עוד ⬅</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PostCard;

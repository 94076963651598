import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './CampaignForm.css';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContactFilter from '../Contacts/ContactFilter';
import TemplatesHandler from '../Templates/TemplatesHandler';
import Scheduled from '../General/Scheduled';

const CampaignForm = (currenUser) => {
    console.log(currenUser?.currentUser?.userId);
    const [userId, setUserId] = useState(currenUser?.currentUser?.userId);
    const [userName, setUserName] = useState(currenUser?.currentUser?.fullname);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const [successMessage, setSuccessMessage] = useState('');
    const [filtersContacts, setFiltersContacts] = useState(null);
    const [template, setTemplate] = useState(null);
    const [templateVariables, setTemplateVariables] = useState({});
    const [queries, setQueries] = useState([]);
    const [locationName, setLocationName] = useState('');
    const [locationAddress, setLocationAddress] = useState('');
    const [longitude, setLongitude] = useState('');
    const [latitude, setLatitude] = useState('');
    const [templateVariablesToChildren, setTemplateVariablesToChildren] = useState([]);
    const [campaignData, setCampaignData] = useState({
        campaignName: '',
        campaignTrigger: '',
        campaignStatus: '',
        recipientSource: '',
        ContactsQuery: null,
        campaignResults: null,
        // createdOn: new Date().toLocaleString('en-US', { timeZone: 'Asia/Jerusalem' }),
        // updatedOn: new Date().toLocaleString('en-US', { timeZone: 'Asia/Jerusalem' }),
        createdBy: searchParams.get('userEmail') || '',
        messageType: '',
        message: '',
        wabaTemplateId: null,
        runAt: '',
        runBy: '',
        fbTemplateID: ''
    });

    const campaignID = useMemo(() => searchParams.get('campaingId'), [searchParams]);
    const organization = useMemo(() => searchParams.get('organization'), [searchParams]);
    const userEmail = useMemo(() => searchParams.get('userEmail'), [searchParams]);
    const isEdit = useMemo(() => searchParams.has('campaingId'), [searchParams]);

    const handleFiltersDataChange = useCallback((data) => {
        setFiltersContacts(data);
        console.log("Filters data changed:", data);
    }, [filtersContacts]);

    const handleTemplatesVariableChange = useCallback((template, variables, queries) => {
        setTemplate(template);
        setTemplateVariables(variables);
        setQueries(queries);
        console.log("Templates variable changed:", { template, variables, queries });
    }, []);

    useEffect(() => {
        if (filtersContacts) {
            setCampaignData(prevData => ({ ...prevData, ContactsQuery: filtersContacts }));
        }
    }, [filtersContacts]);

    useEffect(() => {
        if (template) {
            setCampaignData(prevData => ({ ...prevData, wabaTemplateId: template.Id, fbTemplateID: template.Id }));
        }
    }, [template]);

    useEffect(() => {
        if (queries.length > 0) {
            setCampaignData(prevData => ({
                ...prevData,
                templateVariableQuery: queries
            }));
        }
    }, [queries]);


    useEffect(() => {
        const fetchCampaignData = async () => {
            if (campaignID && !campaignData.campaignName) {
                try {
                    const response = await axios.post(
                        "https://gambot.azurewebsites.net/api/Webhooks/GetCampaignById",
                        {
                            organization: organization,
                            CampaignId: campaignID
                        },
                        {
                            headers: { 'Content-Type': 'application/json' }
                        }
                    );

                    const fetchedData = response.data;
                    const temVar = fetchedData.templateVariableQuery;

                    // Process ContactsQuery to group by selectedoperator
                    const ContactsQuery = [...(fetchedData.ContactsQuery || [])];
                    const groupedContactsQuery = [];

                    while (ContactsQuery.length > 0) {
                        // Take the first item in the array
                        const currentGroup = ContactsQuery.shift();
                        const currentOperator = currentGroup[0]?.selectedoperator;

                        // Combine all items with the same operator
                        const combinedGroup = {
                            selectedgroup: [...currentGroup[0].selectedgroup],
                            selectedoperator: currentOperator
                        };

                        for (let i = ContactsQuery.length - 1; i >= 0; i--) {
                            if (ContactsQuery[i][0]?.selectedoperator === currentOperator) {
                                combinedGroup.selectedgroup = [
                                    ...combinedGroup.selectedgroup,
                                    ...ContactsQuery[i][0].selectedgroup
                                ];
                                // Remove the item after combining
                                ContactsQuery.splice(i, 1);
                            }
                        }

                        // Push the combined group to the new array
                        groupedContactsQuery.push([combinedGroup]);
                    }

                    setTemplateVariablesToChildren(temVar);

                    // Update state with grouped data
                    setCampaignData(prevData => ({
                        ...prevData,
                        ...fetchedData,
                        ContactsQuery: groupedContactsQuery,
                        templateVariableQuery: temVar || []
                    }));

                    console.log("Processed campaign data:", {
                        ...fetchedData,
                        ContactsQuery: groupedContactsQuery
                    });
                } catch (error) {
                    console.error('Error fetching campaign:', error);
                }
            }
        };

        fetchCampaignData();
    }, [campaignID, organization]);

    console.log(campaignData);
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Clone the current campaignData to avoid mutating the state directly
        const cleanedContactsQuery = campaignData?.ContactsQuery?.map((row) => {
            if (Array.isArray(row) && row?.length > 0) {
                // Keep only the last sub-array
                return [row[row.length - 1]];
            }
            return row;
        });
        // Prepare updated campaign data
        const updatedCampaignData = {
            ...campaignData,
            ContactsQuery: cleanedContactsQuery, // Replace ContactsQuery with cleaned data
            locationDetails: {
                locationName,
                locationAddress,
                longitude,
                latitude,
            },
        };
        console.log(updatedCampaignData);
        const user = {
            userId: userId,
            userName: userName
        }
        const endpoint = isEdit ? 'UpdateCampaign' : 'CreateCampaign';
        try {
            const response = await axios.post(`https://gambot.azurewebsites.net/api/Webhooks/${endpoint}`, {
                organization: organization,
                campaignData: updatedCampaignData,
                user: user
            });
            setSuccessMessage(`Campaign ${isEdit ? 'updated' : 'created'} successfully!`);
            console.log("Campaign submitted:", response.data);
            navigate('/Campaign');
        } catch (error) {
            console.error('Error submitting campaign:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCampaignData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleBackButtonClick = useCallback(() => {
        navigate('/Campaign');
    }, [navigate]);

    const handleRunAtChange = (value) => {
        console.log(value);
        setCampaignData(prevData => ({ ...prevData, runAt: value.date, timezone: value.timezone }));    
    };
    const handleKeyPress = (event) => {
        if ((event.key === 'Enter' && event.shiftKey) || (event.key === 'Enter' && event.altKey)) {
            // Prevent default action and insert a newline
            event.preventDefault();
            setCampaignData(prevData => ({ ...prevData, message: prevData.message + '\n' }));
        } else if (event.key === 'Enter') {
            // Prevent default and trigger the send action
            event.preventDefault();
            handleSubmit(event); // Call the submit function
        }
    };

    return (
        <div className='campaign-container'>
            <form onSubmit={handleSubmit} className='campaign-form'>
                <div className='campaign-form-header'>
                    <IconButton onClick={handleBackButtonClick} aria-label="back" className='IconButtonCampaign'>
                        <ArrowBackIcon />
                    </IconButton>
                    <h2>{isEdit ? "Update" : "Create"} Campaign form</h2>
                </div>
                <div className='campaign-form-row'>
                    <label>
                        <p className='pCampaign'>Campaign name:</p>
                        <input 
                            className='pCampaigninput'
                            type="text" 
                            name="campaignName" 
                            value={campaignData.campaignName} 
                            onChange={handleChange} 
                            required 
                            placeholder="Enter campaign name..." 
                            />
                    </label>
                </div>
                <div className='campaign-form-row'>
                    <label>
                    <p className='pCampaign'>Trigger:</p>
                        <select name='campaignTrigger' value={campaignData.campaignTrigger} onChange={handleChange} required>
                            <option value="" disabled>Select trigger</option>
                            <option value="Manually">Manually</option>
                            <option value="Scheduled">Scheduled</option>
                        </select>
                    </label>
                </div>
                {campaignData.campaignTrigger === 'Scheduled' && (
                    <div className='campaign-form-row'>
                        <Scheduled label={"Run's At:"} onChange={handleRunAtChange} initialValue={campaignData.runAt} />
                    </div>
                )}

                <div className='campaign-form-row'>
                    <label>
                    <p className='pCampaign'> Recipient source:</p>
                        <select name='recipientSource' value={campaignData.recipientSource} onChange={handleChange} required>
                            <option value="" disabled>Select recipient source</option>
                            <option value="Contacts">Contacts</option>
                            <option value="Integrated-DB">Integrated-DB</option>
                        </select>
                    </label>
                </div>
                <div className='campaign-form-row'>
                    {campaignData.recipientSource === 'Contacts' && (
                        <ContactFilter organization={organization} onFiltersDataChange={handleFiltersDataChange} contactsFilter={campaignData.ContactsQuery} />
                    )}

                </div>
                <div className='campaign-form-row'>
                    {campaignData.recipientSource === 'Contacts' && (
                        <label>
                            Message type:
                            <br></br>
                            <br></br>
                            <select className='campaignSelectType' name='messageType' value={campaignData.messageType} onChange={handleChange} required>
                                <option value="Regular" disabled>Select message type</option>
                                <option value="Regular">Regular</option>
                                <option value="Template">Template</option>
                            </select>
                        </label>
                    )}
                </div>
                <div className='campaign-form-row'>
                    <div className="campaign-form-row">
                        <div className="campaign-form-row">
                            <div className="campaign-form-row">
                                {campaignData.recipientSource === 'Contacts' && campaignData.messageType === "Template" ? (
                                    <TemplatesHandler
                                        organization={organization}
                                        templateId={campaignData.wabaTemplateId}
                                        templateVariableQuery={campaignData.templateVariableQuery}
                                        onTemplatesVariableChange={handleTemplatesVariableChange}
                                        recipientSource={campaignData.recipientSource}
                                        locationName={locationName}
                                        locationAddress={locationAddress}
                                        longitude={longitude}
                                        latitude={latitude}
                                        setLocationName={setLocationName}
                                        setLocationAddress={setLocationAddress}
                                        setLongitude={setLongitude}
                                        setLatitude={setLatitude}
                                        parent="CampaignForm"
                                    />
                                ) : (
                                    campaignData.recipientSource === 'Contacts' && (
                                        <textarea
                                            className='TypeAMessage'
                                            placeholder="Type a message"
                                            value={campaignData.message}
                                            onChange={(e) => {
                                                setCampaignData(prevData => ({
                                                    ...prevData,
                                                    message: e.target.value,
                                                    messageType: "Regular" // Set messageType to Regular
                                                }));
                                            }}
                                            onKeyDown={handleKeyPress}
                                            rows="3"
                                            style={{ width: '100%' }}
                                        />
                                    )
                                )}
                            </div>

                        </div>

                    </div>

                    {/* {campaignData.recipientSource === 'Contacts' && campaignData.messageType === "Template" ? (
                        <TemplatesHandler
                            organization={organization}
                            templateId={campaignData.wabaTemplateId}
                            templateVariableQuery={campaignData.templateVariableQuery}
                            onTemplatesVariableChange={handleTemplatesVariableChange}
                            recipientSource={campaignData.recipientSource}
                            locationName={locationName}
                            locationAddress={locationAddress}
                            longitude={longitude}
                            latitude={latitude}
                            setLocationName={setLocationName}
                            setLocationAddress={setLocationAddress}
                            setLongitude={setLongitude}
                            setLatitude={setLatitude}
                            parent={'CampaignForm'}
                        />
                    ) : (
                        campaignData.recipientSource === 'Contacts' && (
                            <input type='text' placeholder='Message' name='message' value={campaignData.message} onChange={handleChange} />
                        )
                    )} */}
                </div>
                <button className='campaignButton' type="submit">Submit</button>
            </form>
        </div>
    );
};

export default CampaignForm;

import React, { useEffect, useState, useCallback, useRef } from 'react';
import TollIcon from '@mui/icons-material/Toll';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import './Sidebar.css';
import UserProfile from '../Chats/UserProfile.js';
import NewContactForm from '../Contacts/NewContactForm.js';
import axios from 'axios';
import { FaUserPlus } from "react-icons/fa";
import LoadingOverlay from './LoadingOverlay.js';

function Sidebar({
    currentUser,
    signOut,
    currentChatRecipient,
    webSocket,
    onContactsUpdate,
    onContactClick,
    categories,
    users,
}) {
    const [searchInput, setSearchInput] = useState('');
    const [contacts, setContacts] = useState([]);
    const [openConversations, setOpenConversations] = useState([]); // State to store open conversations
    const [showNewContactForm, setShowNewContactForm] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showFilterPanel, setShowFilterPanel] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState({
        openConversations: false,
        myConversations: false,
        category: '',
        status: '',
        owner: '', // New field for owner filter
    });

    const hasFetchedContacts = useRef(false);

    const closeForm = () => {
        setShowNewContactForm(false);
    };

    // Fetch contacts
    const fetchContacts = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/GetAllContactsByOrg", {
                organizationiD: currentUser.organization,
            });
            const fetchedContacts = response.data || [];
            fetchedContacts.sort((a, b) => new Date(b.time) - new Date(a.time));
            setContacts(fetchedContacts);
            onContactsUpdate(fetchedContacts);
            hasFetchedContacts.current = true;
        } catch (error) {
            console.error('Error fetching contacts:', error);
        } finally {
            setIsLoading(false);
        }
    }, [currentUser.organization]);

    // Fetch open conversations
    const fetchOpenConversations = useCallback(async () => {
        try {
            const response = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/GetOpenConversations", {
                organization: currentUser.organization,
            });
            setOpenConversations(response.data || []);
        } catch (error) {
            console.error('Error fetching open conversations:', error);
        }
    }, [currentUser.organization]);

    useEffect(() => {
        if (!hasFetchedContacts.current) {
            fetchContacts();
        }
    }, [fetchContacts]);

    useEffect(() => {
        if (selectedFilters.openConversations) {
            fetchOpenConversations();
        }
    }, [fetchOpenConversations, selectedFilters.openConversations]);

    useEffect(() => {
        const handleMessage = (event) => {
            const message = JSON.parse(event.data);
            if (message.type === 'contacts') {
                fetchContacts();
            }
        };

        if (webSocket) {
            webSocket.addEventListener('message', handleMessage);
        }

        return () => {
            if (webSocket) {
                webSocket.removeEventListener('message', handleMessage);
            }
        };
    }, [webSocket, fetchContacts]);

    // Filter logic
// Filter logic for sidebar
const filteredContacts = contacts.filter((contact) => {
    console.log(contact);
    const matchesSearch =
        (contact.name && contact.name.toLowerCase().includes(searchInput.toLowerCase())) ||
        (contact.phoneNumber && contact.phoneNumber.toLowerCase().includes(searchInput.toLowerCase()));

    const matchesFilters =
        (!selectedFilters.openConversations || openConversations.some((convo) => convo.ConversationId === contact.id)) &&
        (!selectedFilters.myConversations || contact.ownerId === currentUser.userId) &&
        (!selectedFilters.category || contact.lastConversationCategory === selectedFilters.category) &&
        (!selectedFilters.status || contact.lastConversationStatus === selectedFilters.status) &&
        (!selectedFilters.owner || contact.ownerId === selectedFilters.owner);

    return matchesSearch && matchesFilters;
});

    const handleAddContact = (newContact) => {
        setShowNewContactForm(false);
    };

    const handleFilterChange = (filterKey) => {
        setSelectedFilters((prevFilters) => ({
            ...prevFilters,
            [filterKey]: !prevFilters[filterKey],
        }));
    };

    const companyLogo = './Gambot_Logo_rounded.png';
    const photoURL = './question-mark.png';

    return (
        <div className="sidebar">
            <LoadingOverlay loading={isLoading} />
            <div className="sidebar-header">
                <div className="sidebar-header-img" onClick={signOut}>
                    <img src={companyLogo} alt="" />
                </div>
                <div className="sidebar-header-btn">
                    <FaUserPlus onClick={() => setShowNewContactForm(true)} />
                </div>
            </div>
            <div className="sidebar-search">
                <div className="sidebar-search-input">
                    <SearchIcon />
                    <input
                        type="text"
                        placeholder="Search by name or phone..."
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                    />
                    <div className="sidebar-filter-btn" onClick={() => setShowFilterPanel(!showFilterPanel)}>
                    <FilterAltIcon />
                </div>
                </div>
            </div>

            {showFilterPanel && (
                <div className="sidebar-filter-panel">
                    <label>
                        <span>Open Conversations</span>
                        <input
                            type="checkbox"
                            checked={selectedFilters.openConversations}
                            onChange={() => handleFilterChange('openConversations')}
                        />
                    </label>
                    <label>
                        <span>My Conversations</span>
                        <input
                            type="checkbox"
                            checked={selectedFilters.myConversations}
                            onChange={() => handleFilterChange('myConversations')}
                        />
                    </label>
                    <label>
                        <span>Category</span>
                        <select
                            value={selectedFilters.category}
                            onChange={(e) => setSelectedFilters({ ...selectedFilters, category: e.target.value })}
                        >
                            <option value="">All Categories</option>
                            {categories.map((category) => (
                                <option key={category} value={category}>
                                    {category}
                                </option>
                            ))}
                        </select>
                    </label>
                    <label>
                        <span>Status</span>
                        <select
                            value={selectedFilters.status}
                            onChange={(e) => setSelectedFilters({ ...selectedFilters, status: e.target.value })}
                        >
                            <option value="">All Statuses</option>
                            <option value="Open">Open</option>
                            <option value="In Process">In Process</option>
                            <option value="Closed">Closed</option>
                        </select>
                    </label>
                    <label>
                        <span>Owner</span>
                        <select
                            value={selectedFilters.owner}
                            onChange={(e) => setSelectedFilters({ ...selectedFilters, owner: e.target.value })}
                        >
                            <option value="">All Owners</option>
                            {users.map((user) => (
                                <option key={user.uID} value={user.uID}>
                                    {user.UserName}
                                </option>
                            ))}
                        </select>
                    </label>
                </div>
            )}

            <div className="sidebar-chat-list">
                {filteredContacts.length > 0 ? (
                    filteredContacts.map((contact) => (
                        <UserProfile
                            key={contact.id}
                            name={contact.name || 'Unknown Name'}
                            photoURL={photoURL}
                            lastMessage={contact.lastMessage || 'No message available'}
                            email={contact.email || 'default@email.com'}
                            time={contact.time}
                            contact={contact}
                            onClick={() => onContactClick(contact)}
                        />
                    ))
                ) : (
                    <p>No contacts found.</p>
                )}
            </div>

            {showNewContactForm && (
                <div className={`new-contact-form-modal ${showForm ? 'show' : ''}`}>
                    <div className="new-contact-form-modal-content">
                        <span className="new-contact-form-close-btn" onClick={closeForm}>
                            &times;
                        </span>
                        <NewContactForm
                            onAddContact={handleAddContact}
                            currentUser={currentUser}
                            showForm={showForm}
                            toggleForm={() => setShowForm(!showForm)}
                            closeForm={closeForm}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default React.memo(Sidebar);

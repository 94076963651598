import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import './UserForm.css';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Import the back icon
import PhoneInput from 'react-phone-input-2';

const UserForm = ({ currentUser }) => {
    const [formData, setFormData] = useState({
        FullName: '',
        Email: '',
        Status: 'active',
        UserName: '',
        timeZone: 'Asia/Jerusalem',
        uID: '',
        FirstName: '',
        LastName: '',
        PhoneNumber: '',
        Role: '',
        UserType: '',
        CreatedOn: '',
        CreatedBy: '',
        ModifiedOn: '',
        ModifiedBy: '',
        wabaNumber : currentUser?.wabaNumber,
        organization : currentUser?.organization
    });

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        const userId = searchParams.get('userId');
        if (userId) {
            setIsEdit(true);
            fetchUserData(userId);
        }
    }, []);

    const fetchUserData = async (userId) => {
        try {
            const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/getUserById', {
                organization: currentUser?.organization,
                userId: userId
            });
            setFormData({
                FullName: response?.data?.FullName,
                Email: response?.data?.Email,
                Status: response?.data?.Status,
                UserName: response?.data?.UserName,
                timeZone: response?.data?.timeZone,
                uID: response.data.uID,
                FirstName: response.data.FirstName,
                LastName: response.data.LastName,
                PhoneNumber: response.data.PhoneNumber,
                Role: response.data.Role,
                UserType: response.data.UserType,
                CreatedOn: response.data.CreatedOn,
                CreatedBy: response.data.CreatedBy,
                ModifiedOn: response.data.ModifiedOn,
                ModifiedBy: response.data.ModifiedBy,
                wabaNumber : currentUser?.wabaNumber,
                organization : currentUser?.organization
            });
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleBackButtonClick = () => {
        navigate('/Users');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            organization: currentUser?.organization,
            userData: formData
        };

        try {
            const endpoint = isEdit ? 'updateUser' : 'createUser';
            await axios.post(`https://gambot.azurewebsites.net/api/Webhooks/${endpoint}`, payload);
            navigate('/Users');  // Navigate back to the Users list page
        } catch (error) {
            console.error('Error submitting user data:', error);
        }
    };
    const handlePhoneNumberChange = (value) => {
        const formattedValue = value.startsWith('+') ? value.slice(1) : value;

        setFormData((prev) => ({
            ...prev,
            PhoneNumber: formattedValue,
        }));
    };
    return (
        <div className="user-form-container">
            {/* Header Section */}
            <div className="user-form__header">
                <IconButton onClick={handleBackButtonClick} aria-label="back" className="IconButtonGambotAi">
                    <ArrowBackIcon />
                </IconButton>
                <h1>{isEdit ? 'Update User' : 'Create User'}</h1>
            </div>

            {/* Form Section */}
            <div className="user-form__form-container">
                <form onSubmit={handleSubmit}>
                    <div className="user-form__input">
                        <label htmlFor="FullName">Full Name:</label>
                        <input
                            type="text"
                            id="FullName"
                            name="FullName"
                            value={formData.FullName}
                            onChange={handleInputChange}
                            required
                            placeholder="Enter full name"
                        />
                    </div>

                    <div className="user-form__input">
                        <label htmlFor="FirstName">First Name:</label>
                        <input
                            type="text"
                            id="FirstName"
                            name="FirstName"
                            value={formData.FirstName}
                            onChange={handleInputChange}
                            required
                            placeholder="Enter first name"
                        />
                    </div>

                    <div className="user-form__input">
                        <label htmlFor="LastName">Last Name:</label>
                        <input
                            type="text"
                            id="LastName"
                            name="LastName"
                            value={formData.LastName}
                            onChange={handleInputChange}
                            required
                            placeholder="Enter last name"
                        />
                    </div>

                    <div className="user-form__input">
                        <label htmlFor="Email">Email:</label>
                        <input
                            type="email"
                            id="Email"
                            name="Email"
                            value={formData.Email}
                            onChange={handleInputChange}
                            required
                            placeholder="Enter email"
                        />
                    </div>

                    <div className="user-form__input">
                        <label htmlFor="UserName">User Name:</label>
                        <input
                            type="text"
                            id="UserName"
                            name="UserName"
                            value={formData.UserName}
                            onChange={handleInputChange}
                            required
                            placeholder="Enter user name"
                        />
                    </div>

                    <div className="user-form__input">
                        <label htmlFor="Status">Status:</label>
                        <select
                            id="Status"
                            name="Status"
                            value={formData.Status}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                        </select>
                    </div>

                    <div className="user-form__input">
                        <label htmlFor="timeZone">Time Zone:</label>
                        <input
                            type="text"
                            id="timeZone"
                            name="timeZone"
                            value={formData.timeZone}
                            onChange={handleInputChange}
                            required
                            placeholder="Enter time zone"
                        />
                    </div>

                    <div className="user-form__input">
                <label htmlFor="PhoneNumber">Phone Number:</label>
                <PhoneInput
                    country={'il'}
                    value={formData.PhoneNumber}
                    onChange={handlePhoneNumberChange}
                    inputProps={{
                        name: 'phoneNumber',
                        required: true,
                        placeholder: "הזן את מספר הטלפון שלך (+972559776699)",
                    }}
                    enableSearch
                    disableDropdown={false}
                    containerClass="user-form-phone-number-input-container"
                    inputClass="user-form-phone-number-input-field"
                />
            </div>

                    <div className="user-form__input">
                        <label htmlFor="Role">Role - position job description:</label>
                        <input
                            type="text"
                            id="Role"
                            name="Role"
                            value={formData.Role}
                            onChange={handleInputChange}
                            placeholder="Enter role"
                        />
                    </div>

                    <div className="user-form__input">
                        <label htmlFor="SecurityRole">User Security Role:</label>
                        <select
                            id="SecurityRole"
                            name="SecurityRole"
                            value={formData.UserType}
                            onChange={handleInputChange}
                        >
                            <option value="Admin">Admin</option>
                            <option value="Chat">Chat</option>
                        </select>
                    </div>

                    <button className="user-form-submit-btn" type="submit">
                        {isEdit ? 'Update' : 'Create'} User
                    </button>
                </form>
            </div>
        </div>
    );
};

export default UserForm;

import React , {useEffect} from 'react';
import { IoIosAddCircleOutline } from "react-icons/io";
import './FormFlowsScreens.css';
import { GoTrash } from "react-icons/go";


const FormFlowsScreens = ({ flow, onAddScreen, onSelectScreen, onRemoveScreen }) => {
  console.log(flow);
  
  return (
    <div className="FormFlowsScreens-section">
          <h2 className="h2FormFlowsScreens">Manage screen addition</h2>
          <button className="buttonFormFlowsScreens" onClick={onAddScreen}>
            <span className="button-text">Add new screen</span>
            <IoIosAddCircleOutline className="button-icon" />
          </button>
    <div className="FormFlowsScreens-list">
        {flow?.screens && flow.screens.length > 0 ? (
          flow.screens.map((screen) => (
            <div key={screen.id} className="screen-item">
              <span onClick={() => onSelectScreen(screen.id)}>
                {screen.title || 'Untitled Screen'}
              </span>
              {!screen?.isReadMoreScreen && (
                <button onClick={() => onRemoveScreen(screen.id)}><GoTrash /></button>
              )}
            </div>
          ))
        ) : (
          <div>No screens available</div> // Fallback if no screens
        )}
      </div>
    </div>
  );
};

export default FormFlowsScreens;

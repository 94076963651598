
import React, { useState } from "react";
import "./ContactUs.css";
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { IoLocation } from "react-icons/io5";
import { FaCheckDouble } from "react-icons/fa6";
import { CiInstagram } from "react-icons/ci";
import { CiFacebook } from "react-icons/ci";
import { CiLinkedin } from "react-icons/ci";
import { CiYoutube } from "react-icons/ci";
import GambotLogo from '../../../../src/assets/Gambot_Logo_rounded.png'; 




const ContactUs = () => {
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      phone: "",
      message: "",
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      // Prepare data to send
      const requestData = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        message: formData.message,
        ClientId: "R9f6r4oe5PSCLr6CnYRQ", // Example ClientId, replace with actual if needed
        source: "Gambot Website - Contact Us Page",
      };
  
      try {
        // Send request to the endpoint
        const response = await fetch("https://prod-63.northeurope.logic.azure.com:443/workflows/ddf6cff7db1b4d438d460e8c4221e768/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ll3QhRKnU64AkU4nk7kF31ob5ZrQzdJElP_ubUtKM5o", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
        });
  
        // Check if the request was successful
        if (response.ok) {
          alert('הטופס נשלח בהצלחה!');
          // Redirect to the approve page or handle success here
          // window.location.href = '/approve'; // Navigate to approve page if needed
        } else {
          console.error('Request failed with status:', response.status);
          alert('משהו השתבש, אנא נסה שוב');
        }
      } catch (error) {
        console.error('Error during request:', error);
        alert('אירעה בעיה במהלך שליחת הטופס');
      }
    };
  
  
    return (
      <div className="ContactUsContainer">
        <p className="ContactUsTitel">צור קשר</p>
        <div className="contact-container">
          <form className="contact-form" onSubmit={handleSubmit}>
            <h2>צור קשר</h2>
            <div className="ContactUsContainer-form-group">
              <label htmlFor="name">שם מלא</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="הקלד את שמך"
                required
              />
            </div>
  
            <div className="ContactUsContainer-form-group">
              <label htmlFor="email">אימייל</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="הקלד את האימייל שלך"
                required
              />
            </div>
  
            <div className="ContactUsContainer-form-group">
              <label htmlFor="phone">טלפון</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="הקלד את מספר הטלפון שלך"
              />
            </div>
  
            <div className="ContactUsContainer-form-group messageConUs">
              <label htmlFor="message">הודעה</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="כתוב הודעה"
                required
              />
            </div>
  
            <button type="submit" className="ContactUsContainer-submit-btn">
              שלח
            </button>
          </form>


          <div className="additional-info">
          <img src={GambotLogo} alt="info" className="infoCon"/>
          <h2 className="H2Con">מידע נוסף <FaCheckDouble /></h2>
          <p className="PCon">כדי ליצור איתנו קשר מהיר, פשוט לחצו על סמל הווצאפ שנמצא בכל עמוד  בפינה הימנית,  או לחלופין באחד מאמצעי התקשורת הבאים:</p>
            <ul>
              <li> info@GamBot.co.il <MdEmail /></li>
              <li> 055-9776-699 <FaPhone /></li>
              <li> ישראל, תל מונד <IoLocation /></li>
            </ul>
            <h2 className="H2Icon">
                <CiInstagram className="instagram" />
                <CiFacebook className="facebook" />
                <CiLinkedin className="linkedin" />
                <CiYoutube className="youtube" />
            </h2>
          </div>
        </div>

        
      </div>
    );
  };
  
  export default ContactUs;
  
import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Table, TableHead, TableRow, TableCell, TableBody, TextField, Button } from '@mui/material';
import axios from 'axios';

const BotomationHistoryRunList = ({ botomationId, open, onClose, currenUser }) => {
  const [runs, setRuns] = useState([]);
  const [filteredRuns, setFilteredRuns] = useState([]);
  const [searchPhoneNumber, setSearchPhoneNumber] = useState('');

  const formatToUserTimezone = (utcDate, timezone) => {
    if (!utcDate) return 'No date available';

    try {
      return new Intl.DateTimeFormat('en-GB', {
        timeZone: timezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).format(new Date(utcDate));
    } catch (error) {
      console.error('Error formatting date:', error, 'Timezone:', timezone);
      return 'Invalid date';
    }
  };

  useEffect(() => {
    if (botomationId && open) {
      const fetchRuns = async () => {
        try {
          const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetAllBotomationRunsByBotomationId', {
            organization: currenUser?.organization, // Replace with dynamic value if needed
            BotomationId: botomationId,
          });

          // Format and sort the runs by CreatedOn (new to old)
          const formattedRuns = response.data
            .map(run => ({
              ...run,
              CreatedOn: formatToUserTimezone(run.CreatedOn, currenUser.timeZone), // Format if necessary
            }))
            .sort((a, b) => {
              const dateA = parseDate(a.CreatedOn);
              const dateB = parseDate(b.CreatedOn);
              return dateB - dateA; // Descending order (new to old)
            });

          setRuns(formattedRuns);
          setFilteredRuns(formattedRuns); // Initialize the filtered runs with all runs
        } catch (error) {
          console.error('Error fetching botomation runs:', error);
        }
      };

      fetchRuns();
    }
  }, [botomationId, open, currenUser]);

  // Helper function to parse "DD/MM/YYYY, HH:mm:ss" into a valid Date object
  const parseDate = (dateString) => {
    const [datePart, timePart] = dateString.split(', ');
    const [day, month, year] = datePart.split('/');
    return new Date(`${year}-${month}-${day}T${timePart}`);
  };

  // Handle phone number search
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchPhoneNumber(value);

    if (value === '') {
      setFilteredRuns(runs); // Reset to all runs if search is cleared
    } else {
      const filtered = runs.filter(run => run.PhoneNumber && run.PhoneNumber.includes(value));
      setFilteredRuns(filtered); // Filter runs based on phone number
    }
  };

  // Refresh the runs list
  const handleRefresh = async () => {
    setSearchPhoneNumber(''); // Clear the search input
    try {
      // Re-fetch the runs from the server
      const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetAllBotomationRunsByBotomationId', {
        organization: currenUser?.organization, // Replace with dynamic value if needed
        BotomationId: botomationId,
      });
  
      // Format and sort the runs by CreatedOn (new to old)
      const formattedRuns = response.data
        .map(run => ({
          ...run,
          CreatedOn: formatToUserTimezone(run.CreatedOn, currenUser.timeZone), // Format if necessary
        }))
        .sort((a, b) => {
          const dateA = parseDate(a.CreatedOn);
          const dateB = parseDate(b.CreatedOn);
          return dateB - dateA; // Descending order (new to old)
        });
  
      setRuns(formattedRuns);
      setFilteredRuns(formattedRuns); // Reset to all runs
    } catch (error) {
      console.error('Error refreshing botomation runs:', error);
    }
  };
  
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Botomation Runs History</DialogTitle>
      <DialogContent>
        {/* Search Input */}
        <TextField
          label="Search by Phone Number"
          value={searchPhoneNumber}
          onChange={handleSearchChange}
          fullWidth
          margin="normal"
        />

        {/* Refresh Button */}
        <Button variant="contained" color="primary" onClick={handleRefresh} style={{ marginBottom: '20px' }}>
          Refresh
        </Button>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Created On</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Current Step</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Error</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRuns.map((run, index) => (
              <TableRow key={index}>
                <TableCell>{run.CreatedOn}</TableCell>
                <TableCell>{run.Status}</TableCell>
                <TableCell>{run.CurrentStepId}</TableCell>
                <TableCell>{run.PhoneNumber}</TableCell>
                <TableCell>{run.Error}</TableCell>
                <TableCell>
                  <button
                    onClick={() =>
                      window.open(
                        `/workflow-designer/history?botomationRunId=${run.Id}`,
                        '_blank'
                      )
                    }
                  >
                    View
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

export default BotomationHistoryRunList;

import React, { useState, useEffect } from 'react';
import WabaChatWidget from '../../ChatWidget/WabaChatWidget'; // Import the original WabaChatWidget component

const WabaChatWidgetIframe = () => {
  const [widgetConfig, setWidgetConfig] = useState({});
  const [isOpen, setIsOpen] = useState(false);  // Manage widget open state
  
  useEffect(() => {
    // Get the widget parameters from the URL query string
    const params = new URLSearchParams(window.location.search);

    // Build the widget configuration from the URL parameters
    setWidgetConfig({
      brandColor: params.get('brandColor') || '#25d366',
      chatBubbleText: params.get('chatBubbleText') || 'Chat With Us',
      position: params.get('position') || 'right',
      chatButton: params.get('chatButton') || 'whatsapp',
      phoneNumber: params.get('phoneNumber') || '972559776699',
      welcomeText: params.get('welcomeText') || 'Hello! How can I assist you?',
      openWidgetByDefault: params.get('openWidgetByDefault') === 'true',
      isOpen: params.get('isOpen') === 'true',
      isMinimized: params.get('isMinimized') === 'true',
      font: params.get('font') || 'Arial',
      textColor: params.get('textColor') || '#ffffff',
      iconColor: params.get('iconColor') || '#ffffff',
      chatBubbleTextColor: params.get('chatBubbleTextColor') || '#000000',
      welcomeTextColor: params.get('welcomeTextColor') || '#000000',
      openFormButtonText: params.get('openFormButtonText') || 'Open Conversation',
      openFormButtonFont: params.get('openFormButtonFont') || 'Arial',
      openFormButtonColor: params.get('openFormButtonColor') || '#000000',
      openFormButtonTextColor: params.get('openFormButtonTextColor') || '#ffffff',
      openFormButtonAlignTo: params.get('openFormButtonAlignTo') || 'center',
      openFormBodyBackgroundColor: params.get('openFormBodyBackgroundColor') || '#ffffff',
      openFormBodyBorderLineColor: params.get('openFormBodyBorderLineColor') || '#25d366',
      prefilledMessage: params.get('prefilledMessage') || 'Hello, I have a question about {{page_link}}',

    });
  }, []);
console.log(widgetConfig);
  const toggleWidgetState = () => {
    setIsOpen(!isOpen);  // Toggle the widget open/close state
  };

  return (
    <div>
      {/* Render the WabaChatWidget component and pass the parameters */}
      <WabaChatWidget {...widgetConfig} isOpen={isOpen} toggleWidget={toggleWidgetState} />
    </div>
  );
};

export default WabaChatWidgetIframe;

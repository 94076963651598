import React, { useState, useEffect ,useCallback} from 'react';
import ContactFilterRow from '../Contacts/ContactFilterRow';
import './ContactFilter.css';

const ContactFilter = ({ organization, onFiltersDataChange, contactsFilter }) => {
    const [filters, setFilters] = useState(contactsFilter || []);
    const [firstRow, setFirstRow] = useState(contactsFilter?.[0] || []);
    const [secondRow, setSecondRow] = useState(contactsFilter?.[1] || []);
    const [filtersData, setFiltersData] = useState(contactsFilter || []);
    const [excludedKeys, setExcludedKeys] = useState([]); // Tracks selected options
    useEffect(() => {
        const selectedValues = filtersData
            .flatMap(row => row.flatMap(filter => filter.selectedgroup?.map(group => group.value) || []));
        setExcludedKeys(selectedValues);
    }, [filtersData]);
    // Trigger onFiltersDataChange when filtersData changes
    useEffect(() => {
        console.log(filtersData);
        console.log(onFiltersDataChange);
        onFiltersDataChange(filtersData); // Update parent component with the filters data
    }, [ filtersData,onFiltersDataChange]);

    const handleRowsDataChange = useCallback((data, index) => {
        setFiltersData(prevFiltersData => {
            const updatedFilters = [...prevFiltersData];
            updatedFilters[index] = data; // Update only the specific row
            return updatedFilters;
        });
    }, []);
    
    useEffect(() => {
        // Update filtersData based on firstRow and secondRow
        if (firstRow.length > 0 && secondRow.length > 0) {
            setFiltersData([firstRow, secondRow]);
        } else if (firstRow.length > 0) {
            setFiltersData([firstRow]);
        } else if (secondRow.length > 0) {
            setFiltersData([secondRow]);
        } else {
            setFiltersData([]);
        }
    }, [firstRow, secondRow]);
    
    const handleAddFilter = (e) => {
        e.preventDefault(); // Prevent form submission
        if (filters.length < 2) {
            // Add new filter with empty selected group and operator
            const newFilter = { group: '', operator: filters.length === 0 ? 'equals' : 'notEquals' };
            setFilters([...filters, newFilter]);
        }
    };

    const handleDeleteFilter = (index) => {
        const updatedFilters = filters.filter((_, i) => i !== index);
        setFilters(updatedFilters);
            // Update filtersData accordingly after deleting the filter
    const updatedFiltersData = filtersData.filter((_, i) => i !== index);
    setFiltersData(updatedFiltersData);
    };
 
        
    return (
        <div className='contactFilter-container'>
            <h3 className='contactFilter-header'>Filter contacts by group</h3>
            <table className='contactFilter-tbl'>
                <tbody>
                    {filters.map((filter, index) => (
                        <ContactFilterRow
                            key={index}
                            filter={filter}
                            onDelete={() => handleDeleteFilter(index)}
                            organization={organization}
                            onRowsDataChange={(data) => handleRowsDataChange(data, index)}
                            firstRow={firstRow}
                            secondRow={secondRow}
                            excludedKeys={excludedKeys} // Pass excluded keys
                        />
                    ))}
                </tbody>
            </table>
            {filters.length < 2 && (
                <button className='contactFilter-tbl-add-btn' onClick={handleAddFilter}>Add filter</button>
            )}
        </div>
    );
};

export default ContactFilter;
  import React, { useEffect } from 'react';
  import './FacebookLogin.css';
  import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

  const FacebookLogin = ({organization , buisnessEmail}) => {
      const navigate = useNavigate(); // Initialize navigate function
    
    useEffect(() => {
      // Load the Facebook SDK
      const loadFbSdk = () => {
        window.fbAsyncInit = function () {
          window.FB.init({
            appId: '655177883181597',
            autoLogAppEvents: true,
            xfbml: true,
            version: 'v22.0',
          });
        };

        // Load the SDK asynchronously
        (function (d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {
            return;
          }
          js = d.createElement(s);
          js.id = id;
          js.src = 'https://connect.facebook.net/en_US/sdk.js';
          fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
      };

      loadFbSdk();
    }, []);
    const exchangeTokenForBusinessToken = async (code, organization) => {
      try {
          const response = await fetch('https://gambot.azurewebsites.net/api/Webhooks/exchangeTokenForBusinessToken', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ 
                  code,               // Pass the code received from Facebook
                  organization        // Pass the organization received as prop
              })
          });

          const data = await response.json();
          if (data?.message?.contains('successfully')) {
              console.log('Business token received:', data.businessToken);
              navigate('/Login');
              // You can now use the business token to interact with the WhatsApp Business API
          } else {
              console.log('Error exchanging token');
          }
      } catch (error) {
          console.error('Error:', error);
      }
  };

    const launchWhatsAppSignup = () => {
      window.FB.login(
        function (response) {
          if (response.authResponse) {
            const accessToken = response.authResponse.accessToken;
            console.log(accessToken);
            exchangeTokenForBusinessToken(response.authResponse.code , organization);

            // Use this token to call the debug_token API and get the shared WABA's ID
          } else {
            console.log('User cancelled login or did not fully authorize.');
          }
        },
        {
          ///config_id: '432095546293940', // configuration ID obtained in the previous step goes here
          ///config_id: '805398158064492', // configuration ID obtained in the previous step goes here
          config_id: '588741093999991', // configuration ID goes here
          response_type: 'code', // must be set to 'code' for System User access token
          override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
          extras: {
            setup: {
              setup: {},
              featureType: 'whatsapp_business_app_onboarding',
              sessionInfoVersion: '2',
              business :{
                email: buisnessEmail,
              },
              phone: {
                description: 'חשבון וואטסאפ עסקי זה מופעל על ידי גמבוט https://gambot.co.il עבור ' + organization
              }
              // Prefilled data can go here
            },
          },
        }
      );
    };

    return (
      <button className="fb-login-button" onClick={launchWhatsAppSignup}>
        Login with Facebook
      </button>
    );
  };

  export default FacebookLogin;

import React, { useState, useEffect } from 'react';
import './WebhookTrigger.css';

const WebhookTrigger = ({ currentUser, onChange, initialConfig, historyMode, initialValue }) => {
  const [webhookName, setWebhookName] = useState(initialConfig?.webhookName || '');
  const [generatedUrl, setGeneratedUrl] = useState(initialConfig?.url || '');
  const [organizationToken, setOrganizationToken] = useState(currentUser?.organizationToken || '');
  const [jsonSchema, setJsonSchema] = useState(initialConfig?.jsonSchema || '');
  const [isJsonValid, setIsJsonValid] = useState(true);
  console.log(initialValue);
  // useEffect(() => {
  //   if (!historyMode && webhookName) {
  //     const baseUrl = `https://gambot.azurewebsites.net/api/external/webhooks/${currentUser.organization}`;
  //     const url = `${baseUrl}/${organizationToken}${webhookName}`;
  //     setGeneratedUrl(url);
  //     onChange({ webhookName, url, organizationToken, jsonSchema });
  //   } else if (!historyMode) {
  //     setGeneratedUrl(''); // Clear URL if webhookName is empty
  //     onChange({ webhookName: '', url: '', organizationToken, jsonSchema });
  //   }
  // }, [webhookName, jsonSchema, organizationToken, currentUser.organization, onChange, historyMode]);
  useEffect(() => {
    if (!historyMode && webhookName) {
      const baseUrl = `https://gambot.azurewebsites.net/api/external/webhooks/${currentUser.organization}`;
      const url = `${baseUrl}/${organizationToken}${webhookName}`;
      setGeneratedUrl(url);
      onChange({ webhookName, url, organizationToken, jsonSchema });
    } else if (!historyMode && !webhookName) {
      setGeneratedUrl('');
      onChange({ webhookName: '', url: '', organizationToken, jsonSchema });
    }
  }, [webhookName, jsonSchema]); // Removed other unnecessary deps
  const handleWebhookNameChange = (e) => {
    const value = e.target.value;
    const sanitizedValue = value
      .replace(/[^a-zA-Z0-9_]/g, '') // Allow only English letters, numbers, and underscores
      .replace(/\s+/g, ''); // Remove spaces
    setWebhookName(sanitizedValue);
  };

  const handleJsonSchemaChange = (e) => {
    const value = e.target.value;
    setJsonSchema(value);

    if (value) {
      try {
        JSON.parse(value);
        setIsJsonValid(true);
      } catch (error) {
        setIsJsonValid(false);
      }
    } else {
      setIsJsonValid(null);
    }
  };

  return (
    <div className="webhook-trigger">
      <div className="form-group inline-form-group">
        <label>Webhook name:</label>
        <input
          type="text"
          value={webhookName}
          onChange={handleWebhookNameChange}
          placeholder="Enter webhook name (English letters, numbers, underscores)"
          disabled={historyMode} // Disable input if in history mode
        />
      </div>
      <div className="form-group inline-form-group">
        <label>Generated Webhook URL:</label>
        <input
          type="text"
          value={generatedUrl}
          readOnly
        />
      </div>
      <div className="form-group">
        <label  className="formGroupWebhook">{historyMode ? 'Received Data' : 'Request Body JSON Schema'}:</label>
        <textarea
          value={
            historyMode
              ? JSON.stringify(initialValue || {}, null, 2) // Display received data in history mode
              : jsonSchema
          }
          onChange={!historyMode ? handleJsonSchemaChange : undefined} // Disable changes in history mode
          placeholder="Paste your JSON schema here"
          rows="20"
          readOnly={historyMode} // Make textarea read-only in history mode
          className={`json-schema-textarea ${isJsonValid ? '' : 'invalid'}`}
        />
        {!isJsonValid && !historyMode && (
          <small className="webhook-trigger-error-text">Invalid JSON. Please correct the syntax.</small>
        )}
      </div>
    </div>
  );
};

export default WebhookTrigger;

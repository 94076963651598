import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PhoneNumbersList.css'; // Import the CSS for styling
import Swal from 'sweetalert2';

const PhoneNumbersList = ({ onPhoneNumberSelect }) => {
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [countryCode, setCountryCode] = useState('IL'); // Default to Israel country code
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null); // Store selected phone number

    // Fetch available phone numbers based on the selected country code
    const fetchPhoneNumbers = (selectedCountryCode) => {
        // Adjust the URL to point to the correct API endpoint
        axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetAvailablePhoneNumbersToPurchaseByCountry', {
            countryCode: selectedCountryCode,
        })
            .then((response) => {
                setPhoneNumbers(response.data);
            })
            .catch((error) => {
                console.error("There was an error fetching phone numbers!", error);
            });
    };

    // Handle country code change
    const handleCountryCodeChange = (event) => {
        setCountryCode(event.target.value);
        fetchPhoneNumbers(event.target.value); // Fetch numbers based on selected country code
    };

    // Fetch available phone numbers when the component mounts or country code changes
    useEffect(() => {
        fetchPhoneNumbers(countryCode);
    }, [countryCode]);


    const formatPhoneNumber = (phoneNumber) => {
        const prefix = phoneNumber.slice(0, 4);
        const number = phoneNumber.slice(4);
        return `
            <div class="phone-number-container">
                <span class="phone-wrapper">
                    <span class="phone-prefix">${prefix}</span>
                    <span class="phone-space">&nbsp;</span>
                    <span class="phone-number">${number}</span>
                </span>
            </div>`;
    };
    
    
    
    const handleBuyClick = (phoneNumber) => {
        const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
        Swal.fire({
            title: 'אישור בחירה',
            html: `האם אתה בטוח שברצונך לבחור את המספר ${formattedPhoneNumber}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'כן, אני בטוח',
            cancelButtonText: 'ביטול',
            reverseButtons: true,
            customClass: {
                popup: 'rtl-popup',
                confirmButton: 'swal2-confirm',
                cancelButton: 'swal2-cancel'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                setSelectedPhoneNumber(phoneNumber);
                Swal.fire({
                    title: 'מצוין!',
                    html: `בחרת את המספר - ${formattedPhoneNumber}`,
                    icon: 'success',
                    confirmButtonText: 'סגור',
                    customClass: {
                        popup: 'rtl-popup'
                    }
                });
                onPhoneNumberSelect(phoneNumber);
            }
        });
    };
    
    

    return (
        <div className="phone-numbers-list-container" dir="rtl">
            <h1 className="phone-numbers-list-header">בחר קוד מדינה ומספר טלפון:</h1>

            {/* Country code dropdown */}
            <div className="phone-numbers-list-country-select">
                <label htmlFor="countryCode-select">בחר מדינה: </label>
                <select id="countryCode-select" value={countryCode} onChange={handleCountryCodeChange}>
                    <option value="IL">ישראל (IL)</option>
                    <option value="US">ארצות הברית (US)</option>
                    <option value="ES">ספרד (ES)</option>
                    <option value="FR">צרפת (FR)</option>
                    {/* Add other country codes as needed */}
                </select>
            </div>
            <br></br>
            {/* Available phone numbers list */}
            <h2 className="phone-numbers-list-title">מספרי טלפון זמינים:</h2>

                {/* Show table only if selectedPhoneNumber is null */}
                {selectedPhoneNumber === null ? (
                    phoneNumbers.length > 0 ? ( // Check if there are phone numbers
                        <div className="phone-numbers-list-table-container">
                            <table className="phone-numbers-list-table">
                                <thead>
                                    <tr>
                                        <th>מספר טלפון</th>
                                        <th>תשלום חודשי</th>
                                        <th>פעולה</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {phoneNumbers.map((number, index) => (
                                        <tr
                                            key={index}
                                            className={selectedPhoneNumber === number.PhoneNumberValue ? 'phone-numbers-selected' : ''}>
                                            <td className='phone-numbers-list-table-container-phonenumber-value'>
                                                <div>
                                                    <div>{number.PhoneNumberValue}</div> {/* Phone number */}
                                                    <div className="district">{number.District}</div> {/* District under phone number */}
                                                </div>
                                            </td>
                                            <td>35₪</td>
                                            <td>
                                                <button
                                                    className="phone-numbers-list-buy-button"
                                                    onClick={() => handleBuyClick(number.PhoneNumberValue)}
                                                >
                                                    בחירת מספר
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <p>לא נמצאו מספרי טלפון זמינים למדינה שנבחרה.</p>
                    )
                ) : null}

            {/* Display selected phone number */}
            {selectedPhoneNumber && (
                <div className="phone-numbers-list-selected">
                    <h3>בחרת את:</h3>
                    <p className='phone-numbers-list-table-container-phonenumber-value'>{selectedPhoneNumber}</p>
                </div>
            )}
        </div>
    );
};

export default PhoneNumbersList;

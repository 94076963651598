import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Assuming you are using axios for API calls
import './ButtonOptions.css';
import LaunchIcon from '@mui/icons-material/Launch';
import PhoneIcon from '@mui/icons-material/Phone';

const ButtonOptions = ({ buttons, onAddButton, onRemoveButton  , organization}) => {

  const [buttonType, setButtonType] = useState('');
  const [buttonLabel, setButtonLabel] = useState('');
  const [additionalInput, setAdditionalInput] = useState('');
  const [selectedFlow, setSelectedFlow] = useState('');
  const [availableFlows, setAvailableFlows] = useState([]); // State to hold the fetched flows
  const [loading, setLoading] = useState(true); // Loading state
  const [urlType, setUrlType] = useState('static'); // For static or dynamic URL selection
  const [exampleInput, setExampleInput] = useState('');

  // Fetch the available flows from the backend when the component mounts
  useEffect(() => {
    const fetchFlows = async () => {
      if (organization) {  // Ensure the currentUser and organization are available
        try {
          const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetAllFormFlows', {
            organization: organization
          });
  
          // Log the fetched flows
          console.log('Fetched Form Flows:', response.data);
  
          // Map the response to extract necessary data
          const publishedFlows = response.data
        .filter(flow => flow.status === 'Published') // Filter flows by status
        .map(flow => ({
          id: flow.Id,
          name: flow.flowName,
          firstScreenId: flow?.flow?.screens?.[0]?.Id || null,  // Access the first screen ID safely
        }));
        console.log(publishedFlows);
          // Update the state with the fetched flows
          setAvailableFlows(publishedFlows);
          setLoading(false); // Stop loading when the data is fetched
        } catch (error) {
          console.error('Error fetching form flows:', error);
          setLoading(false); // Stop loading even if there's an error
        }
      }
    };
  
    fetchFlows();
  }, [buttonType]);  // Re-run the effect if currentUser changes
  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\+972\d{9}$/; // Matches the Israeli phone number format: +972 followed by 9 digits
    return phoneRegex.test(phoneNumber);
  };

  const handleAddButtonClick = () => {
    if (buttonType && buttonLabel) {
      if (buttonType === 'URL' && !additionalInput) {
        alert('You must provide the URL.');
        return;
      }
      if (buttonType === 'PHONE_NUMBER' && !validatePhoneNumber(additionalInput)) {
        alert('Please enter a valid phone number in the format +972505278310.');
        return;
      }
      if (buttonType === 'PHONE_NUMBER' && !additionalInput) {
        alert('You must provide the phone number.');
        return;
      }
      if (buttonType === 'FLOW' && !selectedFlow) {
        alert('You must select a flow.');
        return;
      }
      

      const newButton = { type: buttonType };

      // Handle URL button
      if (buttonType === 'URL') {
        newButton.label = buttonLabel;  // URL button uses `label`
        newButton.url = additionalInput;
        if (urlType === 'dynamic') {
          // Add example for dynamic URL
          newButton.example = exampleInput;  // This could be passed as part of the URL if needed
        }
      } else if(buttonType === "Quick Reply"){
        newButton.label = buttonLabel;
      }
      // Handle PHONE_NUMBER button
      else if (buttonType === 'PHONE_NUMBER') {
        newButton.label = buttonLabel;  // Phone button uses `label`
        newButton.phone_number = additionalInput;
      }
      else if (buttonType === 'CATALOG') {
        newButton.label = buttonLabel;
        
        // Add any other necessary fields for the CATALOG button here
      }
      // Handle FLOW button
      else if (buttonType === 'FLOW') {
        newButton.text = buttonLabel;  // FLOW button uses `text` instead of `label`
        const flow = availableFlows.find(f => f.id === selectedFlow);
        if (flow) {
          newButton.flow_id = flow.id;
          newButton.navigate_screen = flow.firstScreenId; // Automatically set the first screen of the selected flow
          newButton.flow_action = 'navigate'; // Set the flow action
        }
      }

      // Additional settings for URL, Phone, or Flow types
      if (buttonType === 'URL') {
        newButton.url = additionalInput;
        if(urlType === "dynamic"){
          newButton.example = exampleInput; // Add dynamic example
        }
      } else if (buttonType === 'PHONE_NUMBER') {
        newButton.phone_number = additionalInput;
      } else if (buttonType === 'FLOW') {
        const flow = availableFlows.find(f => f.id === selectedFlow);
        if (flow) {
          newButton.flow_id = flow.id;
          newButton.navigate_screen = flow.firstScreenId; // Automatically set the first screen of the selected flow
          newButton.flow_action = 'navigate'; // Set the flow action
        }
      }

      onAddButton(newButton);
      setButtonType('');
      setButtonLabel('');
      setAdditionalInput('');
      setSelectedFlow('');
      setUrlType('static'); // For static or dynamic URL selection
      setExampleInput('');
    } else {
      alert('You must choose a button type and provide a label.');
    }
};

const isPhoneNumberButtonDisabled = buttons.some(button => button.type === 'PHONE_NUMBER');
const isUrlButtonDisabled = buttons.filter(button => button.type === 'URL').length >= 2;

const isFirstButtonPhoneNumber = buttons.length === 0 && buttonType === 'PHONE_NUMBER';

const handleUrlTypeChange = (e) => {
    setUrlType(e.target.value);
    setAdditionalInput(''); // Reset URL input when changing type
    setExampleInput(''); // Reset example input when changing type
};

  console.log(buttons);
  return (
    <div className="button-options">
      <h2>Buttons</h2>
      <p className="button-text-p">Create buttons that let customers respond to your message or take action.</p>

      <div className="add-button-section">
        <select
          value={buttonType}
          onChange={(e) => setButtonType(e.target.value)}
          placeholder="Select button type"
          disabled={isFirstButtonPhoneNumber}  // Disable all options if phone number is selected first

        >
         <option value="">Select Type</option>
          <option value="Quick Reply">Custom</option>
          <option value="URL" disabled={isUrlButtonDisabled}>Visit Website - 2 Button Maximum</option>
          <option value="PHONE_NUMBER" disabled={isPhoneNumberButtonDisabled}>Phone Call Number - 1 Button Maximum</option>
          <option value="CATALOG" disabled={isPhoneNumberButtonDisabled}>CATALOG - 1 Button Maximum</option>
          <option value="FLOW">FormFlow</option>
        </select>
        <input
          type="text"
          value={buttonLabel}
          onChange={(e) => setButtonLabel(e.target.value)}
          placeholder="Enter button label"
          maxLength={24}
        />

        {/* Show additional input based on the button type */}
        {/* Show URL input */}
          {buttonType === 'URL' && (
          <div className='button-options-url-div'>
            <select className='button-options-url-div-options' value={urlType} onChange={handleUrlTypeChange}>
              <option value="static">Static URL</option>
              <option value="dynamic">Dynamic URL</option>
            </select>

            {/* Same input field for both static and dynamic URL */}
            <input
              // className="button-options-url-field"
              type="text"
              value={additionalInput}
              onChange={(e) => setAdditionalInput(e.target.value)}
              placeholder={urlType === 'static' ? 'Enter Static URL' : 'Enter Dynamic URL with Variable in that format{{1}}'}
            />

            {/* Show example input field if dynamic URL is selected */}
            {urlType === 'dynamic' && (
              <input
                // className="button-options-url-field"
                type="text"
                value={exampleInput}
                onChange={(e) => setExampleInput(e.target.value)}
                placeholder="Enter dynamic string (example) - Like gambot.co.il/userId"
              />
            )}
          </div>
        )}

        
        {buttonType === 'PHONE_NUMBER' && (
          <>
            <input
              type="text"
              value={additionalInput}
              onChange={(e) => setAdditionalInput(e.target.value)}
              placeholder="Enter phone number"
            />
          <p className="phone-number-example">Only The Following Format Allowed +972559776699</p> {/* Add user example */}

          </>
        )}

        {/* Show flow selection when FLOW is selected */}
        {buttonType === 'FLOW' && (
          <select
            value={selectedFlow}
            onChange={(e) => setSelectedFlow(e.target.value)}
            placeholder="Select Flow"
          >
            <option value="">Select Flow</option>
            {!loading && availableFlows.length > 0 ? (
              availableFlows.map((flow) => (
                <option key={flow.id} value={flow.id}>
                  {flow.name}
                </option>
              ))
            ) : (
              <option value="">Loading flows...</option>
            )}
          </select>
        )}

        <button className="button-options-add-button" onClick={handleAddButtonClick}>
          Add
        </button>
      </div>

      {buttons.length > 0 && (
        <ul className="buttons-list">
          {buttons.map((button, index) => (
            <li key={index} className="button-item">
              <span>
                {button.label} ({button.type})
                {button.type === 'URL' && <LaunchIcon className="button-icon" />}
                {button.type === 'PHONE_NUMBER' && <PhoneIcon className="button-icon" />}
              </span>
              <button className="button-options-remove" onClick={() => onRemoveButton(index)}>
                Remove
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ButtonOptions;

import React from 'react';
import FacebookLogin from './FacebookLogin'; // Assuming you already have this component
import './WabaOnboarding.css';

const WabaOnboarding = ({ videoUrl, onPrevStep, organization, email, hasSim, simPhoneNumber, contactPhoneNumber }) => {
    

    return (
        <div className="waba-onboarding-container">
            <div className="waba-onboarding-body">
                {/* Video Section (Now on the left) */}
                <div className="waba-onboarding-left">
                    <h2>צפה בסרטון הדרכה</h2>
                    <video className="waba-onboarding-left-video" controls>
                        <source src={videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>

                {/* Explanation Section (Now on the right) */}
                <div className="waba-onboarding-right">
                    <h2>הכנות מקדימות לחיבור לחשבון WhatsApp API</h2>
                    <p><u>דרישות קדם:</u></p>
                    <ul className="waba-onboarding-left-list">
                        <li>
                            עליך להתחבר לחשבון פייסבוק (רצוי משויך לחשבון העסקי). רק חשבון עסקי מאומת יכול להתחבר ל-WABA.
                        </li>
                        <li>
                            חשבון הפייסבוק העסקי חייב לכלול <b>אמצעי תשלום פעיל</b> ומאומת.
                        </li>

                        {!hasSim ? (
                            <li>
                                מספר הטלפון שנרכש ({simPhoneNumber}) ישמש לפעילות במערכת. <br />
                                קוד אימות יישלח לטלפון {contactPhoneNumber} ולמייל {email}, כטקסט והודעה קולית. <br />
                                יש להזין את 6 הספרות במסך האימות.
                            </li>
                        ) : (
                            <li>
                                יש לבחור מספר טלפון שישמש לפעילות. ודא שיש לך גישה לקוד האימות שיישלח אליו. <br />
                                <b>לא מומלץ להשתמש במספר אישי פעיל</b> כדי למנוע אובדן מידע – העדף מספר ייעודי ונקי.
                            </li>
                        )}

                        <li>
                            כעת תחבר את חשבונך לגמבוט, כדי שנוכל לנהל עבורך את WhatsApp API.
                        </li>
                    </ul>
                    <p>אם אתה עומד בדרישות – המשך להתחברות עם פייסבוק.</p>
                </div>
            </div>

            {/* Facebook Login Section */}
            <div className="waba-onboarding-footer">
                <div className="fb-login-center">
                    <FacebookLogin organization={organization} />
                </div>
                <div className="prev-button-wrapper">
                    <button className="waba-onboarding-prev-button" onClick={onPrevStep}>הקודם</button>
                </div>
            </div>


        </div>

    );
};

export default WabaOnboarding;

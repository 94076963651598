import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import useLocation
import Home from './components/General/Home';
import ChatPage from './components/Chats/ChatPage';
import { useState, useEffect } from 'react';
import Login from './components/Website/Login/Login';
import { auth } from './components/General/firebase';
import Settings from './components/Settings/Settings';
import PriceList from './components/Website/PriceList/PriceList';
import OurFeatures from './components/Website/OurFeatures/OurFeatures';
import WhyWhatsapp from './components/Website/WhyWhatsapp/WhyWhatsapp';
import HowWorks from './components/Website/HowWorks/HowWorks';
import Footer from './components/Website/Footer/Footer';
import ContactUs from './components/Website/ContactUs/ContactUs';
import QuestionsAnswers from './components/Website/QuestionsAnswers/QuestionsAnswers';
import NavbarMenu from './components/Website/MenuNavBar/NavBarMenu';
import Blog from './components/Website/Blog/Blog';
import BlogPostPage from './components/Website/Blog/BlogPostPage';
import HomePageElements from './components/Website/HomePageElements/HomePageElements';
import Contacts from './components/Contacts/Contacts';
import Menu from './components/General/Menu';
import TemplateGenerator from './components/Templates/TemplateGenerator';
import Templates from './components/Templates/Templates';
import NewMessageTemplate from './components/Templates/NewMessageTemplate';
import TemplateUpdate from './components/Templates/TemplateUpdate';
import Integrations from './components/Integrations/Integrations';
import IntegrationsForm from './components/Integrations/IntegrationsForm';
import Botomation from './components/Botomation/Botomation';
import BotomationForm from './components/Botomation/BotomationForm';
import Campaigns from './components/Campaign/Campaigns';
import CampaignForm from './components/Campaign/CampaignForm';
import WebSocketInstance from './components/General/WebSocketInstance'; // Ensure WebSocketInstance is imported
import FacebookLogin from './components/Website/OnBoard/FacebookLogin';
import PhoneNumbersList from './components/Website/OnBoard/PhoneNumbersList';
import FormFlows from './components/FormFlows/FormFlows';
import NewFormFlow from './components/FormFlows/NewFormFlows';
import FormFlowsGenerator from './components/FormFlows/FormFlowsGenerator';
import WorkflowHistoryPage from './components/Botomation/WorkflowDesigner/WorkflowHistoryPage';
import OnboardingProcess from './components/Website/OnBoard/OnboardingProcess';
import TermOfPayments from './components/Website/Agreements_N_Terms/TermOfPayments';
import TermOfUse from './components/Website/Agreements_N_Terms/TermOfUse';
import GambotAction from './components/Botomation/WorkflowDesigner/GambotAction';
import GambotAiForm from './components/GambotAi/GambotAiForm';
import GambotAi from './components/GambotAi/GambotAi';
import ChatWidgetForm from './components/ChatWidget/ChatWidgetForm';
import ChatWidget from './components/ChatWidget/ChatWidget';
import WabaChatWidgetIframe from './components/Website/Iframe/WabaChatWidgetIframe';
import Catalog from './components/Catalog/Catalog';
import Users from './components/Users/Users';
import UserForm from './components/Users/UserForm';
import WabaOnboarding from './components/Website/OnBoard/WabaOnboarding';
import ActivityLog from './components/ActivityLog/ActivityLog';
import ActivityLogForm from './components/ActivityLog/ActivityLogForm';
function App() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [webSocket, setWebSocket] = useState(null);
  const isIframeRoute = window.location.pathname === "/wabaChatWidgetIframe"; 

  useEffect(() => {
    if (user && !webSocket) {
      setWebSocket(new WebSocketInstance(user.organization));
    }
  }, [user, webSocket]);

  const signOut = () => {
    auth.signOut().then(() => {
      setUser(null);
      setWebSocket(null);
      localStorage.removeItem('user');
    }).catch((err) => alert(err.message));
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Router>
      <div className="App">
        {isIframeRoute ? (
          // Only render the iframe content when on the iframe route
          <WabaChatWidgetIframe />
        ) : (
          <>
            {user ? (
              <div className="app-container">
                <div className="menu-column">
                  <Menu user={user} signOut={signOut} />
                </div>
                <div className="content-column">
                  <Routes>
                    <Route path='/workflow-designer/history' element={<WorkflowHistoryPage currentUser={user} signOut={signOut} />} />
                    <Route path='/New-Template' element={<TemplateGenerator currentUser={user} signOut={signOut} />} />
                <Route path='/Templates' element={<Templates currentUser={user} signOut={signOut} />} />
                <Route path='/FormFlows' element={<FormFlows currentUser={user} signOut={signOut} />} />
                <Route path='/Integrations' element={<Integrations currentUser={user} signOut={signOut} />} />
                <Route path='/NewIntegration' element={<IntegrationsForm currentUser={user} signOut={signOut} />} />
                <Route path='/UpdateIntegration' element={<IntegrationsForm currentUser={user} signOut={signOut} />} />
                <Route path='/Botomations' element={<Botomation currentUser={user} signOut={signOut} />} />
                <Route path='/NewBotomation' element={<BotomationForm currentUser={user} signOut={signOut} />} />
                <Route path='/UpdateBotomation' element={<BotomationForm currentUser={user} signOut={signOut} />} />
                <Route path='/Campaign' element={<Campaigns currentUser={user} signOut={signOut} />} />
                <Route path='/NewCampaign' element={<CampaignForm currentUser={user} signOut={signOut} />} />
                <Route path='/UpdateCampaign' element={<CampaignForm currentUser={user} signOut={signOut} />} />
                <Route path='/UpdateTemplate' element={<TemplateUpdate currentUser={user} signOut={signOut} />} />
                <Route path='/' element={<Contacts currentUser={user} signOut={signOut} />} />
                <Route path='/Contacts' element={<Contacts currentUser={user} signOut={signOut} />} />
                <Route path='/settings' element={<Settings currentUser={user} signOut={signOut} />} />
                <Route path='/:contactphonenumber' element={<ChatPage currentUser={user} webSocket={webSocket} signOut={signOut} />} />
                <Route path='/chat/:contactphonenumber' element={<ChatPage currentUser={user} webSocket={webSocket} signOut={signOut} />} />
                <Route path='/:contactphonenumber/:name' element={<ChatPage currentUser={user} webSocket={webSocket} signOut={signOut} />} />
                <Route path='/chat/:contactphonenumber/:name' element={<ChatPage currentUser={user} webSocket={webSocket} signOut={signOut} />} />
                <Route path='/:contactphonenumber' element={<ChatPage currentUser={user} signOut={signOut} />} />
                <Route path='/NewMessageTemplate' element={<NewMessageTemplate currentUser={user} signOut={signOut} />} />
                <Route path='/NewFormFlow' element={<NewFormFlow currentUser={user} signOut={signOut} />} />
                <Route path='/FormFlowsGenerator' element={<FormFlowsGenerator currentUser={user} signOut={signOut} />} />
                <Route path='/UpdateFormFlow' element={<FormFlowsGenerator currentUser={user} signOut={signOut} />} />
                <Route path='/GambotAiForm' element={<GambotAiForm currentUser={user} signOut={signOut} />} />
                <Route path='/GambotAi' element={<GambotAi currentUser={user} signOut={signOut} />} />
                <Route path='/ChatWidget' element={<ChatWidget currentUser={user} signOut={signOut} />} />
                <Route path='/catalog' element={<Catalog/>} />
                <Route path='/users' element={<Users currentUser={user} signOut={signOut} />} />
                <Route path='/UserForm' element={<UserForm currentUser={user} signOut={signOut} />} />
                <Route path='/WabaOnboarding' element={<WabaOnboarding  currentUser={user} signOut={signOut} />} />
                <Route path='/ActivityLog' element={<ActivityLog  currentUser={user} signOut={signOut} />} />
                <Route path='/ActivityLogForm/:logId' element={<ActivityLogForm  currentUser={user} signOut={signOut} />} />

                
                {/* <Route path='/WabaChatWidget' element={<WabaChatWidget currentUser={user} signOut={signOut} />} /> */}
                <Route path='/ChatWidgetForm' element={<ChatWidgetForm currentUser={user} signOut={signOut} />} />
                  </Routes>
                </div>
              </div>
            ) : (
              <>
                   <NavbarMenu />
            {/* <Routes>
              <Route path='/aboutus' element={<AboutUs />} />
              <Route path='*' element={<HomePageMainSection setUser={setUser} setWebSocket={setWebSocket} />} />
              <Route path='/Login' element={<Login setUser={setUser} setWebSocket={setWebSocket} />} />
              <Route path='/FacebookLogin' element={<FacebookLogin />} />
            </Routes> */}
            <Routes>
              <Route path="/" element={<HomePageElements setUser={setUser} setWebSocket={setWebSocket} />} />
              {/* <Route path="/aboutus" element={<AboutUs />} /> */}
              <Route path="/ContactUs" element={<ContactUs />} />
              <Route path="/Blog" element={<Blog />} />
              <Route path="/blog/:id" element={<BlogPostPage />} />
              <Route path="/login" element={<Login setUser={setUser} setWebSocket={setWebSocket} />} />
              <Route path="/FacebookLogin" element={<FacebookLogin />} />
              <Route path="/PhoneNumbersList" element={<PhoneNumbersList />} />
              <Route path="/OnboardingProcess" element={<OnboardingProcess />} />
              <Route path="/PriceList" element={<PriceList />} />
              <Route path="/TermOfUse" element={<TermOfUse />}></Route>
              <Route path="/TermOfPayments" element={<TermOfPayments />} />
              <Route path="*" element={<HomePageElements setUser={setUser} setWebSocket={setWebSocket} />} />
            </Routes>
            <Footer />
              </>
            )}
          </>
        )}
      </div>
    </Router>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import './NewContactForm.css';
import firebase from 'firebase';
import ContactsKeys from './ContactsKeys';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const formatTime = (firebaseTimestamp) => {
  if (!firebaseTimestamp) return '--:--';
  const date = new Date(firebaseTimestamp.seconds * 1000);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return `${hours}:${minutes}`;
};

function NewContactForm({ onUpdate, currentUser, showForm, closeForm, initialData, isEditing }) {
  const [contactName, setContactName] = useState('');
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [whatsappError, setWhatsappError] = useState('');
  const [searchKeys, setSearchKeys] = useState([]);

  useEffect(() => {
    if (initialData) {
      setContactName(initialData.name || '');
      setWhatsappNumber(initialData.phoneNumber || '');
      setEmail(initialData.email || '');
      setSearchKeys(initialData.keys || []);
    }
  }, [initialData]);

  const handleSearchKeysChange = (newKeys) => {
    setSearchKeys(newKeys);
  };

  const isEmailValid = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const handleSubmit = (e) => {
    e.preventDefault();
  
    let validEmail = true;
    let validWhatsapp = true;
  
    // Validate WhatsApp number (it is required)
    if (!whatsappNumber) {
      setWhatsappError('WhatsApp phone number is required.');
      validWhatsapp = false;
    } else {
      setWhatsappError(''); // Clear error when valid
    }
  
    // Validate email (it is optional but must be in a valid format if provided)
    if (email && !isEmailValid(email)) {
      setEmailError('Please enter a valid email address.');
      validEmail = false;
    } else {
      setEmailError(''); // Clear error when valid
    }
  
    // If both WhatsApp and email validations are passed
    if (contactName && validWhatsapp && validEmail) {
      const contactData = {
        organization: currentUser.organization,
        email: email,
        name: contactName,
        photoURL: '',
        lastMessage: isEditing ? 'Contact Updated' : 'New Contact Created',
        time: formatTime(firebase.firestore.Timestamp.now()),
        from: currentUser.wabaNumber,
        to: whatsappNumber.replace(/^\+/, ''), // Format the phone number
        phoneNumber: whatsappNumber.replace(/^\+/, ''), // Format the phone number
        id: whatsappNumber.replace(/^\+/, ''), // Using phone number as ID
        keys: searchKeys,
      };
  
      const requestData = {
        organization: currentUser.organization,
        contactData: contactData,
        user: {
          userId: currentUser?.userId,
          userName: currentUser?.fullname || 'Gambot', // Default value if fullname is undefined
        },
      };
  
      const url = isEditing
        ? 'https://gambot.azurewebsites.net/api/Webhooks/UpdateContactById'
        : 'https://gambot.azurewebsites.net/api/Webhooks/CreateNewContact';
  
      axios
        .post(url, requestData) // Use requestData here
        .then((response) => {
          if (response.data && response.data.Success) {
            onUpdate(response?.data?.Data); // Call the update function to refresh the contact list
            closeForm(); // Close the form after saving
            // Clear the form fields
            setContactName('');
            setWhatsappNumber('');
            setEmail('');
            setEmailError('');
            setWhatsappError('');
          } else {
            console.error('Error saving contact:', response.data ? response.data.Message : 'Unknown error');
          }
        })
        .catch((error) => {
          console.error('Error saving document:', error);
        });
    }
  };
  
  const handleOutsideClick = (e) => {
    // If the clicked element is the modal background, close the form
    if (e.target.classList.contains('new-contact-form-modal')) {
      closeForm();
    }
  };

  return (
    <div
      className={`new-contact-form-modal ${showForm ? 'show' : ''}`}
      onClick={handleOutsideClick} // Add click event to modal background
    >
      <div
        className="new-contact-form-modal-content"
        onClick={(e) => e.stopPropagation()} // Prevent clicks inside the form from closing it
      >
       <div className="new-contact-form-header">
        <h1>{isEditing ? 'Edit Contact' : 'New Contact Form'}</h1>
        <button className="new-contact-form-close-btn" onClick={closeForm}>
          &times;
        </button>
      </div>
        <form onSubmit={handleSubmit} className="new-contact-form">
          <div className="form-group">
            <label htmlFor="contactName">Contact Name:</label>
            <input
              type="text"
              id="contactName"
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="whatsappNumber">WhatsApp Phone Number:</label>
            <PhoneInput
              country={'il'}
              value={whatsappNumber}
              onChange={(phone) => setWhatsappNumber(phone)}
              inputProps={{
                name: 'whatsappNumber',
                required: true,
                autoFocus: true,
              }}
              enableSearch
              disableDropdown={false}
              placeholder="Enter phone number"
              containerClass="phone-input-container"
              inputClass="phone-input-field"
            />
            {whatsappError && <p className="error">{whatsappError}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {email && emailError && <p className="error">{emailError}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="contactKeys">Contact Group</label>
            <ContactsKeys onChange={handleSearchKeysChange} initialTags={searchKeys} />
          </div>
          <button className="new-contact-form-submit" type="submit">
            {isEditing ? 'Update Contact' : 'Save New Contact'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default NewContactForm;

import React, { useState, useEffect } from "react";
import TemplatesHandler from "../../Templates/TemplatesHandler";
import ActionInputField from "./ActionInputField";

const ReminderAction = ({ steps, onChange, initialConfig = {}, dynamicContent, currentUser, historyMode, initialValue }) => {
  // Find the last "SendMessage" or "OutboundMessage" step
  const lastMessageStep = [...steps]
    .reverse()
    .find((step) => step.action === "SendMessage" || step.action === "OutboundMessage");

  // Ensure `selectedStepId` is always the last message step unless manually changed
  const defaultConfig = {
    selectedStepId: initialConfig.config?.selectedStepId || lastMessageStep?.StepId || "",
    waitInterval: initialConfig.config?.waitInterval || 1,
    waitUnit: initialConfig.config?.waitUnit || "Minute",
    workingHoursOnly: initialConfig.config?.workingHoursOnly || false,
    messageType: initialConfig.config?.messageType || "regular",
    messageContent: initialConfig.config?.messageContent || "",
    templateId: initialConfig.config?.templateId || null,
    templateVariableQuery: initialConfig.config?.templateVariableQuery || [],
  };

  const [config, setConfig] = useState(defaultConfig);

  // useEffect(() => {
  //   // If `historyMode` is enabled, initialize values from `initialValue`
  //   if (historyMode && initialValue) {
  //     setConfig({
  //       selectedStepId: initialValue?.selectedStepId || lastMessageStep?.StepId || "",
  //       waitInterval: initialValue?.waitInterval || 1,
  //       waitUnit: initialValue?.waitUnit || "Minute",
  //       workingHoursOnly: initialValue?.workingHoursOnly || false,
  //       messageContent: initialValue?.messageContent || "",
  //       // templateId: initialValue?.templateId || null,
  //       // templateVariableQuery: initialValue?.templateVariableQuery || [],
  //     });
  //   }
  // }, [historyMode, initialValue, lastMessageStep]);

  useEffect(() => {
    onChange({ config }); // Ensure `config` is structured properly in botomation
  }, [config, onChange]);

  const handleConfigChange = (key, value) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      [key]: value,
    }));
  };

  return (
    <div className="reminder-action">
      <h3 className="reminder-title">🕒 Configure Reminder</h3>

      {/* Select Last Message Step */}
      <div className="reminder-row">
        <label>Previous Message Step:</label>
        <select
          value={config.selectedStepId} disabled
          onChange={(e) => handleConfigChange("selectedStepId", e.target.value)}
        >
          {steps
            .filter((step) => step.action === "SendMessage" || step.action === "OutboundMessage")
            .map((step, index) => (
              <option key={index} value={step.StepId}>
                Step {steps.indexOf(step) + 1}: {step.action}
              </option>
            ))}
        </select>
      </div>

      {/* Repeat Every */}
      <div className="reminder-row wait-settings">
        <label>Wait:</label>
        <div className="wait-container">
          <input
            type="number"
            value={config.waitInterval}
            onChange={(e) => handleConfigChange("waitInterval", Number(e.target.value))}
            min="1"
          />
          <select value={config.waitUnit} onChange={(e) => handleConfigChange("waitUnit", e.target.value)}>
            <option value="Minute">Minutes</option>
            <option value="Hour">Hours</option>
            <option value="Day">Days</option>
          </select>
        </div>
      </div>

      {/* Message Type Selection */}
      <div className="reminder-row">
        <label>Message Type:</label>
        <select value={config.messageType} onChange={(e) => handleConfigChange("messageType", e.target.value)}>
          <option value="regular">Regular</option>
          <option value="template">Template</option>
        </select>
      </div>

      {/* Message Input */}
      {config.messageType === "regular" && (
        <div className="reminder-row">
          <label>Message:</label>
          <ActionInputField
            label=""
            value={config.messageContent}
            onChange={(value) => handleConfigChange("messageContent", value)}
            dynamicContent={dynamicContent}
            type="textarea"
          />
        </div>
      )}

      {/* Template Selection */}
      {config.messageType === "template" && (
        <TemplatesHandler
          onTemplatesVariableChange={(template, variables, queries) => {
            handleConfigChange("templateId", template?.Id);
            handleConfigChange("templateVariableQuery", queries);
          }}
          templateId={config.templateId}
          templateVariableQuery={config.templateVariableQuery}
          recipientSource={"Contacts"}
          parent={"ReminderAction"}
          organization={currentUser?.organization}
          dynamicContent={dynamicContent}
          initialValue={initialValue}
          historyMode={historyMode}
          
        />
      )}
    </div>
  );
};

export default ReminderAction;

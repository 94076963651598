
import { FaCheck, FaCheckCircle } from "react-icons/fa";
import { FaBolt } from "react-icons/fa6"; 
import React, { useState } from "react";
import "./PriceList.css";


const sessionOptions = [
  { value: 1000, price: 90 },
  { value: 2000, price: 180 },
  { value: 3000, price: 270 },
  { value: 5000, price: 450 },
  // { value: 7000, price: 525 },
  // { value: 10000, price: 525 }

];

const sessionOptionsAI = [
  { value: 500, price: 50 },
  { value: 1000, price: 100 },
  { value: 1500, price: 150 },
  { value: 2000, price: 200 }
];

const plans = {
  monthly: [
    {
      name: "Basic",
      price: 199,
      yearlyPrice: 179 * 12, 
      description: "חבילת הבסיס לעסק שלכם",
      features: [
        "עד 5 משתמשים - כל משתמש נוסף 30 ש\"ח",
        "עד 5000 אנשי קשר",
        "עד 15 אלף רשומות דיוור בחודש",
        "AI עד 120 תשובות ",
        "עד 1000 שיחות בחודש",
      ]
    },
    {
      name: "Best",
      price: 399,
      yearlyPrice: 379 * 12, 
      recommended: true,
      description: "חבילת הפרימיום לעסק שלכם",
      features: [
        "עד 5 משתמשים - כל משתמש נוסף 30 ש\"ח",
        "עד 15,000 אנשי קשר",
        "עד 32 אלף רשומות דיוור בחודש",
        "AI עד 300 תשובות ",
        "עד 2000 שיחות בחודש",
      ]
    },
    {
      name: "Extra",
      price: 1199,
      yearlyPrice: 1179 * 12, 
      description: "חבילה מותאמת לארגון גדול",
      features: [
        "עד 5 משתמשים - כל משתמש נוסף 30 ש\"ח",
        "עד 40,000 אנשי קשר",
        "עד 90 אלף רשומות דיוור בחודש",
        "AI עד 900 תשובות ",
        "עד 5000 שיחות בחודש",
      ]
    }
  ]
};

    const features  = [
      "התכתבות בזמן אמת",
      "ניהול טמפלטים",
      "ניהול קמפיינים",
      "ניהול WhatsApp Flows",
      "ניהול אנשי קשר",
      "ניהול הגדרות חשבון",
      "ניהול קטלוג",
      "יצירת תהליכי אוטומציה",
    ];

  const PriceList = () => {
  const [billingCycle, setBillingCycle] = useState("yearly"); 

  const [selectedSessions, setSelectedSessions] = useState(sessionOptions[0]);

  const handleSessionChange = (event) => {
    const selectedValue = parseInt(event.target.value, 10);
    const newSelection = sessionOptions.find((option) => option.value === selectedValue);
    setSelectedSessions(newSelection);
  };


  const [selectedSessionsAI, setSelectedSessionsAI] = useState(sessionOptionsAI[0]);

  const handleSessionChangeAI = (event) => {
    const selectedValueAI = parseInt(event.target.value, 10);
    const newSelectionAI = sessionOptionsAI.find((option) => option.value === selectedValueAI);
    setSelectedSessionsAI(newSelectionAI);
  };
  
  return (
    <div className="price-list-container">
      <h1 className="price-list-title">העסקים שאיתנו דואגים פחות</h1>
      <p className="price-list-subtitle">כולל תמיכה טכנית ברמה הגבוהה ביותר</p>

      {/* בורר סוג תשלום */}
      <div className="billing-toggle">
        <button
          className={billingCycle === "monthly" ? "active" : ""}
          onClick={() => setBillingCycle("monthly")}
        >
          תשלום חודשי
        </button>
        <button
          className={billingCycle === "yearly" ? "active" : ""}
          onClick={() => setBillingCycle("yearly")}
        >
          תשלום שנתי
        </button>
      </div>

      {/* כרטיסיות מחירים */}
      <div className="plans-container">
        {plans.monthly.map((plan, index) => (
          <div key={index} className={`plan-card ${plan.recommended ? "recommended" : ""}`}>
            {plan.recommended && <div className="recommended-badge">המומלץ ביותר</div>}
            <h3 className="plan-name">{plan.name}</h3>
            <p className="plan-description">{plan.description}</p>
            <p className="plan-price">
    ₪{billingCycle === "monthly" ? plan.price : (plan.yearlyPrice / 12).toFixed(0)} 
    <span> לחודש</span>
</p>

            <p className="plan-yearly">
              {billingCycle === "yearly" && (
                <span>בחשבון אחד של ₪ {plan.yearlyPrice.toLocaleString()} (לפני מע"מ)</span>
              )}
            </p>
            <button className="try-button">בחירת תכנית</button>
            <ul className="plan-features">
              {plan.features.map((feature, i) => (
                <li key={i}>{feature} <FaCheck style={{ color: "green" }}/></li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {/* רשימת המאפיינים הכלולים לכל החבילות */}
      <div className="pricing-features-container">
      <h2 className="pricing-features-title">כל תכניות התמחור כוללות:</h2>
      <div className="pricing-features-grid">
        {features.map((feature, index) => (
          <div key={index} className="pricing-feature-item">
            <FaCheckCircle className="feature-icon" />
            {feature}
          </div>
        ))}
      </div>
    </div>


      {/* תמחור נוסף לפעולות הצ'אט */}
      <div className="chatbot-addon-wrapper">
        <div className="chatbot-addon-container">
          <h2 className="chatbot-addon-title">
            <strong>קבל הפעלות צ'אטבוט נוספות כתוספת</strong>
          </h2>
          <p className="chatbot-addon-subtitle">בחר את מספר הפעלות הצ'אטבוט</p>
          <div className="chatbot-addon-dropdown">
            <select value={selectedSessions.value} onChange={handleSessionChange}>
              {sessionOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.value.toLocaleString()}
                </option>
              ))}
            </select>
          </div>
          <p className="chatbot-addon-price">{selectedSessions.price}₪/לחודש</p>
          {/* <p className="chatbot-addon-p">הסבר נוסף הסבר נוסף הסבר נוסף הסבר נוסף הסבר נוסף הסבר נוסף הסבר נוסף הסבר נוסף הסבר נוסף הסבר נוסף
          </p> */}
        </div>
     </div>



     {/* AI תמחור נוסף לפעולות הצ'אט */}
     <div className="chatbotAI-addonAI-wrapperAI">
        <div className="chatbotAI-addonAI-containerAI">
          <h2 className="chatbotAI-addonAI-titleAI">
            <strong>קבל תגובות נוספות של Gambot AI</strong>
          </h2>
          <p className="chatbotAI-addonAI-subtitleAI">בחר את מספר הפעלות הצ'אטבוט</p>
          <div className="chatbotAI-addonAI-dropdownAI">
            <select value={selectedSessionsAI.value} onChange={handleSessionChangeAI}>
              {sessionOptionsAI.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.value.toLocaleString()}
                </option>
              ))}
            </select>
          </div>
          <p className="chatbotAI-addonAI-priceAI">{selectedSessionsAI.price}₪/לחודש</p>
          {/* <p className="chatbotAI-addonAI-pAI">הסבר נוסף הסבר נוסף הסבר נוסף הסבר נוסף הסבר נוסף הסבר נוסף הסבר נוסף הסבר נוסף הסבר נוסף הסבר נוסף
          </p> */}
        </div>
     </div>



      {/* תשלום לפי שיחה */}
      <div className="whatsapp-pricing-container">
        <div className="whatsapp-pricing-icon">
          <FaBolt className="highlight-icon" />
        </div>
        <div className="whatsapp-pricing-content">
          <h2 className="whatsapp-pricing-title">
            <span className="whatsapp-pricing-highlight">תשלום לפי שיחה</span>, לא לפי הודעה
          </h2>
          <p className="whatsapp-pricing-description">
            שלם עבור מה שאתה צריך עם חיובים לשיחות WhatsApp המבוססות על אינטראקציות עם לקוחות. העמלות לשיחה משתנות בהתאם למדינה, מה שמציע פתרון גמיש ומותאם.
          </p>
          <button className="whatsapp-pricing-button">למד עוד על תמחור השיחות</button>
        </div>
      </div>
    </div>
  );
};

export default PriceList;


// קובץ עם רשימת הפוסטים

const posts = [
  {
    id: 1,
    title: "מה זה WhatsApp-API",
    description: "תיאור קצר על נושא הפוסט הראשון",
    content: `זהו פסקה ראשונה של תוכן הפוסט המלא. כאן תוכל להרחיב ולתאר את היתרונות של WhatsApp-API לעסקים.
  הפסקה השנייה תוכל להכיל דוגמאות, תרחישים אמיתיים והסברים טכניים. ניתן להוסיף גם רשימות או הדגשות.`,
      date: "2025-03-15",
      author: "Nir Segas",
      tags: ["עסקים", "ארגונים", "עסק"],
      image: "https://images.unsplash.com/photo-1661862649743-2799867c32b0?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzV8fFdoYXRzQXBwJTIwQVBJfGVufDB8fDB8fHwy",
    },
    {
      id: 2,
      title: "שימושים ב- WhatsApp-API",
      description: "תיאור קצר על נושא הפוסט השני",
      content: `בפוסט זה נסקור את כל האפשרויות לשימוש ב-WhatsApp-API: שליחת הודעות אוטומטיות, תיוגים, שירות לקוחות ועוד.

  זהו פתרון עוצמתי לארגונים המחפשים לשפר תקשורת עם לקוחות.`,
      date: "2025-03-15",
      author: "Nerya Segas",
      tags: ["ווצאפ", "וואטסאפ", "WhatsApp-API"],
      image: "https://plus.unsplash.com/premium_photo-1721225464880-0f42e06dbe08?w=500&auto=format&fit=crop&q=60",
    },
    {
      id: 3,
      title: "מה זה בכלל- API",
      description: "תיאור קצר על נושא הפוסט השלישי",
      content: `API (Application Programming Interface) הוא גשר בין תוכנות שונות. ב-WhatsApp-API מדובר ביכולת לחבר בין מערכות ניהול לבין אפליקציית ווטסאפ עצמה.

  לדוגמה: כשלקוח נרשם בטופס, נוכל לשלוח לו מיידית הודעת תודה בווטסאפ.`,
      date: "2025-03-11",
      author: "Rosso Segas",
      tags: ["ווצאפ", "וואטסאפ", "WhatsApp-API"],
      image: "https://images.unsplash.com/photo-1519389950473-47ba0277781c?q=80&w=2070&auto=format",
    },
    {
        id: 4,
        title: "כותרת הפוסט הרביעי",
        description: "תיאור קצר על נושא הפוסט השלישי",
        content:"ממתין למילוי תוכן ע\י ניר הבטטה ",
        date: "2025-03-17",
        author: "Nir Segas",
        tags: ["עסק", "ארגונים", "וואטסאפ"],
        image: "https://images.unsplash.com/photo-1628277613967-6abca504d0ac?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8QVBJfGVufDB8fDB8fHwy",
      },
      {
        id: 5,
        title: "כותרת הפוסט החמישי",
        description: "תיאור קצר על נושא הפוסט השלישי",
        content:"ממתין למילוי תוכן ע\י ניר הבטטה ",
        date: "2025-03-10",
        author: "Nerya Segas",
        tags: ["תקשורת", "אוטומציה", "תיוגים"],
        image: "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
        id: 6,
        title: "כותרת הפוסט השישי",
        description: "תיאור קצר על נושא הפוסט השלישי",
        content:"ממתין למילוי תוכן ע\י ניר הבטטה ",
        date: "2025-03-16",
        author: "Rosso Segas",
        tags: ["מודלים", "בינה מלאכותית", "אוטומציה"],
        image: "https://images.unsplash.com/photo-1483058712412-4245e9b90334?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
        id: 7,
        title: "כותרת הפוסט השביעי",
        description: "תיאור קצר על נושא הפוסט השלישי",
        content:"ממתין למילוי תוכן ע\י ניר הבטטה ",
        date: "2025-03-12",
        author: "Nerya Segas",
        tags: ["לקוחות", "בינה מלאכותית", "אנשי קשר"],
        image: "https://images.unsplash.com/photo-1432888498266-38ffec3eaf0a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NTl8fHdvcmt8ZW58MHx8MHx8fDA%3D",
      },
      {
        id: 8,
        title: "כותרת הפוסט השביעי",
        description: "תיאור קצר על נושא הפוסט השלישי",
        content:"ממתין למילוי תוכן ע\י ניר הבטטה ",
        date: "2025-03-12",
        author: "Nerya Segas",
        tags: ["לקוחות", "תקשורת", "AI", "שירות אישי"],
        image: "https://images.unsplash.com/photo-1530435460869-d13625c69bbf?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8VGVtcGxhdGV8ZW58MHx8MHx8fDI%3D",
      },
      {
        id: 9,
        title: "כותרת הפוסט השביעי",
        description: "תיאור קצר על נושא הפוסט השלישי",
        content:"ממתין למילוי תוכן ע\י ניר הבטטה ",
        date: "2025-03-12",
        author: "Nerya Segas",
        tags: ["תבניות", "יעילות", "AI", "שירות אישי"],
        image: "https://images.unsplash.com/photo-1697577418970-95d99b5a55cf?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8YWl8ZW58MHx8MHx8fDI%3D",
      },
      {
        id: 10,
        title: "כותרת הפוסט השביעי",
        description: "תיאור קצר על נושא הפוסט השלישי",
        content:"ממתין למילוי תוכן ע\י ניר הבטטה ",
        date: "2025-03-12",
        author: "Nerya Segas",
        tags: ["Template", "Catalog", "AI", "RealTime"],
        image: "https://images.unsplash.com/photo-1694903110330-cc64b7e1d21d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8YWl8ZW58MHx8MHx8fDI%3D",
      },
  ];
  
  export default posts;
  
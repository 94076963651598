import React, { useState, useRef } from 'react';
import ImageIcon from '@mui/icons-material/Image';
import VideoIcon from '@mui/icons-material/VideoLibrary';
import DocumentIcon from '@mui/icons-material/Description';
import LocationIcon from '@mui/icons-material/LocationOn';
import './MediaOptions.css'; // Make sure this file has the updated styles

const MediaOptions = ({ selectedType, onChange}) => {
  const [mediaFile, setMediaFile] = useState(null);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [location, setLocation] = useState({ longitude: '', latitude: '' });
  const fileInputRef = useRef(null);
  const [fileLength, setFileLength] = useState(0);
  const handleIconClick = (type) => {
    setMediaFile(null);
    setMediaUrl(null);
    setLocation({ longitude: '', latitude: '' });

    if (['image', 'video', 'document'].includes(type)) {
      fileInputRef.current.click();
    } else {
      onChange(type, null);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    let mediaFileUrl = null;
  
    if (file) {
      // File size checks for different types
      const fileSizeInMB = file.size / (1024 * 1024); // Convert size to MB
      let isFileValid = true;
      let fileType;
      let fileContent;
  
      if (file.type.startsWith('image/')) {
        if (fileSizeInMB > 5) { // Limit for image is 5MB
          alert('Image file size exceeds the 5MB limit.');
          isFileValid = false;
        }
        fileType = file.type;
        fileContent = await readFileContent(file);
      } else if (file.type.startsWith('video/')) {
        if (fileSizeInMB > 16) { // Limit for video is 16MB
          alert('Video file size exceeds the 16MB limit.');
          isFileValid = false;
        }
        fileType = file.type;
        fileContent = await readFileContent(file);
      } else if (file.type === 'application/pdf') {
        if (fileSizeInMB > 100) { // Limit for document is 100MB
          alert('Document file size exceeds the 5MB limit.');
          isFileValid = false;
        }
        fileType = file.type;
        fileContent = await readFileContent(file);
      } else {
        alert('Please select a valid image, video, or document file.');
        isFileValid = false;
      }
  
      if (isFileValid) {
        setMediaFile(file);
        mediaFileUrl = URL.createObjectURL(file);
        setMediaUrl(mediaFileUrl);
        onChange(selectedType, fileType, fileContent, mediaFileUrl, file);
      } else {
        // Clear the input if the file is invalid
        event.target.value = null;
      }
    }
  };
  
  

  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  const handleLocationChange = (event) => {
    const { name, value } = event.target;
    setLocation({ ...location, [name]: value });
    onChange(selectedType, { ...location, [name]: value });
  };

  const isActive = (type) => selectedType === type;

  return (
    <div className='mediaoptions-container'>
      <div className='mediaoptions-items'>
        <div 
          onClick={() => handleIconClick('image')} 
          className={`media-option ${isActive('image') ? 'active' : ''}`}>
          <ImageIcon />
          <div>Image</div>
          <small className="mediaoptions-file-size-info">Max size: 5MB</small>
        </div>
        <div 
          onClick={() => handleIconClick('video')} 
          className={`media-option ${isActive('video') ? 'active' : ''}`}>
          <VideoIcon />
          <div>Video</div>
          <small className="mediaoptions-file-size-info">Max size: 16MB</small>
        </div>
        <div 
          onClick={() => handleIconClick('document')} 
          className={`media-option ${isActive('document') ? 'active' : ''}`}>
          <DocumentIcon />
          <div>Document</div>
          <small className="mediaoptions-file-size-info">Max size: 100MB</small>
        </div>
        <div 
          onClick={() => handleIconClick('location')} 
          className={`media-option ${isActive('location') ? 'active' : ''}`}>
          <LocationIcon />
          <div>Location</div>
          <small className="mediaoptions-file-size-info">Longitude & Latitude</small>
        </div>
      </div>

      <input
        ref={fileInputRef}
        type="file"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        accept={
          selectedType === 'image' ? 'image/*' :
          selectedType === 'video' ? 'video/*' :
          selectedType === 'document' ? 'application/*' :''
        }
      />

      {selectedType === 'location' && (
        <div style={{ margin: '10px 0' }}>
          <label>Longitude:</label>
          <input 
            type="text" 
            name="longitude" 
            value={location.longitude} 
            onChange={handleLocationChange} 
            style={{ marginRight: '5px' }} 
          />
          <label>Latitude:</label>
          <input 
            type="text" 
            name="latitude" 
            value={location.latitude} 
            onChange={handleLocationChange} 
          />
        </div>
      )}
    </div>
  );
};

export default MediaOptions;

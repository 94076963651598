import React, { useState, useEffect } from 'react';
import { json, useNavigate } from 'react-router-dom';
import HeaderTypeSelector from './HeaderTypeSelector';
import MediaOptions from './MediaOptions';
import VariableInput from './VariableInput';
import MessagePreview from './MessagePreview';
import ButtonOptions from './ButtonOptions';
import FooterText from './FooterText';
import BodyText from './BodyText';
import './TemplateGenerator.css';
import IconButton from '@mui/material/IconButton';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import ConfirmationDialog from '../General/ConfirmationDialog'
import LoadingOverlay from '../General/LoadingOverlay';
const TemplateGenerator = (currentUser) => {
  console.log(currentUser);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const templateName = searchParams.has('templateName') ? searchParams.get('templateName') : null;
  const selectedCategory = searchParams.has('selectedCategory') ? searchParams.get('selectedCategory') : null;
  const selectedLanguage = searchParams.has('selectedLanguage') ? searchParams.get('selectedLanguage') : null;
  const organization = searchParams.has('organization') ? searchParams.get('organization') : null;
  console.log(templateName);

  const navigate = useNavigate();
  const [headerType, setHeaderType] = useState('media');  
  const [mediaType, setMediaType] = useState('none');
  const [mediaContent, setMediaContent] = useState(null);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [variables, setVariables] = useState([]);
  const [footerText, setFooterText] = useState('');
  const [bodyContent, setBodyContent] = useState('');
  const [variableSamples, setVariableSamples] = useState({});
  const [buttons, setButtons] = useState([]);
  const [headerTxt, setHeaderTxt] = useState('');
  const [mediaFile, setMediaFile] = useState('');
  const [mediaPublicUrl, setMediaPublicUrl] = useState('');
  const [mediaSessionId, setMediaSessionId] = useState('');
  const [fileLength, setFileLength] = useState(0);
  const [gmbtMediaId, setGmbtMediaId] = useState('');
  const [loading, setLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openConfirmationErrorMessage, setOpenConfirmationErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
    
  useEffect(() => {
    console.log(gmbtMediaId);
  }, [gmbtMediaId]);

  const handleAddButton = (button) => {
    setButtons([...buttons, button]);
  };

  const handleRemoveButton = (index) => {
    const updatedButtons = [...buttons];
    updatedButtons.splice(index, 1);
    setButtons(updatedButtons);
  };

  const handleBackButtonClick = () => {
    navigate('/NewMessageTemplate');
  };

  const handleBodyContentChange = (newBodyContent) => {
    setBodyContent(newBodyContent);
  };

  const handleHeaderTypeChange = (type) => {
    setHeaderType(type);
  };
  const convertToWhatsAppFormat = (text) => {
    // Convert <b>...</b> to *...* (Bold)
    text = text.replace(/<b>(.*?)<\/b>/g, '*$1*');
    
    // Convert <i>...</i> to _..._ (Italic)
    text = text.replace(/<i>(.*?)<\/i>/g, '_$1_');
    
    // Convert <s>...</s> to ~...~ (Strikethrough)
    text = text.replace(/<s>(.*?)<\/s>/g, '~$1~');
    
    // Convert <code>...</code> to `...` (Monospace)
    text = text.replace(/<code>(.*?)<\/code>/g, '`$1`');

    return text;
};

  const handleMediaTypeChange = (type, fileType, fileContent, mediaUrl, mediaFile) => {
    if(type === 'location'){
      setMediaType('location');
    }
    else{
      setMediaType(fileType);
      setMediaContent(fileContent);
      setMediaUrl(mediaUrl);
      setMediaFile(mediaFile);
      // setFileLength(mediaFile?.size);
    }
    
  };

  const handleAddVariable = (variable) => {
    const updatedBodyText = `${bodyContent} {{${variable}}}`;
    setBodyContent(updatedBodyText);
    setVariables([...variables, variable]);
  };

  const handleRemoveVariable = (index) => {
    const updatedVariables = [...variables];
    updatedVariables.splice(index, 1);
    setVariables(updatedVariables);
  };

  const handleFooterTextChange = (text) => {
    setFooterText(text);
  };

  const handleAddVariableToBody = (updatedContent) => {
    setBodyContent(updatedContent);
  };

  const handleVariableSampleChange = (variable, sample) => {
    setVariableSamples({ ...variableSamples, [variable]: sample });
  };

  const handleHeaderTxt = (txt) => {
    setHeaderTxt(txt);
  };

  const buildHeader = (mediaPublicUrl, newMediaSessionId) => {
    let headerComponent = null;
    console.log(mediaPublicUrl);

    switch (headerType) {
      case 'media':
        headerComponent = {
          type: "HEADER",
          format: mediaType.startsWith('video') ? 'VIDEO' : mediaType.startsWith('image') ? 'IMAGE' : 'DOCUMENT',
          example: {
            header_handle: [newMediaSessionId]
          }
        };
        break;
      case 'location':
        headerComponent = {
          type: "HEADER",
          format: "LOCATION"
        };
        break;
      case 'text':
        headerComponent = {
          type: "HEADER",
          format: "TEXT",
          text: headerTxt,
          example: {
            header_text: [headerTxt]
          }
        };
        break;
      case 'document':
        headerComponent = {
          type: "HEADER",
          format: "DOCUMENT",
          example: {
            header_handle: [mediaFile]
          }
        };
        break;
      default:
        break;
    }

    return headerComponent;
  };

  const handleNewGmbtMediaId = (gmbtMediaId) => {
    setGmbtMediaId(gmbtMediaId);
  };
  const handleConfirmTemplateClick = () => {
    setOpenConfirmation(true);
};

const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
};

const handleCloseConfirmationErrorMessage = () => {
    setOpenConfirmationErrorMessage(false);
};

const handleConfirmFailsTemplate = ()=>{
  navigate('/Templates')
}


  console.log('buttons',buttons);

  
  const handleConfirmTemplate = () => {
    setLoading(true); // Start loading
    setOpenConfirmation(false);
    setErrorMessage(''); // Clear any previous errors
    let extractedLang = JSON.parse(selectedLanguage)[0].value;
    let lang = mapLanguageToCode(extractedLang);
    console.log(lang);
    const template = {
      name: templateName.toLowerCase(),
      language: lang,
      category: selectedCategory.toUpperCase(),
      components: []
    };
    
    if (headerType === 'text' && headerTxt !== '') {
      template.components.push({
        type: "HEADER",
        format: "TEXT",
        text: headerTxt,
        example: {
          header_text: headerTxt
        }
      });
      
    }
     else if (headerType === 'media' && mediaContent !== null && mediaType !== 'location') {
     
      const formData = new FormData();
      formData.append('File', mediaFile);
      formData.append('Org', organization);
      formData.append('file_type', mediaType.toString());
      formData.append('file_length', fileLength.toString());
      console.log("FormData size:", formData.size);
      console.log(formData);
      console.log(mediaType);
      axios.post('https://gambot.azurewebsites.net/api/Webhooks/CreateWabaMedia', formData, {
        headers: {
          'Content-Type': 'multipart/form-data' // This is important for form data
        }
      })
      .then(response => {
        console.log(response);
        setMediaPublicUrl(response.data.mediaUrl);
        const newGmbtMediaId = response.data.gmbtMediaId;
        const newMediaSessionId = response.data.mediaSessionId;
        setMediaSessionId(response.data.mediaSessionId);
        setGmbtMediaId(newGmbtMediaId);
        console.log('New Gmbt Media ID:', newGmbtMediaId);
        console.log('Media Session ID:', response.data.mediaSessionId);
        const headerComponent = buildHeader(response.data.mediaUrl, newMediaSessionId);
        if (headerComponent) {
          template.components.push(headerComponent);
        }

        if (bodyContent !== '' && variables.length > 0) {
          let formattedBodyContent = convertToWhatsAppFormat(bodyContent);

      // Add variables to the body if necessary
          let textWithVarNum = formattedBodyContent;
          // let text = bodyContent;
          // let textWithVarNum = text;
          variables.forEach((variable, index) => {
            let tempI = index + 1;
            let regex = new RegExp(`{{\\s*${variables[index].replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}\\s*}}`, 'g');
            textWithVarNum = textWithVarNum.replace(regex, `{{${tempI}}}`);
          });
          console.log(textWithVarNum);
          let body_text = variables.map(variable => [variable]);
          template.components.push({
            type: "BODY",
            text: textWithVarNum,
            example: {
              body_text: [variables.map(variable => variable)]
            }
          });
        } else if (bodyContent !== '' && variables.length === 0) {
          template.components.push(
            {
              type: "BODY",
              text: bodyContent
            }
          );
        }

        if (buttons.length > 0) {
          const buttonsData = buttons.map(button => {
            if (button.type === 'QUICK_REPLY' || button.type === "Quick Reply") {
              return {
                type: "QUICK_REPLY",
                text: button.label
              };
            } else if (button.type === 'PHONE_NUMBER') {
              return {
                type: "PHONE_NUMBER",
                text: button.label,
                phone_number: button.phone_number
              };
            } else if (button.type === 'URL') {
              // Create the button object with the URL
              const buttonData = {
                type: "URL",
                text: button.label,
                url: button.url
              };
              
              // If it's a dynamic URL and an example exists, add it to the button
              if (button.example) {
                buttonData.example =[ button.example];  // Add dynamic example if it exists
              }
        
              return buttonData;
            }
            else if (button.type === 'CATALOG') {
              // Create the button object with the URL
              const buttonData = {
                type: "CATALOG",
                text: button.label,
              };
              return buttonData;
            }
            else if(button?.type === 'FLOW'){
              // return{
              //   button
              // }
              return{
                type: "FLOW",
                flow_action : "navigate",
                flow_id : button?.flow_id,
                navigate_screen : button?.navigate_screen,
                text : button?.text
              }
            }
            return {};
          });
          template.components.push({
            type: "BUTTONS",
            buttons: buttonsData
          });
        }
  

        if (footerText !== '') {
          template.components.push({
            type: "FOOTER",
            text: footerText
          });
        }
        let templateJson = JSON.stringify(template, null, 2);
        const jsonDataToCreateTemplate = {
          organization: organization,
          template: templateJson,
          gmbtMediaId: newGmbtMediaId !== null ? newGmbtMediaId : '',
          user:{
            userId : currentUser?.currentUser?.userId,
            userName: currentUser?.currentUser?.fullname
          }
        };
        console.log('jsonDataToCreateTemplate:', jsonDataToCreateTemplate);
        return axios.post("https://gambot.azurewebsites.net/api/Webhooks/CreatWabaTemplate", jsonDataToCreateTemplate);
      })
      .then(response => {
        console.log(response);
        if(response?.data?.Success === true){
          navigate('/Templates'); // Redirect to templates page

      }
      else{
          setErrorMessage(response?.data?.Message);
          setOpenConfirmationErrorMessage(true);
      }
        //navigate('/Templates');
      })
      .catch(error => {
        setErrorMessage(error);
          setOpenConfirmationErrorMessage(true);
          console.error('Error:', error);
        console.error('Error:', error);
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
    }
     else {
      if(mediaType === 'location'){
        const headerComponent = {
          type: "HEADER",
          format: "LOCATION"
        };
        template.components.push(headerComponent);
      }
      if (bodyContent !== '' && variables.length > 0) {
        let formattedBodyContent = convertToWhatsAppFormat(bodyContent);

      // Add variables to the body if necessary
          let textWithVarNum = formattedBodyContent;
        // let text = bodyContent;
        // let textWithVarNum = text;
        variables.forEach((variable, index) => {
          let tempI = index + 1;
          let regex = new RegExp(`{{\\s*${variables[index].replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}\\s*}}`, 'g');
          textWithVarNum = textWithVarNum.replace(regex, `{{${tempI}}}`);
        });
        console.log(textWithVarNum);
        let body_text = variables.map(variable => [variable]);
        template.components.push({
          type: "BODY",
          text: textWithVarNum,
          example: {
            body_text: [variables.map(variable => variable)]
          }
        });
      } else if (bodyContent !== '' && variables.length === 0) {
        let formattedBodyContent = convertToWhatsAppFormat(bodyContent);
        template.components.push(
          {
            type: "BODY",
            text: formattedBodyContent
          }
        );
      }

      if (buttons.length > 0) {
        const buttonsData = buttons.map(button => {
          if (button.type === 'QUICK_REPLY' || button.type === "Quick Reply") {
            return {
              type: "QUICK_REPLY",
              text: button.label
            };
          } else if (button.type === 'PHONE_NUMBER') {
            return {
              type: "PHONE_NUMBER",
              text: button.label,
              phone_number: button.phone_number
            };
          } else if (button.type === 'URL') {
            // Create the button object with the URL
            const buttonData = {
              type: "URL",
              text: button.label,
              url: button.url
            };
      
            // If it's a dynamic URL and an example exists, add it to the button
            if (button.example) {
              buttonData.example = [button.example];  // Add dynamic example if it exists
            }
      
            return buttonData;
          }
          else if (button.type === 'CATALOG') {
            // Create the button object with the URL
            const buttonData = {
              type: "CATALOG",
              text: button.label,
            };
            return buttonData;
          }
          else if(button?.type === 'FLOW'){
            // return{
            //   button
            // }
            return{
              type: "FLOW",
              flow_action : "navigate",
              flow_id : button?.flow_id,
              navigate_screen : button?.navigate_screen,
              text : button?.text
            }
          }
          return {};
        });
        template.components.push({
          type: "BUTTONS",
          buttons: buttonsData
        });
      }

      if (footerText !== '') {
        template.components.push({
          type: "FOOTER",
          text: footerText
        });
      }

      let templateJson = JSON.stringify(template, null, 2);
      const jsonDataToCreateTemplate = {
        organization: organization,
        template: templateJson,
        gmbtMediaId: gmbtMediaId !== null ? gmbtMediaId : '',
        user:{
          userId : currentUser?.currentUser?.userId,
          userName: currentUser?.currentUser?.fullname
        }
      };

      console.log('jsonDataToCreateTemplate:', jsonDataToCreateTemplate);

      axios.post("https://gambot.azurewebsites.net/api/Webhooks/CreatWabaTemplate", jsonDataToCreateTemplate)
        .then(response => {
          console.log(response);
          //navigate('/Templates');
          if(response?.data?.Success === true){
            navigate('/Templates'); // Redirect to templates page

        }
        else{
            setErrorMessage(response?.data?.Message);
            setOpenConfirmationErrorMessage(true);
        }
        })
        .catch(error => {
          setErrorMessage(error);
          setOpenConfirmationErrorMessage(true);
          console.error('Error:', error);
        })
        .finally(() => {
          setLoading(false); // Stop loading
        });
    }
  };

  console.log(gmbtMediaId);

  return (
    <div className="template-generator-container">
      <LoadingOverlay loading={loading} /> {/* Loading overlay */}
            <ConfirmationDialog
              open={openConfirmation}
              onClose={handleCloseConfirmation}
              onConfirm={handleConfirmTemplate}
              message="Are you sure? You can only update the template once in 24 hours."
              actionType="Confirm"
            />
      <div className="left-side">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={handleBackButtonClick}
            style={{ color: 'var(--LogoColor)' }}
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>
          <HeaderTypeSelector selectedType={headerType} onChange={handleHeaderTypeChange} />

          {/* Rest of your content */}
        </div>
        {headerType === 'media' && (
          <MediaOptions selectedType={mediaType} onChange={handleMediaTypeChange} />
        )}
        {headerType === "text" && (
          <input
            type="text"
            placeholder='Set Text For Header'
            value={headerTxt}
            onChange={(e) => handleHeaderTxt(e.target.value)}
            className="variable-input-header-txt"
          />
        )}
        <BodyText
          bodyText={bodyContent}
          variables={variables}
          onAddSample={handleBodyContentChange}
          onVariableSampleChange={handleVariableSampleChange}
          
        />
        <VariableInput
          variables={variables}
          onAddVariable={handleAddVariable}
          onRemoveVariable={handleRemoveVariable}
          onAddVariableToBody={handleAddVariableToBody}
          bodyText={bodyContent}
        />
        <FooterText text={footerText} onChange={handleFooterTextChange}/>
        <ButtonOptions organization={organization} buttons={buttons} onAddButton={handleAddButton} onRemoveButton={handleRemoveButton} />
      </div>
      <div className="right-side">
      <button className='template-generator-container-confirm' onClick={handleConfirmTemplateClick}>Create Template</button>
            <ConfirmationDialog
                open={openConfirmationErrorMessage}
                onClose={handleCloseConfirmationErrorMessage}
                onConfirm={handleConfirmFailsTemplate}
                message={errorMessage}
                actionType="Close"
            />
        <MessagePreview
          headerType={headerType}
          mediaType={mediaType}
          mediaContent={mediaContent}
          mediaUrl={mediaUrl}
          variables={variables}
          footerText={footerText}
          variableSamples={variableSamples}
          bodyContent={bodyContent}
          buttons={buttons}
          headerTxt={headerTxt}
        />
      </div>

    </div>
  );
};

export default TemplateGenerator;


const mapLanguageToCode = (languageName) => {
  switch (languageName) {
    case "Afrikaans": return "af";
    case "Albanian": return "sq";
    case "Arabic": return "ar";
    case "Azerbaijani": return "az";
    case "Bengali": return "bn";
    case "Bulgarian": return "bg";
    case "Catalan": return "ca";
    case "Chinese (CHN)": return "zh_CN";
    case "Chinese (HKG)": return "zh_HK";
    case "Chinese (TAI)": return "zh_TW";
    case "Croatian": return "hr";
    case "Czech": return "cs";
    case "Danish": return "da";
    case "Dutch": return "nl";
    case "English": return "en";
    case "English (UK)": return "en_GB";
    case "English (US)": return "en_US";
    case "Estonian": return "et";
    case "Filipino": return "fil";
    case "Finnish": return "fi";
    case "French": return "fr";
    case "Georgian": return "ka";
    case "German": return "de";
    case "Greek": return "el";
    case "Gujarati": return "gu";
    case "Hausa": return "ha";
    case "Hebrew": return "he";
    case "Hindi": return "hi";
    case "Hungarian": return "hu";
    case "Indonesian": return "id";
    case "Irish": return "ga";
    case "Italian": return "it";
    case "Japanese": return "ja";
    case "Kannada": return "kn";
    case "Kazakh": return "kk";
    case "Kinyarwanda": return "rw_RW";
    case "Korean": return "ko";
    case "Kyrgyz (Kyrgyzstan)": return "ky_KG";
    case "Lao": return "lo";
    case "Latvian": return "lv";
    case "Lithuanian": return "lt";
    case "Macedonian": return "mk";
    case "Malay": return "ms";
    case "Malayalam": return "ml";
    case "Marathi": return "mr";
    case "Norwegian": return "nb";
    case "Persian": return "fa";
    case "Polish": return "pl";
    case "Portuguese (BR)": return "pt_BR";
    case "Portuguese (POR)": return "pt_PT";
    case "Punjabi": return "pa";
    case "Romanian": return "ro";
    case "Russian": return "ru";
    case "Serbian": return "sr";
    case "Slovak": return "sk";
    case "Slovenian": return "sl";
    case "Spanish": return "es";
    case "Spanish (ARG)": return "es_AR";
    case "Spanish (SPA)": return "es_ES";
    case "Spanish (MEX)": return "es_MX";
    case "Swahili": return "sw";
    case "Swedish": return "sv";
    case "Tamil": return "ta";
    case "Telugu": return "te";
    case "Thai": return "th";
    case "Turkish": return "tr";
    case "Ukrainian": return "uk";
    case "Urdu": return "ur";
    case "Uzbek": return "uz";
    case "Vietnamese": return "vi";
    case "Zulu": return "zu";
    default: return languageName; // Return the original name if not found
  }
};


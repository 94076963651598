import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Import the back icon
import "./ChatWidgetForm.css";
import WabaChatWidget from "./WabaChatWidget"; // Import the child component

const ChatWidgetForm = ({ currentUser }) => {
  console.log(currentUser);
  const [widgetName, setWidgetName] = useState(""); // New state for widget name
  const [whereImplemented, setWhereImplemented] = useState(""); // New state for where implemented
  const [widgetStatus, setWidgetStatus] = useState("draft");
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [showEmbedPopup, setShowEmbedPopup] = useState(false); // State to control pop-up visibility

  const [brandColor, setBrandColor] = useState("#25d366");
  const [chatBubbleText, setChatBubbleText] = useState("Chat With Us");
  const [position, setPosition] = useState("right");
  const [chatButton, setChatButton] = useState("whatsapp");
  const [brandName, setBrandName] = useState("Your Brand");
  const [phoneNumber, setPhoneNumber] = useState(currentUser?.wabaNumber);
  const [welcomeText, setWelcomeText] = useState("Hello! How can I assist you?");
  const [prefilledMessage, setPrefilledMessage] = useState("Hello, I have a question about {{page_link}}");
  const [openWidgetByDefault, setOpenWidgetByDefault] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [preview, setPreview] = useState(true); // Preview toggle state
  const [font, setFont] = useState("Arial"); // State for font selection
  const [chatBubbleTextColor, setChatBubbleTextColor] = useState("#000000"); // Text color for chat bubble
  const [welcomeTextColor, setWelcomeTextColor] = useState("#000000"); // Text color for welcome text
  const [chatBubbleTextFont, setChatBubbleTextFont] = useState("Arial"); // Font for chat bubble text
  const [welcomeTextFont, setWelcomeTextFont] = useState("Arial"); // Font for welcome text
  const [iconColor, setIconColor] = useState("#ffffff"); // State for icon color selection
  const [textColor, setTextColor] = useState("#ffffff"); // State for icon color selection
  // New variables for the "Open Form Button" customization
  const [openFormButtonText, setOpenFormButtonText] = useState("Open Conversation");
  const [openFormButtonFont, setOpenFormButtonFont] = useState("Arial");
  const [openFormButtonColor, setOpenFormButtonColor] = useState("#000000");
  const [openFormButtonTextColor, setOpenFormButtonTextColor] = useState("#ffffff");
  const [openFormButtonAlignTo, setOpenFormButtonAlignTo] = useState("center");
  const [openFormBodyBackgroundColor, setOpenFormBodyBackgroundColor] = useState("#ffffff");
  const [openFormBodyBorderLineColor, setOpenFormBodyBorderLineColor] = useState("#25d366");

  const [isEdit, setIsEdit] = useState(false); // Track if the form is for editing an existing widget
  const [widgetId, setWidgetId] = useState(null); // To hold the widget ID if editing
  const [embedCode, setEmbedCode] = useState(null);


  useEffect(() => {
    // Fetch the widget ID from the URL query parameter
    const widgetIdFromUrl = searchParams.get("widgetId");
    if (widgetIdFromUrl) {
      setWidgetId(widgetIdFromUrl);
    }
  }, [searchParams]);

  useEffect(() => {
    if (widgetId) {
      const fetchWidgetData = async () => {
        try {
          const response = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/GetWabaWidgetById", {
            organization: currentUser?.organization,
            wabaWidgetId: widgetId,
          });

          const widgetData = response.data;
          // Populate form fields with the existing widget data
          // Manually map and capitalize the property names to match Firestore field naming conventions
          setWidgetName(widgetData.WidgetName || "");
          setWhereImplemented(widgetData.WhereImplemented || "");
          setWidgetStatus(widgetData.WidgetStatus || "draft");
          setBrandColor(widgetData.BrandColor || "#25d366");
          setChatBubbleText(widgetData.ChatBubbleText || "Chat With Us");
          setPosition(widgetData.Position || "right");
          setChatButton(widgetData.ChatButton || "whatsapp");
          setBrandName(widgetData.BrandName || "Your Brand");
          setPhoneNumber(widgetData.PhoneNumber || currentUser?.wabaNumber);
          setWelcomeText(widgetData.WelcomeText || "Hello! How can I assist you?");
          setOpenWidgetByDefault(widgetData.OpenWidgetByDefault || false);
          setIsOpen(widgetData.IsOpen || false);
          setIsMinimized(widgetData.IsMinimized || false);
          setFont(widgetData.Font || "Arial");
          setChatBubbleTextColor(widgetData.ChatBubbleTextColor || "#000000");
          setWelcomeTextColor(widgetData.WelcomeTextColor || "#000000");
          setChatBubbleTextFont(widgetData.ChatBubbleTextFont || "Arial");
          setWelcomeTextFont(widgetData.WelcomeTextFont || "Arial");
          setIconColor(widgetData.IconColor || "#ffffff");
          setTextColor(widgetData.TextColor || "#ffffff");
          setOpenFormButtonText(widgetData.OpenFormButtonText || "Open Conversation");
          setOpenFormButtonFont(widgetData.OpenFormButtonFont || "Arial");
          setOpenFormButtonColor(widgetData.OpenFormButtonColor || "#000000");
          setOpenFormButtonTextColor(widgetData.OpenFormButtonTextColor || "#ffffff");
          setOpenFormButtonAlignTo(widgetData.OpenFormButtonAlignTo || "center");
          setOpenFormBodyBackgroundColor(widgetData.OpenFormBodyBackgroundColor || "#ffffff");
          setOpenFormBodyBorderLineColor(widgetData.OpenFormBodyBorderLineColor || "#25d366");
          setPrefilledMessage(widgetData?.prefilledMessage || "Hello, I have a question about {{page_link}}");

          setIsEdit(true); // Mark as editing mode
        } catch (error) {
          console.error("Error fetching widget data:", error);
        }
      };

      fetchWidgetData();
    }
  }, [widgetId, currentUser]);

  // Function to handle form submission (creating or updating the widget)
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      organization: currentUser?.organization,
      user: JSON.stringify({
        userId: currentUser?.userId,
        userName: currentUser?.userName
      }),
      wabaWidgetId: widgetId,  // Include the widgetId in the payload
      wabaWidgetData: JSON.stringify({
        widgetName, // New widget name
        whereImplemented, // New where implemented
        widgetStatus, // New widget status
        brandColor,
        chatBubbleText,
        position,
        chatButton,
        brandName,
        phoneNumber,
        welcomeText,
        openWidgetByDefault,
        isOpen,
        isMinimized,
        font,
        chatBubbleTextColor,
        welcomeTextColor,
        chatBubbleTextFont,
        welcomeTextFont,
        iconColor,
        textColor,
        openFormButtonText,
        openFormButtonFont,
        openFormButtonColor,
        openFormButtonTextColor,
        openFormButtonAlignTo,
        openFormBodyBackgroundColor,
        openFormBodyBorderLineColor,
        widgetId,
        prefilledMessage
      })
    };

    try {
      const endpoint = isEdit ? "UpdateWabaWidgetById" : "CreateWabaWidget";
      const response = await axios.post(
        `https://gambot.azurewebsites.net/api/Webhooks/${endpoint}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json" // Send as JSON
          }
        }
      );

      alert(`Waba Widget ${isEdit ? "updated" : "created"} successfully!`);
      navigate('/ChatWidget');
    } catch (error) {
      console.error("Error submitting widget:", error);
      alert("Error submitting the widget.");
    }
  };

  // Function to handle back button click
  const handleBackButtonClick = () => {
    navigate('/ChatWidget');
  };


  // Function to toggle the widget open/close
  const toggleWidget = () => {
    setIsOpen(!isOpen);
  };

  // Function to minimize the widget
  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  // Function to toggle the preview mode
  const togglePreview = () => {
    setPreview(!preview);
  };

  // Function to remove chat bubble text
  const removeChatBubbleText = () => {
    setChatBubbleText(""); // Clear the chat bubble text
  };

  // const handleGenerateEmbedCode = () => {
  //   const { iframeCode, scriptCode } = generateEmbedCode();
  //   setEmbedCode({ iframeCode, scriptCode });
  // };
  // Generate embed code (iframe and script)
  // Generate embed code (script code)
  // Function to generate the embed code as a script
  const generateEmbedCode = () => {
    const widgetConfig = {
      brandColor,
      chatBubbleText,
      position,
      chatButton,
      brandName,
      phoneNumber,
      welcomeText,
      openWidgetByDefault,
      isOpen,
      isMinimized,
      font,
      chatBubbleTextColor,
      welcomeTextColor,
      chatBubbleTextFont,
      welcomeTextFont,
      iconColor,
      textColor,
      openFormButtonText,
      openFormButtonFont,
      openFormButtonColor,
      openFormButtonTextColor,
      openFormButtonAlignTo,
      openFormBodyBackgroundColor,
      openFormBodyBorderLineColor,
      prefilledMessage
    };

    const iframeCode = `
    <script>
      window.addEventListener("load", function() {
        const params = new URLSearchParams();
        // Add widget config parameters to the URL
        ${Object.keys(widgetConfig).map(key => {
      return `params.set('${key}', '${widgetConfig[key]}');`;
    }).join('')}
        
        const iframe = document.createElement('iframe');
        iframe.src = 'http://localhost:3000/wabaChatWidgetIframe?' + params.toString();
        iframe.frameBorder = "0";
        
        // Set iframe styles to ensure it is positioned properly
        iframe.style.position = 'fixed';
        iframe.style.bottom = '0';
        iframe.style.left = '0';
        iframe.style.width = '100%';
        iframe.style.height = '100vh';  // You can adjust the height as needed
        iframe.style.border = 'none';
        iframe.style.zIndex = '9999'; // Ensures it stays on top of all other content

        // Append the iframe to the body directly
        document.body.appendChild(iframe);
      });
    </script>
  `;
    setShowEmbedPopup(true); // Show the embed code pop-up

    // Set the embed code (iframe and script) to be displayed in the embed code section
    setEmbedCode({ iframeCode });
    // Generate script code to be embedded
    //   const scriptCode = `
    //   <script>
    //     window.addEventListener("load", function() {
    //       const widgetConfig = ${JSON.stringify(widgetConfig)};
    //       // Function to create and initialize the widget
    //       function createChatWidget(config) {
    //         // Here you can place the logic to initialize and load the widget using the parameters
    //         console.log("Chat Widget Initialized", config);
    //         // Initialize your chat widget with the config, for example:
    //         // window.chatWidget = new ChatWidget(config);
    //       }

    //       createChatWidget(widgetConfig); // Initialize the widget on page load
    //     });
    //   </script>
    // `;

    //   // Set the script code to be displayed in the embed code section
    //   setEmbedCode({ scriptCode });
  };


  // Function to copy the embed code to the clipboard
  const copyToClipboard = () => {
    const textarea = document.getElementById("embedCodeTextarea");
    textarea.select();
    document.execCommand("copy"); // Copy the selected content to clipboard
    // alert("Embed code copied to clipboard!");
  };

  // Function to insert the placeholder into the message
  const insertPlaceholder = (placeholder) => {
    setPrefilledMessage((prevMessage) => prevMessage + ` ${placeholder}`);
  };


  return (
    <div className="chat-widget-form-container">
      <div className="chat-widget-config-form">
        <div className="chat-widget-config-header">
          <IconButton onClick={handleBackButtonClick} aria-label="back" className="chat-widget-config-header-iconButton">
            <ArrowBackIcon />
          </IconButton>
          <h2>
            {isEdit ? 'Update' : 'Create'} your own{'  '}
            <span className="hashtag-color">#</span>{''}
            <span className="chat-color">chat</span>{''}
            <span className="slash-color">_</span>{''}
            <span className="widget-color">widget</span>
          </h2>
        </div>

        <form onSubmit={handleSubmit}>

          {/* Section 1: Widget Name Row */}
          {/* <div className="section-container">
            <h3>General</h3>
            <div className="chat-widget-form-row">
              <div>
                <label className="chat-widget-form-label">Widget name:</label>
                <input
                  type="text"
                  className="chat-widget-form-input"
                  value={widgetName}
                  onChange={(e) => setWidgetName(e.target.value)}
                  required
                />
              </div>
              <div>
                <label className="chat-widget-form-label">Where implemented:</label>
                <input
                  type="text"
                  className="chat-widget-form-input"
                  value={whereImplemented}
                  onChange={(e) => setWhereImplemented(e.target.value)}
                  required
                />
              </div>
              <div>
                <label className="chat-widget-form-label">Widget status:</label>
                <select
                  className="chat-widget-form-select"
                  value={widgetStatus}
                  onChange={(e) => setWidgetStatus(e.target.value)}
                  required
                >
                  <option value="draft">Draft</option>
                  <option value="ready">Ready</option>
                  <option value="implemented">Implemented</option>
                </select>
              </div>
            </div>
          </div> */}
        <div className="section-container widget-name-section">
          <h3>General</h3>
          <div className="widget-name-layout">
            <div className="left-side-fields">
              <div className="widget-name-input">
                <label className="chat-widget-form-label">Widget name:</label>
                <input
                  type="text"
                  className="chat-widget-form-input widget-name-field"
                  value={widgetName}
                  onChange={(e) => setWidgetName(e.target.value)}
                  required
                />
              </div>
              <div className="widget-name-where">
                <label className="chat-widget-form-label">Where implemented:</label>
                <input
                  type="text"
                  className="chat-widget-form-input widget-where-field"
                  value={whereImplemented}
                  onChange={(e) => setWhereImplemented(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="right-side-field">
              <label className="chat-widget-form-label">Widget status:</label>
              <select
                className="chat-widget-form-select widget-status-field"
                value={widgetStatus}
                onChange={(e) => setWidgetStatus(e.target.value)}
                required
              >
                <option value="draft">Draft</option>
                <option value="ready">Ready</option>
                <option value="implemented">Implemented</option>
              </select>
            </div>
          </div>
        </div>

          {/* Section 2: Chat Button and Chat Bubble Text */}
          {/* <div className="section-container">
            <h3>Button</h3>
            <div className="chat-widget-form-row">
              <div>
                <label className="chat-widget-form-label">Chat button color:</label>
                <input
                  type="color"
                  className="chat-widget-form-input"
                  value={brandColor}
                  onChange={(e) => setBrandColor(e.target.value)}
                />
              </div>
              <div>
                <label className="chat-widget-form-label">Chat button icon:</label>
                <select
                  className="chat-widget-form-select"
                  value={chatButton}
                  onChange={(e) => setChatButton(e.target.value)}
                  required
                >
                  <option value="whatsapp">WhatsApp</option>
                  <option value="ci1">Ci1</option>
                  <option value="chatbubble">Chatbubble</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div>
                <label className="chat-widget-form-label">Chat button icon color:</label>
                <input
                  type="color"
                  className="chat-widget-form-input"
                  value={iconColor}
                  onChange={(e) => setIconColor(e.target.value)}
                />
              </div>
            </div>

            <div className="chat-widget-form-row">
              <div>
                <label className="chat-widget-form-label">Chat bubble text:</label>
                <input
                  type="text"
                  className="chat-widget-form-input"
                  value={chatBubbleText}
                  onChange={(e) => setChatBubbleText(e.target.value)}
                  required
                />
              </div>
              <div>
                <label className="chat-widget-form-label">Font for chat bubble:</label>
                <select
                  className="chat-widget-form-select"
                  value={chatBubbleTextFont}
                  onChange={(e) => setChatBubbleTextFont(e.target.value)}
                >
                  <option value="Arial">Arial</option>
                  <option value="Courier New">Courier new</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Tahoma">Tahoma</option>
                  <option value="Verdana">Verdana</option>
                </select>
              </div>
              <div>
                <label className="chat-widget-form-label">Text color for chat bubble:</label>
                <input
                  type="color"
                  className="chat-widget-form-input"
                  value={chatBubbleTextColor}
                  onChange={(e) => setChatBubbleTextColor(e.target.value)}
                />
              </div>
            </div>
          </div> */}
          <div className="section-container button-settings-section">
        <h3>Button</h3>
        <div className="button-layout">
          <div className="left-side-fields">
            <div className="chat-button-color">
              <label className="chat-widget-form-label">Chat button color:</label>
              <input
                type="color"
                className="chat-widget-form-input bbbWidget"
                value={brandColor}
                onChange={(e) => setBrandColor(e.target.value)}
              />
            </div>

            <div className="chat-icon-color">
              <label className="chat-widget-form-label">Chat button icon color:</label>
              <input
                type="color"
                className="chat-widget-form-input bbbWidget"
                value={iconColor}
                onChange={(e) => setIconColor(e.target.value)}
              />
            </div>

            <div className="chat-bubble-text">
              <label className="chat-widget-form-label">Chat bubble text:</label>
              <input
                type="text"
                className="chat-widget-form-input aaaWidget"
                value={chatBubbleText}
                onChange={(e) => setChatBubbleText(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="right-side-field">
            <div className="chat-button-icon">
              <label className="chat-widget-form-label">Chat button icon:</label>
              <select
                className="chat-widget-form-select"
                value={chatButton}
                onChange={(e) => setChatButton(e.target.value)}
                required
              >
                <option value="whatsapp">WhatsApp</option>
                <option value="ci1">Ci1</option>
                <option value="chatbubble">Chatbubble</option>
                <option value="other">Other</option>
              </select>
            </div>

            {/* <div className="chat-bubble-font">
              <label className="chat-widget-form-label">Font for chat bubble:</label>
              <select
                className="chat-widget-form-select"
                value={chatBubbleTextFont}
                onChange={(e) => setChatBubbleTextFont(e.target.value)}
              >
                <option value="Arial">Arial</option>
                <option value="Courier New">Courier new</option>
                <option value="Georgia">Georgia</option>
                <option value="Tahoma">Tahoma</option>
                <option value="Verdana">Verdana</option>
              </select>
            </div> */}

              <div className="chat-bubble-font">
                <label className="chat-widget-form-label">Font for chat bubble:</label>
                <select
                  className="chat-widget-form-select"
                  value={chatBubbleTextFont}
                  onChange={(e) => setChatBubbleTextFont(e.target.value)}
                >
                  <option value="Arial" className="font-arial">Arial</option>
                  <option value="Courier New" className="font-courier">Courier New</option>
                  <option value="Georgia" className="font-georgia">Georgia</option>
                  <option value="Tahoma" className="font-tahoma">Tahoma</option>
                  <option value="Verdana" className="font-verdana">Verdana</option>
                  <option value="Trebuchet MS" className="font-trebuchet">Trebuchet MS</option>
                  <option value="Comic Sans MS" className="font-comic">Comic Sans MS</option>
                  <option value="Times New Roman" className="font-times">Times New Roman</option>
                  <option value="Lucida Console" className="font-lucida">Lucida Console</option>
                  <option value="Segoe UI" className="font-segoe">Segoe UI</option>
                  <option value="Outfit" className="font-outfit">Outfit</option>
                  <option value="Sour Gummy" className="font-sourgummy">Sour Gummy</option>
                </select>
              </div>


            <div className="chat-bubble-color">
              <label className="chat-widget-form-label">Text color for chat bubble:</label>
              <input
                type="color"
                className="chat-widget-form-input bbbWidget"
                value={chatBubbleTextColor}
                onChange={(e) => setChatBubbleTextColor(e.target.value)}
              />
            </div>
          </div>
        </div>
          </div>


          {/* Section 3: Welcome Text and Open Form Button */}
          {/* <div className="section-container">
            <h3>Open Form</h3>
            <div className="chat-widget-form-row">
              <div className="Pre-filled-chat-widget-form-row">
                <div>
                  <label className="chat-widget-form-label">Pre-filled message:</label>
                  <textarea
                    className="chat-widget-form-input"
                    value={prefilledMessage}
                    onChange={(e) => setPrefilledMessage(e.target.value)}
                    rows="4" 
                    cols="50"
                  />
                </div>

                <div className="pre-filled-button-container">
                  <button
                    className="pre-filled-insert-button"
                    onClick={() => insertPlaceholder("{{page_link}}")}
                  >
                    Page link
                  </button>
                  <button
                    className="pre-filled-insert-button"
                    onClick={() => insertPlaceholder("{{page_title}}")}
                  >
                    Page title
                  </button>
                </div>
              </div>

              <div>
                <label className="chat-widget-form-label">Welcome text:</label>
                <input
                  type="text"
                  className="chat-widget-form-input"
                  value={welcomeText}
                  onChange={(e) => setWelcomeText(e.target.value)}
                  required
                />
              </div>
              <div>
                <label className="chat-widget-form-label">Font for welcome text:</label>
                <select
                  className="chat-widget-form-select"
                  value={welcomeTextFont}
                  onChange={(e) => setWelcomeTextFont(e.target.value)}
                >
                  <option value="Arial">Arial</option>
                  <option value="Courier New">Courier new</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Tahoma">Tahoma</option>
                  <option value="Verdana">Verdana</option>
                </select>
              </div>
              <div>
                <label className="chat-widget-form-label">Text color for welcome text:</label>
                <input
                  type="color"
                  className="chat-widget-form-input"
                  value={welcomeTextColor}
                  onChange={(e) => setWelcomeTextColor(e.target.value)}
                />
              </div>
            </div>

            <div className="chat-widget-form-row">
              <div>
                <label className="chat-widget-form-label">Open form button text:</label>
                <input
                  type="text"
                  className="chat-widget-form-input"
                  value={openFormButtonText}
                  onChange={(e) => setOpenFormButtonText(e.target.value)}
                />
              </div>
              <div>
                <label className="chat-widget-form-label">Open form button font:</label>
                <select
                  className="chat-widget-form-select"
                  value={openFormButtonFont}
                  onChange={(e) => setOpenFormButtonFont(e.target.value)}
                >
                  <option value="Arial">Arial</option>
                  <option value="Courier New">Courier new</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Tahoma">Tahoma</option>
                  <option value="Verdana">Verdana</option>
                </select>
              </div>
              <div>
                <label className="chat-widget-form-label">Open form button color:</label>
                <input
                  type="color"
                  className="chat-widget-form-input"
                  value={openFormButtonColor}
                  onChange={(e) => setOpenFormButtonColor(e.target.value)}
                />
              </div>
              <div>
                <label className="chat-widget-form-label">Open form button algin to:</label>
                <select
                  onChange={(e) => setOpenFormButtonAlignTo(e.target.value)} 
                  value={openFormButtonAlignTo} 
                >
                  <option value="left">left</option>
                  <option value="center">center</option>
                  <option value="right">right</option>
                </select>
              </div>
            </div>
          </div> */}
          <div className="section-container open-form-section3">
            <h3>Open Form</h3>

            <div className="open-form-layout">
              <div className="left-side-fields">
                {/* Pre-filled message */}
                <div className="form-group">
                  <label className="chat-widget-form-label">Pre-filled message:</label>
                  <textarea
                    className="chat-widget-form-textarea"
                    value={prefilledMessage}
                    onChange={(e) => setPrefilledMessage(e.target.value)}
                    rows="4"
                  />
                  <div className="pre-filled-button-container">
                    <button
                      className="pre-filled-insert-button"
                      onClick={() => insertPlaceholder("{{page_link}}")}
                    >
                      Page link
                    </button>
                    <button
                      className="pre-filled-insert-button"
                      onClick={() => insertPlaceholder("{{page_title}}")}
                    >
                      Page title
                    </button>
                  </div>
                </div>

                <div className="form-group">
                  <label className="chat-widget-form-label">Welcome text:</label>
                  <input
                    type="text"
                    className="chat-widget-form-input"
                    value={welcomeText}
                    onChange={(e) => setWelcomeText(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label className="chat-widget-form-label">Font for welcome text:</label>
                  <select
                  className="chat-widget-form-select"
                  value={welcomeTextFont}
                  onChange={(e) => setWelcomeTextFont(e.target.value)}
                >
                  <option value="Arial" className="font-arial">Arial</option>
                  <option value="Courier New" className="font-courier">Courier New</option>
                  <option value="Georgia" className="font-georgia">Georgia</option>
                  <option value="Tahoma" className="font-tahoma">Tahoma</option>
                  <option value="Verdana" className="font-verdana">Verdana</option>
                  <option value="Trebuchet MS" className="font-trebuchet">Trebuchet MS</option>
                  <option value="Comic Sans MS" className="font-comic">Comic Sans MS</option>
                  <option value="Times New Roman" className="font-times">Times New Roman</option>
                  <option value="Lucida Console" className="font-lucida">Lucida Console</option>
                  <option value="Segoe UI" className="font-segoe">Segoe UI</option>
                  <option value="Outfit" className="font-outfit">Outfit</option>
                  <option value="Sour Gummy" className="font-sourgummy">Sour Gummy</option>
                </select>
                </div>

                <div className="form-group">
                  <label className="chat-widget-form-label">Text color for welcome text:</label>
                  <input
                    type="color"
                    className="chat-widget-form-input"
                    value={welcomeTextColor}
                    onChange={(e) => setWelcomeTextColor(e.target.value)}
                  />
                </div> 
              
              </div>

              <div className="right-side-field">
                <div className="form-group">
                  <label className="chat-widget-form-label">Open form button text:</label>
                  <input
                    type="text"
                    className="chat-widget-form-input"
                    value={openFormButtonText}
                    onChange={(e) => setOpenFormButtonText(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label className="chat-widget-form-label">Open form button font:</label>
                  <select
                  className="chat-widget-form-select"
                  value={openFormButtonFont}
                    onChange={(e) => setOpenFormButtonFont(e.target.value)}
                >
                  <option value="Arial" className="font-arial">Arial</option>
                  <option value="Courier New" className="font-courier">Courier New</option>
                  <option value="Georgia" className="font-georgia">Georgia</option>
                  <option value="Tahoma" className="font-tahoma">Tahoma</option>
                  <option value="Verdana" className="font-verdana">Verdana</option>
                  <option value="Trebuchet MS" className="font-trebuchet">Trebuchet MS</option>
                  <option value="Comic Sans MS" className="font-comic">Comic Sans MS</option>
                  <option value="Times New Roman" className="font-times">Times New Roman</option>
                  <option value="Lucida Console" className="font-lucida">Lucida Console</option>
                  <option value="Segoe UI" className="font-segoe">Segoe UI</option>
                  <option value="Outfit" className="font-outfit">Outfit</option>
                  <option value="Sour Gummy" className="font-sourgummy">Sour Gummy</option>
                </select>
                </div>

                <div className="form-group">
                  <label className="chat-widget-form-label">Open form button color:</label>
                  <input
                    type="color"
                    className="chat-widget-form-input"
                    value={openFormButtonColor}
                    onChange={(e) => setOpenFormButtonColor(e.target.value)}
                  />
                </div>
                
                <div className="form-group">
                  <label className="chat-widget-form-label">Open form button align to:</label>
                  <select
                    className="chat-widget-form-select"
                    onChange={(e) => setOpenFormButtonAlignTo(e.target.value)}
                    value={openFormButtonAlignTo}
                  >
                    <option value="left">left</option>
                    <option value="center">center</option>
                    <option value="right">right</option>
                  </select>
                </div>
              </div>
            </div>
          </div>


          {/* Section 4: Position and Settings */}
           {/* <div className="section-container">
            <h3>Position and Settings</h3>
            <div className="chat-widget-form-row">
              <div>
                <label className="chat-widget-form-label">Position (Left or Right):</label>
                <select
                  className="chat-widget-form-select"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                  required
                >
                  <option value="left">Left</option>
                  <option value="right">Right</option>
                </select>
              </div>
              <div>
                <label className="chat-widget-form-label">Phone number:</label>
                <input
                  type="text"
                  className="chat-widget-form-input"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </div>

              <div>
                <label className="chat-widget-form-label">Open new conversation by default:</label>
                <input
                  type="checkbox"
                  className="chat-widget-form-checkbox"
                  checked={openWidgetByDefault}
                  onChange={(e) => setOpenWidgetByDefault(e.target.checked)}
                />
              </div>
              <div className="chat-widget-preview-toggle">
                <label>Show preview:</label>
                <input
                  type="checkbox"
                  checked={preview}
                  onChange={togglePreview}
                />
              </div>
            </div>
          </div>  */}
            <div className="section-container position-settings-section">
              <h3>Position and Settings</h3>
              <div className="position-settings-layout">
                <div className="form-group">
                  <label className="chat-widget-form-label">Position (Left or Right):</label>
                  <select
                    className="chat-widget-form-select"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                    required
                  >
                    <option value="left">Left</option>
                    <option value="right">Right</option>
                  </select>
                </div>

                <div className="form-group">
                  <label className="chat-widget-form-label">Phone number:</label>
                  <input
                    type="text"
                    className="chat-widget-form-input"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                  />
                </div>

                <div className="checkboxRowCheckBox">
                    <div className="form-group">
                      <label className="chat-widget-form-label">Open new conversation by default:</label>
                      <input
                        type="checkbox"
                        className="circleCheckboxx"
                        checked={openWidgetByDefault}
                        onChange={(e) => setOpenWidgetByDefault(e.target.checked)}
                      />
                    </div>

                    <div className="form-group">
                      <label className="chat-widget-form-label">Show preview:</label>
                      <input
                        type="checkbox"
                        className="circleCheckboxx"
                        checked={preview}
                        onChange={togglePreview}
                      />
                    </div>
                  </div>


              </div>
            </div>



          {/* Submit and Generate Embed Code */}
          <div className="form-actions">
            <button type="submit" className="submit-widget-button">
              {isEdit ? 'Update Widget' : 'Create Widget'}
            </button>
            <button type="button" onClick={generateEmbedCode} className="generate-embed-button">
              Generate embed code
            </button>
          </div>
        </form>
      </div>

      {/* Embed Code Section */}
      {/* Embed Code Pop-up */}
      {showEmbedPopup && embedCode && (
        <div className="embed-popup">
          <div className="embed-popup-content">
            <h4>Embed code (Script)</h4>
            <textarea
              id="embedCodeTextarea"
              readOnly
              value={embedCode.iframeCode}
              rows="10"
              cols="50"
              style={{
                width: "100%",
                padding: "7px",
                border: "1px solid rgb(0, 0, 0)",
                borderRadius: "20px",
                fontFamily: "monospace",
                fontSize: "14px",
                lineHeight: "1.5",
              }}
            ></textarea>
            <button type="button" onClick={copyToClipboard} className="embedCodeTextarea-copy-button">
              Copy to clipboard
            </button>
            <button type="button" onClick={() => setShowEmbedPopup(false)} className="embedCodeTextarea-close-button">
              Close
            </button>
          </div>
        </div>
      )}


      {/* Preview Widget */}
      {preview && (
        <WabaChatWidget
          brandColor={brandColor}
          chatBubbleText={chatBubbleText}
          position={position}
          chatButton={chatButton}
          brandName={brandName}
          phoneNumber={phoneNumber}
          welcomeText={welcomeText}
          openWidgetByDefault={openWidgetByDefault}
          isOpen={isOpen}
          toggleWidget={toggleWidget}
          isMinimized={isMinimized}
          toggleMinimize={toggleMinimize}
          removeChatBubbleText={removeChatBubbleText} // Pass function to remove chat bubble text
          font={font}
          chatBubbleTextFont={chatBubbleTextFont} // Pass chat bubble text font
          welcomeTextFont={welcomeTextFont} // Pass welcome text font
          textColor={textColor} // Pass the common text color
          iconColor={iconColor}
          chatBubbleTextColor={chatBubbleTextColor} // Pass chat bubble text color
          welcomeTextColor={welcomeTextColor} // Pass welcome text color
          // Pass new parameters to WabaChatWidget component
          openFormButtonText={openFormButtonText}
          openFormButtonFont={openFormButtonFont}
          openFormButtonColor={openFormButtonColor}
          openFormButtonTextColor={openFormButtonTextColor}
          openFormButtonAlignTo={openFormButtonAlignTo}
          openFormBodyBackgroundColor={openFormBodyBackgroundColor}  // New prop
          openFormBodyBorderLineColor={openFormBodyBorderLineColor}  // New prop
          preview={preview} 
          prefilledMessage={prefilledMessage}
        />
      )}

    </div>
  );
};

export default ChatWidgetForm;
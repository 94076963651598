import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Users.css';
import { IoAddCircleOutline, IoTrashOutline } from 'react-icons/io5';
import { MdOutlineModeEdit } from 'react-icons/md';
import ConfirmationDialog from '../General/ConfirmationDialog';

const Users = ({ currentUser }) => {
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetAllUsersByOrganizationAsync_RegularUsers', {
                    organization: currentUser?.organization
                });
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        if (currentUser?.organization) {
            fetchUsers();
        }
    }, [currentUser?.organization]);

    const handleEditClick = (user) => {
        navigate(`/UserForm/?userId=${user.uID}`);
    };

    const handleDeleteClick = (user) => {
        console.log("Selected User for Deletion: ", user); // Check the user object
        setSelectedUser(user);  // Ensure that `user` is passed correctly
        setOpenDialog(true);
    };
    
    const handleConfirmDelete = () => {
        if (selectedUser) {
            const payload = {
                organization: currentUser?.organization,
                userId: selectedUser.uID
            };
    
            axios.post('https://gambot.azurewebsites.net/api/Webhooks/deleteUser', payload)  // Ensure the correct endpoint
                .then((response) => {
                    if (response.data.Success) {
                        setUsers(prevList => prevList.filter(item => item.uID !== selectedUser.uID));
                        setOpenDialog(false);  // Close the dialog on success
                        alert('User deleted successfully');
                    } else {
                        alert('Failed to delete the user. Please try again.');
                    }
                })
                .catch((error) => {
                    console.error('Error deleting user:', error);
                    alert('An error occurred while deleting the user. Please try again later.');
                });
        } else {
            console.log("Selected user is null.");
            alert("User not selected. Please try again.");
        }
    };
    
    

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredUsers = users.filter((user) =>
        (user.FullName || '').toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="users-list-container">
            <h1 className="users-list-header">Users List</h1>
            <div className="users-search-bar">
                <input
                    type="text"
                    placeholder="Search Users by Name"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className='users-search-bar-input'
                />
            </div>

            <div className="users-action-buttons">
                <button className="add-new-user-button" onClick={() => navigate('/UserForm')}>
                    Add New User <IoAddCircleOutline />
                </button>
            </div>

            <div className="users-list-table-container">
                <table className="users-list-table">
                    <thead>
                        <tr>
                            <th>Full Name</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredUsers.map((user) => (
                            <tr key={user.uID}>
                                <td>{user.FullName}</td>
                                <td>{user.Email}</td>
                                <td>{user.Status}</td>
                                <td>
                                    <button className="user-edit-button" onClick={() => handleEditClick(user)}>
                                        <MdOutlineModeEdit />
                                    </button>
                                    <button className="user-delete-button" onClick={() => handleDeleteClick(user)}>
                                        <IoTrashOutline />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <ConfirmationDialog
                open={openDialog}
                onClose={handleCloseDialog}
                onConfirm={handleConfirmDelete}
            />
        </div>
    );
};

export default Users;
